import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { isLayerValid, SurveyProvider } from '../context/SurveyContext';
import { SURVEY_LAYERS } from '../constants/SurveyLayers';
import {
  setClassificationsEnabled,
  setSelectedLayer,
  setVariationsEnabled,
} from '../reducers/SurveyReducer';
import {
  Action,
  useActionSnackbarContext,
} from '../components/ActionSnackbarHandler/ActionSnackbarHandler';
import { useLangFile } from '../context/LanguageContext';

const mapStoreToProps = (store) => ({
  showSoilSurveys: store.survey.showSoilSurveys,
  variationsEnabled: store.survey.variationsEnabled,
  classificationsEnabled: store.survey.classificationsEnabled,
  surveys: store.survey.surveys,
  selectedLayer: store.survey.selectedLayer,
  selectedSurvey: store.survey.selectedSurvey,
  surveyViewCapabilities: store.survey.surveyViewCapabilities,
  viewMode: store.control.viewMode,
});

export const validateLayerOrGetValidConfiguration = (
  layer,
  survey,
  viewMode,
  surveyViewCapabilities,
  classificationsEnabled,
  variationsEnabled
) => {
  let isSelectedLayerAllowed = isLayerValid(
    layer,
    survey,
    viewMode,
    surveyViewCapabilities,
    classificationsEnabled,
    variationsEnabled
  );

  if (isSelectedLayerAllowed === undefined || isSelectedLayerAllowed === true) {
    return true;
  }

  if (!isSelectedLayerAllowed) {
    if (!classificationsEnabled && !variationsEnabled) {
      if (isLayerValid(layer, survey, viewMode, surveyViewCapabilities, false, true)) {
        return {
          variationsEnabled: true,
          classificationsEnabled: false,
          raw: false,
        };
      }

      if (isLayerValid(layer, survey, viewMode, surveyViewCapabilities, true, false)) {
        return {
          variationsEnabled: false,
          classificationsEnabled: true,
          raw: false,
        };
      }
    } else {
      if (isLayerValid(layer, survey, viewMode, surveyViewCapabilities, false, false)) {
        return {
          variationsEnabled: false,
          classificationsEnabled: false,
          raw: true,
        };
      }
    }
  }

  return false;
};

const SurveyContainer = ({ dispatch, ...props }) => {
  const LangFile = useLangFile();
  const { addAction } = useActionSnackbarContext();

  useEffect(() => {
    if (!props.showSoilSurveys || !props.selectedLayer) {
      return;
    }

    let validConfig = validateLayerOrGetValidConfiguration(
      props.selectedLayer,
      props.selectedSurvey,
      props.viewMode,
      props.surveyViewCapabilities,
      props.classificationsEnabled,
      props.variationsEnabled
    );

    if (validConfig === true) {
      return;
    } else if (validConfig === false) {
      // Find another layer to show
      let layers = Object.keys(SURVEY_LAYERS);
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];

        if (
          isLayerValid(
            layer,
            props.selectedSurvey,
            props.viewMode,
            props.surveyViewCapabilities,
            props.classificationsEnabled,
            props.variationsEnabled
          )
        ) {
          dispatch(setSelectedLayer(layer));
          addAction(
            new Action('layer-reset', LangFile.SurveyContainer.layerReset, 'info', 'filled')
          );
          break;
        }
      }
    } else {
      let { variationsEnabled, classificationsEnabled, raw } = validConfig;

      if (variationsEnabled || classificationsEnabled || raw) {
        dispatch(setVariationsEnabled(variationsEnabled));
        dispatch(setClassificationsEnabled(classificationsEnabled));
      }
    }
  }, [
    props.selectedLayer,
    props.selectedSurvey,
    props.viewMode,
    props.showSoilSurveys,
    props.classificationsEnabled,
    props.variationsEnabled,
    props.surveyViewCapabilities,
  ]);

  return <SurveyProvider value={props}>{props.children}</SurveyProvider>;
};

export default memo(connect(mapStoreToProps)(SurveyContainer));
