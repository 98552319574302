import useEvent from 'js/hooks/useEvent';
import { useCallback, useState } from 'react';

const getDimensions = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

const useWindowDimensions = () => {
  const [state, setState] = useState(getDimensions());

  const callback = useCallback(() => {
    setState(getDimensions());
  }, []);

  useEvent('resize', callback);

  return state;
};

export default useWindowDimensions;
