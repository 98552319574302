import React, { useContext } from 'react';

const DataLayerContext = React.createContext({});

export const DataLayerProvider = DataLayerContext.Provider;
export const DataLayerConsumer = DataLayerContext.Consumer;

export function useDataLayer() {
  return useContext(DataLayerContext);
}
