import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Box, Paper } from '@material-ui/core';

import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { useLangFile, useLanguage } from 'js/context/LanguageContext';

import { DATE_FORMAT_LONG_DANISH, DATE_FORMAT_LONG_ENGLISH } from 'js/reducers/LanguageReducer';

const DateTimePicker = ({
  date,
  setDate,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  minutesStep,
}) => {
  const LangFile = useLangFile();
  const language = useLanguage();

  const dateFormat =
    language === 'da' ? DATE_FORMAT_LONG_DANISH.picker : DATE_FORMAT_LONG_ENGLISH.picker;

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Box pr={1}>
        <Paper elevation={2}>
          <KeyboardDatePicker
            margin={'normal'}
            label={LangFile.DateTimePicker.date}
            variant={'static'}
            inputVariant={'outlined'}
            format={dateFormat}
            value={date}
            onChange={setDate}
            maxDate={maxDate}
            maxDateMessage={maxDateMessage}
            minDate={minDate}
            minDateMessage={minDateMessage}
            invalidDateMessage={LangFile.DateTimePicker.invalidDateMessage}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Paper>
      </Box>
      <Box>
        <Paper elevation={2}>
          <KeyboardTimePicker
            margin="normal"
            label={LangFile.DateTimePicker.time}
            ampm={false}
            variant={'static'}
            inputVariant="outlined"
            minutesStep={minutesStep}
            maxDate={maxDate}
            maxDateMessage={maxDateMessage}
            minDate={minDate}
            minDateMessage={minDateMessage}
            value={date}
            onChange={setDate}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Paper>
      </Box>
    </Box>
  );
};

DateTimePicker.propTypes = {
  date: PropTypes.object,
  setDate: PropTypes.func,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  minDateMessage: PropTypes.string,
  maxDateMessage: PropTypes.string,
  minutesStep: PropTypes.number,
};

DateTimePicker.defaultProps = {
  setDate: () => {},
  minutesStep: 1,
};

export default memo(DateTimePicker);
