import React, { Fragment, memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Paper, Portal } from '@material-ui/core';
import MapCanvasMiniature from './MapCanvasMiniature';
import { SATELLITE_LAYERS } from 'js/constants/SatelliteLayers';
import AnalysisMapView from 'js/components/AnalysisMapView/AnalysisMapView';
import { getLayerConfig } from 'js/components/Prescription/PrescriptionUtils';
import { ClearRounded } from '@material-ui/icons';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useLangFile } from 'js/context/LanguageContext';
import { getTooltip } from './MapCanvasTooltip';

const SIZE = 180;
const PADDING = 8;

import Styles from './MapCanvasMiniature.module.less';
import { useGoogleMapDOM } from 'js/context/GoogleMapContext';
import ManualFeatureConstants from 'js/constants/ManualFeatureConstants';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import { isSatelliteLayer, isSurveyLayer, isYieldLayer } from '../../Prescription/PrescriptionJob';
import { usePrescriptionJob } from '../../Prescription/PrescriptionJobContext';
import { validateLayerOrGetValidConfiguration } from '../../../datacontainers/SurveyContainer';
import { useSurveyContext, useSurveyLayerViewCapabilities } from '../../../context/SurveyContext';
import { getDemandTooltip } from './MapCanvasDemandTooltip';
import { YIELD_LAYERS } from '../../../constants/YieldLayer';
import { getLayerCategory } from '../../../helpers/SurveyUtils';

const getMaxHeight = (googleMapRef, legendRef) => {
  let maxHeight = null;
  let padding = 24;

  if (googleMapRef && legendRef) {
    let mH = googleMapRef.getBoundingClientRect().height;
    let pH = legendRef.previousSibling.getBoundingClientRect().height;

    maxHeight = mH - pH - padding;
  }

  return maxHeight;
};

const MapCanvasMiniatureContainer = (props: MapCanvasMiniatureContainer.propTypes) => {
  const googleMapRef = useGoogleMapDOM();

  const LangFile = useLangFile();
  const layerCapabilities = useSurveyLayerViewCapabilities(
    props.selectedLayer,
    ViewModeConstants.PRESCRIPTION
  );
  const [target, setTarget] = useState(null);
  const [hovered, setHovered] = useState(null);

  const { surveyViewCapabilities, selectedSurvey } = useSurveyContext();
  const { prescriptionJob } = usePrescriptionJob();

  const handleRemove = useCallback(
    (layer) => {
      let result = props.multipleSelections.filter((item) => item !== layer);
      props.onMultipleSelectChanged(result);
    },
    [props.multipleSelections, props.onMultipleSelectChanged]
  );

  const maxHeight = getMaxHeight(googleMapRef, props.legendRef);

  return (
    <Fragment>
      {ManualFeatureConstants.PRESCRIPTION_MINIATURES &&
        props.legendRef &&
        props.multipleSelections.length > 0 && (
          <Portal container={props.legendRef}>
            <Box
              mt={1}
              className={Styles.Root}
              width={SIZE}
              maxHeight={`${maxHeight}px`}
              maxWidth={SIZE + 2 * PADDING + 24}>
              <Paper className={Styles.Paper} elevation={2} style={{ padding: PADDING }}>
                {props.multipleSelections.map((layer, idx, arr) => {
                  let config = getLayerConfig(layer);
                  let tooltip =
                    props.classificationsEnabled && layerCapabilities.enableCustomClassification
                      ? getDemandTooltip(
                          layer,
                          props.values,
                          target,
                          props.classificationValues,
                          props.classificationsEnabled,
                          props.variationsEnabled,
                          LangFile
                        )
                      : getTooltip(
                          layer,
                          props.values,
                          target,
                          props.classificationsEnabled,
                          props.variationsEnabled,
                          LangFile
                        );
                  let error = false;

                  const showTargetAndValues =
                    getLayerCategory(props.selectedLayer) == getLayerCategory(layer);

                  let url;
                  if (isSatelliteLayer(layer)) {
                    url = prescriptionJob.assets[layer];
                  } else if (isSurveyLayer(layer)) {
                    let validConfig = validateLayerOrGetValidConfiguration(
                      layer,
                      selectedSurvey,
                      props.viewMode,
                      surveyViewCapabilities,
                      props.classificationsEnabled,
                      props.variationsEnabled
                    );
                    if (validConfig === true) {
                      url = config.getImageUrl(
                        prescriptionJob.assets,
                        props.classificationsEnabled,
                        props.variationsEnabled,
                        ViewModeConstants.PRESCRIPTION
                      );
                    } else if (validConfig !== false) {
                      url = config.getImageUrl(
                        prescriptionJob.assets,
                        validConfig.classificationsEnabled,
                        validConfig.variationsEnabled,
                        ViewModeConstants.PRESCRIPTION
                      );
                    } else {
                      error = true;
                    }
                  } else if (isYieldLayer(layer)) {
                    const yieldAssets = [...prescriptionJob.assets[layer].yieldImages];
                    const yieldSortedByDate = yieldAssets.sort((a, b) => {
                      return new Date(b.date) - new Date(a.date);
                    });
                    url = yieldSortedByDate[0].url;
                  } else {
                    return null;
                  }

                  let hideTooltipValue = layer === SATELLITE_LAYERS.VISIBLE;

                  return (
                    <Box position={'relative'} key={layer} maxWidth={SIZE} mt={idx === 0 ? 0 : 1}>
                      <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <Box
                          minHeight={'24px'}
                          fontSize={'0.75rem'}
                          lineHeight={'0.75rem'}
                          width={'100%'}
                          display={'inline-flex'}
                          flexWrap={'nowrap'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          onMouseEnter={() => setHovered(layer)}
                          onMouseLeave={() => setHovered(null)}>
                          {hovered === layer && (
                            <ButtonBase
                              className={Styles.CloseButton}
                              focusRipple={true}
                              onClick={() => handleRemove(layer)}>
                              <ClearRounded fontSize={'small'} />

                              {LangFile.MapCanvasMiniature.hide}
                            </ButtonBase>
                          )}

                          {hovered !== layer && (
                            <Box
                              pr={1}
                              maxWidth={'60%'}
                              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                              {tooltip.text}
                            </Box>
                          )}

                          {hovered !== layer && showTargetAndValues && (
                            <Box
                              pl={1}
                              style={{ whiteSpace: 'nowrap' }}
                              height={'100%'}
                              alignItems={'center'}
                              display={'flex'}>
                              {target && !hideTooltipValue && (
                                <span>
                                  {' '}
                                  {tooltip.value}
                                  {tooltip.unit}
                                </span>
                              )}

                              <span
                                style={{
                                  paddingLeft: 4,
                                  display: tooltip.icon ? 'initial' : 'none',
                                }}
                              />

                              {props.classificationsEnabled && tooltip.icon}
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Box maxHeight={SIZE} width={'100%'} my={1}>
                        <MapCanvasMiniature
                          error={error}
                          imageURL={url}
                          target={target}
                          maxHeight={SIZE - PADDING * 2}
                          maxWidth={SIZE - PADDING * 2}
                          showTarget={showTargetAndValues}
                        />
                      </Box>

                      {idx < arr.length - 1 && <Divider />}
                    </Box>
                  );
                })}
              </Paper>
            </Box>
          </Portal>
        )}

      <AnalysisMapView
        viewMode={props.viewMode}
        imageURL={props.imageURL}
        selectedField={props.field}
        selectedLayer={props.selectedLayer}
        multipleSelections={props.multipleSelections}
        classificationsEnabled={props.classificationsEnabled}
        variationsEnabled={props.variationsEnabled}
        enableTooltip={props.selectedLayer !== SATELLITE_LAYERS.VISIBLE && !props.isOverriding}
        onTargetChanged={setTarget}
        clickable={false}
        primaryValues={props.values[props.selectedLayer]}
        values={props.values}
        classificationValues={props.classificationValues}
      />
    </Fragment>
  );
};

MapCanvasMiniatureContainer.propTypes = {
  legendRef: PropTypes.any,
  viewMode: PropTypes.string,
  imageURL: PropTypes.string,
  field: PropTypes.object,
  values: PropTypes.object,
  fieldImages: PropTypes.object,
  selectedLayer: PropTypes.string,
  multipleSelections: PropTypes.array,
  classificationsEnabled: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  isOverriding: PropTypes.bool,
  onMultipleSelectChanged: PropTypes.func,
  classificationValues: PropTypes.object,
};

export default memo(MapCanvasMiniatureContainer);
