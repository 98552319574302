'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect className="a" fill={'none'} width="16" height="16" />
      <g transform="translate(3.513 1.847)">
        <path
          d="M6.282,14.829A1.286,1.286,0,0,0,5,16.111h8.974a1.286,1.286,0,0,0-1.282-1.282H10.128V13.547h1.923a1.286,1.286,0,0,0,1.282-1.282H8.205A1.917,1.917,0,0,1,7.218,8.7a1.584,1.584,0,0,1-.295-.923,1.653,1.653,0,0,1,.058-.4,3.2,3.2,0,0,0,1.224,6.167v1.282Z"
          transform="translate(-5 -3.931)"
        />
        <path
          d="M10.359,4.25a1.595,1.595,0,0,1,1.019,2.795l.378,1.038.6-.218.218.6,1.205-.436-.218-.6.6-.218L12.41,2.385l-.6.218L11.59,2l-1.205.436.218.6-.6.224Z"
          transform="translate(-6.795 -2)"
        />
        <circle cx="0.962" cy="0.962" r="0.962" transform="translate(2.564 2.885)" />
      </g>
    </SvgIcon>
  );
};
