import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { distance, lineIntersect, lineString, point } from '@turf/turf';
import ConfirmActionPopover from 'js/components/ConfirmPopovers/ConfirmActionPopover';
import MapPolygon from 'js/components/MapObjects/MapPolygon';
import MapMarker from 'js/components/MapObjects/MapMarker';
import PolygonToolbar from 'js/components/PolygonDrawing/PolygonToolbar';
import areaCalculation from 'js/algorithms/geometry/AreaCalculation';
import polygonSvgEdit from 'style/images/drawing/edit_location.svg';
import polygonSvg from 'style/images/drawing/polygon-marker.svg';
import deleteLocationMarker from 'style/images/drawing/delete-location.svg';
import Slider from '@material-ui/core/Slider';
import magic from 'js/components/PolygonDrawing/MagicSelection';
import Polygons from 'js/algorithms/geometry/Polygons';
import debounce from 'lodash.debounce';
import { voidFunc } from 'js/constants/PropTypeUtils';
import { Box } from '@material-ui/core';

export default class PolygonDrawing extends Component {
  constructor(props) {
    super(props);
    this.map = this.props.map;
    this.addMarkerListener = null;
    this.polyline = null;

    this.state = {
      markers: [],
      polygons: [],
      actions: [],
      acIndex: -1,
      selectedMarker: null,
      drawingMode: 'NOTE',
      polygonVisible: false,
      cutMarkers: [],
      isSlicingPolygon: false,
      isMergingPolygon: false,
      size: 0,
      changingThreshold: this.props.magicWand ? this.props.magicWand.threshold : 7,
    };
  }

  setDrawingStateAndStartDrawing() {
    if (this.props.isDrawingNote) {
      this.setState({ drawingMode: 'NOTE' });
      this.startDrawing();
    } else if (this.props.isDrawingField) {
      this.setState({ drawingMode: 'FIELD' });
      this.startDrawing();
    } else if (this.props.isPlacingMarker) {
      this.setState({ drawingMode: 'MARKER' });
      this.startDrawing();
    } else if (this.props.magicWand.isSelecting) {
      this.setState({ drawingMode: 'SELECT' }, () => this.startDrawing());
    }
  }

  componentDidMount() {
    this.setDrawingStateAndStartDrawing();
  }

  componentDidUpdate(prev) {
    let doMagic = prev.magicWand.targetLatLng !== this.props.magicWand.targetLatLng;

    if (doMagic) {
      this.drawMagicSelection(this.props.magicWand);
    } else if (prev.drawnPolygon !== this.props.drawnPolygon) {
      const polygon = [{ valid: true, path: this.props.drawnPolygon }];

      const actions = [];
      const action = [...polygon];

      actions.push(action);
      this.setState({ polygons: action, polygonVisible: true, actions: actions, acIndex: 0 });
    }
  }

  drawMagicSelection = async (magicWand) => {
    let { fieldImage, surveys, selectedLayer, showSoilSurveys, selectedField } = this.props;
    let { actions, acIndex } = this.state;

    let newActions = [...actions];
    let imageUrl = fieldImage.url;

    if (showSoilSurveys) {
      let survey = surveys.find((survey) => survey.fieldId === selectedField.fieldId);
      imageUrl = survey.images[selectedLayer];
    }

    let { targetLatLng, field, isSelecting, threshold } = magicWand;

    if (!field || !targetLatLng || !isSelecting) {
      return null;
    }

    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(field.bounds.south, field.bounds.west),
      new google.maps.LatLng(field.bounds.north, field.bounds.east)
    );

    try {
      const selection = await magic(bounds, field.polygon, imageUrl, threshold, targetLatLng);
      let polygon = [{ valid: true, path: selection }];

      const action = [...polygon];

      if (acIndex < newActions.length - 1) {
        newActions = [];
      }

      newActions.push(action);

      this.setState({
        polygons: action,
        polygonVisible: true,
        actions: newActions,
        acIndex: newActions.length - 1,
      });
    } catch (e) {
      return null;
    }
  };

  startDrawing = () => {
    // Init map
    this.map = this.props.map;
    if (this.state.drawingMode === 'SELECT') {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        markerOptions: {
          visible: false,
        },
        map: this.map,
      });
    } else {
      /* init drawing manager */
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        markerOptions: {
          visible: false,
        },
        map: this.map,
      });
      /* add listener for adding markers on map */
      this.addMarkerListener = google.maps.event.addListener(
        this.drawingManager,
        'markercomplete',
        this.markerCompleteCallback
      );
    }
  };

  stopDrawing = () => {
    this.resetHandler();
    this.drawingManager.setMap(null);
    // Dispatch
    this.props.setIsMagicSelecting(false);
    this.props.setIsDrawingNote(false);
    this.props.setIsDrawingField(false);
    this.props.setIsPlacingMarker(false);
  };

  stopSlicing = () => {
    const { cutMarkers } = this.state;
    cutMarkers.splice(0, cutMarkers.length);
    this.drawingManager.setDrawingMode(null);
    if (this.polyline) {
      this.polyline.setMap(null);
    }
    google.maps.event.removeListener(this.addMarkerListener);
    this.setState({ cutMarkers, isSlicingPolygon: false });
  };

  shouldMarkerSnapToFinish = (marker) => {
    let { markers } = this.state;

    const point1 = point([marker.position.lng(), marker.position.lat()]);

    const point2 = point([markers[0].position.lng(), markers[0].position.lat()]);

    return (
      distance(point1, point2, { units: 'kilometers' }) <
      Polygons.getSnapDistance(this.map.getZoom())
    );
  };

  markerCompleteCallback = (marker) => {
    if (this.state.drawingMode === 'MARKER') {
      this.props.onMarkerPlaced(marker);
      this.stopDrawing();
    } else {
      // Remove marker, we want the marker to be drawn on the map using Mapmarker

      marker.setMap(null);

      let { markers, actions, acIndex } = this.state;

      let finished = false;
      if (markers.length > 2) {
        /* Check if we are within reach of the first marker */
        if (this.shouldMarkerSnapToFinish(marker)) {
          finished = true;
        }
      }
      if (finished) {
        const { polygons } = this.state;
        this.polyline.setMap(null);
        this.removeAddMarkerListener();
        const newPath = markers.map((marker) => marker.position);
        polygons.splice(0, polygons.length);
        const newPolygon = { path: newPath };
        actions.push([newPolygon]);
        polygons.push(newPolygon);
        this.setState({ polygons, polygonVisible: true, actions, acIndex: actions.length - 1 });
      } else {
        const newMarker = { position: marker.position };
        markers.push(newMarker);
        if (acIndex !== -1) {
          actions.splice(acIndex + 1);
        }
        const mPolygons = [{ path: [...markers] }];
        actions.push(mPolygons);
        acIndex = actions.length - 1;
        this.setState({ markers, actions, acIndex });
        this.drawPolyline();
      }
    }
  };

  /* Parameter determines whether it's a standard polyline or editable polyline for slicing a polygon */
  drawPolyline = (editable) => {
    const { markers, cutMarkers } = this.state;
    if (this.polyline != null) {
      this.polyline.setMap(null);
    }
    const markersToMap = editable ? cutMarkers : markers;

    if (markersToMap) {
      this.polyline = new google.maps.Polyline({
        path: markersToMap
          .filter((p) => p.position)
          .map((poly) => new google.maps.LatLng(poly.position.lat(), poly.position.lng())),
        fillColor: editable ? 'black' : 'white',
        fillOpacity: 0.2,
        strokeColor: editable ? 'black' : 'white',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        map: this.map,
        editable: editable,
      });
    }
  };

  removeAddMarkerListener = () => {
    google.maps.event.removeListener(this.addMarkerListener);
    this.drawingManager.setDrawingMode(null);
  };

  deleteHandler = () => {
    const { actions, polygons, selectedMarker } = this.state;

    // Find out what array in path the selectedMarker is
    let selectedMarkerIndex = 0;
    polygons.forEach((o, index) =>
      o.path.find((i) => {
        if (i == selectedMarker.position) {
          selectedMarkerIndex = index;
        }
      })
    );
    if (!(polygons[selectedMarkerIndex].path.length > 3 && selectedMarker)) {
      return;
    }
    const newArr = polygons.map((outer, index) => {
      const nested = { ...outer, path: [...outer.path] };
      if (index == selectedMarkerIndex) {
        const spliceIndex = nested.path.findIndex((o) => {
          if (
            JSON.stringify(o.lat() + o.lng()) ===
            JSON.stringify(selectedMarker.position.lat() + selectedMarker.position.lng())
          ) {
            return o;
          }
        });
        nested.path.splice(spliceIndex, 1);
      }
      return nested;
    });

    actions.push(newArr);

    this.setState({
      polygons: newArr,
      actions,
      acIndex: actions.length - 1,
      selectedMarker: null,
    });
  };

  onInsert = (index, newPath, pindex) => {
    let { actions, selectedMarker, polygons } = this.state;

    /* Immutable array traversal */
    const modifiedPath = polygons.map((outer, index) =>
      index == pindex ? { ...outer, path: newPath } : { ...outer }
    );

    if (selectedMarker != null) {
      selectedMarker.position = modifiedPath[pindex].path[index];
    } else {
      selectedMarker = { position: modifiedPath[pindex].path[index] };
    }
    actions.push(modifiedPath);
    this.setState({
      actions,
      acIndex: actions.length - 1,
      polygons: modifiedPath,
      selectedMarker,
    });
  };

  onMove = (index, point, newPath, pindex, selectedMarker) => {
    const { actions, acIndex, polygons } = this.state;
    actions.splice(acIndex + 1);

    /* Immutable array traversal */
    const modifiedPath = polygons.map((outer, index) =>
      index == pindex ? { ...outer, path: newPath } : { ...outer }
    );
    actions.push(modifiedPath);

    if (selectedMarker) {
      selectedMarker.position = modifiedPath[pindex].path[index];
    }

    this.setState({
      actions: actions,
      acIndex: actions.length - 1,
      polygons: modifiedPath,
      selectedMarker,
    });
  };

  undoHandler = () => {
    let { actions, acIndex, polygons, polygonVisible } = this.state;

    if (acIndex >= 0) {
      acIndex = acIndex - 1;
      const action = actions[acIndex];

      let markers = action ? action[0].path : null;
      let valid = action ? action[0].hasOwnProperty('valid') : false;

      if (!polygonVisible) {
        this.setState({ markers: markers }, () => this.drawPolyline());
      } else if (polygons[0] && !valid) {
        this.setState({ polygonVisible: false });

        if (!this.props.magicWand.isSelecting) {
          this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
          this.addMarkerListener = google.maps.event.addListener(
            this.drawingManager,
            'markercomplete',
            this.markerCompleteCallback
          );
        }

        this.drawPolyline();
      } else {
        polygons = action;

        if (this.props.magicWand.isSelecting) {
          this.drawingManager.setDrawingMode(null);
        }
      }

      this.setState({ acIndex, actions, polygons, selectedMarker: null });
    }
  };

  redoHandler = () => {
    let { actions, acIndex, polygons, polygonVisible } = this.state;
    if (acIndex + 1 !== actions.length) {
      acIndex = acIndex + 1;
      const action = actions[acIndex];
      if (!polygonVisible && !action[0].hasOwnProperty('valid')) {
        this.setState({ markers: action[0].path }, () => this.drawPolyline());
      } else if (!polygonVisible) {
        this.setState({ polygonVisible: true });
        this.polyline.setMap(null);
        this.removeAddMarkerListener();
      } else {
        polygons = action;
      }
      this.setState({ acIndex, actions, polygons });
    }
  };

  resetHandler = () => {
    // Remove the line from the map
    if (this.polyline) {
      this.polyline.setMap(null);
    }

    // Reset all variables for map
    this.setState({
      actions: [],
      acIndex: -1,
      markers: [],
      polygonVisible: false,
      selectedMarker: false,
      polygons: [],
      size: 0,
    });

    // Remove listeners
    this.drawingManager.setDrawingMode(null);
    google.maps.event.removeListener(this.addMarkerListener);

    // Restart
    this.setDrawingStateAndStartDrawing();
  };

  toggleIsSlicing = () => {
    if (!this.state.isSlicingPolygon) {
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      this.addMarkerListener = google.maps.event.addListener(
        this.drawingManager,
        'markercomplete',
        this.sliceListenerCallback
      );
      this.setState({ isSlicingPolygon: true });
    }
  };

  sliceListenerCallback = (marker) => {
    const { cutMarkers } = this.state;
    marker.setDraggable(true);
    cutMarkers.push(marker);
    marker.setMap(this.map);
    if (cutMarkers.length === 2) {
      this.drawPolyline(true);
      cutMarkers.splice(0, cutMarkers.length);
      this.drawingManager.setDrawingMode(null);
    }
    this.setState({ cutMarkers });
  };

  sliceHandler = () => {
    // Different integration of slicer, faster, avoids self-intersecting polygons
    const { polygons, actions } = this.state;
    const cutLine = this.polyline.getPath();
    const cutLineTurfArr = [];
    cutLine.forEach((line) => {
      let lat = line.lat();
      let lng = line.lng();
      cutLineTurfArr.push([lat, lng]);
    });
    const cutLineTurf = lineString(cutLineTurfArr);
    const newPolygons = [
      { path: [], valid: true },
      { path: [], valid: true },
    ];
    let error = false;
    let potentialError = false;
    let prev;
    let polyIndex = 0;
    polygons[0].path.forEach((point, index) => {
      if (index > 0) {
        const turfLine = lineString([
          [prev.lat(), prev.lng()],
          [point.lat(), point.lng()],
        ]);
        const intersect = lineIntersect(turfLine, cutLineTurf);
        if (intersect.features.length === 0) {
          newPolygons[polyIndex].path.push(point);
        } else {
          newPolygons[polyIndex].path.push(
            new google.maps.LatLng(
              intersect.features[0].geometry.coordinates[0],
              intersect.features[0].geometry.coordinates[1]
            )
          );
          polyIndex++;
          if (potentialError) {
            error = true;
          }
          if (polyIndex === 2) {
            polyIndex = 0;
            potentialError = true;
          }
          newPolygons[polyIndex].path.push(
            new google.maps.LatLng(
              intersect.features[0].geometry.coordinates[0],
              intersect.features[0].geometry.coordinates[1]
            )
          );
          newPolygons[polyIndex].path.push(point);
        }
      } else {
        newPolygons[polyIndex].path.push(point);
      }
      if (polyIndex === 1 && index === polygons[0].path.length - 1) {
        const turfLine = lineString([
          [point.lat(), point.lng()],
          [polygons[0].path[0].lat(), polygons[0].path[0]],
        ]);
        const intersect = lineIntersect(turfLine, cutLineTurf);
        if (intersect.features[0]) {
          newPolygons[0].path.push(
            new google.maps.LatLng(
              intersect.features[0].geometry.coordinates[0],
              intersect.features[0].geometry.coordinates[1]
            )
          );
          newPolygons[1].path.push(
            new google.maps.LatLng(
              intersect.features[0].geometry.coordinates[0],
              intersect.features[0].geometry.coordinates[1]
            )
          );
        } else {
          error = true;
        }
      }
      prev = point;
    });

    google.maps.event.removeListener(this.addMarkerListener);

    this.drawingManager.setDrawingMode(null);

    if (!error) {
      this.polyline.setMap(null);
      const nPolys = newPolygons.map((n) => ({ ...n }));
      actions.push(nPolys);
      this.setState({
        polygons: newPolygons,
        actions,
        isSlicingPolygon: false,
        acIndex: actions.length - 1,
      });
    } else {
      alert('Invalid line. Try again.');
    }
  };

  toggleIsMergingHandler = () => {
    this.setState({ isMergingPolygon: true });
  };

  setPolygonMerge = (index) => {
    const { polygons } = this.state;
    if (!polygons[index].merge) {
      polygons[index].merge = true;
      if (polygons.filter((p) => p.merge).length === 2) {
        this.mergeHandler();
      }
    } else {
      // Already Added
    }
  };

  mergeHandler = () => {
    const { polygons, actions } = this.state;
    const newPolygon = [];
    const points = [];
    for (let polygon of polygons) {
      if (polygon.merge) {
        newPolygon.push(polygon);
        polygon.path.forEach((p) => points.push(p));
      }
      if (newPolygon.length === 2) {
        break;
      }
    }

    const modifiedPath = polygons.filter((p) => p !== newPolygon[0] && p !== newPolygon[1]);
    const newPolygonPathMerged = {
      path: [...newPolygon[0].path, ...newPolygon[1].path],
      merge: false,
    };

    modifiedPath.push(newPolygonPathMerged);
    actions.push(modifiedPath);
    this.setState({
      isMergingPolygon: false,
      polygons: modifiedPath,
      actions,
      acIndex: actions.length - 1,
    });
  };

  setSelectedMarker = (marker) => {
    this.setState({ selectedMarker: marker });
  };

  onMouseEnter = () => {
    const { selectedMarker } = this.state;
    if (selectedMarker) {
      this.resizeIcon(2, selectedMarker);
    }
  };

  onMouseLeave = () => {
    const { selectedMarker } = this.state;
    if (selectedMarker) {
      this.resizeIcon(1, selectedMarker);
    }
  };

  resizeIcon = (scale, selectedMarker) => {
    const size = 24 * scale;

    selectedMarker.icon = {
      url: scale === 1 ? polygonSvgEdit : deleteLocationMarker,
      scaledSize: new google.maps.Size(size, size),
      anchor: new google.maps.Point(size / 2, size),
    };

    this.setSelectedMarker(selectedMarker);
  };

  onSubmit = () => {
    const { drawingMode, polygons } = this.state;

    if (polygons[0]) {
      const outline = polygons.map((p) => this.convertToArrayOfLatLngRealNumbers(p.path));

      if (drawingMode === 'NOTE' || drawingMode === 'SELECT') {
        for (let o of outline) {
          this.props.selectAndCreateNote(o);
        }
        this.stopDrawing();
      } else if (drawingMode === 'FIELD') {
        this.props.addDrawnField(outline[0]);
        this.stopDrawing();
      }
    }
  };

  convertToArrayOfLatLngRealNumbers(polygonPath) {
    let mappedArray = polygonPath.map((latLng) => {
      return { latitude: latLng.lat(), longitude: latLng.lng() };
    });

    // To finish the polygon we add the first point again
    let firstPoint = polygonPath[0];
    mappedArray.push({
      latitude: firstPoint.lat(),
      longitude: firstPoint.lng(),
    });

    return mappedArray;
  }

  setIsPolygonValid = (valid, pindex) => {
    const { polygons } = this.state;
    const totalSize = polygons.reduce((acc, polygon) => {
      let { size } = areaCalculation({ coords: polygon.path });
      return (acc += size);
    }, 0);
    polygons[pindex].valid = valid;

    this.setState({ polygons, size: totalSize });
  };

  shouldSubmitButtonDisable = () => {
    if (!this.state.polygonVisible) {
      return { disable: true, reason: '' };
    }

    const valid = this.state.polygons.every((polygon) => polygon.valid);

    if (!valid) {
      return { disable: true, reason: this.props.LangFile.PolygonDrawing.invalidPolygon };
    }

    if (this.state.drawingMode === 'FIELD') {
      if (Number(this.state.size) > 5000) {
        return { disable: true, reason: this.props.LangFile.PolygonDrawing.exceedsLimit };
      }
    }

    return { disable: false, reason: '' };
  };

  handleThresholdCommitted = debounce((value) => {
    this.props.onSetMagicWandThreshold(value);
  }, 250);

  handleThresholdChanged = (event, value) => {
    this.setState({ changingThreshold: value });
    this.handleThresholdCommitted(value);
  };

  renderMagicThresholdSlider = () => {
    if (!this.props.magicWand.isSelecting) {
      return null;
    }

    // The slider uses local state for UI feedback and only dispatches the change to store onDragStop to avoid lag.
    return (
      <Fragment>
        <Box fontWeight={300} pb={1} display={'flex'} justifyContent={'space-between'}>
          <span>{this.props.LangFile.PolygonDrawing.magicThresholdLabel}:</span>{' '}
          {this.state.changingThreshold}
        </Box>
        <Slider
          step={2}
          value={this.state.changingThreshold}
          min={1}
          max={50}
          onChange={this.handleThresholdChanged}
          style={{ padding: '10px 0' }}
        />
      </Fragment>
    );
  };

  renderMapPolygons = () => {
    if (this.state.polygonVisible) {
      return this.state.polygons
        .filter((p) => Array.isArray(p.path))
        .map((p, polygonIndex) => (
          <MapPolygon
            coordinates={p.path}
            map={this.map}
            strokeColor={'white'}
            strokeWeight={4}
            editable={true}
            fillColor={'#aaaaaa'}
            fillOpacity={0.2}
            onInsert={this.onInsert}
            onMove={this.onMove}
            isValid={p.valid}
            setIsPolygonValid={this.setIsPolygonValid}
            selectedMarker={this.state.selectedMarker}
            setPolygonMerge={this.setPolygonMerge}
            isMergingPolygon={this.state.isMergingPolygon}
            setSelectedMarker={this.setSelectedMarker}
            key={`pdc_${polygonIndex}`}
            pindex={polygonIndex}
            isMerged={p.merge}
          />
        ));
    } else {
      return null;
    }
  };

  renderCutMarkers = (iconOptions) => {
    if (this.state.polygonVisible && this.state.cutMarkers.length > 0) {
      return this.state.cutMarkers.map((m, idx) => (
        <MapMarker
          position={m.position}
          icon={iconOptions}
          key={JSON.stringify(m.position) + idx}
          draggable={m.draggable}
          optimized={false}
        />
      ));
    } else {
      return null;
    }
  };

  renderPolygonMapMarkers = (iconOptions) => {
    if (!this.state.polygonVisible && Array.isArray(this.state.markers)) {
      return this.state.markers
        .filter((m) => Boolean(m.position))
        .map((m, idx) => (
          <MapMarker
            position={m.position}
            icon={iconOptions}
            key={JSON.stringify(m.position) + idx}
          />
        ));
    } else {
      return null;
    }
  };

  renderSelectedMarker = () => {
    let marker = this.state.selectedMarker;

    if (marker) {
      const selectedMarkerIconOptions = {
        url: polygonSvgEdit,
      };

      return (
        <MapMarker
          position={marker.position}
          icon={marker.icon ? marker.icon : selectedMarkerIconOptions}
        />
      );
    } else {
      return null;
    }
  };

  renderPolygonToolbar = () => {
    const { markers, polygons, selectedMarker, acIndex, actions, isSlicingPolygon } = this.state;

    if (this.props.controls) {
      return (
        <PolygonToolbar
          acIndex={acIndex}
          selectedMarker={selectedMarker}
          deleteHandler={this.deleteHandler}
          undoHandler={this.undoHandler}
          redoHandler={this.redoHandler}
          resetHandler={this.resetHandler}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          polygons={polygons}
          actions={actions}
          markers={markers}
          isMagicSelecting={this.props.magicWand.isSelecting}
          toggleIsSlicing={this.toggleIsSlicing}
          isSlicingPolygon={isSlicingPolygon}
          toggleIsMergingHandler={this.toggleIsMergingHandler}
          isMergingPolygon={this.state.isMergingPolygon}
          LangFile={this.props.LangFile}
        />
      );
    } else {
      return null;
    }
  };

  renderSlicingPopover = () => {
    return (
      <ConfirmActionPopover
        shown={this.state.isSlicingPolygon}
        title={'Slice Polygon'}
        message={this.props.LangFile.PolygonDrawing.drawPolygon}
        actionText={'Slice'}
        disabled={false}
        action={this.sliceHandler}
        cancelText={this.props.LangFile.PolygonDrawing.cancel}
        cancelAction={this.stopSlicing}
        style={{ position: this.props.staticBox ? 'static' : 'fixed', marginTop: '30px' }}
      />
    );
  };

  renderDrawingPopover = () => {
    if (!this.props.popup) {
      return null;
    }

    let submitButtonDisable = this.shouldSubmitButtonDisable();

    let status = '';
    let hectaresToAdd = this.state.size.toFixed(1);

    if (this.state.drawingMode === 'FIELD') {
      let hectaresClass =
        this.state.size > this.props.subscriptionLimit - this.props.currentHectare
          ? 'invalid'
          : 'valid';

      status = (
        <Box fontWeight={300}>
          <Box className={hectaresClass} display={'flex'} justifyContent={'space-between'}>
            {this.props.LangFile.PolygonDrawing.selectedHectares} <span>{hectaresToAdd} ha</span>
          </Box>
        </Box>
      );
    } else if (this.state.drawingMode === 'NOTE' || this.state.drawingMode === 'SELECT') {
      status = (
        <Box fontWeight={300}>
          <Box className={'valid'} display={'flex'} justifyContent={'space-between'}>
            {' '}
            {this.props.LangFile.PolygonDrawing.area}: <span>{hectaresToAdd} ha</span>
          </Box>
        </Box>
      );
    }

    return (
      <ConfirmActionPopover
        shown={
          (this.props.isDrawingNote ||
            this.props.isPlacingMarker ||
            this.props.isDrawingField ||
            this.props.magicWand.isSelecting) &&
          !this.state.isSlicingPolygon
        }
        title={
          (this.props.magicWand.isSelecting && this.props.LangFile.PolygonDrawing.drawNote) ||
          (this.props.isDrawingField && this.props.LangFile.PolygonDrawing.drawField) ||
          (this.props.isDrawingNote && this.props.LangFile.PolygonDrawing.drawNote) ||
          (this.props.isPlacingMarker && this.props.LangFile.PolygonDrawing.placeMarker)
        }
        message={
          ((this.props.isDrawingNote || this.props.isDrawingField) &&
            this.props.LangFile.PolygonDrawing.drawPolygon) ||
          (this.props.isPlacingMarker && this.props.LangFile.PolygonDrawing.clickMap) ||
          (this.props.magicWand.isSelecting && this.props.LangFile.PolygonDrawing.clickMap)
        }
        style={{ marginTop: '30px' }}
        status={status}
        actionText={this.props.LangFile.PolygonDrawing.add}
        disabled={submitButtonDisable.disable}
        disabledReason={submitButtonDisable.reason}
        action={!this.props.isPlacingMarker && this.onSubmit}
        cancelText={this.props.LangFile.PolygonDrawing.cancel}
        cancelAction={() => this.stopDrawing()}
      >
        <Fragment>{this.renderMagicThresholdSlider()}</Fragment>
      </ConfirmActionPopover>
    );
  };

  render() {
    const iconOptions = {
      url: polygonSvg,
      scaledSize: new google.maps.Size(20, 20),
      anchor: new google.maps.Point(20 / 2, 20 / 2),
    };

    return (
      <Fragment>
        {this.renderMapPolygons()}
        {this.renderCutMarkers(iconOptions)}
        {this.renderPolygonMapMarkers(iconOptions)}
        {this.renderSelectedMarker()}
        {this.renderPolygonToolbar()}
        {this.renderSlicingPopover()}
        {this.renderDrawingPopover()}
      </Fragment>
    );
  }
}

PolygonDrawing.propTypes = {
  isDrawingNote: PropTypes.bool.isRequired,
  isDrawingField: PropTypes.bool.isRequired,
  isPlacingMarker: PropTypes.bool.isRequired,
  LangFile: PropTypes.object.isRequired,
  onMarkerPlaced: PropTypes.func,
  controls: PropTypes.bool,
  popup: PropTypes.bool,
  fieldImage: PropTypes.obj || PropTypes.any,
};

PolygonDrawing.defaultProps = {
  isDrawingNote: false,
  isDrawingField: false,
  isPlacingMarker: false,
  fieldImage: {},
  onMarkerPlaced: voidFunc,
};
