'use strict';

// Library imports
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './js/Store';

import routes from './js/Routes';

import APIConstants from 'js/APIConstants';

import firebase from 'firebase/app';
import 'firebase/analytics';

// A workaround to fix a moment bug, where locale does not get loaded.
// http://momentjs.com/docs/#/use-it/browserify/
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/et';
import 'moment/locale/sv';
import 'moment/locale/lv';
import 'moment/locale/lt';

// Initialize Firebase
firebase.initializeApp(APIConstants.firebaseConfig);

if (APIConstants.enableAnalytics) {
  firebase.analytics();
}

// Mount the React app.
ReactDOM.render(<Provider store={store}>{routes}</Provider>, document.getElementById('app'));
