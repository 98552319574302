// @flow

import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Box, Button, Snackbar } from '@material-ui/core';
import Styles from './ErrorSnackbar.module.less';
import { useLangFile } from 'js/context/LanguageContext';
import { voidFunc } from 'js/constants/PropTypeUtils';

const ErrorSnackbar = (props: ErrorSnackbar.propTypes) => {
  const LangFile = useLangFile();

  return (
    <Snackbar
      open={props.open}
      className={Styles.Error}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      color={'secondary'}
      message={
        <div>
          <Box fontWeight={'bold'}>{props.title}</Box>
          <Box fontWeight={'light'}>{props.subtitle}</Box>
          {props.errorMessage && (
            <Box fontSize={'8pt'} fontWeight={'light'}>
              <br />
              {LangFile.ErrorSnackbar.errorMessage}: "{props.errorMessage}"
            </Box>
          )}
        </div>
      }
      action={
        <Button size={'small'} className={Styles.ErrorAction} onClick={props.onDismiss}>
          {LangFile.ErrorSnackbar.ok}
        </Button>
      }
    />
  );
};

ErrorSnackbar.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  errorMessage: PropTypes.string,
  onDismiss: PropTypes.func,
};

ErrorSnackbar.defaultProps = {
  onDismiss: voidFunc,
};

export default memo(ErrorSnackbar);
