import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoginDialog from 'js/components/Login/LoginDialog';
import { authenticateUser } from 'js/reducers/UserReducer';
import RestorePasswordErrorDialog from 'js/components/Login/RestorePasswordErrorDialog';
import LoginErrorDialog from 'js/components/Login/LoginErrorDialog';
import { restorePassword } from 'js/reducers/CredentialReducer';
import { authenticateFarm, fetchFarms } from 'js/reducers/FarmReducer';
import { getOwnerFarm } from 'js/helpers/FarmUtils';
import { useLangFile, useLanguage } from '../../context/LanguageContext';
import useFirebaseAnalytics, {
  FIREBASE_EVENTS,
  FIREBASE_SCREENS,
} from '../../hooks/useFirebaseAnalytics';
import ActionToast from '../Toasts/ActionToast';

const mapStateToProps = (store) => {
  return {
    loggedIn: store.credential.loggedIn,
    loggingIn: store.credential.loggingIn,
    syncing: store.sync.syncing,
  };
};

const LoginContainer = ({ dispatch, loggedIn, syncing, loggingIn }) => {
  const analytics = useFirebaseAnalytics();
  const language = useLanguage();
  const LangFile = useLangFile();
  const [view, setView] = useState(0);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showRestoreError, setShowRestoreError] = useState(false);
  const [showRestoreSuccess, setShowRestoreSuccess] = useState(false);

  const doLogin = useCallback((username, password, rememberMe) => {
    analytics.logEvent(FIREBASE_EVENTS.LOGIN_SUBMIT);

    dispatch(authenticateUser(username, password, rememberMe))
      .then((userResult) => {
        let user = userResult.action.payload.user;

        dispatch(fetchFarms(user.id)).then((farmResult) => {
          let farm = getOwnerFarm(farmResult.action.payload);

          dispatch(authenticateFarm(farm));
        });
      })
      .catch((e) => {
        setShowLoginError(true);
      });
  }, []);

  const doRestorePassword = useCallback((email) => {
    analytics.logEvent(FIREBASE_EVENTS.LOGIN_RESTORE_SUBMIT);
    let params = {};
    params['username'] = email;
    params['platform'] = 'CROPLINE';
    params['language'] = language;
    const payload = JSON.stringify(params);
    dispatch(restorePassword(payload))
      .then(() => {
        setShowRestoreSuccess(true);
      })
      .catch((e) => {
        setShowRestoreError(true);
      });
    setView(0);
  }, []);

  const handleOnActionRestoreSuccess = useCallback(() => {
    setShowRestoreSuccess(false);
  }, []);

  useEffect(() => {
    setShowLoginError(false);
    setShowRestoreError(false);
  }, [language]);

  let shown = !loggedIn && !syncing;

  if (shown) {
    if (view === 0) {
      analytics.setCurrentScreen(FIREBASE_SCREENS.LOGIN);
    } else {
      analytics.setCurrentScreen(FIREBASE_SCREENS.RESTORE);
    }
  }

  return (
    <Fragment>
      <LoginDialog
        view={view}
        setView={setView}
        doLogin={doLogin}
        loggingIn={loggingIn}
        doRestorePassword={doRestorePassword}
        shown={shown}
      />
      {showRestoreSuccess && (
        <ActionToast
          open={showRestoreSuccess}
          onClose={handleOnActionRestoreSuccess}
          onPrimaryAction={handleOnActionRestoreSuccess}
          actionTextPrimary={LangFile.RestorePasswordSuccess.ok}
          message={LangFile.RestorePasswordSuccess.message}
          autoHideDuration={6000}
        />
      )}
      }
      {showRestoreError && (
        <RestorePasswordErrorDialog
          open={showRestoreError}
          onClose={() => setShowRestoreError(false)}
        />
      )}
      {showLoginError && (
        <LoginErrorDialog open={showLoginError} onClose={() => setShowLoginError(false)} />
      )}
    </Fragment>
  );
};

export default memo(connect(mapStateToProps)(LoginContainer));
