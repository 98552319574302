import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import TextFormControl from 'js/components/UI-Elements/TextFormControl.jsx';
import Button from '@material-ui/core/Button';
import { useLangFile } from 'js/context/LanguageContext';
import useEvent from 'js/hooks/useEvent';
import { flushSync } from 'react-dom';

const EditTextDialog = ({
  open,
  title,
  onUpdate,
  value,
  defaultValue,
  placeholder,
  onClose,
  type,
}) => {
  const LangFile = useLangFile();
  const [updatedValue, setUpdatedValue] = useState(value);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  const handleKeyboardEvent = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onUpdate(updatedValue);
        onClose();
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        onClose();
      }
    },
    [updatedValue, onUpdate]
  );

  useEvent('keydown', handleKeyboardEvent);

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onEscapeKeyDown={onClose}
      onBackdropClick={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextFormControl
          disablePadding
          autoFocus
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={setUpdatedValue}
          onUpdate={setUpdatedValue}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'primary'} onClick={() => onClose()}>
          {LangFile.EditTextDialog.cancel}
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            flushSync(() => {
              onUpdate(updatedValue);
            });
            onClose();
          }}>
          {LangFile.EditTextDialog.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditTextDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  type: PropTypes.string,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

EditTextDialog.defaultProps = {
  type: 'text',
};

export default memo(EditTextDialog);
