import { Component } from 'react';
import PropTypes from 'prop-types';

import MarkerClusterer from 'js/components/SoilSamples/markerclusterer';
import {
  getMenuOption,
  GPS_AGRO_PROPERTIES,
  LMO_PROPERTIES,
  SOURCE,
} from 'js/components/SoilSamples/SoilSampleConstants';
import { defaultClusterStyles, getCircularIcon } from 'js/components/SoilSamples/ClusterUtils';

class SoilSampleHeatmap extends Component {
  componentDidMount() {
    this.addMarkers();
  }

  componentWillUnmount() {
    this.removeMarkers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.property !== prevProps.property) {
      this.removeMarkers();
      this.addMarkers();
    }
  }

  removeMarkers = () => {
    if (this.markerCluster) {
      this.markerCluster.removeMarkers(this.markers);
    }
  };

  getPropertyKeys = () => {
    let source = this.props.source;
    let propertyKeys;

    if (source === SOURCE.GPS_AGRO) {
      propertyKeys = GPS_AGRO_PROPERTIES;
    } else if (source === SOURCE.LMO) {
      propertyKeys = LMO_PROPERTIES;
    }

    return propertyKeys;
  };

  addMarkers = () => {
    let { features, property, map, zoomOnClick } = this.props;

    let propertyKeys = this.getPropertyKeys();

    let propertyKey = propertyKeys[property];
    let option = getMenuOption(property);
    let decimals = option.decimals;

    this.markers = features.map((feature) => {
      let value = feature.properties[propertyKey];

      if (value < 0) {
        return null;
      }

      let coords = feature.geometry.coordinates;
      let location = new google.maps.LatLng(coords[1], coords[0]);
      let { primary, contrast } = option.getColor(value);

      let image = {
        url: getCircularIcon(primary, 12),
        size: new google.maps.Size(24, 24),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(12, 12),
        scaledSize: new google.maps.Size(24, 24),
      };

      let options = {
        position: location,
        label: {
          text: `${Number(value).toFixed(decimals)}`,
          color: contrast,
        },
        icon: image,
      };

      return new google.maps.Marker(options);
    });

    this.markerCluster = new MarkerClusterer(map, this.markers, {
      styles: defaultClusterStyles,
      zoomOnClick: zoomOnClick,
      averageCenter: true,
      minimumClusterSize: 2,
    });

    this.markerCluster.setCalculator(this.calculateClusters);
  };

  calculateClusters = (markers, numStyles) => {
    let index = 0;
    let count = markers.length;
    let total = count;

    while (total !== 0) {
      total = parseInt(total / 5, 10);
      index++;
    }

    index = Math.min(index, numStyles);

    let avg =
      markers.map((marker) => Number(marker.label.text)).reduce((acc, cur) => acc + cur, 0) / count;

    let option = getMenuOption(this.props.property);
    let decimals = option.decimals;
    let { primary, contrast } = option.getColor(avg);

    return {
      text: `<div style="color: ${contrast}; background-color:${primary}; overflow:"visible"><div>${Number(avg).toFixed(decimals)}</div></div>`,
      index: index,
    };
  };

  render() {
    return null;
  }
}

SoilSampleHeatmap.propTypes = {
  features: PropTypes.array,
  property: PropTypes.string,
  source: PropTypes.string,
  zoomOnClick: PropTypes.bool,
  map: PropTypes.object,
};

export default SoilSampleHeatmap;
