export const TEMPERATURE = {
  CELCIUS: 'CELCIUS',
  FAHRENHEIT: 'FAHRENHEIT',
};

export const WIND = {
  METERS_PER_SECOND: 'METERS_PER_SECOND',
  MILES_PER_HOUR: 'MILES_PER_HOUR',
};

export const RAIN = {
  MILLIMETERS: 'MILLIMETERS',
  INCHES: 'INCHES',
};

export const TEMPERATURE_CONSTANTS = {
  [TEMPERATURE.CELCIUS]: {
    key: TEMPERATURE.CELCIUS,
    unit: 'ºC',
    exportSpecificUnit: 'Celcius',
    mapTo: (value, unit) => {
      if (value === undefined || value === null) {
        return null;
      }
      if (unit === TEMPERATURE.FAHRENHEIT) {
        return (value + 40.0) * 1.8 - 40.0;
      }
      return value;
    },
  },
  [TEMPERATURE.FAHRENHEIT]: {
    key: TEMPERATURE.FAHRENHEIT,
    unit: 'ºF',
    exportSpecificUnit: 'Fahrenheit',
    mapTo: (value, unit) => {
      if (value === undefined || value === null) {
        return null;
      }
      if (unit === TEMPERATURE.CELCIUS) {
        return (value + 40.0) / 1.8 - 40.0;
      }
      return value;
    },
  },
};

export const WIND_CONSTANTS = {
  [WIND.METERS_PER_SECOND]: {
    key: WIND.METERS_PER_SECOND,
    unit: 'm/s',
    mapTo: (value, unit) => {
      if (value === undefined || value === null) {
        return null;
      }
      if (unit === WIND.MILES_PER_HOUR) {
        return value * 2.236936;
      }
      return value;
    },
  },
  [WIND.MILES_PER_HOUR]: {
    key: WIND.MILES_PER_HOUR,
    unit: 'mph',
    mapTo: (value, unit) => {
      if (value === undefined || value === null) {
        return null;
      }
      if (unit === WIND.METERS_PER_SECOND) {
        return value * 0.44704;
      }
      return value;
    },
  },
};

export const RAIN_CONSTANTS = {
  [RAIN.MILLIMETERS]: {
    key: RAIN.MILLIMETERS,
    unit: 'mm',
    mapTo: (value, unit) => {
      if (value === undefined || value === null) {
        return null;
      }
      if (unit === RAIN.INCHES) {
        return value / 25.4;
      }
      return value;
    },
  },
  [RAIN.INCHES]: {
    key: RAIN.INCHES,
    unit: 'in',
    mapTo: (value, unit) => {
      if (value === undefined || value === null) {
        return null;
      }
      if (unit === RAIN.MILLIMETERS) {
        return value * 25.4;
      }
      return value;
    },
  },
};
