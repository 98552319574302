import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { RAIN_ACCUMULATION } from 'js/constants/WeatherConstants';
import SelectFormControl from 'js/components/UI-Elements/SelectFormControl.jsx';
import { useLangFile } from 'js/context/LanguageContext';

const RainInfoSettingsDialog = (props: RainInfoSettingsDialog.propTypes) => {
  const LangFile = useLangFile();

  return (
    <Dialog maxWidth={'xs'} fullWidth={true} open={props.shown} onClose={props.onClose}>
      <DialogTitle>{LangFile.RainInfoSettingsDialog.title}</DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <SelectFormControl
            title={LangFile.RainInfoSettingsDialog.accumulation}
            label={LangFile.RainInfoSettingsDialog.accumulationLabel}
            disablePadding={true}
            value={props.accumulation}
            onChange={props.onChangeAccumulation}
            options={[
              {
                value: RAIN_ACCUMULATION.LAST_24_HOURS,
                label: LangFile.RainInfoSettingsDialog.accumulationContinuously,
              },
              {
                value: RAIN_ACCUMULATION.SINCE_MIDNIGHT,
                label: LangFile.RainInfoSettingsDialog.accumulationDaily,
              },
            ]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} onClick={props.onClose}>
          {LangFile.RainInfoSettingsDialog.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RainInfoSettingsDialog.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  accumulation: PropTypes.string,
  onChangeAccumulation: PropTypes.func,
};

export default memo(RainInfoSettingsDialog);
