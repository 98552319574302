import { useLangFile } from '../../context/LanguageContext';
import React, { memo, useCallback, useEffect, useRef, useState, dispatch, useMemo } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import SearchBar from 'material-ui-search-bar';
import Styles from './PrescriptionArchive.module.less';
import { Card, Checkbox, FormControlLabel, FormLabel } from '@material-ui/core';
import moment from 'moment-timezone';
import PrescriptionArchiveSelector, {
  PrescriptionArchiveSelectorItem,
} from './PrescriptionArchiveSelector';
import PrescriptionArchiveTable from './PrescriptionArchiveTable';
import { voidFunc } from '../../constants/PropTypeUtils';
import { parseLayerName, parseMetaType } from 'js/components/Prescription/PrescriptionJob';
import { useSeasonContext } from '../../context/SeasonContext';
import { getComparator, stableSort, TableColumns } from './PrescriptionArchiveUtils';
import { groupBy } from '../../algorithms/grouping';
import { getFieldName } from '../../helpers/StateInterpreters';
import PrescriptionFieldCardItem from './PrescriptionFieldCardItem';
import PropTypes from 'prop-types';
import SeasonSelector from '../Seasons/SeasonSelector';
import { PrescriptionJob } from '../Prescription/PrescriptionJob';
import { fuzzyFilter } from 'js/algorithms/filtering';
import DateRangeInput from 'js/components/FormControls/DateRangeInput';

const PrescriptionArchive = (props: PrescriptionArchive.propTypes) => {
  const LangFile = useLangFile();
  const { selectedSeason } = useSeasonContext();

  const seasonStartDate = useRef(moment().startOf('year'));
  const seasonEndDate = useRef(moment().startOf('year'));
  const validMetaTypes = useRef([]);
  const validCropTypes = useRef([]);
  const validDataSources = useRef([]);

  const [metaTypes, setMetaTypes] = useState(validMetaTypes.current);
  const [cropTypes, setCropTypes] = useState(validCropTypes.current);
  const [dataSources, setDataSources] = useState(validDataSources.current);
  const [showOutsideMaps, setShowOutsideMaps] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [excludedFields, setExcludedFields] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(TableColumns.createdDate);
  const [cards, setCards] = useState([]);
  const [datePickerFromDate, setDatePickerFromDate] = useState();
  const [datePickerToDate, setDatePickerToDate] = useState();
  const [datePickerEnabled, setDatePickerEnabled] = useState(false);

  const handleDateRangeChange = (startDate, endDate) => {
    setDatePickerFromDate(startDate);
    setDatePickerToDate(endDate);
  };

  const setDefaultFilters = useCallback(() => {
    if (props.maps) {
      let maps = props.maps.filter((m) => m.seasonId == null || m.seasonId === selectedSeason.id);

      validMetaTypes.current = maps
        .map((item) => item.metaType)
        .filter((item, idx, arr) => arr.indexOf(item) === idx);
      validCropTypes.current = maps
        .map((item) => {
          let field = props.fields.get(item.fieldId);
          return field && field.crop;
        })
        .filter(Boolean)
        .filter((item, idx, arr) => arr.indexOf(item) === idx);
      validDataSources.current = maps
        .map((item) => item.layer)
        .filter((item, idx, arr) => arr.indexOf(item) === idx);

      setMetaTypes(validMetaTypes.current);
      setCropTypes(validCropTypes.current);
      setDataSources(validDataSources.current);
      handleDateRangeChange();
      setDatePickerEnabled(false);
      setShowOutsideMaps(true);
    }
  }, [props.maps, selectedSeason]);

  const onShowMapsOutsideChange = useCallback((event, value) => {
    setShowOutsideMaps(value);
  }, []);

  const onJobTypesChange = useCallback((metaTypes: [PrescriptionArchiveSelectorItem]) => {
    setMetaTypes(metaTypes.map((metaType) => metaType.item));
  }, []);

  const onCropTypesChange = useCallback((cropTypes: [PrescriptionArchiveSelectorItem]) => {
    setCropTypes(cropTypes.map((cropType) => cropType.item));
  }, []);

  const onDataSourcesChange = useCallback((surveyLayers: [PrescriptionArchiveSelectorItem]) => {
    setDataSources(surveyLayers.map((surveyLayer) => surveyLayer.item));
  }, []);

  const onSearchChange = useCallback((text) => {
    setSearchText(text);
  }, []);

  const onSearchCancel = useCallback(() => {
    setSearchText('');
  }, []);

  const onOrderByChange = useCallback((orderBy: string) => {
    setOrderBy(orderBy);
  }, []);

  const onOrderChange = useCallback((order: string) => {
    setOrder(order);
  }, []);

  const ondatePickerEnabledChange = useCallback((event, value) => {
    setDatePickerEnabled(value);
  }, []);

  const sort = (array) => {
    if (orderBy === TableColumns.crop) {
      return array;
    } else {
      const comparator = getComparator(order, orderBy);
      return stableSort(array, comparator);
    }
  };

  const sortCards = (array) => {
    if (orderBy === TableColumns.crop) {
      return array.sort((lhs, rhs) => {
        const lhsCrop = lhs.field.crop;
        const rhsCrop = rhs.field.crop;
        if (lhsCrop < rhsCrop) {
          return order === 'desc' ? -1 : 1;
        }
        if (lhsCrop > rhsCrop) {
          return order === 'desc' ? 1 : -1;
        }
        return 0;
      });
    } else {
      return array.sort((lhs, rhs) => {
        const lhsFieldName = getFieldName(lhs.field, LangFile);
        const rhsFieldName = getFieldName(rhs.field, LangFile);
        if (lhsFieldName < rhsFieldName) {
          return -1;
        } else {
          return lhsFieldName > rhsFieldName ? 1 : 0;
        }
      });
    }
  };

  useEffect(() => {
    if (selectedSeason) {
      seasonStartDate.current = moment(selectedSeason.startDate);
      seasonEndDate.current = moment(selectedSeason.endDate);
    }
  }, [selectedSeason]);

  useEffect(() => {
    setDefaultFilters();
  }, [setDefaultFilters]);

  useEffect(() => {
    if (datePickerEnabled) {
      handleDateRangeChange(seasonStartDate.current, seasonEndDate.current);
    } else {
      handleDateRangeChange();
    }
  }, [datePickerEnabled]);

  const filteredAndSortedMaps = useMemo(() => {
    if (order && orderBy && props.maps) {
      let result = props.maps || [];

      // start by filtering on missing seasonId and matching seasonId
      result = result.filter((map) => map.seasonId == null || map.seasonId === selectedSeason.id);

      let cards = [];
      let numberOfFields = Object.keys(groupBy(result, 'fieldId')).length;

      // Filtering by search text
      if (searchText.length > 0) {
        result = result.filter((map: PrescriptionJob) => {
          let field = props.fields.get(Number(map.fieldId));

          return (
            fuzzyFilter(searchText, `${map.fieldId}`) || // Field ID
            fuzzyFilter(searchText, `${getFieldName(field, LangFile)}`) || // Field Name
            fuzzyFilter(searchText, `${map.jobName}`) || // Job Name
            fuzzyFilter(searchText, `${parseLayerName(map.layer, LangFile)}`) || // Layer Name
            fuzzyFilter(searchText, `${LangFile.CropType[field.crop]}`) || // Crop Name
            fuzzyFilter(searchText, `${moment(map.createdDate).format('LL')}`) || // Created Date
            (field.sowing && fuzzyFilter(searchText, `${moment(field.sowing).format('LL')}`)) || // Sowing Date
            fuzzyFilter(searchText, `${parseMetaType(map.metaType, LangFile)}`) // Job Type
          );
        });
      }

      // Filtering by metaType
      if (metaTypes.length > 0) {
        result = result.filter((map) => metaTypes.indexOf(map.metaType) !== -1);
      }

      // Filtering by cropType
      if (cropTypes.length > 0) {
        result = result
          .filter((map) => {
            let field = props.fields.get(map.fieldId);
            if (field) {
              return cropTypes.indexOf(field.crop) !== -1;
            }
          })
          .filter(Boolean);
      }

      // Filtering by dataSource
      if (dataSources.length > 0) {
        result = result.filter((map) => dataSources.indexOf(map.layer) !== -1);
      }

      // Filter out maps created outside the date range (datePickerFromDate to datePickerToDate)
      if (datePickerFromDate != undefined && datePickerToDate != undefined) {
        result = result.filter((map) => {
          const createdDate = moment(map.createdDate).startOf('day');
          return createdDate.isBetween(
            datePickerFromDate.startOf('day'),
            datePickerToDate.startOf('day'),
            null,
            '[]'
          );
        });
      }

      if (!showOutsideMaps) {
        let ignoredMaps = groupBy(
          result.filter((map) => map.seasonId == null),
          'fieldId'
        );
        numberOfFields = numberOfFields - Object.keys(ignoredMaps).length;
      }

      let outSideSeasonMaps = null;
      if (showOutsideMaps) {
        let outsideSeason = result.filter(
          (map: PrescriptionJob) => map.seasonId == null || map.seasonId === -1
        );
        outsideSeason = sort(outsideSeason);
        outSideSeasonMaps = groupBy(outsideSeason, 'fieldId');
        for (let key of Object.keys(outSideSeasonMaps)) {
          cards.push(
            new PrescriptionFieldCardItem(props.fields.get(Number(key)), [], outSideSeasonMaps[key])
          );
        }
      }

      result = result.filter((map) => map.seasonId === selectedSeason.id);

      // Sorting
      const sortedResult = sort(result);

      const maps = groupBy(sortedResult, 'fieldId');

      // Prescription card creation
      for (let key of Object.keys(maps)) {
        let card = cards.find((card) => card.field.fieldId === Number(key));
        if (card != null) {
          card.currentMaps = maps[key];
        } else {
          cards.push(new PrescriptionFieldCardItem(props.fields.get(Number(key)), maps[key], []));
        }
      }

      numberOfFields = numberOfFields - cards.length;
      setExcludedFields(numberOfFields);
      return cards;
    }
  }, [
    order,
    orderBy,
    props.maps,
    metaTypes,
    cropTypes,
    dataSources,
    showOutsideMaps,
    searchText,
    datePickerFromDate,
    datePickerToDate,
  ]);

  useEffect(() => {
    filteredAndSortedMaps ? setCards(sortCards(filteredAndSortedMaps)) : [];
  }, [filteredAndSortedMaps]);

  return (
    <Box maxHeight={'100vh'} height={'100vh'}>
      <Card elevation={4} className={Styles.root}>
        <AppBar elevation={0} position={'static'}>
          <Toolbar className={Styles.toolbar}>
            <Box className={Styles.buttonWrapper}>
              <IconButton className={Styles.menuButton} onClick={props.onClose}>
                <ArrowBackIcon />
              </IconButton>
              <SeasonSelector color={'white'} selectOnly={true} onChange={props.onSeasonChange} />
            </Box>
            <SearchBar
              className={Styles.search}
              value=""
              onChange={onSearchChange}
              onCancelSearch={onSearchCancel}
            />
          </Toolbar>
          <Box className={Styles.filter}>
            <Box className={Styles.datePickerBox}>
              <FormControlLabel
                className={datePickerEnabled ? null : Styles.datePickerLabel}
                control={
                  <Checkbox
                    disableRipple
                    color={'default'}
                    classes={{ root: Styles.checkbox }}
                    checked={datePickerEnabled}
                    onChange={ondatePickerEnabledChange}
                  />
                }
                label={LangFile.PrescriptionArchive.dateFilter}></FormControlLabel>
              <DateRangeInput
                startDate={datePickerFromDate}
                endDate={datePickerToDate}
                onChanged={handleDateRangeChange}
                disabled={datePickerEnabled ? false : true}
              />
            </Box>
            <PrescriptionArchiveSelector
              title={LangFile.PrescriptionArchive.jobType}
              items={validMetaTypes.current.map(
                (metaType) =>
                  new PrescriptionArchiveSelectorItem(metaType, parseMetaType(metaType, LangFile))
              )}
              initialSelections={metaTypes.map(
                (metaType) =>
                  new PrescriptionArchiveSelectorItem(metaType, parseMetaType(metaType, LangFile))
              )}
              orderBy={TableColumns.metaType}
              selectedOrderBy={orderBy}
              onChange={onJobTypesChange}
              onOrderByChange={onOrderByChange}
              onOrderChange={onOrderChange}></PrescriptionArchiveSelector>
            <PrescriptionArchiveSelector
              title={LangFile.PrescriptionArchive.cropType}
              items={validCropTypes.current.map(
                (cropType) =>
                  new PrescriptionArchiveSelectorItem(cropType, LangFile.CropType[cropType])
              )}
              initialSelections={cropTypes.map(
                (cropType) =>
                  new PrescriptionArchiveSelectorItem(cropType, LangFile.CropType[cropType])
              )}
              orderBy={TableColumns.crop}
              selectedOrderBy={orderBy}
              onChange={onCropTypesChange}
              onOrderByChange={onOrderByChange}
              onOrderChange={onOrderChange}></PrescriptionArchiveSelector>
            <PrescriptionArchiveSelector
              title={LangFile.PrescriptionArchive.inputLayer}
              items={validDataSources.current.map(
                (surveyLayer) =>
                  new PrescriptionArchiveSelectorItem(
                    surveyLayer,
                    parseLayerName(surveyLayer, LangFile)
                  )
              )}
              initialSelections={dataSources.map(
                (surveyLayer) =>
                  new PrescriptionArchiveSelectorItem(
                    surveyLayer,
                    parseLayerName(surveyLayer, LangFile)
                  )
              )}
              orderBy={TableColumns.layer}
              selectedOrderBy={orderBy}
              onChange={onDataSourcesChange}
              onOrderByChange={onOrderByChange}
              onOrderChange={onOrderChange}></PrescriptionArchiveSelector>
            <FormControlLabel
              className={Styles.formLabel}
              control={
                <Checkbox
                  disableRipple
                  color={'default'}
                  classes={{ root: Styles.checkbox }}
                  checked={showOutsideMaps}
                  onChange={onShowMapsOutsideChange}
                />
              }
              label={LangFile.PrescriptionArchive.showMapsOutsideOfSeason}
            />
          </Box>
        </AppBar>
      </Card>
      <Box style={{ height: `165px` }} />
      <Box className={Styles.container}>
        <Box className={Styles.innerContainer}>
          {props.loadingMaps ? (
            <Box className={Styles.loadingWrapper}>
              <CircularProgress variant={'indeterminate'} size={30} color={'primary'} />
            </Box>
          ) : (
            <PrescriptionArchiveTable
              cards={cards}
              documentation={props.documentation}
              numberOfExcludedFields={excludedFields}
              onFilterReset={setDefaultFilters}
              onDocumentationUploaded={props.onDocumentationUploaded}
              onDeleteDocumentation={props.onDeleteDocumentation}
              onSetSeason={props.onSetSeason}
              onDownload={props.onDownload}
              onDelete={props.onDelete}
              onEdit={props.onEdit}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

PrescriptionArchive.propTypes = {
  maps: PropTypes.array,
  documentation: PropTypes.object,
  fields: PropTypes.object,
  loadingMaps: PropTypes.bool,
  selectedField: PropTypes.object,
  onClose: PropTypes.func,
  onSetSeason: PropTypes.func,
  onDocumentationUploaded: PropTypes.func,
  onDeleteDocumentation: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onSeasonChange: PropTypes.func,
};

PrescriptionArchive.defaultProps = {
  maps: [],
  onClose: voidFunc,
  onSetSeason: voidFunc,
  onDocumentationUploaded: voidFunc,
  onDeleteDocumentation: voidFunc,
  onEdit: voidFunc,
  onDelete: voidFunc,
  onDownload: voidFunc,
  onSeasonChange: voidFunc,
};

export default memo(PrescriptionArchive);
