import { SURVEY_LAYERS } from '../../../constants/SurveyLayers';
import { FIELD_STATISTICS_CLAAS_COLUMNS } from '../FieldStatisticsColumns';
import moment from 'moment/moment';

export const getSoilOptixStatsForColumn = (column, stats) => {
  switch (column) {
    case SURVEY_LAYERS.PHOSPHORUS: {
      return stats.phosphorus;
    }
    case SURVEY_LAYERS.MAGNESIUM: {
      return stats.magnesium;
    }
    case SURVEY_LAYERS.COPPER: {
      return stats.copper;
    }
    case SURVEY_LAYERS.POTASSIUM: {
      return stats.potassium;
    }
    case SURVEY_LAYERS.RT: {
      return stats.rt;
    }
    case SURVEY_LAYERS.PH: {
      return stats.ph;
    }
    case SURVEY_LAYERS.BORON: {
      return stats.boron;
    }
    case SURVEY_LAYERS.ZINC: {
      return stats.zinc;
    }
    case SURVEY_LAYERS.CALCIUM: {
      return stats.calcium;
    }
    case SURVEY_LAYERS.MOLYBDENUM: {
      return stats.molybdenum;
    }
    case SURVEY_LAYERS.SULFUR: {
      return stats.sulfur;
    }
    case SURVEY_LAYERS.MANGANESE: {
      return stats.manganese;
    }
    case SURVEY_LAYERS.SODIUM: {
      return stats.sodium;
    }
    case SURVEY_LAYERS.IRON: {
      return stats.iron;
    }
    case SURVEY_LAYERS.JB: {
      return stats.soilType;
    }
    case SURVEY_LAYERS.FI_SOIL_CLASS: {
      return stats.fiSoilClass;
    }
    case SURVEY_LAYERS.ALTITUDE: {
      return stats.altitude;
    }
    case SURVEY_LAYERS.CLAY: {
      return stats.clay;
    }
    case SURVEY_LAYERS.HUMUS: {
      return stats.humus;
    }
    case SURVEY_LAYERS.COARSE_SAND: {
      return stats.coarseSand;
    }
    case SURVEY_LAYERS.FINE_SAND: {
      return stats.fineSand;
    }
    case SURVEY_LAYERS.SAND: {
      return stats.sand;
    }
    case SURVEY_LAYERS.SILT: {
      return stats.silt;
    }
    default:
      return null;
  }
};

export const getSurveyUnit = (column, fieldStatistics) => {
  if (Object.keys(SURVEY_LAYERS).includes(column)) {
    const fieldStats = fieldStatistics.find((fieldStats) => {
      if (fieldStats.soilOptix) {
        const stats = getSoilOptixStatsForColumn(column, fieldStats.soilOptix);
        return stats && stats.raw;
      }
      return false;
    });

    if (fieldStats) {
      const stats = getSoilOptixStatsForColumn(column, fieldStats.soilOptix);
      return stats.raw.unit ? stats.raw.unit : '%';
    }
  }
  return null;
};

export const sortByDate = (lhsDate, rhsDate) => {
  if (!lhsDate.isValid()) {
    return -1;
  } else if (!rhsDate.isValid()) {
    return 1;
  } else if (lhsDate > rhsDate) {
    return 1;
  }
  if (rhsDate > lhsDate) {
    return -1;
  }
  return 0;
};

export const sortedClaasRows = (column, rows) => {
  switch (column) {
    case FIELD_STATISTICS_CLAAS_COLUMNS.HARVEST_DATE:
      return rows.sort((lhs, rhs) => {
        return sortByDate(moment(lhs.claas.harvest), moment(rhs.claas.harvest));
      });
    case FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_TOTAL:
      return rows.sort((lhs, rhs) => {
        return lhs.claas.totalYield - rhs.claas.totalYield;
      });
    case FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_AVERAGE:
      return rows.sort((lhs, rhs) => {
        return lhs.claas.yieldNormalized - 0.5 - (rhs.claas.yieldNormalized - 0.5);
      });
    case FIELD_STATISTICS_CLAAS_COLUMNS.CROP_MOISTURE:
      return rows.sort((lhs, rhs) => {
        const lhsCropMoisture = lhs.claas.averageMoisture ? lhs.claas.averageMoisture : 0;
        const rhsCropMoisture = rhs.claas.averageMoisture ? rhs.claas.averageMoisture : 0;
        return lhsCropMoisture - rhsCropMoisture;
      });
    case FIELD_STATISTICS_CLAAS_COLUMNS.FUEL_CONSUMPTION:
      return rows.sort((lhs, rhs) => {
        return lhs.claas.averageFuel - rhs.claas.averageFuel;
      });
    default:
      return rows;
  }
};

export const sortedSoilOptixRows = (column, rows) => {
  return rows.sort((lhs, rhs) => {
    const lhsStats = getSoilOptixStatsForColumn(column, lhs.soilOptix);
    const rhsStats = getSoilOptixStatsForColumn(column, rhs.soilOptix);

    if (column === SURVEY_LAYERS.JB) {
      if (lhsStats && lhsStats.mode) {
        if (rhsStats && rhsStats.mode) {
          // Only compare the actual number to avoid issues with JB 1 and 10 both being before JB 2.
          let leftJB = lhsStats.mode.split('_').pop();
          let rightJB = rhsStats.mode.split('_').pop();

          return leftJB - rightJB;
        }
        return 1;
      } else {
        return -1;
      }
    } else if (column === SURVEY_LAYERS.FI_SOIL_CLASS) {
      if (lhsStats && lhsStats.mode) {
        if (rhsStats && rhsStats.mode) {
          return lhsStats.mode.localeCompare(rhsStats.mode);
        }
        return 1;
      } else {
        return -1;
      }
    } else {
      if (lhsStats && lhsStats.raw) {
        if (rhsStats && rhsStats.raw) {
          return lhsStats.raw.mean - rhsStats.raw.mean;
        }
        return 1;
      } else {
        return -1;
      }
    }
  });
};
