import { FIELD_STATISTICS_CLAAS_COLUMNS } from '../FieldStatisticsColumns';
import moment from 'moment/moment';
import { Box, Typography } from '@material-ui/core';
import Styles from '../FieldStatisticsContainer.module.less';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import EmptyColumnCell from './EmptyColumnCell';
import StandardColumnCell from './StandardColumnCell';

const ClaasColumnCell = ({ column, stats }) => {
  switch (column) {
    case FIELD_STATISTICS_CLAAS_COLUMNS.HARVEST_DATE:
      return stats.harvest ? (
        <StandardColumnCell
          primary={moment(stats.harvest).format('MMMM DD')}
          secondary={moment(stats.harvest).format('YYYY')}
        />
      ) : (
        <EmptyColumnCell />
      );
    case FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_AVERAGE:
      if (stats.averageYield) {
        let lhsPercentage = 0;
        let rhsPercentage = 0;
        if (stats.yieldNormalized > 0.5) {
          lhsPercentage = 0;
          rhsPercentage = (stats.yieldNormalized - 0.5) * 200;
        } else if (stats.yieldNormalized < 0.5) {
          lhsPercentage = (0.5 - stats.yieldNormalized) * 200;
          rhsPercentage = 0;
        }
        return (
          <Box width={'100%'}>
            <Box display={'flex'} className={Styles.absolute} style={{ padding: '8px 0' }}>
              <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'center'}>
                <Box display={'flex'} flex={49} height={'100%'} justifyContent={'flex-end'}>
                  <Box width={`${lhsPercentage}%`} height={'100%'} bgcolor={'#CE1A3C4C'}></Box>
                </Box>
                <Box display={'flex'} flex={2} bgcolor={'#4140421A'} height={'100%'}></Box>
                <Box display={'flex'} flex={49} height={'100%'}>
                  <Box width={`${rhsPercentage}%`} height={'100%'} bgcolor={'#78A22F80'}></Box>
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              className={Styles.absolute}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography className={Styles.title}>
                {stats.averageYield.toFixed(1) + ' t/ha'}
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return <EmptyColumnCell />;
      }
    case FIELD_STATISTICS_CLAAS_COLUMNS.CROP_MOISTURE:
      return stats.averageMoisture ? (
        <StandardColumnCell primary={`${Number(stats.averageMoisture.toFixed(1))} %`} />
      ) : (
        <EmptyColumnCell />
      );
    case FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_TOTAL:
      return stats.totalYield ? (
        <StandardColumnCell primary={`${Number(stats.totalYield.toFixed(1))} t`} />
      ) : (
        <EmptyColumnCell />
      );
    case FIELD_STATISTICS_CLAAS_COLUMNS.FUEL_CONSUMPTION:
      return stats.averageFuel ? (
        <StandardColumnCell primary={`${Number(stats.averageFuel.toFixed(1))} L/ha`} />
      ) : (
        <EmptyColumnCell />
      );
    default:
      return <EmptyColumnCell />;
  }
};

ClaasColumnCell.propTypes = {
  column: PropTypes.string,
  stats: PropTypes.object,
};

export default memo(ClaasColumnCell);
