'use strict';

import React, { Fragment, memo, useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLangFile } from 'js/context/LanguageContext';

import style from './PrescriptionDownloader.module.less';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import PrescriptionDownloadTile from 'js/components/Prescription/Downloading/PrescriptionDownloadTile';

import shapefileImage from 'style/images/shapefile/shapefile.png';
import bogballeImage from 'style/images/shapefile/bogballe.png';
import claasImage from 'style/images/shapefile/claas.png';
import trimble from 'style/images/shapefile/trimble.png';
import fmxImage from 'style/images/shapefile/FMX-GFX-750-1060-1260.png';
import piqImage from 'style/images/shapefile/Precision-IQ_TMX-2050.png';
import johndeereImage from 'style/images/shapefile/johndeere.png';
import topconImage from 'style/images/shapefile/topcon.png';
import amazoneImage from 'style/images/shapefile/amazone.gif';
import agLeaderImage from 'style/images/shapefile/AgLeaderBlue.png';
import fendtImage from 'style/images/isoxml/fendt-isobus.png';
import claasIsoImage from 'style/images/isoxml/claas-isobus.png';
import claasShpImage from 'style/images/shapefile/claas-shp.png';
import kvernelandIsoImage from 'style/images/isoxml/kverneland-isobus.png';
import cciImage from 'style/images/isoxml/cci.png';

import { useHasFeatures } from '../../../context/PermissionContext';
import FeatureConstants from '../../../constants/FeatureConstants';

export const Formats = {
  STANDARD: 'standard',
  BOGBALLE: 'bogballe',
  TRIMBLE_FMX: 'trimble_fmx',
  TRIMBLE_PIQ: 'trimble_piq',
  JOHNDEERE: 'johndeere',
  KVERNELAND_ISOXML: 'kverneland',
  FENDT_ISOXML: 'fendt',
  CLASS_ISOXML: 'cemis1200',
  CCI_ISOXML: 'cci',
};

const PrescriptionDownloader = ({
  loading,
  savingJob,
  savingError,
  downloadJob,
  downloadError,
  onBack,
  downloadShapefile,
  downloadISOXML,
}) => {
  const LangFile = useLangFile();
  const [trimbleOpen, setTrimbleOpen] = useState(false);
  const [claasOpen, setClaasOpen] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={true}
        fullWidth
        maxWidth={'lg'}
        className={clsx({ [style.LoadingRoot]: loading })}
        onBackdropClick={onBack}>
        <DialogTitle>
          {loading && LangFile.PrescriptionDownloader.preparing}
          {savingJob && LangFile.PrescriptionDownloader.saving}
          {!loading && downloadError && LangFile.PrescriptionDownloader.downloadErrorTitle}
          {!loading && !downloadError && LangFile.PrescriptionDownloader.title}
        </DialogTitle>
        <DialogContent>
          {(loading || savingJob) && (
            <div className={style.LoadingWrapper}>
              <CircularProgress size={80} thickness={5} />
            </div>
          )}
          {!(loading || savingJob) && (
            <Fragment>
              {downloadError && (
                <Box color={'secondary.main'}>{LangFile.PrescriptionDownloader.downloadError}</Box>
              )}
              {savingError && (
                <Box color={'secondary.main'}>{LangFile.PrescriptionDownloader.savingError}</Box>
              )}

              {downloadJob.legacy && !(downloadError || savingError) && (
                <div className={style.GridList}>
                  <PrescriptionDownloadTile
                    format={Formats.STANDARD}
                    imageSource={shapefileImage}
                    onDownload={downloadShapefile}
                  />
                </div>
              )}

              {!downloadJob.legacy && !(downloadError || savingError) && (
                <div className={style.GridList}>
                  <PrescriptionDownloadTile
                    format={Formats.STANDARD}
                    imageSource={shapefileImage}
                    onDownload={downloadShapefile}
                  />

                  <PrescriptionDownloadTile
                    imageSource={fendtImage}
                    format={Formats.FENDT_ISOXML}
                    onDownload={downloadISOXML}
                  />

                  <PrescriptionDownloadTile
                    imageSource={kvernelandIsoImage}
                    format={Formats.KVERNELAND_ISOXML}
                    onDownload={downloadISOXML}
                  />

                  <PrescriptionDownloadTile
                    imageSource={claasImage}
                    onDownload={() => setClaasOpen(true)}
                  />

                  {useHasFeatures(FeatureConstants.ISOXML_CCI) && (
                    <PrescriptionDownloadTile
                      imageSource={cciImage}
                      format={Formats.CCI_ISOXML}
                      onDownload={downloadISOXML}
                    />
                  )}

                  <PrescriptionDownloadTile
                    format={Formats.BOGBALLE}
                    imageSource={bogballeImage}
                    onDownload={downloadShapefile}
                  />

                  <PrescriptionDownloadTile
                    imageSource={trimble}
                    onDownload={() => setTrimbleOpen(true)}
                  />

                  <PrescriptionDownloadTile
                    format={Formats.JOHNDEERE}
                    imageSource={johndeereImage}
                    onDownload={downloadShapefile}
                  />

                  <PrescriptionDownloadTile
                    format={Formats.STANDARD}
                    imageSource={topconImage}
                    onDownload={downloadShapefile}
                  />

                  <PrescriptionDownloadTile
                    format={Formats.STANDARD}
                    imageSource={amazoneImage}
                    onDownload={downloadShapefile}
                  />

                  <PrescriptionDownloadTile
                    format={Formats.STANDARD}
                    imageSource={agLeaderImage}
                    onDownload={downloadShapefile}
                  />
                </div>
              )}
            </Fragment>
          )}
        </DialogContent>

        {!(loading || savingJob) && <Divider />}

        <DialogActions>
          {!loading && !savingJob && (
            <Fragment>
              {downloadError ? (
                <Button variant="contained" onClick={onBack} color="primary">
                  {LangFile.PrescriptionDownloader.close}
                </Button>
              ) : (
                <Box
                  pl={2}
                  display={'flex'}
                  justifyContent={'space-between'}
                  width={'100%'}
                  alignItems={'center'}>
                  <i>{LangFile.PrescriptionDownloader.disclaimer}</i>

                  <Box display={'flex'}>
                    <Button variant="outlined" onClick={onBack} color="primary" key={'back'}>
                      {LangFile.PrescriptionDownloader.done}
                    </Button>
                    <Box pr={1} />
                  </Box>
                </Box>
              )}
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={trimbleOpen}
        maxWidth={'lg'}
        className={clsx({ [style.LoadingRoot]: loading })}
        onBackdropClick={() => setTrimbleOpen(false)}>
        <DialogTitle>{LangFile.PrescriptionDownloader.title}</DialogTitle>
        <DialogContent>
          <Fragment>
            <div className={style.GridList}>
              <PrescriptionDownloadTile
                format={Formats.TRIMBLE_FMX}
                imageSource={fmxImage}
                onDownload={(format) => {
                  downloadShapefile(format);
                  setTrimbleOpen(false);
                }}
              />

              <PrescriptionDownloadTile
                format={Formats.TRIMBLE_PIQ}
                imageSource={piqImage}
                onDownload={(format) => {
                  downloadShapefile(format);
                  setTrimbleOpen(false);
                }}
              />
            </div>
          </Fragment>
        </DialogContent>
      </Dialog>
      <Dialog
        open={claasOpen}
        maxWidth={'lg'}
        className={clsx({ [style.LoadingRoot]: loading })}
        onBackdropClick={() => setClaasOpen(false)}>
        <DialogTitle>{LangFile.PrescriptionDownloader.title}</DialogTitle>
        <DialogContent>
          <Fragment>
            <div className={style.GridList}>
              <PrescriptionDownloadTile
                format={Formats.STANDARD}
                imageSource={claasShpImage}
                onDownload={(format) => {
                  downloadShapefile(format);
                  setClaasOpen(false);
                }}
              />

              <PrescriptionDownloadTile
                format={Formats.CLASS_ISOXML}
                imageSource={claasIsoImage}
                onDownload={(format) => {
                  downloadISOXML(format);
                  setClaasOpen(false);
                }}
              />
            </div>
          </Fragment>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default memo(PrescriptionDownloader);

PrescriptionDownloader.propTypes = {
  loading: PropTypes.bool,
  savingJob: PropTypes.bool,
  savingError: PropTypes.bool,
  legacy: PropTypes.bool,
  downloadError: PropTypes.object,
  downloadJob: PropTypes.array,
  onBack: PropTypes.func,
  downloadShapefile: PropTypes.func,
  downloadISOXML: PropTypes.func,
};
