'use strict';

import React, { memo, useCallback, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChangePassword from 'js/components/Settings/ChangePassword';
import Tooltip from '@material-ui/core/Tooltip';
import ImpersonationDialog from 'js/components/ImpersonationDialog';

import {
  Toolbar,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Portal,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import SecurityIcon from '@material-ui/icons/Security';
import LockIcon from '@material-ui/icons/LockOpen';
import AccountIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import Business from '@material-ui/icons/Business';
import NavigationClose from '@material-ui/icons/Close';
import ArrowDropRight from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';

import { useLangFile } from 'js/context/LanguageContext';
import { useFarm, useUser } from 'js/context/AccountContext';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import SelectFarmDialog from '../SelectFarmDialog';
import { DeviceHub } from '@material-ui/icons';
import DataIntegrationDialogContainer from '../DataIntegration/DataIntegrationDialogContainer';

const styles = (theme) => ({
  list: {
    textAlign: 'left',
    overflow: 'auto',
    maxHeight: 'calc(~"100vh - 64px")',
  },
  menuList: {
    maxHeight: '95vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  button: {
    height: 'auto',
    color: theme.palette.alternateTextColor,
    disabledTextColor: theme.palette.alternateTextColorDisabled,
  },
  title: {
    fontSize: 16,
    textAlign: 'left',
  },
  paper: {
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px',
    borderLeft: 'none',
  },
  popper: {
    zIndex: 1400,
  },
});

const SettingsDrawer = ({
  classes,
  handleUserClose,
  onDisplayFarmSettings,
  handleUserTap,
  open,
  onImpersonate,
  otherFarms,
  changeFarm,
  logOut,
  updatePassword,
  onEditAppSettings,
}) => {
  const analytics = useFirebaseAnalytics();
  const ref = useRef(React.createRef());
  const LangFile = useLangFile();
  const farm = useFarm();
  const user = useUser();

  const [changePasswordShown, setChangePasswordShown] = useState(false);
  const [showSelectFarmDialog, setShowSelectFarmDialog] = useState(false);
  const [showDataIntegrationDialog, setShowDataIntegrationDialog] = useState(false);

  const editFarm = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ACCOUNT);
    handleUserClose();
    onDisplayFarmSettings();
  }, [handleUserClose, onDisplayFarmSettings]);

  const showChangePassword = useCallback((shown) => {
    setChangePasswordShown(shown);
  }, []);

  const handleDataIntegrationClicked = useCallback(() => {
    setShowDataIntegrationDialog(true);
  }, []);

  const editSettings = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_SETTINGS);
    handleUserClose();
    onEditAppSettings();
  }, [onEditAppSettings]);

  const onShowSelectFarmDialog = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_SELECT_FARM);
    setShowSelectFarmDialog(true);
  }, [ref]);

  const onUserTap = useCallback(
    (event) => {
      analytics.logEvent(FIREBASE_EVENTS.MM_OPEN);
      handleUserTap(event);
    },
    [handleUserTap]
  );

  const closeSelectFarmDialog = useCallback(() => {
    setShowSelectFarmDialog(null);
  }, []);

  return (
    <div id={'user-menu'}>
      <Tooltip title={LangFile.SettingsDrawer.header}>
        <IconButton onClick={onUserTap}>
          <AccountIcon color={'primary'} />
        </IconButton>
      </Tooltip>

      <Portal>
        <Drawer
          variant={'temporary'}
          anchor={'right'}
          onClose={handleUserClose}
          open={open}
          classes={{ paper: classes.paper }}
        >
          <AppBar position="static" color="primary" elevation={6}>
            <Toolbar>
              <IconButton onClick={handleUserClose} tooltip={LangFile.SettingsDrawer.close}>
                <NavigationClose style={{ color: 'white' }} />
              </IconButton>
              <Typography style={{ color: 'white' }}>{LangFile.SettingsDrawer.header}</Typography>
            </Toolbar>
          </AppBar>

          <List className={`${classes.list} narrow-scrollbar`}>
            <ListItem button onClick={onShowSelectFarmDialog}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText
                primary={farm.name || `${farm.owner.firstName} ${farm.owner.lastName}`}
              />
              <ListItemSecondaryAction>
                <ArrowDropRight color="primary" />
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />

            <ListItem button onClick={editFarm}>
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary={LangFile.SettingsDrawer.adminFarm} />
            </ListItem>

            <ListItem button onClick={editSettings}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={LangFile.SettingsDrawer.appSettings} />
            </ListItem>

            <ListItem button onClick={handleDataIntegrationClicked}>
              <ListItemIcon>
                <DeviceHub />
              </ListItemIcon>
              <ListItemText primary={LangFile.DataIntegrationDialog.title} />
            </ListItem>

            <Divider />

            <ImpersonationDialog
              email={user.email}
              onImpersonateSubmit={onImpersonate}
              LangFile={LangFile}
            />

            <ListItem button onClick={() => showChangePassword(true)}>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary={LangFile.SettingsDrawer.changePassword} />
            </ListItem>

            <ListItem button onClick={logOut}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary={LangFile.SettingsDrawer.logOut} />
            </ListItem>
          </List>
        </Drawer>
      </Portal>

      <ChangePassword
        onClose={() => showChangePassword(false)}
        shown={changePasswordShown}
        updatePassword={updatePassword}
      />

      <SelectFarmDialog
        farm={farm}
        onClose={closeSelectFarmDialog}
        onFarmSelected={changeFarm}
        otherFarms={otherFarms}
        shown={showSelectFarmDialog}
      />

      <DataIntegrationDialogContainer
        open={showDataIntegrationDialog}
        onClose={() => {
          setShowDataIntegrationDialog(false);
        }}
      />
    </div>
  );
};

export default memo(withStyles(styles)(SettingsDrawer));
