import React, { Fragment, memo, useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import {
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  ListSubheader,
  Divider,
} from '@material-ui/core';

import CloudIcon from '@material-ui/icons/Cloud';
import TimeIcon from '@material-ui/icons/Timelapse';
import NotesIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import AnalysisIcon from '@material-ui/icons/Assessment';
import PrescriptionIcon from '@material-ui/icons/GraphicEq';

import { getFieldName } from 'js/helpers/StateInterpreters';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import { SATELLITE_LAYERS } from 'js/constants/SatelliteLayers';
import { detectBrowser } from 'js/helpers/BrowserDetection';
import { useLangFile } from 'js/context/LanguageContext';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useEvent from '../../hooks/useEvent';
import { saveData } from '../../DownloadFile';
import { blue } from '@material-ui/core/colors';
import { useHasFeatures } from 'js/context/PermissionContext';
import FeatureConstants from 'js/constants/FeatureConstants';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';

const styles = (theme) => ({
  root: {
    zIndex: 8,
    position: 'relative',
    width: 225,
    '& > div[role="presentation"] > div[role="menu"]': {
      padding: '0 0 10px 0 !important',
    },
  },
  link: {
    color: blue['A700'],
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const getImageTypeName = (imageType) => {
  switch (imageType) {
    case SATELLITE_LAYERS.VITALITY_NDVI:
      return 'ndvi';
    case SATELLITE_LAYERS.VITALITY_NDRE:
      return 'ndre';
    case SATELLITE_LAYERS.VARIATIONS_NDVI:
      return 'ndvi_variation';
    case SATELLITE_LAYERS.VARIATIONS_NDRE:
      return 'ndre_variation';
    case SATELLITE_LAYERS.VISIBLE:
      return 'rgb_photo';
    default:
      return 'ndvi';
  }
};

const FieldPopupContent = ({
  classes,
  field,
  survey,
  fieldImage,
  imageType,
  date,
  onAnalyse,
  onPrescribe,
  onNotes,
  onSettings,
  satelliteImagerySelected,
}) => {
  const analytics = useFirebaseAnalytics();
  const legacySatellite = useHasFeatures([FeatureConstants.LEGACY_SATELLITE]);
  const [showDebug, setShowDebug] = useState(false);
  const LangFile = useLangFile();
  const Theme = useTheme();

  const handleDebugDown = useCallback((event) => {
    if (!event) {
      return;
    }

    if (event.altKey) {
      setShowDebug((val) => !val);
    }
  }, []);

  const onAnalyseClick = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.FPO_ANALYSIS);
    onAnalyse(field);
  }, [field]);

  const onPrescribeClick = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.FPO_VRM);
    onPrescribe(field);
  }, [field]);

  const onNotesClick = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.FPO_NOTES);
    onNotes(field);
  }, [field]);

  const onSettingsClick = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.FPO_SETTINGS);
    onSettings(field);
  }, [field]);

  useEvent('keydown', handleDebugDown);

  const ready = field.ready;
  const cloudy = !fieldImage || !(fieldImage[imageType] || fieldImage.url);
  const prescriptionEnabled =
    (legacySatellite && ready && satelliteImagerySelected && !!field.bundle) ||
    (survey != null && !satelliteImagerySelected);
  const analysisEnabled = prescriptionEnabled;

  let warningIcon = <CloudIcon color={'secondary'} />;
  let readyIcon = <TimeIcon color={'secondary'} />;
  let imageName =
    getFieldName(field, LangFile) + '_' + date + '_' + getImageTypeName(imageType) + '.png';
  let browser = detectBrowser();

  const downloadHandler = useCallback(async () => {
    analytics.logEvent(FIREBASE_EVENTS.FPO_DOWNLOAD);

    let response = await fetch(fieldImage.url, { method: 'GET', cache: 'no-cache' });
    let blob = await response.blob();
    saveData(blob, imageName);
  }, [fieldImage, imageName]);

  const downloadHandlerIE = useCallback(() => {
    let image = document.getElementById('hidden-image-download');
    let canvas = document.getElementById('hidden-image-canvas');
    let ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, image.clientWidth, image.clientHeight);
    window.navigator.msSaveBlob(canvas.msToBlob(), imageName);
  }, [fieldImage, imageName]);

  let cloudWarning =
    legacySatellite && ready && cloudy ? (
      <ListItem disabled divider>
        <ListItemIcon>{warningIcon}</ListItemIcon>
        <ListItemText
          primary={LangFile.FieldPopupContent.cloudy}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      </ListItem>
    ) : null;

  let readyWarning =
    legacySatellite && !ready ? (
      <ListItem disabled divider style={{ color: Theme.palette.secondary.main }}>
        <ListItemIcon style={{ width: 42 }}>{readyIcon}</ListItemIcon>
        <ListItemText
          primary={LangFile.FieldPopupContent.notReady}
          style={{ whiteSpace: 'normal' }}
        />
      </ListItem>
    ) : null;

  let downloadImageRow = !analysisEnabled ? null : (
    <TableRow style={{ height: 36 }}>
      <TableCell>{LangFile.FieldPopupContent.image}</TableCell>

      <TableCell>
        {browser.isIE ? (
          <Fragment>
            <div onClick={downloadHandlerIE} className={classes.link}>
              {LangFile.FieldPopupContent.download}
            </div>

            <img
              id={'hidden-image-download'}
              alt="hidden"
              src={fieldImage.url}
              style={{
                position: 'fixed',
                top: -9999,
                left: -9999,
              }}
              tabIndex={-1}
            />
            <canvas
              id={'hidden-image-canvas'}
              style={{ position: 'fixed', top: -9999, left: -9999 }}
              tabIndex={-1}
            />
          </Fragment>
        ) : (
          <div onClick={downloadHandler} className={classes.link}>
            {LangFile.FieldPopupContent.download}
          </div>
        )}
      </TableCell>
    </TableRow>
  );

  let placeholderIcon = <div style={{ width: '16.8px', height: '18.9px' }} />;

  return (
    <div className={classes.root}>
      <List dense>
        <ListSubheader disableSticky>{LangFile.FieldPopupContent.shortcuts}</ListSubheader>

        <ListItem button disabled={!analysisEnabled} onClick={onAnalyseClick} dense>
          <ListItemIcon>
            <AnalysisIcon color={'action'} />
          </ListItemIcon>
          <ListItemText primary={LangFile.FieldPopupContent.analyse} />
        </ListItem>

        <ListItem button onClick={onPrescribeClick} disabled={!prescriptionEnabled} dense>
          <ListItemIcon>
            <PrescriptionIcon color={'action'} />
          </ListItemIcon>
          <ListItemText primary={LangFile.FieldPopupContent.prescribe} />
          <ListItemSecondaryAction>
            {legacySatellite && ready && cloudy ? warningIcon : placeholderIcon}
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem onClick={onNotesClick} disabled={!analysisEnabled} button dense>
          <ListItemIcon>
            <NotesIcon color={'action'} />
          </ListItemIcon>
          <ListItemText primary={LangFile.FieldPopupContent.notes} />
          <ListItemSecondaryAction>
            {legacySatellite && !ready ? readyIcon : placeholderIcon}
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem onClick={onSettingsClick} button divider dense>
          <ListItemIcon>
            <SettingsIcon color={'action'} />
          </ListItemIcon>
          <ListItemText primary={LangFile.FieldPopupContent.manage} />
        </ListItem>

        {cloudWarning}
        {cloudWarning && <Divider />}

        {readyWarning}
        {readyWarning && <Divider />}
      </List>
      <Table size={'small'}>
        <TableBody>
          {showDebug && (
            <TableRow style={{ height: 36 }}>
              <TableCell>{LangFile.FieldPopupContent.bundleId}</TableCell>
              <TableCell>{field.bundle || '–'}</TableCell>
            </TableRow>
          )}
          {showDebug && (
            <TableRow style={{ height: 36 }}>
              <TableCell>{LangFile.FieldPopupContent.fieldId}</TableCell>
              <TableCell>{field.fieldId}</TableCell>
            </TableRow>
          )}
          <TableRow style={{ height: 36 }}>
            <TableCell>{LangFile.FieldPopupContent.name}</TableCell>
            <TableCell style={{ whiteSpace: 'break-spaces' }}>
              {field.name || field.mef || '-'}
            </TableCell>
          </TableRow>
          <TableRow style={{ height: 36 }}>
            <TableCell>{LangFile.FieldPopupContent.area}</TableCell>
            <TableCell>{field.size.toFixed(1) + ' ha'}</TableCell>
          </TableRow>
          {downloadImageRow}
        </TableBody>
      </Table>
    </div>
  );
};

FieldPopupContent.propTypes = {
  fieldImage: PropTypes.object,
  LangFile: PropTypes.object,
  field: PropTypes.object,
  date: PropTypes.string,
  onAnalyse: PropTypes.func,
  onSettings: PropTypes.func,
  onPrescribe: PropTypes.func,
  onNotes: PropTypes.func,
  satelliteImagerySelected: PropTypes.bool,
};

export default memo(withStyles(styles)(FieldPopupContent));
