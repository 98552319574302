import React, { memo, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Button, Grid, Box } from '@material-ui/core';
import moment from 'moment';
import { getDeviceLabel } from 'js/helpers/StateInterpreters';
import { useFarmSettings } from 'js/context/SettingsContext';

const SelectStationGrid = ({ stations, selected, setSelected }) => {
  const farmSettings = useFarmSettings();

  const handleSelect = (s) =>
    useCallback(() => {
      setSelected(s);
    }, []);

  return (
    <Box my={2}>
      <Grid container justify={'flex-start'} spacing={1}>
        {stations.map((s, idx) => {
          let label = getDeviceLabel(s.identifier, farmSettings);

          if (label !== s.label) {
            label += ` (${s.label})`;
          }

          return (
            <Grid item key={idx} onClick={handleSelect(s)}>
              <Button variant={'contained'} color={s === selected ? 'primary' : 'default'}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Box fontWeight={'bold'}>{label}</Box>
                  <Box style={{ fontWeight: '300 !important' }}>
                    {moment(s.claimedSince).format('LLL')}
                  </Box>
                </Box>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

SelectStationGrid.propTypes = {
  stations: PropTypes.array,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

SelectStationGrid.defaultProps = {
  setSelected: () => {},
};

export default memo(SelectStationGrid);
