import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CompareArrows from '@material-ui/icons/CompareArrows';
import Paper from '@material-ui/core/Paper';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AverageIcon from 'js/components/Icons/AverageIcon';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    overflow: 'visible',
  },
  paper: {
    overflow: 'visible',
  },
  title: {
    paddingTop: 6,
    paddingBottom: 6,
    borderBottom: '1px solid ' + grey['200'],
  },
  bottomNav: {
    width: 250,
    overflow: 'visible',
  },
  compareArrows: {
    zIndex: 200,
    position: 'absolute',
    top: -50,
    left: '50%',
    transform: 'translate(-50%, 0)',
    fill: grey['600'],
  },
});

class PrescriptionMergePopover extends Component {
  state = {
    highlightedOption: null,
  };

  handleClick = (option) => {
    let { primaryIndex, secondaryIndex } = this.props;

    let left, right;

    // Figure our the position of the intervals relative to each other
    if (primaryIndex < secondaryIndex) {
      left = primaryIndex;
      right = secondaryIndex;
    } else {
      left = secondaryIndex;
      right = primaryIndex;
    }

    this.props.onMergeIntervals(left, right, option);
  };

  handleMouseEnter = (option) => {
    this.setState({ highlightedOption: option });
  };

  handleMouseLeave = () => {
    this.setState({ highlightedOption: null });
  };

  render() {
    let { classes, LangFile, anchorEl, onCancel, primaryIndex, secondaryIndex } = this.props;

    if (primaryIndex === null || secondaryIndex === null) {
      return null;
    }

    let horizontalPosition = primaryIndex < secondaryIndex ? 'right' : 'left';

    return (
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: horizontalPosition,
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCancel}
        classes={{ paper: classes.root }}
        placement="top"
      >
        <Paper className={classes.paper}>
          <CompareArrows className={classes.compareArrows} />
          <ClickAwayListener onClickAway={onCancel}>
            <Fragment>
              <Typography align={'center'} variant={'h6'} noWrap={true} className={classes.title}>
                {LangFile.PrescriptionMergePopover.title}
              </Typography>
              <BottomNavigation
                showLabels={true}
                value={this.state.highlightedOption}
                className={classes.bottomNav}
                onMouseLeave={this.handleMouseLeave}
              >
                <BottomNavigationAction
                  onMouseUp={() => this.handleClick(0)}
                  label={LangFile.PrescriptionMergePopover.left}
                  icon={<ChevronLeft />}
                  onMouseEnter={() => this.handleMouseEnter(0)}
                />
                <BottomNavigationAction
                  onMouseUp={() => this.handleClick(1)}
                  label={LangFile.PrescriptionMergePopover.average}
                  icon={<AverageIcon />}
                  onMouseEnter={() => this.handleMouseEnter(1)}
                />
                <BottomNavigationAction
                  onMouseUp={() => this.handleClick(2)}
                  label={LangFile.PrescriptionMergePopover.right}
                  icon={<ChevronRight />}
                  onMouseEnter={() => this.handleMouseEnter(2)}
                />
              </BottomNavigation>
            </Fragment>
          </ClickAwayListener>
        </Paper>
      </Popover>
    );
  }
}

export default withStyles(styles)(PrescriptionMergePopover);

PrescriptionMergePopover.propTypes = {
  LangFile: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  onCancel: PropTypes.func,
  onMergeIntervals: PropTypes.func,
  primaryIndex: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  secondaryIndex: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  intervals: PropTypes.array,
};

PrescriptionMergePopover.defaultProps = {
  onCancel: () => {},
  onMergeIntervals: (left, right, option) => {},
  intervals: [],
};
