import React, { memo, useEffect, useRef, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { LanguageProvider } from 'js/context/LanguageContext';
import { getLanguageFile } from 'js/helpers/LanguageUtils';

import English from 'js/language/en';

const mapStateToProps = (store) => {
  return {
    language: store.language.language,
  };
};

const LanguageContainer = (props) => {
  const currentLanguage = useRef(props.language);
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    if (props.language) {
      setFile(getLanguageFile(props.language));

      currentLanguage.current = props.language;
    }
  }, [props.language]);

  return (
    <LanguageProvider value={{ language: props.language, LangFile: file || English }}>
      {props.children}
    </LanguageProvider>
  );
};

export default memo(connect(mapStateToProps)(LanguageContainer));
