import React, { memo, useCallback } from 'react';

import { useBottomDrawer } from '../../context/BottomDrawerContext';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons';
import StyleConstants from '../../StyleConstants';
import { useLangFile } from '../../context/LanguageContext';
import Box from '@material-ui/core/Box';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';

const BottomDrawerToggle = () => {
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const { open, setOpen } = useBottomDrawer();

  const onToggle = useCallback(() => {
    setOpen((current) => {
      if (current) {
        analytics.logEvent(FIREBASE_EVENTS.BOTTOM_DRAWER_MINIMIZE);
      } else {
        analytics.logEvent(FIREBASE_EVENTS.BOTTOM_DRAWER_MAXIMIZE);
      }

      return !current;
    });
  }, []);

  return (
    <Box mx={2}>
      <Tooltip title={open ? LangFile.BottomDrawerToggle.hide : LangFile.BottomDrawerToggle.show}>
        <Fab size="large" onClick={onToggle}>
          {open ? (
            <KeyboardArrowDownRounded
              style={{ backgroundColor: StyleConstants.colors.white, color: '#000' }}
            />
          ) : (
            <KeyboardArrowUpRounded
              style={{ backgroundColor: StyleConstants.colors.white, color: '#000' }}
            />
          )}
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default memo(BottomDrawerToggle);
