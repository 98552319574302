import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { polygon } from '@turf/turf';

import useOnMouseLeave from 'js/components/DataLayer/hooks/useOnMouseLeave';
import { useDataLayer } from 'js/context/DataLayerContext';

const FeaturePolygon = ({ id, coords }) => {
  const dataLayer = useDataLayer();
  const feature = useRef();

  useOnMouseLeave(dataLayer, () => {
    dataLayer.revertStyle();
  });

  useEffect(() => {
    if (dataLayer) {
      let p = polygon(coords);
      p.properties['id'] = id;

      feature.current = dataLayer.addGeoJson(p);

      return () => {
        if (feature.current) {
          feature.current.forEach((f) => dataLayer.remove(f));
        }
      };
    }
  }, [id, coords, dataLayer]);

  return null;
};

FeaturePolygon.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.array),
};

FeaturePolygon.defaultProps = {};

/** @component */
export default memo(FeaturePolygon);
