import WebAPIUtils from 'js/WebAPIUtils';

/* ============== //
||      TYPES     ||
// ============== */

const FETCH_NEWS = 'fieldsense/NewsReducer/FETCH_NEWS';
const SET_ARTICLES_SEEN = 'fieldsense/NewsReducer/SET_ARTICLES_SEEN';

/* ============== //
||     ACTIONS    ||
// ============== */

export function fetchNews(farmId, lang) {
  return {
    type: FETCH_NEWS,
    payload: WebAPIUtils.fetchNews(farmId, lang),
  };
}

export function setArticlesSeen(articles) {
  return {
    type: SET_ARTICLES_SEEN,
    payload: articles,
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  articles: [],
  seenArticles: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case FETCH_NEWS + '_FULFILLED': {
      let articles = action.payload ? [...action.payload.news] : [];

      state = { ...state, articles: articles };
      break;
    }

    case SET_ARTICLES_SEEN: {
      let seen = action.payload;
      state = { ...state, seenArticles: seen };
      break;
    }

    default:
      break;
  }

  return state;
}
