import NumberUtils from 'js/helpers/NumberUtils';

export const getIntersection = (limitline, line) => {
  let limit = limitline.y1;

  let firstPoint = line[0];
  let lastPoint = line[1];

  if (firstPoint.y < limit && lastPoint.y > limit) {
    return limit;
  } else if (firstPoint.y > limit && lastPoint.y < limit) {
    return limit;
  }

  return null;
};

export const getSplitPoint = (line, value) => {
  let firstPoint = line[0],
    lastPoint = line[1];

  let time = NumberUtils.map(value, firstPoint.y, lastPoint.y, firstPoint.x, lastPoint.x);
  return { x: time, y: value };
};

export const getSplitLines = (line, limitlines) => {
  let intersection;

  Object.values(limitlines).forEach((limit) => {
    let inters = getIntersection(limit.line, line);

    if (inters) {
      intersection = inters;
    }
  });

  if (intersection) {
    let splitPoint = getSplitPoint(line, intersection);
    let lineA = [line[0], splitPoint];
    let lineB = [splitPoint, line[1]];

    return [...getSplitLines(lineA, limitlines), ...getSplitLines(lineB, limitlines)];
  } else {
    return [line];
  }
};

export const getLineSegments = (points, limitlines) => {
  let result = [];
  let path = [];

  points.forEach((point, idx) => {
    if (path.length === 0) {
      // First point starts the first path
      path.push(point);
    } else {
      // Draw a line from this point to the previous point
      let prev = path[path.length - 1];
      let line = [prev, point];

      // Recursively split the line into smaller lines if they intersect with the limitlines.
      let lines = getSplitLines(line, limitlines);

      // Add the last point of the first line to the current path
      let lastPoint = lines[0][1];
      path.push(lastPoint);

      if (lines.length > 1) {
        // Finish off the first path
        result.push(path);

        // All remaining paths
        for (let i = 1; i < lines.length; i++) {
          let L = lines[i];

          if (i !== lines.length - 1) {
            // all paths between the first and the last are independent paths, push them directly to the result
            result.push(L);
          } else {
            // The last path is left open and will be evaluated when the points loop continues
            path = L;
          }
        }
      }
    }

    // Make sure to add the last path built
    if (idx === points.length - 1) {
      result.push(path);
    }
  });

  return result;
};
