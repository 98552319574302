import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DataLayer from 'js/components/DataLayer/DataLayer';
import FeaturePaper from 'js/components/DataLayer/FeaturePaper';
import { LocationOnRounded, CloseRounded } from '@material-ui/icons';
import { Paper, Tooltip } from '@material-ui/core';
import { getLatLngValues } from 'js/helpers/MapsUtils';

const styles = (theme) => ({
  root: {},
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'all',
  },
  closeButton: {
    position: 'absolute',
    top: -24,
    right: -24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    opacity: 0.5,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

const SearchMapTarget = ({ classes, latLng, onRemove, text }) => {
  if (!latLng) {
    return null;
  }

  let { lat, lng } = getLatLngValues(latLng);

  return (
    <DataLayer>
      <FeaturePaper round lat={lat} lng={lng}>
        <div className={classes.wrapper}>
          <Tooltip title={text}>
            <LocationOnRounded />
          </Tooltip>

          <Paper className={classes.closeButton} onClick={onRemove}>
            <CloseRounded fontSize={'small'} />
          </Paper>
        </div>
      </FeaturePaper>
    </DataLayer>
  );
};

SearchMapTarget.propTypes = {
  googleMap: PropTypes.object,
  latLng: PropTypes.object,
  onRemove: PropTypes.func,
  text: PropTypes.string,
};

export default withStyles(styles)(SearchMapTarget);
