'use strict';

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { point, distance } from '@turf/turf';

import PropTypes from 'prop-types';

import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
  Grid,
  Button,
  Dialog,
  TextField,
  Box,
  Paper,
  List,
  ListSubheader,
  ListItem,
  IconButton,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useLangFile } from 'js/context/LanguageContext';
import { getNoteTypeIcon, getNoteTypeName, NoteTypes } from 'js/constants/NoteTypes';
import { getPolygonAnchor } from '../MapObjects/MapNote';
import ManualFeatureConstants from '../../constants/ManualFeatureConstants';
import { Clear } from '@material-ui/icons';

const styles = (theme) => ({
  dialogPaper: {
    overflow: 'visible',
  },
  icon: {
    fontSize: 36,
    fill: grey['800'],
    '&.selected': {
      fill: theme.palette.primary.main,
    },
  },
  button: {
    margin: [[0, theme.spacing(1)]],
  },
  content: {
    overflow: 'visible',
  },
});

const getNotePoint = (note) => {
  const anchor = getPolygonAnchor(note.polygon);
  return point([anchor.lng, anchor.lat]);
};

function NoteCollisionPaper(props) {
  const LangFile = useLangFile();
  const rootRef = useRef();

  const onClick = useCallback(
    (n) => {
      if (props.isDirtyState) {
        props.handleUpdateNote(props.selectedNote);
      }
      props.onCollisionNoteClicked(n);
    },
    [props.handleUpdateNote, props.selectedNote, props.onCollisionNoteClicked, props.isDirtyState]
  );

  if (!props.collidedNotes || props.collidedNotes.length <= 1) {
    return null;
  }

  return (
    <Box
      ref={rootRef}
      position={'absolute'}
      left={0}
      style={{ transform: 'translate(-100%, 0px) translateX(-32px)' }}
      height={'100%'}
    >
      <Paper elevation={4}>
        <List dense={true} style={{ overflow: 'auto', cursor: 'pointer' }}>
          <ListSubheader>{LangFile.NoteCollisionPaper.listHeader}</ListSubheader>
          {props.collidedNotes.map((note) => (
            <ListItem
              dense={true}
              button={true}
              alignItems={'center'}
              selected={note.id === props.selectedNote.id}
              key={'note:' + note.id}
              onClick={() => onClick(note)}
            >
              {React.createElement(
                getNoteTypeIcon(note.type),
                {
                  style: {
                    fontSize: 18,
                  },
                },
                null
              )}
              <Box pr={1} />
              <span>{note.text}</span>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

const NoteDialog = ({
  classes,
  note,
  notes,
  shown,
  activeDate,
  onDismiss,
  createNote,
  field,
  updateNote,
  deleteNote,
  onCollisionNoteClicked,
  onEditMakerLocation,
}) => {
  const LangFile = useLangFile();
  const [currentNote, setCurrentNote] = useState(note);
  const [collidedNotes, setCollidedNotes] = useState([]);

  const isDirtyState = currentNote !== note;

  useEffect(() => {
    if (notes && note) {
      let fieldNotes = notes[note.fieldId];

      if (fieldNotes && fieldNotes.length > 0) {
        const anchor = getNotePoint(note);

        let collisions = fieldNotes.filter((n) => distance(anchor, getNotePoint(n)) < 0.001);

        setCollidedNotes(collisions);
      }
    }
  }, [note, notes]);

  useEffect(() => {
    if (note) {
      setCurrentNote(note);
    }
  }, [note]);

  const handleUpdateImage = useCallback(
    (noteType) => {
      setCurrentNote({ ...currentNote, type: noteType });
    },
    [currentNote]
  );

  const handleUpdateDate = useCallback(
    (notePersistence) => {
      let date = '';
      if (notePersistence === 'CURRENT') {
        date = activeDate;
      }

      setCurrentNote({ ...currentNote, date: date });
    },
    [currentNote, activeDate]
  );

  const handleUpdateText = useCallback(
    (event) => {
      setCurrentNote({ ...currentNote, text: event.target.value });
    },
    [currentNote]
  );

  const handleOnDismiss = useCallback(() => {
    onDismiss();
  }, []);

  const handleCreateNote = useCallback(() => {
    createNote(
      field.fieldId,
      currentNote.date,
      currentNote.polygon,
      currentNote.text,
      currentNote.type
    );
    onDismiss();
  }, [currentNote, field]);

  const handleUpdateNote = useCallback(() => {
    updateNote(currentNote);
    onDismiss();
  }, [currentNote]);

  const handleDeleteNote = useCallback(() => {
    deleteNote(currentNote, field.fieldId);
    onDismiss();
  }, [currentNote, field]);

  const renderNoteTypeButtons = () => {
    let uiElements = [];

    Object.keys(NoteTypes).map((noteType, index) => {
      let noteName = getNoteTypeName(LangFile, noteType);
      let selected = noteType === currentNote.type;

      let element = (
        <Tooltip title={noteName} placement="top" key={`note_typeBtns${index}`}>
          <Button
            variant={'contained'}
            color={selected ? 'primary' : 'default'}
            className={classes.button}
            onClick={() => handleUpdateImage(noteType)}
          >
            {React.createElement(
              getNoteTypeIcon(noteType),
              {
                className: `${classes.icon} ${selected ? 'selected' : ''}`,
              },
              null
            )}
          </Button>
        </Tooltip>
      );

      uiElements.push(element);
    });

    return uiElements;
  };

  const renderNotePersistenceButtons = () => {
    let uiElements = [];

    Object.keys(LangFile.NoteDialog.notePersistence).map((key) => {
      let notePersistence = LangFile.NoteDialog.notePersistence[key];
      let sameDate =
        currentNote.date &&
        (currentNote.date === activeDate || currentNote.date.split('T')[0] === activeDate);

      let selected =
        (notePersistence === LangFile.NoteDialog.notePersistence.CURRENT && sameDate) ||
        (notePersistence === LangFile.NoteDialog.notePersistence.PERSIST &&
          (!currentNote.date || currentNote.date === ''));

      let element = (
        <Button
          key={key}
          variant={'contained'}
          color={selected ? 'primary' : 'default'}
          className={classes.button}
          onClick={() => handleUpdateDate(key)}
        >
          {'' + notePersistence}
        </Button>
      );

      uiElements.push(element);
    });

    return uiElements;
  };

  return (
    <Dialog open={shown} fullWidth maxWidth={'md'} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <span>
            {currentNote.id ? LangFile.NoteDialog.editNote : LangFile.NoteDialog.createNote}
          </span>
          <Tooltip title={LangFile.NoteDialog.close}>
            <IconButton onClick={handleOnDismiss}>
              <Clear />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Box width={'100%'} height={'100%'} position={'relative'}>
          {ManualFeatureConstants.NOTE_COLLISION_LIST && (
            <NoteCollisionPaper
              selectedNote={note}
              isDirtyState={isDirtyState}
              handleUpdateNote={handleUpdateNote}
              onCollisionNoteClicked={onCollisionNoteClicked}
              collidedNotes={collidedNotes}
            />
          )}

          <Grid container spacing={4}>
            <Grid item xs={2}>
              {LangFile.NoteDialog.type}
            </Grid>
            <Grid item container xs={10} wrap={'nowrap'} justify={'flex-start'}>
              {renderNoteTypeButtons()}
            </Grid>

            <Grid item xs={2}>
              {LangFile.NoteDialog.visibility}
            </Grid>
            <Grid item container xs={10} wrap={'nowrap'} justify={'flex-start'}>
              {renderNotePersistenceButtons()}
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant={'outlined'}
                id="note-text-input"
                value={currentNote.text || ''}
                fullWidth
                multiline
                rows={3}
                rowsMax={10}
                autoFocus
                placeholder={LangFile.NoteDialog.noteHintText}
                onChange={handleUpdateText}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
          {currentNote.id && (
            <Button key={'note_editBtn1'} color={'secondary'} onClick={handleDeleteNote}>
              {LangFile.NoteDialog.delete}
            </Button>
          )}

          <Box display={'inline-flex'}>
            {currentNote.id ? (
              <>
                <Button
                  key={'note_editLocationBtn'}
                  variant="text"
                  color="primary"
                  onClick={onEditMakerLocation}
                >
                  {LangFile.NoteDialog.editLocation}
                </Button>
                <Box pr={1} />
                <Button
                  key={'note_editBtn3'}
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateNote}
                >
                  {LangFile.NoteDialog.update}
                </Button>
              </>
            ) : (
              <Button
                key={'note_actionBtn2'}
                variant="contained"
                color="primary"
                onClick={handleCreateNote}
              >
                {LangFile.NoteDialog.create}
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default memo(withStyles(styles)(NoteDialog));

NoteDialog.propTypes = {
  /** The language file providing strings for the current language*/
  LangFile: PropTypes.object,
  /** Tell if the modal should be displayed or not */
  shown: PropTypes.bool,
  /** The current date to create the note for */
  activateDate: PropTypes.object,
  /** The field for which the note is created */
  field: PropTypes.object,
  /** The note to display in the dialog. If the note has an id it is treated as an updated note. Otherwise it is a new note */
  note: PropTypes.object.isRequired,
  /** The function invoked when a note should be deleted */
  deleteNote: PropTypes.func,
  /** The function invoked when a note is updated */
  updateNote: PropTypes.func,
  /** The function invoked when a new note is created */
  createNote: PropTypes.func,
  /** Invoked when the modal is closed */
  onDismiss: PropTypes.func,
  /** Invoked when a collision note is clicked*/
  onCollisionNoteClicked: PropTypes.func,
  /** Invoked when the position of a note is to be editing on the map*/
  onEditMakerLocation: PropTypes.func,
};
