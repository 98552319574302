import { SATELLITE_LAYERS } from 'js/constants/SatelliteLayers';
import { YIELD_LAYERS } from './YieldLayer';

const tinycolor = require('tinycolor2');

export const imageTypes = {
  [SATELLITE_LAYERS.VITALITY_NDVI]: {
    intervals: [
      { min: 0, max: 0.1, r: 102, g: 51, b: 1 },
      { min: 0.1, max: 0.2, r: 153, g: 102, b: 51 },
      { min: 0.2, max: 0.3, r: 204, g: 153, b: 51 },
      { min: 0.3, max: 0.4, r: 255, g: 204, b: 51 },
      { min: 0.4, max: 0.5, r: 255, g: 255, b: 102 },
      { min: 0.5, max: 0.65, r: 153, g: 255, b: 153 },
      { min: 0.65, max: 0.7, r: 1, g: 255, b: 1 },
      { min: 0.7, max: 0.75, r: 1, g: 204, b: 1 },
      { min: 0.75, max: 0.8, r: 1, g: 153, b: 1 },
      { min: 0.8, max: 1, r: 1, g: 102, b: 1 },
      { min: 1, max: 1, r: 1, g: 51, b: 1 },
    ],
  },
  [SATELLITE_LAYERS.VITALITY_NDRE]: {
    intervals: [
      { min: -1, max: -0.8, r: 162, g: 73, b: 24 },
      { min: -0.8, max: -0.6, r: 184, g: 109, b: 39 },
      { min: -0.6, max: -0.4, r: 204, g: 146, b: 62 },
      { min: -0.4, max: -0.2, r: 221, g: 181, b: 88 },
      { min: -0.2, max: 0, r: 238, g: 218, b: 121 },
      { min: 0, max: 0.2, r: 212, g: 227, b: 124 },
      { min: 0.2, max: 0.4, r: 169, g: 201, b: 94 },
      { min: 0.4, max: 0.6, r: 125, g: 175, b: 65 },
      { min: 0.6, max: 0.8, r: 79, g: 149, b: 38 },
      { min: 0.8, max: 1, r: 2, g: 123, b: 9 },
    ],
  },
  [SATELLITE_LAYERS.VARIATIONS_NDVI]: {
    intervals: [
      { min: 0, max: 0.166667, r: 0, g: 0, b: 102 },
      { min: 0.166667, max: 0.2, r: 7, g: 0, b: 250 },
      { min: 0.2, max: 0.333333, r: 0, g: 41, b: 255 },
      { min: 0.333333, max: 0.45, r: 0, g: 255, b: 255 },
      { min: 0.45, max: 0.5, r: 0, g: 196, b: 69 },
      { min: 0.5, max: 0.625, r: 13, g: 179, b: 0 },
      { min: 0.625, max: 0.65, r: 213, g: 242, b: 0 },
      { min: 0.65, max: 0.666667, r: 243, g: 255, b: 0 },
      { min: 0.666667, max: 0.8, r: 255, g: 238, b: 0 },
      { min: 0.8, max: 0.916667, r: 255, g: 97, b: 0 },
      { min: 0.916667, max: 0.95, r: 255, g: 14, b: 0 },
      { min: 0.95, max: 1, r: 228, g: 0, b: 0 },
      { min: 1, max: 1, r: 132, g: 0, b: 0 },
    ],
  },
  [SATELLITE_LAYERS.VARIATIONS_NDRE]: {
    intervals: [
      { min: 0, max: 0.166667, r: 0, g: 0, b: 102 },
      { min: 0.166667, max: 0.2, r: 7, g: 0, b: 250 },
      { min: 0.2, max: 0.333333, r: 0, g: 41, b: 255 },
      { min: 0.333333, max: 0.45, r: 0, g: 255, b: 255 },
      { min: 0.45, max: 0.5, r: 0, g: 196, b: 69 },
      { min: 0.5, max: 0.625, r: 13, g: 179, b: 0 },
      { min: 0.625, max: 0.65, r: 213, g: 242, b: 0 },
      { min: 0.65, max: 0.666667, r: 243, g: 255, b: 0 },
      { min: 0.666667, max: 0.8, r: 255, g: 238, b: 0 },
      { min: 0.8, max: 0.916667, r: 255, g: 97, b: 0 },
      { min: 0.916667, max: 0.95, r: 255, g: 14, b: 0 },
      { min: 0.95, max: 1, r: 228, g: 0, b: 0 },
      { min: 1, max: 1, r: 132, g: 0, b: 0 },
    ],
  },
};

export const getImageTypeColorScale = (imageType) => {
  const imageTypeObject = imageTypes[imageType];
  if (imageTypeObject) {
    return imageTypeObject['intervals'].map((step, idx, arr) => {
      let color = tinycolor({ r: step.r, g: step.g, b: step.b });
      return {
        min: step.min,
        max: step.max,
        color: color.toString(),
        contrast: 'white',
      };
    });
  } else {
    return null;
  }
};

export const getVariableColorScale = (imageIntervals) => {
  return imageIntervals
    ? imageIntervals.map((step, idx, arr) => {
        let color = tinycolor({ r: step.r, g: step.g, b: step.b });
        return {
          min: step.min,
          max: step.max,
          color: color.toString(),
          contrast: 'white',
        };
      })
    : null;
};

// Generate a LUT with 256 different colors generated by the given intervals
// This way we can quickly archieve approximated colors.
let generateLUTArray = (intervals) => {
  // Create a scaled version of the intervals with values from 0 to 255
  let scaledIntervals = {};
  intervals.forEach((interval, idx) => {
    let current = { ...interval };
    current.val = Math.floor(current.min * 255);
    scaledIntervals[current.val] = { r: current.r, g: current.g, b: current.b };
  });

  // Create the lut
  let lut = [];
  for (let val = 0; val < 256; val++) {
    // Make the lookup in the scaledIntervals
    let below = 0;
    let above = 0;
    for (let prop in scaledIntervals) {
      if (prop > val) {
        above = prop;
        break;
      }
      below = prop;
    }

    if (above === 0 || below === 1) {
      let color =
        'rgb(' +
        scaledIntervals[below].r +
        ',' +
        scaledIntervals[below].g +
        ',' +
        scaledIntervals[below].b +
        ')';
      lut.push(color);
      continue;
    }

    // The t is the interpolation parameter, at below it will be 0 at above it will be 1
    let t = (val - below) / (above - below);
    let r = Math.floor(
      scaledIntervals[below].r + (scaledIntervals[above].r - scaledIntervals[below].r) * t
    );
    let g = Math.floor(
      scaledIntervals[below].g + (scaledIntervals[above].g - scaledIntervals[below].g) * t
    );
    let b = Math.floor(
      scaledIntervals[below].b + (scaledIntervals[above].b - scaledIntervals[below].b) * t
    );

    let color = 'rgb(0,0,0)';
    color = 'rgb(' + r + ',' + g + ',' + b + ')';
    lut.push(color);
  }
  return lut;
};

export const generateLUTArrayForLayer = (layer) => {
  const imageType = imageTypes[layer];
  if (imageType) {
    return generateLUTArray(imageType['intervals']);
  }
};

// let vitalityNDVILUT = generateLUTArray(imageTypes[SATELLITE_LAYERS.VITALITY_NDVI]['intervals']);
// let vitalityNDRELUT = generateLUTArray(imageTypes[SATELLITE_LAYERS.VITALITY_NDRE]['intervals']);
// let variationsNDVILUT = generateLUTArray(imageTypes[SATELLITE_LAYERS.VARIATIONS_NDVI]['intervals']);
// let variationsNDVILUT = generateLUTArray(imageTypes[SATELLITE_LAYERS.VARIATIONS_NDVI]['intervals']);

// export {variationsNDVILUT};
// export {vitalityNDVILUT};
export { generateLUTArray };
export default imageTypes;
