// @flow

import React, { memo, useContext, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import Box from '@material-ui/core/Box';

const ActionSnackbarContext = React.createContext({});

export const ActionSnackbarProvider = ActionSnackbarContext.Provider;
export const ActionSnackbarConsumer = ActionSnackbarContext.Consumer;

export function useActionSnackbarContext(): {
  actions: Array<Action>,
  addAction: function,
  removeAction: function,
} {
  return useContext(ActionSnackbarContext);
}

const ActionSnackbarHandler = (props: ActionSnackbarHandler.propTypes) => {
  const [actions, setActions] = useState([]);

  const removeAction = useCallback((action) => {
    setActions((current) => [...current.filter((item) => item.id !== action.id)]);
  }, []);

  const addAction = useCallback((action) => {
    removeAction(action);
    setActions((current) => [...current, action]);
  }, []);

  return (
    <ActionSnackbarProvider value={{ completedActions: actions, addAction, removeAction }}>
      {props.children}
      {actions.map((action: Action, idx) => {
        let onClose = () => removeAction(action);

        return (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={onClose}
            key={action.id}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ top: 24 + 56 * idx }}
          >
            <Box boxShadow={3}>
              <Alert onClose={onClose} severity={action.severity}>
                {action.message}
              </Alert>
            </Box>
          </Snackbar>
        );
      })}
    </ActionSnackbarProvider>
  );
};

ActionSnackbarHandler.propTypes = {
  children: PropTypes.any,
};

export class Action {
  id: number | string;
  message: any;
  severity: string;
  variant: string;

  /**
   * @param {String|Number} id Used to distinguish actions from one another.
   * @param {*} message The message to display in the snackbar.
   * @param {String} severity "success", "error", "warning", "info"
   * @param {String} variant "standard", "filled", "outlined
   */
  constructor(id, message, severity, variant) {
    this.id = id;
    this.message = message;
    this.severity = severity;
    this.variant = variant;
  }
}

export default memo(ActionSnackbarHandler);
