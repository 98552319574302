import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@material-ui/core';
import TextFormControl from 'js/components/UI-Elements/TextFormControl.jsx';
import Button from '@material-ui/core/Button';
import { useLangFile } from 'js/context/LanguageContext';
import useEvent from 'js/hooks/useEvent';

import Styles from './CreateBufferDialog.module.less';

const CreateBufferDialog = ({ open, onUpdate, initialWidth, initialValue, unit, onClose }) => {
  const LangFile = useLangFile();

  const [updatedWidth, setUpdatedWidth] = useState(Number(initialWidth));
  const [updatedValue, setUpdatedValue] = useState(Number(initialValue));
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setUpdatedWidth(Number(initialWidth));
  }, [initialWidth]);

  useEffect(() => {
    setUpdatedValue(Number(initialValue));
  }, [initialValue]);

  const handleOnError = (key) =>
    useCallback((error) => {
      setErrors((current) => ({ ...current, [key]: error }));
    }, []);

  const onCommit = useCallback(() => {
    if (open) {
      onUpdate(Number(updatedWidth), Number(updatedValue));
      onClose();
    }
  }, [open, updatedWidth, updatedValue, onUpdate, onClose]);

  const handleKeyboardEvent = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onCommit();
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        onClose();
      }
    },
    [onClose, onCommit]
  );

  useEvent('keydown', handleKeyboardEvent);

  // If there are any errors, disable the submit button
  let disabled = Object.keys(errors)
    .map((key) => errors[key])
    .reduce((result, current) => result || current, false);

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onEscapeKeyDown={onClose}
      disableBackdropClick={true}>
      <DialogTitle>{LangFile.CreateBufferDialog.title}</DialogTitle>
      <DialogContent classes={{ root: Styles.Root }}>
        <TextFormControl
          label={LangFile.CreateBufferDialog.labelWidth}
          disablePadding
          autoFocus
          value={updatedWidth}
          required={true}
          acceptZero={true}
          acceptEmpty={false}
          type={'number'}
          defaultValue={initialWidth}
          onError={handleOnError('bufferWidth')}
          errorText={LangFile.CreateBufferDialog.errorWidth}
          onChange={setUpdatedWidth}
          onUpdate={setUpdatedWidth}
        />

        <Box py={2} />

        <TextFormControl
          label={LangFile.CreateBufferDialog.labelValue}
          disablePadding
          endAdornment={unit}
          value={updatedValue}
          required={true}
          acceptZero={true}
          acceptEmpty={false}
          type={'number'}
          defaultValue={initialValue}
          onError={handleOnError('bufferValue')}
          errorText={LangFile.CreateBufferDialog.errorValue}
          onChange={setUpdatedValue}
          onUpdate={setUpdatedValue}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'primary'} onClick={() => onClose()}>
          {LangFile.EditTextDialog.cancel}
        </Button>
        <Button variant={'contained'} disabled={disabled} color={'primary'} onClick={onCommit}>
          {LangFile.EditTextDialog.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateBufferDialog.propTypes = {
  open: PropTypes.bool,
  initialWidth: PropTypes.any,
  initialValue: PropTypes.any,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  unit: PropTypes.string,
};

export default memo(CreateBufferDialog);
