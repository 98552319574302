import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SurveyStatisticsBarGroup from './SurveyStatisticsBarGroup';
import Box from '@material-ui/core/Box';
import Styles from './SurveyStatisticsBarChart.module.less';

const SurveyStatisticsBarChart = ({ items, width }) => {
  const barHeight = 25;

  const barGroups = items.map((d, i) => (
    <Box key={i} transform={`translate(0, ${i * barHeight})`}>
      <SurveyStatisticsBarGroup entry={d} barHeight={barHeight} width={width} />
    </Box>
  ));

  return <Box className={Styles.container}>{barGroups}</Box>;
};

SurveyStatisticsBarChart.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.number,
};

export default memo(SurveyStatisticsBarChart);
