'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_5" data-name="Layer 5">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <path d="M22.5,5.5H8.2A1.79,1.79,0,0,0,6.4,7.3V21.6a1.79,1.79,0,0,0,1.8,1.8H22.5a1.79,1.79,0,0,0,1.8-1.8V7.3A1.93,1.93,0,0,0,22.5,5.5ZM11.7,21.6H8.1V18h3.6Zm0-5.4H8.1V12.6h3.6Zm0-5.3H8.1V7.3h3.6Zm5.4,10.7H13.5V18h3.6Zm0-5.4H13.5V12.6h3.6Zm0-5.3H13.5V7.3h3.6Zm5.4,10.7H18.9V18h3.6Zm0-5.4H18.9V12.6h3.6Zm0-5.3H18.9V7.3h3.6Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
