import React, { memo, useCallback, useEffect } from 'react';

import {
  selectStation,
  fetchStationReadings,
  setShowWeatherStations,
  setShowWeatherDrawer,
  setDateRange,
  setWeatherSensor,
  setAccumulation,
  fetchWeatherStations,
  setEnableWindGust,
  setEnableSprayConditions,
} from 'js/reducers/WeatherReducer';
import { updateGlobalFarmSetting } from 'js/reducers/SettingsReducer';
import { getDeviceLabel } from 'js/helpers/StateInterpreters';
import WeatherDrawer from './WeatherDrawer';
import { saveViewport, setKeyboardShortcutsEnabled, setViewMode } from 'js/reducers/ControlReducer';

import { connect } from 'react-redux';
import FeatureConstants from 'js/constants/FeatureConstants';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import { useHasFeatures } from 'js/context/PermissionContext';
import { getPresetPeriod } from 'js/helpers/WeatherUtils';
import { useGoogleMap } from 'js/context/GoogleMapContext';
import moment from 'moment';
import { getWeatherDataPeriod, periodResolution } from '../../helpers/WeatherUtils';
import {
  RAIN_COLOR,
  RAIN_COLOR_SECONDARY,
  WeatherPeriod,
  WeatherSensor,
} from '../../constants/WeatherConstants';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { useMeasureSettings } from '../../context/AppSettings/AppSettingsContext';

const mapStateToProps = (store) => {
  return {
    farm: store.farm.farm,
    viewMode: store.control.viewMode,
    stations: store.weather.stations,
    weatherData: store.weather.weatherData,
    weatherSensor: store.weather.weatherSensor,
    selectedStation: store.weather.selectedStation,
    stationReadings: store.weather.stationReadings,
    enableWindGust: store.weather.enableWindGust,
    enableSprayConditions: store.weather.enableSprayConditions,
    period: store.weather.period,
    dateRangeStart: store.weather.dateRangeStart,
    dateRangeEnd: store.weather.dateRangeEnd,
    resolution: store.weather.resolution,
    displayData: store.weather.displayData,
    accumulation: store.weather.accumulation,
    error: store.weather.error,
    showWeatherStations: store.weather.showWeatherStations,
    showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
    loadingStations: store.weather.loadingStations,
    loadingReadings: store.weather.loadingReadings,
    showWeatherDrawer: store.weather.showWeatherDrawer,
    language: store.language,
    isDrawingField: store.addField.isDrawing,
    isAddingFields: store.addField.isAddingFields,
    isImportingFields: store.addField.isImportingFields,
    isDrawingNote: store.note.isDrawing,
    farmSettings: store.settings.farmSettings,
  };
};

const WeatherDrawerContainer = ({
  dispatch,
  farm,
  resolution,
  showWeatherStations,
  weatherData,
  showWeatherDrawer,
  isDrawingField,
  isDrawingNote,
  isAddingFields,
  isImportingFields,
  weatherSensor,
  showWeatherNetwork,
  viewMode,
  stationReadings,
  period,
  dateRangeStart,
  dateRangeEnd,
  stations,
  selectedStation,
  language,
  displayData,
  error,
  loadingStations,
  loadingReadings,
  farmSettings,
  accumulation,
  enableWindGust,
  enableSprayConditions,
}) => {
  const analytics = useFirebaseAnalytics();
  const googleMap = useGoogleMap();
  const measureSettings = useMeasureSettings();
  const shown = showWeatherDrawer && showWeatherStations && viewMode === ViewModeConstants.OVERVIEW;
  const hide =
    isDrawingField || isDrawingNote || isImportingFields || isAddingFields || showWeatherNetwork;

  const onChangeStation = useCallback((identifier) => {
    if (identifier) {
      dispatch(selectStation(identifier));
    }
  }, []);

  const onTickClicked = useCallback(
    (millis) => {
      if (weatherData) {
        let start = moment(millis);
        let end = start.clone();
        let { since, until } = getWeatherDataPeriod(weatherData);
        let diff = Math.abs(moment(since).diff(moment(until), 'days'));

        if (diff <= 14) {
          // select a day
          end = end.add(1, 'day');
        } else if (diff <= 100) {
          // select a week
          end = end.add(1, 'week');
        } else if (diff > 100) {
          // select a month
          end = end.add(1, 'month');
        } else {
          return;
        }

        dispatch(setDateRange(start, end));
      }
    },
    [weatherData]
  );

  const onChangeDateRange = useCallback((start, end, preset) => {
    dispatch(setDateRange(start, end, preset));
  }, []);

  const onChangeSensor = useCallback(
    (value) => {
      if (!loadingReadings) {
        analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_SENSOR, { sensor: value });
        dispatch(setWeatherSensor(value));
      }
    },
    [loadingReadings]
  );

  const onEnableWindGustChanged = useCallback((value) => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_SETTINGS_GUSTS, { shown: value });

    dispatch(setEnableWindGust(value));
  }, []);

  const onEnableSprayConditionsChanged = useCallback((value) => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_SETTINGS_SPRAYING_CONDITIONS, {
      shown: value,
    });

    dispatch(setEnableSprayConditions(value));
  }, []);

  const onSelectPeriodPreset = useCallback(
    (preset) => {
      if (preset && selectedStation) {
        let logSuffix = '';
        switch (preset) {
          case WeatherPeriod.DAY:
            logSuffix = '24H';
            break;
          case WeatherPeriod.WEEK:
            logSuffix = '7D';
            break;
          case WeatherPeriod.WEEKS4:
            logSuffix = '4W';
            break;
          default:
            break;
        }

        analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_PRESET + logSuffix);

        let { start, end } = getPresetPeriod(moment(), preset);

        onChangeDateRange(start, end, preset);
      }
    },
    [selectedStation, weatherSensor]
  );

  const onAccumulationChanged = useCallback(
    (value) => {
      analytics.logEvent(FIREBASE_EVENTS.WEATHER_LIST_ACCUMULATION, { accumulation: value });

      dispatch(setAccumulation(value));
      dispatch(fetchWeatherStations(farm.farmId, value, measureSettings));
    },
    [farm, measureSettings]
  );

  const onManageConnections = useCallback(
    (stationId) => {
      if (stationId && googleMap) {
        dispatch(saveViewport(googleMap.getCenter(), googleMap.getZoom()));
        dispatch(selectStation(stationId));
        dispatch(setViewMode(ViewModeConstants.MANAGE_WEATHER_STATION_FIELD_CONNECTIONS));
      }
    },
    [googleMap]
  );

  useEffect(() => {
    let id = selectedStation && selectedStation.id;

    if (!id) {
      return;
    }

    if (!measureSettings) {
      console.error('Invalid measure settings');
      console.trace(measureSettings);
      return;
    }

    let since = dateRangeStart,
      until = dateRangeEnd;
    if (!dateRangeEnd || !dateRangeStart) {
      let { start, end } = getPresetPeriod(moment(), period);
      since = start;
      until = end;
    }

    if (weatherSensor === WeatherSensor.LUX || weatherSensor === WeatherSensor.UV) {
      if (until.isAfter(moment())) {
        until = moment();
      }
    }

    let sensors = [];
    let days = Math.abs(since.diff(until, 'days'));
    let resolution = periodResolution(since, until, weatherSensor);

    if (weatherSensor === WeatherSensor.COMBINED_TEMP) {
      sensors.push({ sensor: WeatherSensor.AIR_TEMP, axis: 'left', resolution });
      sensors.push({ sensor: WeatherSensor.SOIL_TEMP, axis: 'left', resolution });

      if (days > 1) {
        sensors.push({ sensor: WeatherSensor.GDD, axis: 'right', resolution });
      }
    } else if (weatherSensor === WeatherSensor.WIND) {
      sensors.push({ sensor: WeatherSensor.WIND, axis: 'left', resolution });
      if (enableWindGust) {
        sensors.push({ sensor: WeatherSensor.WIND_MAX, axis: 'left', resolution });
      }
    } else if (weatherSensor === WeatherSensor.RAIN) {
      if (days < 3) {
        sensors.push({
          sensor: WeatherSensor.RAIN_HOURLY,
          axis: 'left',
          resolution: '2h',
          color: RAIN_COLOR_SECONDARY,
        });
        sensors.push({ sensor: WeatherSensor.RAIN, axis: 'left', color: RAIN_COLOR });
      } else if (days < 33) {
        sensors.push({
          sensor: WeatherSensor.RAIN_DAILY,
          axis: 'left',
          resolution: '1d',
          color: RAIN_COLOR_SECONDARY,
        });
        sensors.push({
          sensor: WeatherSensor.RAIN_HOURLY,
          axis: 'left',
          resolution: '2h',
          color: RAIN_COLOR,
        });
      } else {
        sensors.push({
          sensor: WeatherSensor.RAIN_WEEKLY,
          axis: 'left',
          resolution: '1w',
          color: RAIN_COLOR_SECONDARY,
        });
        sensors.push({
          sensor: WeatherSensor.RAIN_DAILY,
          axis: 'left',
          resolution: '1d',
          color: RAIN_COLOR,
        });
      }
    } else if (weatherSensor === WeatherSensor.LUX) {
      sensors.push({ sensor: WeatherSensor.SOLAR_RADIATION, axis: 'left', resolution: resolution });
    } else {
      sensors.push({ sensor: weatherSensor, axis: 'left', resolution });
    }

    dispatch(fetchStationReadings(id, farm.farmId, since, until, sensors, measureSettings));
  }, [farm, dateRangeStart, dateRangeEnd, weatherSensor, selectedStation, period, measureSettings]);

  if (!useHasFeatures([FeatureConstants.LEGACY_WEATHER])) {
    return null;
  }

  return (
    <WeatherDrawer
      shown={shown && !hide}
      googleMap={googleMap}
      weatherSensor={weatherSensor}
      weatherData={weatherData}
      dateRangeStart={dateRangeStart}
      dateRangeEnd={dateRangeEnd}
      setShowWeatherDrawer={(show) => dispatch(setShowWeatherDrawer(show))}
      showWeatherStations={showWeatherStations}
      stations={stations}
      language={language}
      loadingStations={loadingStations}
      loadingReadings={loadingReadings}
      error={error}
      displayData={displayData}
      selectedStation={selectedStation}
      stationReadings={stationReadings}
      period={period}
      resolution={resolution}
      accumulation={accumulation}
      onAccumulationChanged={onAccumulationChanged}
      onManageConnections={onManageConnections}
      onEnableShortcuts={() => dispatch(setKeyboardShortcutsEnabled(true))}
      onDisableShortcuts={() => dispatch(setKeyboardShortcutsEnabled(false))}
      onClose={() => dispatch(setShowWeatherStations(false))}
      onSelectPeriodPreset={onSelectPeriodPreset}
      onTickClicked={onTickClicked}
      onChangeStation={onChangeStation}
      onChangeSensor={onChangeSensor}
      onChangeDateRange={onChangeDateRange}
      enableWindGust={enableWindGust}
      enableSprayConditions={enableSprayConditions}
      onEnableWindGustChanged={onEnableWindGustChanged}
      onEnableSprayConditionsChanged={onEnableSprayConditionsChanged}
      onUpdateGlobalFarmSetting={(entry, newName) =>
        dispatch(updateGlobalFarmSetting(farm.farmId, entry, newName))
      }
      setViewMode={(value) => dispatch(setViewMode(value))}
      getDeviceLabel={(label) => getDeviceLabel(label, farmSettings)}
    />
  );
};

export default memo(connect(mapStateToProps)(WeatherDrawerContainer));
