/*
  This is the decorator component providing the google maps script to our google map.
*/

import React from 'react';

import cache from './ScriptCache';
import GoogleMapsAPI from './GoogleMapsAPI';
import APIConstants from 'js/APIConstants';

const wrapper = (options) => (WrappedComponent) => {
  const apiKey = options.apiKey || APIConstants.mapKey;
  const libraries = options.libraries || ['drawing', 'places', 'visualization'];

  class GoogleMapsAPIComponent extends React.Component {
    constructor(props, context) {
      super(props, context);

      this.isUnMounted = false;

      this.scriptCache = cache(
        GoogleMapsAPI({
          apiKey: apiKey,
          libraries: libraries,
        })
      );

      this.state = {
        google: window.google || null,
      };
    }

    componentDidMount() {
      this.waitForScript();
    }

    waitForScript = () => {
      this.scriptCache.then(() => {
        if (this.isUnMounted) {
          return;
        }
        this.setState({
          google: window.google,
        });
      });
    };

    componentWillUnmount() {
      this.isUnMounted = true;
    }

    render() {
      const props = {
        ...this.props,
        google: this.state.google,
      };

      return props.google && <WrappedComponent {...props} />;
    }
  }

  return GoogleMapsAPIComponent;
};

export default wrapper;
