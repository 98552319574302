import React, { useContext } from 'react';

const DrawerPortalContext = React.createContext({});

export const DrawerPortalProvider = DrawerPortalContext.Provider;
export const DrawerPortalConsumer = DrawerPortalContext.Consumer;

export function useDrawerPortal() {
  return useContext(DrawerPortalContext);
}
