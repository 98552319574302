import React, { Fragment, memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import MapCanvas, { drawImageURL } from '../MapObjects/MapCanvas/MapCanvas.jsx';
import DataLayer from '../DataLayer/DataLayer';
import { yellow } from '@material-ui/core/colors';
import FeaturePolygon from '../DataLayer/FeaturePolygon';
import MapCanvasTooltip from '../MapObjects/MapCanvas/MapCanvasTooltip';
import { voidFunc } from '../../constants/PropTypeUtils';
import { useColoringStrategy } from '../../model/surveys/useColoringStrategy';
import ManualFeatureConstants from '../../constants/ManualFeatureConstants';
import { iterateValidValues } from '../../helpers/SurveyUtils';
import MapCanvasDemandTooltip from '../MapObjects/MapCanvas/MapCanvasDemandTooltip';
import { useSurveyLayerViewCapabilities } from '../../context/SurveyContext';
const tinycolor = require('tinycolor2');

const GRID_RES = 10;

const getDimensions = (values) => {
  let height, width;

  if (Array.isArray(values)) {
    height = values.length;

    const row = values[0];
    if (Array.isArray(row)) {
      width = row.length;
    }
  }

  return { height, width };
};

function setPixel(imageData, x, y, color) {
  const { r, g, b, a } = color;
  const index = (x + y * imageData.width) * 4;
  imageData.data[index] = r;
  imageData.data[index + 1] = g;
  imageData.data[index + 2] = b;
  imageData.data[index + 3] = Math.floor(a * 255);
}

const AnalysisMapView = (props: AnalysisMapView.propTypes) => {
  const primaryValues = props.primaryValues;
  const [canvasSize, setCanvasSize] = useState(getDimensions(primaryValues));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const coloringStrategy = useColoringStrategy(
    props.selectedLayer,
    props.viewMode,
    props.classificationsEnabled,
    props.variationsEnabled,
    primaryValues
  );
  const surveyLayerViewCapabilities = useSurveyLayerViewCapabilities(
    props.selectedLayer,
    props.viewMode
  );
  const [imageURL, setImageURL] = useState(null);

  // Handles drawing of surveys on the map canvas.
  const drawSource = useCallback(
    (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
      if (props.imageURL === imageURL) {
        return;
      }
      setLoading(true);
      setError(false);

      if (!props.imageURL) {
        return;
      }

      drawImageURL(
        context,
        canvas,
        props.imageURL,
        null,
        null,
        ManualFeatureConstants.DRAW_IMAGES_MANUALLY
      )
        .then((dimensions) => {
          if (ManualFeatureConstants.DRAW_IMAGES_MANUALLY) {
            const { width, height } = dimensions;
            const imageData = context.createImageData(width, height);

            iterateValidValues(primaryValues, (x, y, value) => {
              const hexColor = coloringStrategy.getColor(value);
              const rgbaColor = tinycolor(hexColor).toRgb();

              setPixel(imageData, x, y, rgbaColor);
            });

            context.putImageData(imageData, 0, 0, 0, 0, width, height);
          }
          setImageURL(props.imageURL);
          setCanvasSize(dimensions);
          setLoading(false);
          setError(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    },
    [props.imageURL, primaryValues, coloringStrategy]
  );

  // Handles drawing of grid lines / cells on the map canvas.
  const onGridCanvasDraw = useCallback(
    (ctx) => {
      if (ctx && primaryValues) {
        const width = canvasSize.width * GRID_RES;
        const height = canvasSize.height * GRID_RES;

        ctx.strokeStyle = 'rgba(0,0,0,0.1)';
        ctx.lineWidth = 0.25;

        for (let y = 0; y < height; y += GRID_RES) {
          const col = primaryValues[y / GRID_RES];

          for (let x = 0; x < width; x += GRID_RES) {
            const value = col && col[x / GRID_RES];
            if (value > 0) {
              ctx.strokeRect(x, y, GRID_RES, GRID_RES);
            }
          }
        }

        ctx.stroke();
      }
    },
    [primaryValues, canvasSize]
  );

  if (!props.selectedField) {
    return null;
  }

  return (
    <Fragment>
      <DataLayer
        onClick={props.clickable ? props.onFieldClicked : null}
        setStyle={(feature) => {
          return {
            strokeColor: yellow['A400'],
            fillOpacity: 0,
            clickable: props.clickable,
          };
        }}>
        <FeaturePolygon coords={props.selectedField.polygon.coordinates} />
      </DataLayer>

      <MapCanvas
        id={'canvas-source'}
        zIndex={1}
        error={error}
        loading={loading}
        clipPathPolygon={props.selectedField.polygon.coordinates[0]}
        bounds={props.selectedField.bounds}
        width={canvasSize.width}
        height={canvasSize.height}
        onDraw={drawSource}
      />

      {props.enableGrid && !error && !loading && (
        <MapCanvas
          id={'canvas-grid'}
          onDraw={onGridCanvasDraw}
          zIndex={2}
          bounds={props.selectedField.bounds}
          clipPathPolygon={props.selectedField.polygon.coordinates[0]}
          width={canvasSize.width * GRID_RES}
          height={canvasSize.height * GRID_RES}
        />
      )}

      {props.enableTooltip &&
        !(props.classificationsEnabled && surveyLayerViewCapabilities.enableCustomClassification) &&
        !error &&
        !loading && (
          <MapCanvasTooltip
            zIndex={3}
            resolutionScale={GRID_RES}
            canvasSize={canvasSize}
            selectedField={props.selectedField}
            onTargetChanged={props.onTargetChanged}
            classificationsEnabled={props.classificationsEnabled}
            variationsEnabled={props.variationsEnabled}
            layers={[props.selectedLayer, ...props.multipleSelections]}
            values={props.values}
          />
        )}

      {props.enableTooltip &&
        props.classificationsEnabled &&
        surveyLayerViewCapabilities.enableCustomClassification &&
        !error &&
        !loading && (
          <MapCanvasDemandTooltip
            zIndex={3}
            resolutionScale={GRID_RES}
            canvasSize={canvasSize}
            selectedField={props.selectedField}
            onTargetChanged={props.onTargetChanged}
            classificationsEnabled={props.classificationsEnabled}
            variationsEnabled={props.variationsEnabled}
            layers={[props.selectedLayer, ...props.multipleSelections]}
            values={props.values}
            classificationValues={props.classificationValues}
          />
        )}
    </Fragment>
  );
};

AnalysisMapView.propTypes = {
  values: PropTypes.object,
  classificationValues: PropTypes.object,
  primaryValues: PropTypes.array,
  viewMode: PropTypes.string,
  imageURL: PropTypes.string,
  selectedLayer: PropTypes.string,
  selectedField: PropTypes.object,
  multipleSelections: PropTypes.array,
  enableTooltip: PropTypes.bool,
  enableGrid: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  onTargetChanged: PropTypes.func,
  onFieldClicked: PropTypes.func,
  clickable: PropTypes.bool,
};

AnalysisMapView.defaultProps = {
  multipleSelections: [],
  onTargetChanged: voidFunc,
  onFieldClicked: voidFunc,
};

export default memo(AnalysisMapView);
