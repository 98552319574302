'use strict';
import React, { memo } from 'react';

import FarmSettings from './FarmSettings';
import { connect } from 'react-redux';
import { updateGlobalFarmSetting, updatePersonalFarmSetting } from 'js/reducers/SettingsReducer';
import { addUserToFarm, removeUserFromFarm } from 'js/reducers/FarmReducer';
import { setShowFarmSettings } from 'js/reducers/ControlReducer';
import SettingsConstants from 'js/constants/SettingsConstants';
import { logout } from 'js/reducers/UserReducer';
import { getOwnerFarm } from 'js/helpers/FarmUtils';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import { useIsAdmin } from '../../context/AccountContext';
import { useLangFile } from '../../context/LanguageContext';

const mapStoreToProps = (store) => ({
  shown: store.control.showFarmSettings,
  farm: store.farm.farm,
  farms: store.farm.farms,
  fields: store.field.fields,
  language: store.language.language,
  user: store.user.user,
  permissions: store.permissions,
  userFarmSettings: store.settings.farmSettings.personal,
  loggedIn: store.credential.loggedIn,
});

const FarmSettingsContainer = (props) => {
  const LangFile = useLangFile();
  const { addAction } = useActionSnackbarContext();
  const isAdmin = useIsAdmin();

  let updateFarmName = (newName) => {
    if (isAdmin) {
      props
        .dispatch(updateGlobalFarmSetting(props.farm.farmId, SettingsConstants.FARM_NAME, newName))
        .then(() => {
          addAction(
            new Action(
              'farm-name-success',
              LangFile.FarmSettingsContainer.updatedFarmNameSuccess,
              'success',
              'filled'
            )
          );
        })
        .catch((e) => {
          addAction(
            new Action(
              'farm-name-error',
              LangFile.FarmSettingsContainer.updatedFarmNameError,
              'error',
              'filled'
            )
          );
        });
    }
  };

  let handleUpdatePersonalFarmSetting = (entry, changedValue) => {
    props.dispatch(updatePersonalFarmSetting(props.farm.farmId, entry, changedValue));
  };

  let addUser = (userObject) => {
    let farmId = props.farm.farmId;
    props
      .dispatch(addUserToFarm(farmId, userObject))
      .then(() => {
        addAction(
          new Action(
            'add-user-success',
            LangFile.FarmSettingsContainer.userAddedSuccess,
            'success',
            'filled'
          )
        );
      })
      .catch((e) => {
        addAction(
          new Action(
            'add-user-error',
            LangFile.FarmSettingsContainer.userAddedError,
            'error',
            'filled'
          )
        );
      });
  };

  let removeUser = (id) => {
    props
      .dispatch(removeUserFromFarm(id, props.farm.farmId))
      .then(() => {
        if (id === props.user.id) {
          addAction(
            new Action(
              'remove-user-self',
              LangFile.FarmSettingsContainer.removeUserSelf,
              'warning',
              'filled'
            )
          );
          props.dispatch(setShowFarmSettings(false));
          removeSelfFromFarm();
        } else {
          addAction(
            new Action(
              'remove-user-success',
              LangFile.FarmSettingsContainer.removeUserSuccess,
              'success',
              'filled'
            )
          );
        }
      })
      .catch((e) => {
        addAction(
          new Action(
            'remove-user-error',
            LangFile.FarmSettingsContainer.removeUserError,
            'error',
            'filled'
          )
        );
      });
  };

  let removeSelfFromFarm = () => {
    // Find your own farm
    let ownFarm = getOwnerFarm(props.farms);

    if (!ownFarm) {
      props.dispatch(logout());
      return;
    }

    props.changeFarm(ownFarm);
  };

  if (!props.shown) {
    return null;
  }

  return (
    <FarmSettings
      fields={props.fields}
      shown={props.shown}
      language={props.language}
      onDismiss={() => props.dispatch(setShowFarmSettings(false))}
      updateFarmName={updateFarmName}
      userFarmSettings={props.userFarmSettings}
      updateFarmSetting={handleUpdatePersonalFarmSetting}
      addUser={addUser}
      user={props.user}
      farm={props.farm}
      removeUser={removeUser}
    />
  );
};

export default memo(connect(mapStoreToProps)(FarmSettingsContainer));
