import NetworkSensor from 'js/model/network/NetworkSensor';
import { RAIN_SCALE, TEMPERATURE_SCALE, WIND_SCALE } from 'js/constants/WeatherNetworkColorScales';
import { mapTemperatureMeasurement, mapWindMeasurement } from './MeasureUtils';
import { TEMPERATURE, WIND } from '../constants/MeasureConstants';
import { KEYS } from '../reducers/AppSettingsReducer';

export const getScale = (sensor, measureSettings) => {
  let temperatureUnit = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
  let windUnit = measureSettings[KEYS.MEASURE_UNIT_WIND];

  if (sensor === NetworkSensor.AIR_TEMP_24H || sensor === NetworkSensor.SOIL_TEMP_24H) {
    return TEMPERATURE_SCALE.map((step) => ({
      min: mapTemperatureMeasurement(step.min, TEMPERATURE.CELCIUS, temperatureUnit),
      max: mapTemperatureMeasurement(step.max, TEMPERATURE.CELCIUS, temperatureUnit),
      color: step.color,
      contrast: step.contrast,
    }));
  } else if (sensor === NetworkSensor.WIND_AVG) {
    return WIND_SCALE.map((step) => ({
      min: mapWindMeasurement(step.min, WIND.METERS_PER_SECOND, windUnit),
      max: mapWindMeasurement(step.max, WIND.METERS_PER_SECOND, windUnit),
      color: step.color,
      contrast: step.contrast,
    }));
  } else {
    return RAIN_SCALE.map((step, idx, arr) => ({
      min: step.min,
      max: step.max,
      color: step.color,
      contrast: step.contrast,
    }));
  }
};
