'use strict';

import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import Toast from 'js/components/UI-Elements/Toast';

import { createFieldsMap, fetchDates, fetchFields } from 'js/reducers/FieldReducer';
import { connect } from 'react-redux';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import { useFarm } from 'js/context/AccountContext';
import { useLangFile } from 'js/context/LanguageContext';
import { useSelectedSeason } from 'js/context/SeasonContext';
import { useHasFeatures } from 'js/context/PermissionContext';
import FeatureConstants from 'js/constants/FeatureConstants';
import LocationIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { goToFields } from 'js/helpers/MapsUtils';
import { useGoogleMap } from 'js/context/GoogleMapContext';

const REFRESH_INTERVAL_MILLIS = 60000; // 60s

const mapStoreToProps = (store) => ({
  drawingField: store.addField.isDrawing,
  isAddingFields: store.addField.outlines.length !== 0,
  drawingNote: store.note.isDrawing,
  unprocessedFields: store.field.unprocessedFields,
  fields: store.field.fields,
  changingFarm: store.farm.changingFarm,
  viewMode: store.control.viewMode,
});

const ToastContainer = ({
  dispatch,
  drawingField,
  isAddingFields,
  drawingNote,
  unprocessedFields,
  changingFarm,
  viewMode,
}) => {
  const LangFile = useLangFile();
  const farm = useFarm();
  const selectedSeason = useSelectedSeason();
  const googleMap = useGoogleMap();

  const [showFieldsToast, setShowFieldsToast] = useState(null);
  const [fieldsToastHidden, setFieldsToastHidden] = useState(false);

  const fieldRefreshTask = useRef(null);
  const legacySatellite = useHasFeatures([FeatureConstants.LEGACY_SATELLITE]);

  const evaluateFields = useCallback(() => {
    let farmId = farm.farmId;

    // If any fields aren't ready we should inform (keep informing) the user and show the toast
    let shouldInform = unprocessedFields.length > 0;

    // If we should start/keep informing
    if (shouldInform) {
      clearTimeout(fieldRefreshTask.current);

      fieldRefreshTask.current = setTimeout(() => {
        dispatch(fetchFields(selectedSeason.id, true));
      }, REFRESH_INTERVAL_MILLIS);

      setShowFieldsToast(unprocessedFields);
    }

    // If we should stop informing
    else if (showFieldsToast && !shouldInform) {
      clearTimeout(fieldRefreshTask.current);

      setShowFieldsToast(null);
      dispatch(fetchDates(farmId));
      dispatch(fetchFields(selectedSeason.id, false));
    }
  }, [unprocessedFields, showFieldsToast, farm, selectedSeason]);

  const onHide = useCallback(() => {
    setFieldsToastHidden(true);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(fieldRefreshTask.current);
    };
  }, []);

  useEffect(() => {
    if (changingFarm) {
      clearTimeout(fieldRefreshTask.current);
    }
  }, [changingFarm]);

  useEffect(() => {
    if (legacySatellite) {
      evaluateFields();
    }
  }, [evaluateFields, legacySatellite]);

  if (viewMode !== ViewModeConstants.OVERVIEW || isAddingFields || drawingField || drawingNote) {
    return null;
  }

  const pluralString =
    unprocessedFields.length === 1
      ? LangFile.ToastContainer.fieldProcessing
      : LangFile.ToastContainer.fieldsProcessing;
  const title = unprocessedFields.length + ' ' + pluralString;

  const content = (
    <Box display={'flex'} alignItems={'center'}>
      <Box pr={1}>{title}</Box>
      <IconButton
        size={'small'}
        onClick={() => goToFields(googleMap, createFieldsMap(unprocessedFields))}
      >
        <LocationIcon color={'primary'} />
      </IconButton>
    </Box>
  );

  return (
    <Toast shown={showFieldsToast && !fieldsToastHidden} onClose={onHide} title={content}></Toast>
  );
};

export default memo(connect(mapStoreToProps)(ToastContainer));
