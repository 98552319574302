import { useCallback, useState } from 'react';
import useMapEvent from 'js/components/DataLayer/hooks/useMapEvent';
import { useHookRef } from 'js/hooks/useHookRef';

const useGoogleMapCenter = (googleMap) => {
  const mapRef = useHookRef(googleMap);

  const [latLng, setLatLng] = useState(mapRef.current.getCenter());

  useMapEvent(
    googleMap,
    'drag',
    useCallback((e) => {
      setLatLng(mapRef.current.getCenter());
    }, [])
  );

  return latLng;
};

export default useGoogleMapCenter;
