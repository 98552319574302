import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import type { LAYER_CONFIG } from 'js/model/surveys/SurveyLayerConfig';
import ListItem from '@material-ui/core/ListItem';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedOutlined from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlined from '@material-ui/icons/RadioButtonCheckedOutlined';
import { getLayerConfig } from 'js/components/Prescription/PrescriptionUtils';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';
import { voidFunc } from 'js/constants/PropTypeUtils';
import useShiftKeyDown from 'js/hooks/useShiftKeyDown';
import { useLangFile } from 'js/context/LanguageContext';
import { getSourceCategory } from '../../helpers/SurveyUtils';
import { SOURCE_CATEGORY } from '../Prescription/PrescriptionJob';

const MapLegendLayerListItem = (props: MapLegendLayerListItem.propTypes) => {
  const config: LAYER_CONFIG = getLayerConfig(props.layer);
  const shiftDown = useShiftKeyDown();
  const LangFile = useLangFile();

  const handleOnClick = useCallback(() => {
    if (shiftDown && props.enableMultipleSelect) {
      props.onCheckboxClick(props.layer);
    } else {
      props.onClick(props.layer);
    }
  }, [props.layer, props.onClick, shiftDown, props.enableMultipleSelect, props.onCheckboxClick]);

  const handleOnCheckboxClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (props.enableMultipleSelect) {
        props.onCheckboxClick(props.layer);
      } else {
        props.onClick(props.layer);
      }
    },
    [props.layer, props.enableMultipleSelect, props.onClick, props.onCheckboxClick]
  );

  if (!config) {
    return null;
  }

  const icon = props.enableMultipleSelect ? (
    <CheckBoxOutlineBlankOutlined fontSize="small" />
  ) : (
    <RadioButtonUncheckedOutlined fontSize="small" />
  );
  const checkedIcon = props.enableMultipleSelect ? (
    <CheckBoxOutlined fontSize="small" />
  ) : (
    <RadioButtonCheckedOutlined fontSize="small" />
  );

  let title = config.getName(LangFile);
  const category = getSourceCategory(props.layer);
  if (category === SOURCE_CATEGORY.VITALITY) {
    const shortHand = config.getShorthand(LangFile);
    if (shortHand) {
      title = title + ` (${shortHand})`;
    }
  }

  return (
    <ListItem
      dense={true}
      button={true}
      disabled={props.disabled}
      onClick={handleOnClick}
      key={props.layer}>
      {!props.disableCheckbox && (
        <ListItemIcon style={{ minWidth: 36 }}>
          <Checkbox
            style={{ padding: 0 }}
            checked={props.isSelected}
            onClick={handleOnCheckboxClicked}
            icon={icon}
            checkedIcon={checkedIcon}
            color="primary"
          />
        </ListItemIcon>
      )}
      <ListItemText primary={title} />
      {props.renderListItemSecondayAction && props.renderListItemSecondayAction()}
    </ListItem>
  );
};

MapLegendLayerListItem.propTypes = {
  layer: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  disabled: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  enableMultipleSelect: PropTypes.bool,

  elevated: PropTypes.bool,
  colorStrategy: PropTypes.object,
  title: PropTypes.string,
  renderListItemSecondayAction: PropTypes.func,
};

MapLegendLayerListItem.defaultProps = {
  onClick: voidFunc,
  onCheckboxClick: voidFunc,
};

export default memo(MapLegendLayerListItem);
