import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

import FeatureDOM from 'js/components/DataLayer/FeatureDOM';

const FeaturePaper = (props) => {
  let {
    children,
    elevation,
    onMouseEnter,
    onMouseLeave,
    onClick,
    round,
    clickable,
    disablePadding,
  } = props;
  const theme = useTheme();

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: clickable && onClick ? 'pointer' : 'inherit',
    borderRadius: round ? 500 : 4,
    padding: theme.spacing(disablePadding ? 0 : 1),
    position: 'relative',
  };

  return (
    <FeatureDOM {...props}>
      <Paper
        style={style}
        elevation={elevation}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {children}
      </Paper>
    </FeatureDOM>
  );
};

FeaturePaper.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  elevation: PropTypes.number,
  round: PropTypes.bool,
  disablePadding: PropTypes.bool,
};

FeaturePaper.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
  elevation: 2,
  round: false,
  disablePadding: false,
};

/** @component */
export default memo(FeaturePaper);
