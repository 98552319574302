import axios from 'axios';
import APIConstants from 'js/APIConstants';
import store from 'js/Store';
import { setAccessToken } from 'js/reducers/CredentialReducer';
import { logout } from 'js/reducers/UserReducer';
import { getDeviceInfoHeader } from 'js/WebAPIUtils';

const getAccessToken = () => {
  return store.getState().credential.accessToken;
};

const isLoggedIn = () => {
  return store.getState().credential.loggedIn;
};

const onAuthorizationFailed = (error) => {
  if (isLoggedIn()) {
    store.dispatch(logout());
  }
};

export default class TokenHandler {
  constructor() {
    this.unauthorizedClient = this.createUnauthorizedClient();
    this.refreshHandler = null;
    this.isRefreshing = false;
    this.requestQueue = [];
    this.accessToken = null;
  }

  /**
   * Create an Axios client WITHOUT authorization response interceptors.
   * @returns {AxiosInstance} the client.
   */
  createUnauthorizedClient() {
    let client = axios.create({ baseURL: APIConstants.baseURL });

    client.interceptors.response.use(
      function (response) {
        return response.data;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return client;
  }

  createAuthClient = () => {
    let client = axios.create({ baseURL: APIConstants.baseURL });
    let this_ = this;

    // REQUEST INTERCEPTOR
    client.interceptors.request.use(
      function (config) {
        config.headers.Authorization = getAccessToken();

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // RESPONSE INTERCEPTOR
    client.interceptors.response.use(
      function (response) {
        return response.data;
      },
      function (error) {
        if (error && error.response) {
          const {
            config,
            response: { status },
          } = error;
          if (status === 401) {
            if (!this_.isRefreshing) {
              this_.isRefreshing = true;

              return new Promise((resolve) => {
                this_
                  .refreshAccessTokenPromise()
                  .then((token) => {
                    if (token) {
                      config.headers.Authorization = token;
                      this_.accessToken = token;
                      resolve(this_.unauthorizedClient(config));
                    } else {
                      onAuthorizationFailed(error);
                    }
                  })
                  .catch((error) => onAuthorizationFailed(error))
                  .finally(() => {
                    this_.processRequestQueue();
                    this_.isRefreshing = false;
                    this_.accessToken = null;
                  });
              });
            } else {
              return new Promise((resolve) => {
                this_.enqueueRequest(() => {
                  config.headers.Authorization = this_.accessToken;
                  resolve(this_.unauthorizedClient(config));
                });
              });
            }
          }
          return Promise.reject(error);
        }
      }
    );

    return client;
  };

  createKanisaClient = () => {
    return this.createAuthClient();
  };

  refreshAccessTokenPromise = () => {
    return new Promise((resolve, reject) => {
      let refreshToken = store.getState().credential.refreshToken;

      // Short circuit if the refresh token is undefined
      if (refreshToken == null) {
        reject(new Error('No refresh token found'));
      }

      // Fetch new access token
      resolve(
        this.unauthorizedClient
          .post(
            'tokens/refresh',
            { refreshToken },
            {
              headers: {
                ...getDeviceInfoHeader(),
              },
            }
          )
          .catch((error) => {
            // Logout if an error occurred during token refreshing
            onAuthorizationFailed(error);
          })
      );
    })
      .then((tokenRefreshResponse) => {
        if (tokenRefreshResponse == null) {
          return Promise.reject(new Error('RefreshToken expired.'));
        }

        // Get the new access token from the response body
        let token = 'Bearer ' + tokenRefreshResponse.accessToken;

        // Save the new token in the redux store
        store.dispatch(setAccessToken(tokenRefreshResponse));

        // Return the token to the promise chain to initiate resending of the queue
        return Promise.resolve(token);
      })
      .catch((error) => onAuthorizationFailed(error));
  };

  enqueueRequest = (request) => {
    this.requestQueue.push(request);
  };

  processRequestQueue = () => {
    while (this.requestQueue.length > 0) {
      const request = this.requestQueue.shift();
      request();
    }
  };
}
