import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HelpIcon from '@material-ui/icons/Help';

import { ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';

import { useLangFile } from 'js/context/LanguageContext';
import MarkdownArticleDialog from 'js/components/MarkdownArticleDialog/MarkdownArticleDialog';
import { setHelpArticleSeen } from 'js/reducers/HelpReducer';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { voidFunc } from '../../constants/PropTypeUtils';
import LanguageConstants from '../../constants/LanguageConstants';

const mapStateToProps = (store) => {
  return {
    helpArticles: store.help.helpArticles,
    seenHelpArticleIDs: store.help.seenHelpArticleIDs,
    language: store.language.language,
    showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
  };
};

const styles = (theme) => ({
  iconRoot: {
    minWidth: 36,
  },
});

const HelpItemContainer = ({
  dispatch,
  classes,
  helpArticles,
  seenHelpArticleIDs,
  language,
  articleId,
  showWeatherNetwork,
  type,
  onClose,
  onShow,
}) => {
  const LangFile = useLangFile();
  const [showArticle, setShowArticle] = useState(false);

  const articleRoot = helpArticles[articleId];
  const article = articleRoot && (articleRoot[language] || articleRoot[LanguageConstants.ENGLISH]);
  const seen = article && seenHelpArticleIDs.indexOf(article.id) !== -1;

  const handleOnClick = useCallback(() => setShowArticle(true), []);

  useEffect(() => {
    if (showWeatherNetwork && article) {
      if (!seen) {
        setShowArticle(true);
        onShow();
      }
    }
  }, [article, seen, showWeatherNetwork, onShow]);

  const handleOnClose = useCallback(
    (a) => {
      if (article && !seen) {
        dispatch(setHelpArticleSeen(a.id));
      }
      setShowArticle(false);
      onClose();
    },
    [article, seen, onClose]
  );

  if (!article) {
    return null;
  }

  let item;
  if (type === 'button') {
    item = (
      <Button variant={'contained'} onClick={handleOnClick}>
        <HelpIcon color="primary" fontSize={'small'} />
        <Box color={'primary.main'} ml={0.5}>
          {LangFile.HelpItemContainer.primary}
        </Box>
      </Button>
    );
  } else {
    item = (
      <ListItem dense button divider onClick={handleOnClick}>
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <HelpIcon color="primary" fontSize={'small'} />
        </ListItemIcon>
        <ListItemText primary={LangFile.HelpItemContainer.primary} />
      </ListItem>
    );
  }

  return (
    <Fragment>
      {item}

      {showArticle && (
        <MarkdownArticleDialog
          LangFile={LangFile}
          article={article}
          onClose={handleOnClose}
          key={article.id}
        />
      )}
    </Fragment>
  );
};

HelpItemContainer.propTypes = {
  articleId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'listitem']),
  onShow: PropTypes.func,
  onClose: PropTypes.func,
};

HelpItemContainer.defaultProps = {
  type: 'listitem',
  onShow: voidFunc,
  onClose: voidFunc,
};

export default memo(withStyles(styles)(connect(mapStateToProps)(HelpItemContainer)));
