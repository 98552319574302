import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default (props) => {
  let prop = { props, viewBox: '0, 0, 9.789, 9.789' };
  return (
    <SvgIcon {...prop}>
      <path
        d="M0,4.894a4.895,4.895 0,1 1,4.895 4.895A4.894,4.894 0,0 1,0 4.894ZM2.834,5.464 L4.263,3.974v3.6a0.473,0.473 0,0 0,0.474 0.474h0.316a0.473,0.473 0,0 0,0.474 -0.474v-3.6l1.429,1.49a0.474,0.474 0,0 0,0.677 0.008l0.215,-0.217a0.472,0.472 0,0 0,0 -0.669L5.23,1.967a0.472,0.472 0,0 0,-0.669 0L1.937,4.586a0.472,0.472 0,0 0,0 0.669l0.215,0.217A0.477,0.477 0,0 0,2.837 5.464Z"
        stroke="0.6"
      />
    </SvgIcon>
  );
};
