import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useOnZoomChanged from 'js/components/DataLayer/hooks/useOnZoomChanged';
import { useGoogleMap } from 'js/context/GoogleMapContext';
import useOnClick from './hooks/useOnClick';
import useOnMouseEnter from './hooks/useOnMouseEnter';
import useOnMouseLeave from './hooks/useOnMouseLeave';
import { voidFunc } from '../../constants/PropTypeUtils';
import { DataLayerProvider } from 'js/context/DataLayerContext';
import { useHookRef } from '../../hooks/useHookRef';

const DataLayer = ({
  children,
  minZoom,
  maxZoom,
  shown,
  hoverFillColor,
  hoverStrokeColor,
  hoverFillOpacity,
  onClick,
  onMouseEnter,
  onMouseLeave,
  setStyle,
}) => {
  const googleMap = useGoogleMap();
  const mapRef = useHookRef(googleMap);
  const layer = useRef(new google.maps.Data());

  let [zoom, setZoom] = useState(googleMap.getZoom());
  useOnZoomChanged(googleMap, () => setZoom(googleMap.getZoom()));

  let visible = shown && zoom >= minZoom && zoom <= maxZoom;

  useEffect(() => {
    if (mapRef.current && layer.current) {
      layer.current.setStyle(setStyle);
    }
  }, [setStyle]);

  useEffect(() => {
    if (visible) {
      layer.current.setMap(googleMap);
      layer.current.setStyle(setStyle);
    } else {
      layer.current.setMap(null);
    }

    return () => {
      layer.current.setMap(null);
    };
  }, [visible, googleMap]);

  useOnClick(
    layer.current,
    useCallback(
      (feature) => {
        if (typeof onClick === 'function') {
          onClick(feature);
        }
      },
      [onClick]
    )
  );

  useOnMouseEnter(
    layer.current,
    useCallback(
      (feature) => {
        if (layer.current) {
          layer.current.revertStyle();
          layer.current.overrideStyle(feature, {
            fillColor: hoverFillColor,
            strokeColor: hoverStrokeColor,
            fillOpacity: hoverFillOpacity,
          });

          onMouseEnter(feature);
        }
      },
      [onMouseEnter, hoverFillColor, hoverStrokeColor, hoverFillOpacity]
    )
  );

  useOnMouseLeave(
    layer.current,
    useCallback(
      (feature) => {
        if (layer.current) {
          layer.current.revertStyle();
          onMouseLeave();
        }
      },
      [onMouseLeave]
    )
  );

  if (!visible) {
    return null;
  }

  return <DataLayerProvider value={layer.current}>{layer.current && children}</DataLayerProvider>;
};

DataLayer.propTypes = {
  zIndex: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  shown: PropTypes.bool,
  /**
   * Should return an object of @type {!google.maps.Data.StyleOptions} https://developers.google.com/maps/documentation/javascript/reference/data#Data.StyleOptions
   */
  setStyle: PropTypes.func,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  hoverFillColor: PropTypes.string,
  hoverStrokeColor: PropTypes.string,
  hoverFillOpacity: PropTypes.number,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  fillOpacity: PropTypes.number,
};

DataLayer.defaultProps = {
  zIndex: 1,
  minZoom: 1,
  maxZoom: 20,
  shown: true,
  setStyle: voidFunc,
  onClick: voidFunc,
  onMouseEnter: voidFunc,
  onMouseLeave: voidFunc,
  hoverFillColor: 'white',
  hoverStrokeColor: 'black',
  hoverFillOpacity: 0.5,
  strokeColor: 'black',
  fillColor: 'black',
  fillOpacity: 0.5,
};

/** @component */
export default memo(DataLayer);
