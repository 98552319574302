'use strict';
import React, { memo, useCallback } from 'react';

import Dialog from '@material-ui/core/Dialog';
import Logo from 'js/components/UI-Elements/Logo';

import { DialogContent, DialogTitle } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import LoginForm from 'js/components/Login/LoginForm';
import RestorePasswordForm from 'js/components/Login/RestorePasswordForm';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Style from './LoginDialog.module.less';
import LanguageSelectorContainer from '../LanguageSelector/LanguageSelectorContainer';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import Box from '@material-ui/core/Box';

const LoginDialog = ({ shown, doLogin, loggingIn, doRestorePassword, view, setView }) => {
  const analytics = useFirebaseAnalytics();

  const handleOnSlide = (destination) =>
    useCallback(() => {
      setView(destination);

      if (view === 0) {
        analytics.logEvent(FIREBASE_EVENTS.LOGIN_RESTORE_BACK);
      } else {
        analytics.logEvent(FIREBASE_EVENTS.LOGIN_RESTORE);
      }
    }, []);

  return (
    <Dialog
      open={shown}
      maxWidth={'xs'}
      fullWidth
      scroll={'body'}
      style={{ opacity: loggingIn ? 0 : 1 }}
      classes={{ paper: Style.dialogPaper }}
    >
      <DialogTitle className={Style.Title} disableTypography>
        <Box width={'100%'} p={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box maxWidth={'50%'}>
            <Logo variant={'full'} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent classes={{ root: Style.Content }}>
        <Grid container direction={'column'}>
          <Grid item>
            <Box className={Style.Language}>
              <LanguageSelectorContainer />
            </Box>

            <SwipeableViews axis={'x'} index={view} slideClassName={Style.Slider}>
              <Typography component="div" className={Style.Slide}>
                <LoginForm shown={view === 0} onSubmit={doLogin} onSlide={handleOnSlide(1)} />
              </Typography>
              <Typography component="div" className={Style.Slide}>
                <RestorePasswordForm
                  shown={view === 1}
                  onSubmit={doRestorePassword}
                  onSlide={handleOnSlide(0)}
                />
              </Typography>
            </SwipeableViews>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  shown: PropTypes.bool,
  loggingIn: PropTypes.bool,
  view: PropTypes.number,
  setView: PropTypes.func,
  doLogin: PropTypes.func,
  doRestorePassword: PropTypes.func,
};

export default memo(LoginDialog);
