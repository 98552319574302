// Load all state available in the loacl storage. This will be done on page load when store is initialized

import { WeatherSensor } from 'js/constants/WeatherConstants';
import { HARDWARE_ID_KEY } from 'js/helpers/BrowserDetection';
import { getLanguageFile } from 'js/helpers/LanguageUtils';
import NetworkSensor from '../model/network/NetworkSensor';
import SettingsConstants, { isGdprValid } from 'js/constants/SettingsConstants';
import { DEFAULT_COLUMNS } from '../reducers/StatisticsReducer';

export function clearLocalStorage() {
  let hardwareId = localStorage.getItem(HARDWARE_ID_KEY);
  localStorage.clear();
  localStorage.setItem(HARDWARE_ID_KEY, hardwareId);
}

export function loadState(initialState) {
  try {
    // First load the session and localstorage
    let serializedSessionStorageState = sessionStorage.getItem('state');
    let serializedLocalStorageState = localStorage.getItem('state');

    // If one is not available just set it to the serialized version of an empty object
    if (!serializedLocalStorageState) {
      serializedLocalStorageState = '{}';
    }

    if (!serializedSessionStorageState) {
      serializedSessionStorageState = '{}';
    }

    // Turn the serialized data into actual objects
    const sessionStorageState = JSON.parse(serializedSessionStorageState);
    const localStorageState = JSON.parse(serializedLocalStorageState);

    let rememberMe = localStorageState.credential ? localStorageState.credential.rememberMe : false;

    let finalState = initialState;

    if (rememberMe) {
      finalState = mergeStates(initialState, localStorageState);
      // sessionStorage.removeItem("state");
      sessionStorage.clear();
    } else {
      finalState = mergeStates(initialState, sessionStorageState);
      clearLocalStorage();
    }

    // Catch invalid state - if so, return initial state (log out the user)
    if (!finalState.farm.farm) {
      return initialState;
    }

    // The LangFile is no longer saved in local storage but it has been.
    // This ensures that breaking changes to the LangFile is recovered if local state contains a LangFile.
    if (finalState.language.language) {
      finalState.language.LangFile = getLanguageFile(finalState.language.language);
    }

    // Cache clearing required when refactoring to new Weather Readings API.
    if (finalState.weather.displayData) {
      let validKeys = Object.values(WeatherSensor);
      Object.keys(finalState.weather.displayData).forEach((key) => {
        if (validKeys.indexOf(key) === -1) {
          delete finalState.weather.displayData[key];
        }
      });
    }

    // Handle invalid formatting of this exact settings entry due to an error in JSON stringification of the PUT request.
    if (finalState.settings) {
      let gdpr = finalState.settings.userSettings[SettingsConstants.USER_GDPR_CONSENT];

      if (gdpr && !isGdprValid(gdpr)) {
        console.error('Invalid GDPR - Clearing');
        delete finalState.settings.userSettings[SettingsConstants.USER_GDPR_CONSENT];
      }
    }

    // Migration from Kanisa Weather API V1 to Meteor Weather API V1.
    // Date: 2020-04-27
    if (finalState.weatherNetwork.networkSensor === 'rain') {
      finalState.weatherNetwork.networkSensor = NetworkSensor.RAIN_24H;
    }

    // Clean up old "enabled" state to indicate whether the soil surveys were shown.
    // Replaced by the new property "showSoilSurveys"
    if (finalState.survey) {
      if (finalState.survey.enabled) {
        delete finalState.survey.enabled;
        delete finalState.survey.showSoilSurveys;
      }
    }

    // In case a state sets the savedColumns property to an empty array or an undefined value, we reset.
    if (finalState.statistics) {
      if (!finalState.statistics.savedColumns || finalState.statistics.savedColumns.length === 0) {
        finalState.statistics.savedColumns = DEFAULT_COLUMNS;
      }
    }

    finalState = ensureFarmMigration(finalState);

    return finalState;
  } catch (error) {
    return initialState;
  }
}

function ensureFarmMigration(state) {
  let farm = state.farm.farm;
  if (farm.id) {
    farm.farmId = farm.id;
  }
  return state;
}

// Save the part of the state we need into local and session storage
// This way it can be persisted on page reloads etc.
// BE CAREFUL ABOUT LOCAL STORAGE. If this breaks, we can possibly make the webpage crash all the time for a user!
export function persistState(state) {
  if (!state.credential.loggedIn) {
    return;
  }

  try {
    let rememberMe = state.credential.rememberMe;
    let persistedState = JSON.stringify({
      user: state.user,
      credential: state.credential,
      farm: state.farm,
      settings: partiallySettingsToSave(state),
      control: partiallyControlToSave(state),
      field: partiallyFieldToSave(state),
      language: partiallyLanguageToSave(state),
      soilSample: partiallySoilSampleToSave(state),
      help: partiallyHelpToSave(state),
      weather: partiallyWeatherToSave(state),
      weatherNetwork: partiallyWeatherNetworkToSave(state),
      news: partiallyNewsToSave(state),
      appSettings: state.appSettings,
      survey: partiallySurveyToSave(state),
      season: partiallySeasonsToSave(state),
      statistics: partiallyStatisticsToSave(state),
      overlay: partiallyOverlaysToSave(state),
    });

    if (rememberMe) {
      sessionStorage.removeItem('state');
      localStorage.setItem('state', persistedState);
    } else {
      clearLocalStorage();
      sessionStorage.setItem('state', persistedState);
    }
  } catch (error) {
    // Let us do nothing, this means that we can not save stuff
  }
}

// Only save the part of the control reducer which we like to persist

function partiallyStatisticsToSave(state) {
  return {
    savedColumns: state.statistics.savedColumns,
  };
}

function partiallyOverlaysToSave(state) {
  return {
    // showHeightMap: state.overlay.showHeightMap,
  };
}

function partiallySeasonsToSave(state) {
  return {
    selectedSeason: state.season.selectedSeason,
  };
}

function partiallyLanguageToSave(state) {
  return {
    language: state.language.language,
  };
}

function partiallySettingsToSave(state) {
  return {
    language: state.settings.language,
  };
}

function partiallyControlToSave(state) {
  return {
    supportChatSeen: state.control.supportChatSeen,
  };
}

function partiallyWeatherToSave(state) {
  // eslint-disable-next-line no-unused-vars
  const { [WeatherSensor.LUX]: unused, ...displayData } = state.weather.displayData;
  return {
    showWeatherStations: state.weather.showWeatherStations,
    displayData: displayData,
    showWeatherDrawer: state.weather.showWeatherDrawer,
    accumulation: state.weather.accumulation,
    enableWindGust: state.weather.enableWindGust,
    enableSprayConditions: state.weather.enableSprayConditions,
  };
}

function partiallySoilSampleToSave(state) {
  return {
    enabled: state.soilSample.enabled,
    surveys: state.soilSample.surveys,
    selectedLayer: state.soilSample.selectedLayer,
  };
}
function partiallyFieldToSave(state) {
  return {
    showSatelliteImagery: state.field.showSatelliteImagery,
  };
}

function partiallyNewsToSave(state) {
  return {
    seenArticles: state.news.seenArticles,
  };
}

function partiallyHelpToSave(state) {
  return {
    seenHelpArticleIDs: state.help.seenHelpArticleIDs,
  };
}

function partiallySurveyToSave(state) {
  return {
    // Developer note: Disabled because of state inconsistencies when loading from localStorage
    // Resulted in both satellite images and surveys being shown.
    // showSoilSurveys: state.survey.showSoilSurveys,
  };
}

function partiallyWeatherNetworkToSave(state) {
  return {
    networkSensor: state.weatherNetwork.networkSensor,
    showWeatherNetwork: state.weatherNetwork.showWeatherNetwork,
  };
}

function mergeStates(one, two) {
  let merged = {};

  Object.keys(one).forEach((key) => {
    if (typeof one[key] === 'object') {
      if (two[key] != null) {
        merged[key] = { ...one[key], ...two[key] };
      } else {
        merged[key] = { ...one[key] };
      }
    } else {
      if (two[key] != null) {
        merged[key] = two[key];
      } else {
        merged[key] = one[key];
      }
    }
  });

  return merged;
}
