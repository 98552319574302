'use strict';

import React, { memo, useCallback, useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DialogActions, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import { useLangFile } from '../../context/LanguageContext';
import PropTypes from 'prop-types';

const sharedStyle = {
  margin: 8,
};

const ChangePassword = ({ shown, onClose, updatePassword }) => {
  const LangFile = useLangFile();
  const [wasUpdated, setWasUpdated] = useState(false);
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');

  // Reset state handler
  useEffect(() => {
    setWasUpdated(false);
    setPass1('');
    setPass2('');
  }, [shown]);

  const saveChange = useCallback(() => {
    updatePassword(pass1, (didSucceed) => {
      if (didSucceed) {
        setWasUpdated(true);
      } else {
        setWasUpdated(false);
      }
    });
  }, [pass1, updatePassword]);

  const updatePass1 = useCallback((event) => {
    setPass1(event.target.value);
  }, []);

  const updatePass2 = useCallback((event) => {
    setPass2(event.target.value);
  }, []);

  if (wasUpdated) {
    return (
      <Dialog style={{ zIndex: 3000 }} onClose={onClose} open={shown}>
        <DialogTitle>{LangFile.ChangePassword.updated}</DialogTitle>
        <DialogContent>
          <Grid container={true} justify="center">
            <VerifiedUser fontSize="large" color="primary" />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {LangFile.ChangePassword.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  let errorPassTwo = pass1 !== pass2 ? LangFile.ChangePassword.notEqual : '';

  const inputs = (
    <div style={{ display: 'flex' }}>
      <TextField
        type={'password'}
        value={pass1}
        onChange={updatePass1}
        label={LangFile.ChangePassword.newPassword}
        style={sharedStyle}
        error={Boolean(errorPassTwo)}
      />
      <TextField
        helperText={errorPassTwo}
        type={'password'}
        value={pass2}
        onChange={updatePass2}
        label={LangFile.ChangePassword.repeatPassword}
        style={sharedStyle}
        error={Boolean(errorPassTwo)}
      />
    </div>
  );

  const actions = [
    <Button onClick={onClose} key={'cp_a_1'}>
      {LangFile.ChangePassword.cancel}
    </Button>,
    <Button
      variant="contained"
      disabled={!pass1 || !pass2 || pass1 !== pass2}
      onClick={saveChange}
      color="primary"
      key={'cp_a_2'}
    >
      {LangFile.ChangePassword.change}
    </Button>,
  ];

  return (
    <Dialog style={{ zIndex: 3000 }} open={shown}>
      <DialogTitle>{LangFile.ChangePassword.title}</DialogTitle>
      <DialogContent>
        {LangFile.ChangePassword.description}
        {inputs}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

ChangePassword.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  updatePassword: PropTypes.func,
};

export default memo(ChangePassword);
