import { combineReducers } from 'redux';

import InitStore from 'js/Store/InitStore';
import UserReducer, { AUTHENTICATE_USER, USER_LOG_OUT } from 'js/reducers/UserReducer';
import FarmReducer from 'js/reducers/FarmReducer';
import CredentialReducer from 'js/reducers/CredentialReducer';
import LanguageReducer from 'js/reducers/LanguageReducer';
import NoteReducer from 'js/reducers/NoteReducer';
import FieldReducer from 'js/reducers/FieldReducer';
import AddFieldReducer from 'js/reducers/AddFieldReducer';
import ControlReducer from 'js/reducers/ControlReducer';
import ProgressReducer from 'js/reducers/ProgressReducer';
import SettingsReducer from 'js/reducers/SettingsReducer';
import WeatherReducer from 'js/reducers/WeatherReducer';
import HelpReducer from 'js/reducers/HelpReducer';
import PermissionReducer from 'js/reducers/PermissionReducer';
import OverlayReducer from 'js/reducers/OverlayReducer';
import SurveyReducer from 'js/reducers/SurveyReducer';
import WeatherNetworkReducer from 'js/reducers/WeatherNetworkReducer';
import NewsReducer from 'js/reducers/NewsReducer';
import SyncReducer from 'js/reducers/SyncReducer';
import ReplaceStationReducer from 'js/reducers/ReplaceStationReducer';
import PrescriptionReducer from 'js/reducers/PrescriptionReducer';
import SoilSampleReducer from 'js/reducers/SoilSampleReducer';
import StatisticsReducer from 'js/reducers/StatisticsReducer';
import DataIntegrationsReducer from 'js/reducers/DataIntegrationsReducer';

import { loadState, clearLocalStorage, persistState } from 'js/persistence/LocalStorage';
import APIConstants from 'js/APIConstants';

import throttle from 'lodash.throttle';
import SeasonReducer from 'js/reducers/SeasonReducer';
import LocalPreferencesReducer from './reducers/AppSettingsReducer';

export const LOAD_INITIAL_STATE_FROM_STORAGE = 'LOAD_INITIAL_STATE_FROM_STORAGE';

// Combine all reducers into the single store
const reducers = combineReducers({
  appSettings: LocalPreferencesReducer,
  language: LanguageReducer,
  user: UserReducer,
  farm: FarmReducer,
  credential: CredentialReducer,
  note: NoteReducer,
  field: FieldReducer,
  addField: AddFieldReducer,
  control: ControlReducer,
  progress: ProgressReducer,
  integrations: DataIntegrationsReducer,
  settings: SettingsReducer,
  weather: WeatherReducer,
  help: HelpReducer,
  permissions: PermissionReducer,
  overlay: OverlayReducer,
  survey: SurveyReducer,
  soilSample: SoilSampleReducer,
  weatherNetwork: WeatherNetworkReducer,
  news: NewsReducer,
  season: SeasonReducer,
  statistics: StatisticsReducer,
  sync: SyncReducer,
  replaceStation: ReplaceStationReducer,
  prescription: PrescriptionReducer,
});

// Wrap all reducers in a root reducer in charge of completely resetting state on logout.
const rootReducer = (state, action) => {
  if (action.type === USER_LOG_OUT) {
    sessionStorage.clear();

    clearLocalStorage();

    state = undefined;
    state = reducers(state, action);

    return state;
  }

  if (action.type === AUTHENTICATE_USER) {
    return state;
  }

  if (action.type === LOAD_INITIAL_STATE_FROM_STORAGE) {
    state = { ...action.payload };
    return state;
  }
  return reducers(state, action);
};

const store = InitStore(rootReducer, undefined, APIConstants.applyModelStateLogger);
const initialState = store.getState();

// Load initial state from local and session storage
store.dispatch({
  type: LOAD_INITIAL_STATE_FROM_STORAGE,
  payload: loadState(initialState),
});

// Subscribe our localstorage handling to the store. This enables saving of the state in localstorage whenever changes are happening.
// The throttle ensures that it is called at most once every 3 seconds. The latest call will allways be the working one though.
store.subscribe(
  throttle(() => {
    persistState(store.getState());
  }, 3000)
);

export default store;
