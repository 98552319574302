export const Analysis = {
  MEAN: 'ndvi',
  STDDEV: 'stddev',
  SPAN: 'minMax',
};

export const VisibleTooltips = {
  [Analysis.MEAN]: true,
  [Analysis.STDDEV]: false,
  [Analysis.SPAN]: false,
};

export const AnalysisDecimals = {
  [Analysis.MEAN]: 2,
  [Analysis.STDDEV]: 2,
  [Analysis.SPAN]: 2,
};
