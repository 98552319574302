'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_4" data-name="Layer 4">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <path d="M25.4,15a.73.73,0,0,0-.6-.2H21.9V11l2.2-2.2a.73.73,0,0,0,.2-.6,1.42,1.42,0,0,0-.2-.6.73.73,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2L20.7,9.8H9.8L7.6,7.6A.73.73,0,0,0,7,7.4a1.42,1.42,0,0,0-.6.2.73.73,0,0,0-.2.6,1.42,1.42,0,0,0,.2.6L8.6,11v3.8H5.7a1.42,1.42,0,0,0-.6.2.73.73,0,0,0-.2.6,1.42,1.42,0,0,0,.2.6.73.73,0,0,0,.6.2H8.6a6.27,6.27,0,0,0,.8,3.5L6.8,22.8a.73.73,0,0,0-.2.6.86.86,0,0,0,.3.6,1.42,1.42,0,0,0,.6.2.86.86,0,0,0,.6-.3l2.4-2.7.2.2a5.92,5.92,0,0,0,.6.4,7.26,7.26,0,0,0,.9.5,3.58,3.58,0,0,0,1.1.4,5.85,5.85,0,0,0,1.3.2V11.3h1.7V22.9a6.88,6.88,0,0,0,1.3-.2,3.58,3.58,0,0,0,1.1-.4,2.18,2.18,0,0,0,.8-.5c.3-.2.5-.3.5-.4s.2-.1.2-.2l2.6,2.6a.73.73,0,0,0,.6.2,1.42,1.42,0,0,0,.6-.2.73.73,0,0,0,.2-.6,1.42,1.42,0,0,0-.2-.6l-2.7-2.7a7.37,7.37,0,0,0,.9-3.8h2.9a1.42,1.42,0,0,0,.6-.2.73.73,0,0,0,.2-.6C25.9,15.1,25.6,15.2,25.4,15Z" />
            <path d="M18.2,5.2a4.1,4.1,0,0,0-7,2.9h8.3A4.11,4.11,0,0,0,18.2,5.2Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
