import * as Colors from '@material-ui/core/colors';

var StyleConstants = (function () {
  function StyleConstants() {
    this.palette = {
      secondary: {
        main: '#ce123c',
        contrastText: '#ffffff',
      },
      primary: {
        light: '#c4d696',
        main: '#78a22f',
        dark: '#36641e',
        contrastText: Colors.common.white,
      },
      tertiary: {
        light: '#f5c886',
        main: '#f0a946',
        dark: '#ee981a',
        contrastText: '#a7a9ac',
      },
      accent2Color: '#808285',
      accent3Color: '#58595b',
      textColor: '#414042',
      alternateTextColor: Colors.common.white,
      alternateTextColorDisabled: Colors.common.white,
      canvasColor: Colors.common.white,
      borderColor: '#808285',
      disabledColor: '#808285',
      pickerHeaderColor: '#78a22f',
      clockCircleColor: Colors.common.black,
      shadowColor: Colors.common.black,
      iconColor: '#808285',
    };

    this.colors = {
      ...Colors,
      white: Colors.common.white,
      black: '#414042',
    };
  }

  return StyleConstants;
})();

export default new StyleConstants();
