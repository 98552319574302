'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let prop = { props, viewBox: '0, 0, 24, 24' };
  return (
    <SvgIcon {...prop}>
      <path
        transform="matrix(1,0,0,1,1,-202.18482)"
        d="M 19.534533 213.76699 C 19.483199 213.76699 17.579834 213.76699 17.579834 213.76699 C 17.61393 213.40094 17.636664 213.36954 17.637402 213.15058 C 17.643269 211.19797 16.337567 209.49202 14.427601 209.0718 C 13.3389639 208.83245 12.299833 209.0092 11.439997 209.47267 C 10.390964 207.91393 8.652967 206.86254 6.6608655 206.74105 C 6.404567 206.70587 6.1449658 206.685 5.879133 206.685 C 2.6322997 206.685 -.00000022793856 209.39684 -.00000022793856 212.73986 C -.00000022793856 214.48195 .71903297 216.04842 1.8630327 217.15288 C 2.874666 218.27346 4.2793667 218.9975 5.9425666 219.11797 C 5.9425666 219.11797 19.483199 219.18489 19.534533 219.18489 C 20.896332 219.18489 22 217.93991 22 216.47562 C 22 215.01206 20.895964 213.76699 19.534533 213.76699 Z "
        fill="#e4e4e4"
      />
    </SvgIcon>
  );
};
