import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useLangFile } from 'js/context/LanguageContext';

const DialogConfirmDeleteField = ({ open, onClose, onConfirm }) => {
  let LangFile = useLangFile();

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={onClose}>
      <DialogTitle>{LangFile.DialogConfirmDeleteField.deleteField}</DialogTitle>
      <DialogContent>
        <p>{LangFile.DialogConfirmDeleteField.deleteNotice1}</p>
        <p>{LangFile.DialogConfirmDeleteField.deleteNotice2}</p>
        <p>{LangFile.DialogConfirmDeleteField.deleteNotice3}</p>
        <p>{LangFile.DialogConfirmDeleteField.deleteNotice5}</p>
        <br />
        <b>{LangFile.DialogConfirmDeleteField.deleteNotice4}</b>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{LangFile.DialogConfirmDeleteField.abort}</Button>
        <Button color="secondary" variant={'contained'} onClick={onConfirm}>
          {LangFile.DialogConfirmDeleteField.deleteField}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmDeleteField.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DialogConfirmDeleteField;
