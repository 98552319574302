// @flow

import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const MultipleActionsDialog = ({
  open,
  title,
  message,
  loadingText,
  loading,
  error,
  errorText,
  cancelText,
  onCancel,
  primaryText,
  primaryColor,
  onPrimary,
  secondaryText,
  secondaryColor,
  onSecondary,
}) => {
  return (
    <Fragment>
      <Dialog open={Boolean(open)} fullWidth maxWidth={'md'}>
        <DialogTitle>
          {loading && loadingText}
          {!loading && title}
        </DialogTitle>

        <DialogContent style={{ overflow: 'hidden' }}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <CircularProgress size={80} thickness={5} />
            ) : (
              <Fragment>
                {error ? <Box mb={1}>{errorText}</Box> : <Box mb={1}>{message}</Box>}
              </Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {!loading && (
            <Fragment>
              <Button onClick={onCancel} color={'default'} variant={'outlined'}>
                {cancelText ? cancelText : 'Cancel'}
              </Button>

              {error ? (
                <Button onClick={onCancel} color={'secondary'} variant={'contained'}>
                  {cancelText ? cancelText : 'Cancel'}
                </Button>
              ) : (
                <Fragment>
                  <Button
                    onClick={onSecondary}
                    color={secondaryColor ? secondaryColor : 'default'}
                    variant={'outlined'}>
                    {secondaryText ? secondaryText : ''}
                  </Button>
                  <Button
                    onClick={onPrimary}
                    color={primaryColor ? primaryColor : 'primary'}
                    variant={'contained'}>
                    {primaryText ? primaryText : ''}
                  </Button>
                </Fragment>
              )}
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(MultipleActionsDialog);

MultipleActionsDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};
