'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_2-2" data-name="Layer 2">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <polygon points="24.7 25.6 15.5 25.8 5.7 25.6 4.8 21.8 4.7 19 15.4 19 26 19 26 22.4 24.7 25.6" />
            <path
              style={cls1}
              d="M22.1,3.7H8.4A4.59,4.59,0,0,0,3.8,8.3V22a4.59,4.59,0,0,0,4.6,4.6H22.1A4.59,4.59,0,0,0,26.7,22V8.3A4.65,4.65,0,0,0,22.1,3.7ZM5,19.2H7l-.7,1.6-1.2.7A17.69,17.69,0,0,1,5,19.2Zm2.3,6A3.51,3.51,0,0,1,5,22.4l1.2-.6,2,2.6Zm-.4-4.1,1-1.9h4.8l.7.7-2.2,2.7L9.1,23.9Zm1.7,4.3,2.9-2,3.5,2Zm11.9,0H17l-4.8-2.6,2.1-2.6,4.6,1.5,2.5,3.5Zm2.3-.1-.5-.1L20,21.8l3.5-.5,2,.6A3.28,3.28,0,0,1,22.8,25.3Zm2.7-4-1.3-.4,1.3-.3ZM15.3,19.2H25.5V20h0l-2.5.8-3.6.5-4.7-1.5-1.1-.5"
            />
            <path d="M15.3,17.8c1.3,0,2.4-1.5,2.4-3.3V13.1H15.6V7.6a1.91,1.91,0,0,0,1.4-2V4.8H13.9v.8a2,2,0,0,0,1.2,2v5.6H13v1.4C12.9,16.4,14,17.8,15.3,17.8ZM14.2,5.7V5.6h2.3v.1a1.37,1.37,0,0,1-1.2,1.5C14.7,7.2,14.2,6.5,14.2,5.7Zm1.1,10c-.1,0-.1-.4-.2-.9V13.7h.3v1.1C15.5,15.3,15.4,15.7,15.3,15.7Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
