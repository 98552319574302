import React, { useContext } from 'react';
import ManualFeatureConstants from 'js/constants/ManualFeatureConstants';
import { useFarmSettings, useUserSettings } from './SettingsContext';

const PermissionContext = React.createContext({});

export const PermissionProvider = PermissionContext.Provider;

export function usePermissions() {
  return useContext(PermissionContext);
}

export function useHasFeatures(features, featureRule = 'either', initial = false) {
  let input = features;
  let permissions = usePermissions().farm;
  let farmSettings = useFarmSettings();
  let userSettings = useUserSettings();
  let mergedSettings = farmSettings
    ? { ...farmSettings.global, ...farmSettings.personal, ...userSettings }
    : {};

  if (Array.isArray(input) && input.length === 0) {
    return true;
  }

  if (!input || !permissions) {
    return initial;
  }

  if (!Array.isArray(input)) {
    input = [input];
  }

  if (input.length === 0) {
    return initial;
  }

  return input.reduce((acc, cur) => {
    let allowed = permissions[cur] || ManualFeatureConstants[cur] || mergedSettings[cur];

    if (featureRule === 'either') {
      let stringTruth = typeof allowed === 'string' && allowed.toLowerCase() === 'true';
      return acc || allowed === true || stringTruth;
    } else {
      return acc && allowed;
    }
  }, featureRule === 'all');
}
