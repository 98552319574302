export const removeBy = (array, key, value) => {
  return [...array.filter((item) => item[key] !== value)];
};

export const replaceBy = (array, key, newItem) => {
  return [
    ...array.map((item) => {
      if (item[key] === newItem[key]) {
        return newItem;
      } else {
        return item;
      }
    }),
  ];
};
