'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_7" data-name="Layer 7">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <path d="M4.7,22.4H27.2L16,3Zm12.2-3h-2v-2h2Zm0-4.1h-2V11.2h2Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
