// @flow
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MapCanvasOverlay } from 'js/components/MapObjects/MapCanvas/MapCanvasOverlay';
import { useGoogleMap } from 'js/context/GoogleMapContext';

import Styles from './MapCanvas.module.less';
import NumberUtils from 'js/helpers/NumberUtils';

export const drawImageURL = (
  context: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  imageURL: String,
  maxWidth: number,
  maxHeight: number,
  disableDraw: boolean
): Promise<{ width: Number; height: Number }> => {
  return new Promise((resolve, reject) => {
    context.clearRect(0, 0, canvas.width, canvas.height);

    if (imageURL) {
      const img = new Image();
      img.onerror = function (error) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        reject(error);
      };
      img.onload = function () {
        let w = img.width;
        let h = img.height;
        let ratio = 1;

        if (maxWidth || maxHeight) {
          ratio = Math.min(maxWidth / w, maxHeight / h);
          w *= ratio;
          h *= ratio;
        }

        canvas.width = w;
        canvas.height = h;

        if (!disableDraw) {
          context.drawImage(img, 0, 0, w, h);
        }

        resolve({ height: h, width: w, ratio: ratio });
      };
      img.src = imageURL;
    }
  });
};

export const getClipPath = (
  polygon,
  bounds: { north: number; south: number; east: number; west: number }
) => {
  const { north, south, east, west } = bounds;

  // Canvas Origo: top-left (0,0) (northwest).
  // Map from lng;lat to x;y
  const mapY = (y) => NumberUtils.map(y, south, north, 100, 0);
  const mapX = (x) => NumberUtils.map(x, west, east, 0, 100);

  if (polygon) {
    let lat, lng, x, y;
    const points = polygon.map((p) => {
      lng = p[0];
      x = mapX(lng);
      lat = p[1];
      y = mapY(lat);
      return `${x}% ${y}%`;
    });

    return 'polygon(' + points.join(', ') + ')';
  }

  return null;
};

export const MapRef = {
  refresh: () => {},
};

const MapCanvas = ({
  id,
  bounds,
  width,
  height,
  onDraw,
  zIndex,
  updateDependencies,
  clipPathPolygon,
  loading,
  error,
  forceUpdate,
}) => {
  const map = useGoogleMap();
  const [overlay, setOverlay] = useState(null);

  useEffect(() => {
    const o = overlay;
    const canvas = o && o.canvas;

    if (clipPathPolygon && canvas) {
      canvas.setAttribute('id', id);

      if (loading) {
        canvas.className = Styles.Loading;
      } else if (error) {
        canvas.className = Styles.Error;
      } else {
        canvas.className = null;
      }
    }
  }, [overlay, id, error, loading, clipPathPolygon]);

  useEffect(() => {
    if (!overlay || !overlay.ctx) {
      return;
    }

    onDraw(overlay.ctx, overlay.canvas);
  }, [onDraw, overlay, ...updateDependencies]);

  useEffect(() => {
    const latLngBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bounds.south, bounds.west),
      new google.maps.LatLng(bounds.north, bounds.east)
    );

    const ol = new MapCanvasOverlay(map, latLngBounds, width, height, zIndex, (ctx, canvas) => {
      if (clipPathPolygon) {
        canvas.style.clipPath = getClipPath(clipPathPolygon, bounds);
      }

      setOverlay(ol);
    });

    return () => {
      if (ol) {
        ol.remove();
      }
      setOverlay(null);
    };
  }, [bounds, width, height, zIndex, map, clipPathPolygon]);

  return null;
};

MapCanvas.propTypes = {
  forceUpdate: PropTypes.func,
  updateDependencies: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  zIndex: PropTypes.number,
  bounds: PropTypes.object,
  onDraw: PropTypes.func,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  clipPathPolygon: PropTypes.array,
  id: PropTypes.string,
};

MapCanvas.defaultProps = {
  updateDependencies: [],
  zIndex: 0,
};

export default memo(MapCanvas);
