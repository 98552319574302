'use strict';

import React, { memo, useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BusinessIcon from '@material-ui/icons/Business';
import HectareIcon from '@material-ui/icons/DonutLarge';
import { Paper, ListItemIcon, ListItemText } from '@material-ui/core';
import TextFormControl from 'js/components/UI-Elements/TextFormControl.jsx';
import { useLangFile } from 'js/context/LanguageContext';
import { withStyles } from '@material-ui/core/styles';
import { useIsAdmin } from '../../context/AccountContext';

const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    height: '100%',
  },
  list: {
    padding: 0,
  },
  changeNameInput: {
    cursor: 'pointer',
  },
  changeNameTitle: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  item: {
    border: 'none',
    padding: 9,
  },
  icon: {
    justifyContent: 'center',
  },
});

const FarmInfoList = ({ classes, farm, updateFarmName, fields }) => {
  const LangFile = useLangFile();
  const [currentHectare, setCurrentHectare] = useState(0);
  const isAdmin = useIsAdmin();

  useEffect(() => {
    let hectares = Array.from(fields, ([key, value]) => value).reduce(
      (sum, field) => sum + field.size,
      0
    );
    setCurrentHectare(hectares);
  }, [fields]);

  let changeFarmNameInput = (
    <ListItem className={classes.item}>
      <ListItemIcon className={classes.icon}>
        <BusinessIcon />
      </ListItemIcon>

      <TextFormControl
        disablePadding
        defaultValue={farm.owner.firstName + ' ' + farm.owner.lastName}
        titleClassName={classes.changeNameTitle}
        disabled={!isAdmin}
        value={farm.name}
        type={'text'}
        title={LangFile.FarmInfoList.farmName}
        onUpdate={(value) => {
          updateFarmName(value);
        }}
      />
    </ListItem>
  );

  return (
    <Paper elevation={1} className={classes.root}>
      <List className={classes.list}>
        {changeFarmNameInput}

        <ListItem className={classes.item} disabled>
          <ListItemIcon className={classes.icon}>
            <HectareIcon />
          </ListItemIcon>
          <ListItemText
            primary={LangFile.FarmInfoList.hectareUsage}
            secondary={currentHectare.toFixed(2) + ' / ' + LangFile.FarmInfoList.unlimited + ' ha.'}
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default memo(withStyles(styles)(FarmInfoList));
