import React, { memo, useCallback, useState } from 'react';

import { connect } from 'react-redux';
import { setShowCreateSeasonDialog, setViewMode } from 'js/reducers/ControlReducer';

import ViewModeConstants from 'js/constants/ViewModeConstants';
import TopBar from 'js/components/Topbar/TopBar';
import { enableSeason, selectSeason } from 'js/reducers/SeasonReducer';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import UpdateSeasonContainer from '../Seasons/UpdateSeasonContainer';
import Box from '@material-ui/core/Box';
import { useFarm } from '../../context/AccountContext';
import { resetFilters } from '../../reducers/FieldReducer';

const mapStateToProps = (store) => {
  return {
    viewMode: store.control.viewMode,
    previousViewMode: store.control.previousViewMode,
    isDrawingField: store.addField.isDrawing,
    isDrawingNote: store.note.isDrawing,
    isAddingFields: store.addField.outlines.length !== 0,
    isMagicSelecting: store.note.magicWand.isSelecting,
    isPlacingMarker: store.note.isPlacingMarker,
  };
};

const TopBarContainer = ({
  dispatch,
  changeFarm,
  viewMode,
  previousViewMode,
  isDrawingField,
  isDrawingNote,
  isAddingFields,
  isMagicSelecting,
  isPlacingMarker,
}) => {
  const analytics = useFirebaseAnalytics();
  const farm = useFarm();
  const farmId = farm && farm.farmId;
  const [seasonToEdit, setSeasonToEdit] = useState(null);

  const goToPreviousViewMode = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.ANALYSIS_BACK);
    dispatch(setViewMode(previousViewMode));
  }, [previousViewMode]);

  const onChangeSeason = useCallback((seasonId) => {
    analytics.logEvent(FIREBASE_EVENTS.OVERVIEW_SEASON_SELECT);
    dispatch(selectSeason(seasonId));
    dispatch(resetFilters());
  }, []);

  const onAddNewSeason = useCallback(() => {
    dispatch(setShowCreateSeasonDialog(true));
  }, []);

  const handleRestoreSeasonClick = useCallback(
    (season) => {
      dispatch(enableSeason(farmId, season.id));
    },
    [farmId]
  );

  const onEditSeason = useCallback((season) => {
    setSeasonToEdit(season);
  }, []);

  const onEditSeasonClose = useCallback(() => {
    setSeasonToEdit(null);
  }, []);

  if (
    viewMode === ViewModeConstants.PRESCRIPTION ||
    viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE
  ) {
    return null;
  }

  if (isDrawingField || isDrawingNote || isAddingFields || isMagicSelecting || isPlacingMarker) {
    return null;
  }

  return (
    <Box>
      <TopBar
        viewMode={viewMode}
        previousViewMode={previousViewMode}
        changeFarm={changeFarm}
        onChangeSeason={onChangeSeason}
        onAddNewSeason={onAddNewSeason}
        onEditSeason={onEditSeason}
        handleRestoreSeasonClick={handleRestoreSeasonClick}
        goToPreviousViewMode={goToPreviousViewMode}
      />

      <UpdateSeasonContainer
        show={seasonToEdit !== null}
        seasonToEdit={seasonToEdit}
        onDismiss={onEditSeasonClose}
      />
    </Box>
  );
};

export default memo(connect(mapStateToProps)(TopBarContainer));
