'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  let cls2 = {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: 2,
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_6" data-name="Layer 6">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <path d="M25.7,22.7v-.3a7.48,7.48,0,0,0-.5-4.1c.5,2.3-.4,3.6-1,4.1l-.4.4s0-.3-.1-.4c-.4-3.2,1.4-7.3,1.4-7.3-1.2,1-1.9,4.4-2.2,6.3a10.06,10.06,0,0,0-.2-4.6,17.09,17.09,0,0,1-.6,5.6,1,1,0,0,0-.1.4,2.27,2.27,0,0,1-.1-.4,40.53,40.53,0,0,1-.2-6.6,21.79,21.79,0,0,0-.8,5,9.63,9.63,0,0,0-.9-4.4,9,9,0,0,1-.3,5.9c.2-3.2-.6-4-.6-4,.6,1.3-.8,3.9-.8,3.9-.6-3.4.8-8.2.8-8.2-.8,1.1-1.3,4-1.5,6.1a5.35,5.35,0,0,0-1.1-3.7,9.92,9.92,0,0,1,0,6,10.11,10.11,0,0,0-.7-4.7c.1,1.4-1,3.9-1.4,4.8a1,1,0,0,0-.1.4s0-.3-.1-.4c-.4-2.4.4-7.4.4-7.4-1,.8-1.6,6.1-1.7,7.4v0c-.1-2.1-.1-4.4-.1-4.4-.4.5-.7,4.7-.7,4.7v-.4c.3-5.3-.7-7.1-.7-7.1a17.54,17.54,0,0,1-.7,7.1,1,1,0,0,0-.1.4c-.1,0-.1-.3-.2-.4-.6-1.6.2-5.2.2-5.2a8.09,8.09,0,0,0-1.1,3.9c.1-6.4-.4-8.5-.4-8.5.4,2.3-.4,8.4-.6,9.8h0a27,27,0,0,0-.6-6c0,1.7-.4,5-.5,6v.4a.6.6,0,0,1-.1-.4c-.9-2.8.1-7.4.1-7.4C6,16.9,5.8,21.2,5.8,22.4h0s.1.5-.1,0c-.7-1.5.2-6,.2-6-.8.9-1.2,4.9-1.2,6v.4s0-.3-.1-.4c-.7-3.9-.7-3-.7-3-1.9,1.2-.5,3.4-.5,3.4l2.3,2.5,20,.2,1.6-3h0" />
            <path d="M15.7,12.6a1,1,0,0,0,.9-.8l.6-6.9a1.5,1.5,0,0,0-.4-1.1,1.43,1.43,0,0,0-1.1-.5,1.23,1.23,0,0,0-1,.5,1.5,1.5,0,0,0-.4,1.1l.6,6.9A.79.79,0,0,0,15.7,12.6Z" />
            <path d="M15.7,13.1a.9.9,0,1,0,.9.9A.9.9,0,0,0,15.7,13.1Z" />
            <path
              style={cls2}
              d="M22,26.1H8.4a5.23,5.23,0,0,1-5.2-5.2L6,10.5c0-2.8,4-8.3,6.9-8.3h5.4c2.8,0,3.9,1.9,3.9,4.7l4.8,14A4.94,4.94,0,0,1,22,26.1Z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
