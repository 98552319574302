// @flow

import React, { useContext } from 'react';
import type { PrescriptionJob } from 'js/components/Prescription/PrescriptionJob';

const PrescriptionJobContext = React.createContext({});

export const PrescriptionJobProvider = PrescriptionJobContext.Provider;

export function usePrescriptionJob(): {
  prescriptionJob: PrescriptionJob;
  setPrescriptionJob: (newJobChanges: PrescriptionJob, recalculate: boolean) => any;
} {
  const [prescriptionJob, setPrescriptionJob] = useContext(PrescriptionJobContext);
  return { prescriptionJob, setPrescriptionJob };
}

const ShowCurrentJobContext = React.createContext({});

export const ShowCurrentJobProvider = ShowCurrentJobContext.Provider;

export function useMultipleJobs(): {
  showCurrentJob: [boolean, (show: boolean) => void];
  hasMultipleJobs: boolean;
} {
  const {
    showCurrentJob: [showCurrentJob, setShowCurrentJob],
    hasMultipleJobs,
  } = useContext(ShowCurrentJobContext);

  return {
    showCurrentJob: [showCurrentJob, setShowCurrentJob],
    hasMultipleJobs,
  };
}
