import React, { memo } from 'react';
import List from '@material-ui/core/List';
import SubList from '../SatelliteLayerList/SubList';
import { OTHER_LAYERS } from '../../constants/OtherLayers';
import OtherLayerListItem from './OtherLayerListItem';
import { useHasFeatures } from '../../context/PermissionContext';
import FeatureConstants from '../../constants/FeatureConstants';
import { useFarm } from '../../context/AccountContext';
import PropTypes from 'prop-types';
import { voidFunc } from '../../constants/PropTypeUtils';

const OtherLayerList = ({ showHeightMap, onClick }) => {
  const farm = useFarm();
  const heightMapEnabled = useHasFeatures(FeatureConstants.HEIGHT_MAPS_KORTFORSYNINGEN);

  const isDisabled = (layer) => {
    if (layer === OTHER_LAYERS.HEIGHT_MAP) {
      return !heightMapEnabled && !farm.cvr;
    }
    return false;
  };

  const isSelected = (layer) => {
    if (layer === OTHER_LAYERS.HEIGHT_MAP) {
      return showHeightMap;
    }
    return false;
  };

  return (
    <List dense disablePadding>
      <SubList
        title={'Other'}
        items={Object.keys(OTHER_LAYERS)}
        renderItem={(layer) => (
          <OtherLayerListItem
            layer={layer}
            disabled={isDisabled(layer)}
            isSelected={isSelected(layer)}
            onClick={onClick}
          />
        )}
      />
    </List>
  );
};

OtherLayerList.propTypes = {
  showHeightMap: PropTypes.bool,
  onClick: PropTypes.func,
};

OtherLayerList.defaultProps = {
  onClick: voidFunc,
};

export default memo(OtherLayerList);
