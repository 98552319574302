// @flow
import React, { memo, useEffect, useState, useCallback } from 'react';
import { PrescriptionJobProvider } from 'js/components/Prescription/PrescriptionJobContext';
import WebAPIUtils from '../../WebAPIUtils';
import { useFarm } from 'js/context/AccountContext';

import PrescriptionEditorContainer from '../Prescription/PrescriptionEditorContainer';
import PrescriptionJobDownloadContainer from 'js/components/Prescription/PrescriptionJobDownloadContainer';
import FullScreenPortalPaper from 'js/components/Prescription/FullScreenPortalPaper';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  isSatelliteLayer,
  MetaTypes,
  PrescriptionJob,
  SOURCE_CATEGORY,
} from 'js/components/Prescription/PrescriptionJob';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import { setViewMode } from 'js/reducers/ControlReducer';
import { PRESCRIPTION_METERING, PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import { connect } from 'react-redux';
import {
  deletePrescriptionDocumentation,
  deletePrescriptionJobs,
  getPrescriptionDocumentation,
  getPrescriptionMaps,
  savePrescriptionJobLocally,
  savePrescriptionJobPromise,
  setCurrentJob,
} from 'js/reducers/PrescriptionReducer';
import PrescriptionExitSaveDialog from '../Prescription/Dialogs/PrescriptionExitSaveDialog';
import { useLangFile } from 'js/context/LanguageContext';
import { selectField, createFieldsMap, resetFilters } from 'js/reducers/FieldReducer';
import PrescriptionArchive from './PrescriptionArchive';
import { useSeasonContext } from '../../context/SeasonContext';
import { selectSeason } from '../../reducers/SeasonReducer';
import PrescriptionArchiveConfirmDeleteDialog from './PrescriptionArchiveConfirmDeleteDialog';
import {
  resetCurrentJob,
  resetFutureJob,
  setFutureJob,
  setPrescriptionJobSeasonId,
  updateFutureJob,
} from '../../reducers/PrescriptionReducer';
import { formatSeason } from '../../helpers/SeasonUtils';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import Season from '../../model/Season';
import { getReferenceValues, setSelectedSurveyForcefully } from '../../reducers/SurveyReducer';
import { useSelectedSurvey } from '../../context/SurveyContext';
import moment from 'moment';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { YIELD_LAYERS } from '../../constants/YieldLayer';
import { fetchCropHealth, setDate } from '../../reducers/FieldReducer';
import DateHandler from '../DateHandler/DateHandler';
import { ShowCurrentJobProvider } from '../Prescription/PrescriptionJobContext';
import MultipleActionsDialog from '../Prescription/Dialogs/MultipleActionsDialog.jsx';
import { mergePrescriptionJobsValues } from '../Prescription/limeUtils';
import { calculateLimeJobIntervals } from '../Prescription/PrescriptionUtils';

/* endregion IMPORTS */

const getLatestDate = (fieldStatsArray: Array) => {
  const sortedFieldInfo = fieldStatsArray.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  return sortedFieldInfo[0].date;
};

const mapStateToProps = (store) => ({
  maps: store.prescription.maps,
  documentation: store.prescription.documentation,
  currentJob: store.prescription.currentJob,
  viewMode: store.control.viewMode,
  images: store.field.images,
  date: store.field.date,
  selectedField: store.field.selectedField,
  selectedLayer: store.survey.selectedLayer,
  showSatelliteImagery: store.field.showSatelliteImagery,
  showSoilSurveys: store.survey.showSoilSurveys,
  surveys: store.survey.surveys,
  futureJob: store.prescription.futureJob,
  selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
});

const PrescriptionContainer = ({
  dispatch,
  maps,
  documentation,
  currentJob,
  images,
  viewMode,
  date,
  selectedField,
  selectedLayer,
  showSatelliteImagery,
  showSoilSurveys,
  surveys,
  futureJob,
  selectedSurveyReferenceValues,
}) => {
  const LangFile = useLangFile();
  const { selectedSeason, seasons } = useSeasonContext();
  const selectedSurvey = useSelectedSurvey();
  const [showCurrentJob, setShowCurrentJob] = useState(true);

  const hasMultipleJobs = futureJob !== null && futureJob !== undefined;
  const selectedJob = showCurrentJob === true ? currentJob : futureJob;

  const [fields, setFields] = useState(null);

  const [showArchive, setShowArchive] = useState(false);
  const [isResumingJob, setIsResumingJob] = useState(false);
  const [loadingMaps, setLoadingMaps] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showExitSaveDialog, setShowExitSaveDialog] = useState(false);

  const [isExiting, setIsExiting] = useState(false);
  const [loadingJob, setLoadingJob] = useState(false);
  const [downloadJob, setDownloadJob] = useState(null);
  const [savingJob, setSavingJob] = useState(false);
  const [savingError, setSavingError] = useState(false);
  const [deleteJob, setDeleteJob] = useState(null);

  const [datesWithImages, setDatesWithImages] = useState([]);
  const [satelliteDate, setSatelliteDate] = useState();

  const { addAction } = useActionSnackbarContext();

  const farm = useFarm();

  const setPrescriptionJob = useCallback(
    (changes, recalculate) => {
      const currentJobToAction = {
        true: () => {
          dispatch(setCurrentJob({ ...changes, saved: false }, recalculate));
        },
        false: () => {
          dispatch(updateFutureJob({ ...changes, saved: false }));
        },
      };

      const setJob = currentJobToAction[showCurrentJob];

      setJob();
    },
    [showCurrentJob]
  );

  useEffect(() => {
    if (
      !selectedField ||
      !selectedField.bundle ||
      !fields ||
      (selectedSurvey && !selectedSurvey.fieldId)
    ) {
      return;
    }
    const bundleId = selectedField.bundle;

    // gets historical data on field, including dates
    WebAPIUtils.getBundleStats(bundleId, 'ndvi').then((result) => {
      if (!result || result.length === 0) {
        setDatesWithImages(null);
        return;
      }
      const dates = result.map((stat) => stat.date);
      setDatesWithImages(dates);
      const latestDateWithImage = getLatestDate(result);

      // If field has image on the date from the prescriptionjob
      const imageExists = dates.includes(moment(selectedJob.date).format('YYYY-MM-DD'));
      if (
        imageExists &&
        selectedJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] &&
        selectedJob.assets[SATELLITE_LAYERS.VARIATIONS_NDVI] &&
        selectedJob.assets[SATELLITE_LAYERS.VISIBLE]
      ) {
        setSatelliteDate(moment(selectedJob.date).format('YYYY-MM-DD'));
        // If entering prescription view from saved prescription, survey assets are not yet set
        const assets = selectedSurvey != null ? handleSurveyAssets() : null;

        setPrescriptionJob({
          assets: {
            ...selectedJob.assets,
            ...assets,
          },
          date: new Date(latestDateWithImage),
        });
        fetchYieldData(assets);
        return;
      }

      setSatelliteDate(latestDateWithImage);

      // Fetching data to get image on field on the latest date the field has an image
      dispatch(
        fetchCropHealth(farm.farmId, moment(latestDateWithImage).format('YYYY-MM-DD'), fields)
      ).then((result) => {
        // Handling assets on the new date
        const currentImages = result.value;
        const fieldImages =
          currentImages && currentImages.find((image) => image.field_id === selectedJob.fieldId);

        const assets = { ...setSatelliteAssets(fieldImages), ...handleSurveyAssets() };

        setPrescriptionJob({
          assets: {
            ...selectedJob.assets,
            ...assets,
          },
          date: new Date(latestDateWithImage),
        });

        fetchYieldData(assets);
      });
    });
  }, [fields, selectedSurvey]);

  const handleSurveyAssets = () => {
    if (selectedJob.category !== SOURCE_CATEGORY.VITALITY) {
      return;
    }
    const surveyImages = Object.keys(selectedSurvey.newImages);
    const jobAssets = Object.keys(selectedJob.assets);

    const isMissingImages = surveyImages.some((i) => jobAssets.includes(i) === false);

    if (!isMissingImages) {
      return;
    }

    return setSurveyAssets();
  };

  const fetchYieldData = (assets) => {
    WebAPIUtils.getYieldData(farm.farmId, selectedField.fieldId).then((result) => {
      if (!result || result.yieldImages.length === 0) {
        return;
      }
      result.yieldImages.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      setPrescriptionJob({
        assets: { ...selectedJob.assets, ...{ ...assets, [YIELD_LAYERS.YIELD]: result } },
      });
    });
  };

  useEffect(() => {
    setShowArchive(viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE);
  }, [viewMode]);

  useEffect(() => {
    if (selectedSeason) {
      WebAPIUtils.getSeasonFields(selectedSeason.id).then((result) => {
        if (result && result.fields) {
          setFields(createFieldsMap(result.fields));
        }
      });
    }
  }, [selectedSeason]);

  // Create new Prescription Job
  useEffect(() => {
    if (viewMode === ViewModeConstants.PRESCRIPTION) {
      if (selectedField) {
        if (!loadingJob && !selectedJob) {
          const job = new PrescriptionJob();
          job.seasonId = selectedSeason.id;
          job.fieldId = selectedField.fieldId;
          job.fieldSize = selectedField.size;
          job.bounds = selectedField.bounds;
          job.date = new Date(date);
          job.unit = PRESCRIPTION_UNIT.KILOGRAM;
          job.metaType = ''; // Default
          job.metering = PRESCRIPTION_METERING.HA; // Default
          job.saved = true; // true to allow faster exits when selecting source, will be set to false when source is selected

          if (showSatelliteImagery) {
            job.layer = SATELLITE_LAYERS.VITALITY_NDVI;
          } else if (showSoilSurveys) {
            job.layer = selectedLayer;
          }
          if (selectedSurvey && selectedSurvey.newImages) {
            job.survey = selectedSurvey;
            job.assets = { ...job.assets, ...setSurveyAssets() };
          }

          if (images) {
            job.assets = { ...job.assets, ...createJobAssets(job.date, job.fieldId) };
          }
          setPrescriptionJob(job);
        }
      }
    }
  }, [
    viewMode,
    selectedJob,
    images,
    loadingJob,
    selectedField,
    date,
    selectedSeason,
    selectedSurvey,
  ]);

  // Load Prescription Maps for Archive
  useEffect(() => {
    if (showArchive && fields != null) {
      setLoadingMaps(true);
      Promise.all([
        dispatch(getPrescriptionMaps(farm, fields)),
        dispatch(getPrescriptionDocumentation(farm.farmId)),
      ])
        .then(() => {
          setLoadingMaps(false);
        })
        .catch((e) => {
          setLoadingMaps(false);
        });
    }
  }, [farm, showArchive, fields]);

  // DOWNLOADING PRESCRIPTION JOBS
  const fetchJobWithValues = (job: PrescriptionJob, saveFirst: boolean) => {
    return new Promise(async (resolve, reject) => {
      let result: PrescriptionJob = job;

      if (saveFirst) {
        try {
          setSavingJob(true);
          setSavingError(false);

          result = await savePrescriptionJobPromise(result, farm);

          dispatch(savePrescriptionJobLocally(result));
          setPrescriptionJob(result);

          setSavingError(false);
          setSavingJob(false);
        } catch (e) {
          setSavingError(true);
          setSavingJob(false);
          setLoadingJob(false);

          reject(new Error('Failed to save the VRM.'));
          return;
        }
      }

      // Save went well or was skipped
      if (isSatelliteLayer(result.layer)) {
        const data = await WebAPIUtils.getVariableRateMap(result.jobId);

        // required to make sure we have the required TIFF available for creating the VRM backend
        // let payload = await dispatch(fetchCropHealth(farm.farmId, moment(data.date).format("YYYY-MM-DD"), fields));
        // if (payload.value && payload.value.length > 0) {
        result = PrescriptionJob.fromNdviData(data, fields);
        // }
        // else {
        //   setSavingJob(false);
        //   setLoadingJob(false);
        //
        //   reject(new Error("The required TIFF was not found for this VRM."));
        //   return;
        // }
      } else {
        const data = await WebAPIUtils.getPrescriptionJob(farm, result);
        result = PrescriptionJob.fromSurveyData(data, fields);
      }

      setSavingJob(false);
      setLoadingJob(false);
      resolve(result);
    });
  };

  const onResumeJob = useCallback(
    (resumeJob: PrescriptionJob) => {
      setShowArchive(false);

      const field = fields.get(resumeJob.fieldId);
      setIsResumingJob(true);

      fetchJobWithValues(resumeJob)
        .then((result) => {
          dispatch(
            setSelectedSurveyForcefully({ surveyId: 'RESUMED-JOB', newImages: result.images })
          );
          const jobToSave: PrescriptionJob = {
            ...result,
            values: result.metaType === MetaTypes.LIME ? null : result.values,
          };
          setPrescriptionJob(jobToSave);
          dispatch(selectField(field));
          dispatch(setViewMode(ViewModeConstants.PRESCRIPTION));
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [fields, farm, selectedSurvey]
  );

  const onSetSeason = useCallback(
    (job: PrescriptionJob, seasonId: number) => {
      const season = seasons.find((s: Season) => s.id === seasonId);

      dispatch(setPrescriptionJobSeasonId(farm, job, seasonId))
        .then(() => {
          const message = `${LangFile.PrescriptionContainer.job} ${job.jobName} ${LangFile.PrescriptionContainer.successfullyMoved} ${LangFile.PrescriptionContainer.to} ${formatSeason(season, LangFile)}`;
          addAction(new Action(job.jobId, message, 'success', 'filled'));
        })
        .catch((e) => {
          console.error(e);
          const message = `${LangFile.PrescriptionContainer.failedToMove} ${LangFile.PrescriptionContainer.job} ${job.jobName} ${LangFile.PrescriptionContainer.to} ${formatSeason(season, LangFile)}`;
          addAction(new Action(job.jobId, message, 'success', 'filled'));
        });
    },
    [fields, farm, seasons]
  );

  const onConfirmDeleteJob = useCallback(() => {
    setDeleteJob((current) => {
      const job = maps.find((m) => current === m.jobId);
      dispatch(deletePrescriptionJobs([job], farm));
      return null;
    });
  }, [farm, maps]);

  const multipleDownload = async (jobsToDownload: PrescriptionJob[], saveFirst: boolean) => {
    if (!saveFirst) {
      setDownloadJob(jobsToDownload);
      return;
    }

    setSavingJob(true);
    saveMultipleJobs().then((savedJobs) => {
      setSavingJob(false);
      setDownloadJob(savedJobs);
    });
  };

  const singularDownload = (job: PrescriptionJob, saveFirst: boolean) => {
    if (job.legacy) {
      setDownloadJob([job]);
      return;
    }

    fetchJobWithValues(job, saveFirst)
      .then((result) => {
        setDownloadJob([result]);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onDownloadJob = useCallback(
    (shouldDownloadMultipleJobs: boolean) => {
      const jobsToDownload: PrescriptionJob[] = [currentJob, futureJob];
      const shouldDownloadToDownloadJob = {
        true: () =>
          multipleDownload(
            jobsToDownload,
            jobsToDownload.some((job) => job.saved === false)
          ),
        false: () => singularDownload(currentJob, currentJob.saved === false),
      };

      const downloadAction = shouldDownloadToDownloadJob[shouldDownloadMultipleJobs];

      downloadAction();
    },
    [currentJob, futureJob, fields, farm]
  );

  const onResetDownloadJob = useCallback(() => {
    setDownloadJob(null);
  }, []);

  const onDocumentationUploaded = useCallback(() => {
    dispatch(getPrescriptionDocumentation(farm.farmId));
  }, [farm.farmId]);

  const onDeleteDocumentation = useCallback(
    (file) => {
      return dispatch(deletePrescriptionDocumentation(file.id)).then(() => {
        dispatch(getPrescriptionDocumentation(farm.farmId));
      });
    },
    [farm.farmId]
  );

  const onHideSaveDialog = useCallback(() => {
    setShowSaveDialog(false);
    setSavingError(false);
  }, []);

  const onHideExitSaveDialog = useCallback(() => {
    setShowExitSaveDialog(false);
    setSavingError(false);
  }, []);

  const onExit = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    dispatch(resetCurrentJob());
    dispatch(resetFutureJob());
    dispatch(selectField(null));
    dispatch(setSelectedSurveyForcefully());
    setIsResumingJob(false);
  }, []);

  const saveMultipleJobs = async (): Promise => {
    const savedCurrentJob = await savePrescriptionJobPromise(currentJob, farm);
    savePrescriptionJobPromise(
      { ...futureJob, vrmSet: { ...futureJob.vrmSet, sourceVrmId: savedCurrentJob.jobId } },
      farm
    )
      .then((savedFutureJob) => {
        dispatch(setCurrentJob({ ...savedCurrentJob, saved: true }));
        dispatch(setFutureJob({ ...savedFutureJob, saved: true }));
        addAction(new Action(savedFutureJob.jobId, 'Jobs has been saved', 'success', 'filled'));
        return [savedCurrentJob, savedFutureJob];
      })
      .catch((e) => {
        setSavingError(true);
        addAction(
          new Action(
            selectedJob.jobId,
            `Saving Failed: ${e.response.data.message}`,
            'error',
            'filled'
          )
        );
      });
  };

  const saveSingleJob = (): Promise => {
    if (!selectedJob.jobName) {
      selectedJob.jobName = LangFile.PrescriptionContainer.unnamed;
    }

    return savePrescriptionJobPromise(selectedJob, farm)
      .then((job) => {
        dispatch(setCurrentJob({ ...job, saved: true }));
        addAction(new Action(job.jobId, 'Job has been saved', 'success', 'filled'));
      })
      .catch((e) => {
        setSavingError(true);
        addAction(
          new Action(
            selectedJob.jobId,
            `Saving Failed: ${e.response.data.message}`,
            'error',
            'filled'
          )
        );
      });
  };

  const initiateJobSaving = (saveMultiple: boolean): Promise => {
    setSavingJob(true);

    const saveJob = saveMultiple ? saveMultipleJobs : saveSingleJob;

    return saveJob().then(() => {
      setSavingJob(false);
      setShowSaveDialog(false);
    });
  };

  const onSaveJob = useCallback(() => {
    if (hasMultipleJobs) {
      setShowSaveDialog(true);
      return;
    }

    initiateJobSaving(false);
  }, [currentJob, futureJob]);

  const onSaveAndExit = useCallback(() => {
    initiateJobSaving().then(() => {
      onExit();
    });
  }, [selectedJob, farm, onExit]);

  const onExitSafe = useCallback(() => {
    setIsExiting(true);

    if (!selectedJob && !downloadJob) {
      dispatch(setViewMode(ViewModeConstants.OVERVIEW));
      return;
    }

    if (downloadJob) {
      onResetDownloadJob();
    }

    if (selectedJob.saved) {
      onExit();
      return;
    }

    setShowExitSaveDialog(true);
  }, [downloadJob, selectedJob]);

  const onSeasonChange = useCallback((seasonId) => {
    dispatch(selectSeason(seasonId));
    dispatch(resetFilters());
  }, []);

  const onSatelliteDateChange = useCallback(
    (selectedDate) => {
      setSatelliteDate(selectedDate);

      const dateLoaded = !!images.get(selectedDate);
      if (dateLoaded) {
        const fieldHasImage = images.get(moment(date).format('YYYY-MM-DD'));
        if (!fieldHasImage) {
          return;
        }

        setPrescriptionJob({
          assets: {
            ...currentJob.assets,
            ...createJobAssets(new Date(selectedDate), currentJob.fieldId),
          },
        });
        return;
      }

      dispatch(fetchCropHealth(farm.farmId, selectedDate, fields)).then((result) => {
        const currentImages = result.value;
        const fieldImages =
          currentImages && currentImages.find((image) => image.field_id === currentJob.fieldId);

        setPrescriptionJob({
          assets: {
            ...currentJob.assets,
            ...setSatelliteAssets(fieldImages),
          },
        });
      });
    },
    [images, fields, farm, currentJob]
  );

  const createJobAssets = (date: Date, fieldId: number) => {
    const currentImages = images.get(moment(date).format('YYYY-MM-DD'));
    const fieldImages = currentImages && currentImages.find((image) => image.field_id === fieldId);
    return setSatelliteAssets(fieldImages);
  };

  const setSatelliteAssets = (fieldImages) => {
    if (fieldImages === null || fieldImages === undefined) {
      return;
    }

    let assets = {};

    if (fieldImages.hasOwnProperty(SATELLITE_LAYERS.VITALITY_NDVI)) {
      assets = {
        ...assets,
        ...{ [SATELLITE_LAYERS.VITALITY_NDVI]: fieldImages[SATELLITE_LAYERS.VITALITY_NDVI] },
      };
    }

    if (fieldImages.hasOwnProperty(SATELLITE_LAYERS.VARIATIONS_NDVI)) {
      assets = {
        ...assets,
        ...{ [SATELLITE_LAYERS.VARIATIONS_NDVI]: fieldImages[SATELLITE_LAYERS.VARIATIONS_NDVI] },
      };
    }

    if (fieldImages.hasOwnProperty(SATELLITE_LAYERS.VISIBLE)) {
      assets = {
        ...assets,
        ...{ [SATELLITE_LAYERS.VISIBLE]: fieldImages[SATELLITE_LAYERS.VISIBLE] },
      };
    }

    return assets;
  };

  const setSurveyAssets = () => {
    return selectedSurvey && selectedSurvey.newImages ? { ...selectedSurvey.newImages } : {};
  };

  const handleOnJobSwitch = (showCurrent: boolean) => {
    setShowCurrentJob(showCurrent);
  };

  const onDeleteFutureJob = useCallback(() => {
    if (futureJob.saved) {
      dispatch(deletePrescriptionJobs([futureJob], farm));
    }
    dispatch(resetFutureJob());
  });

  const handleMergePrescriptionJobs = useCallback(() => {
    const updatedFutureJob: PrescriptionJob = { ...futureJob, originalValues: currentJob.values };
    dispatch(setFutureJob(updatedFutureJob));
    const mergedValues = mergePrescriptionJobsValues(currentJob.values, futureJob.values);
    const newIntervals = calculateLimeJobIntervals(mergedValues, true);

    const newJob: PrescriptionJob = {
      ...currentJob,
      values: mergedValues,
      intervals: newIntervals,
    };

    dispatch(setCurrentJob(newJob, true));
    onDeleteFutureJob();
  });

  return (
    <FullScreenPortalPaper>
      {loadingJob && (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}>
          <CircularProgress variant={'indeterminate'} color={'primary'} size={80} thickness={4} />
        </Box>
      )}

      {selectedJob && (
        <PrescriptionJobProvider value={[selectedJob, setPrescriptionJob]}>
          <ShowCurrentJobProvider
            value={{
              showCurrentJob: [showCurrentJob, handleOnJobSwitch],
              hasMultipleJobs,
            }}>
            <PrescriptionEditorContainer
              selectedField={selectedField}
              updatePrescriptionJob={setPrescriptionJob}
              onDownloadJob={onDownloadJob}
              onExitSafe={onExitSafe}
              onExit={onExit}
              disableSettingsDialog={datesWithImages === null}
              onSaveJob={onSaveJob}
              onDeleteFutureJob={onDeleteFutureJob}
              isResumingJob={isResumingJob}
              renderSatelliteDateHandler={() => {
                return (
                  <DateHandler
                    dates={datesWithImages}
                    date={satelliteDate}
                    handleDateSet={onSatelliteDateChange}
                  />
                );
              }}
              onMergePrescriptionJobs={handleMergePrescriptionJobs}
            />

            <MultipleActionsDialog
              open={showSaveDialog}
              title={LangFile.PrescriptionSplitViewContainer.multipleJobs.saveDialog.title}
              message={LangFile.PrescriptionSplitViewContainer.multipleJobs.saveDialog.message}
              loading={savingJob}
              cancelText={LangFile.PrescriptionSplitViewContainer.multipleJobs.saveDialog.cancel}
              onCancel={onHideSaveDialog}
              primaryText={LangFile.PrescriptionSplitViewContainer.multipleJobs.saveDialog.primary}
              onPrimary={() => initiateJobSaving(true)}
              secondaryText={
                LangFile.PrescriptionSplitViewContainer.multipleJobs.saveDialog.secondary
              }
              onSecondary={() => initiateJobSaving(false)}
              error={savingError}
            />

            <PrescriptionExitSaveDialog
              onExit={onExit}
              onCancel={onHideExitSaveDialog}
              onSaveAndExit={onSaveAndExit}
              savingJob={savingJob}
              savingError={savingError}
              open={showExitSaveDialog}
            />
          </ShowCurrentJobProvider>
        </PrescriptionJobProvider>
      )}

      {showArchive && fields && (
        <PrescriptionArchive
          maps={maps}
          documentation={documentation}
          loadingMaps={loadingMaps}
          fields={fields}
          onDocumentationUploaded={onDocumentationUploaded}
          onDeleteDocumentation={onDeleteDocumentation}
          onDownload={onDownloadJob}
          onClose={onExitSafe}
          onSetSeason={onSetSeason}
          onEdit={onResumeJob}
          onDelete={setDeleteJob}
          onSeasonChange={onSeasonChange}
        />
      )}

      {downloadJob && (
        <PrescriptionJobDownloadContainer
          legacy={downloadJob.legacy}
          viewMode={viewMode}
          savingJob={savingJob}
          savingError={savingError}
          downloadJob={downloadJob}
          onBack={onResetDownloadJob}
        />
      )}

      <PrescriptionArchiveConfirmDeleteDialog
        open={deleteJob != null}
        onClose={setDeleteJob}
        onConfirm={onConfirmDeleteJob}
      />
    </FullScreenPortalPaper>
  );
};

export default memo(connect(mapStateToProps)(PrescriptionContainer));
