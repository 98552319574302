import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useLangFile } from 'js/context/LanguageContext';

const DialogConfirmRemoveFieldFromSeason = ({ open, onClose, onConfirm }) => {
  let LangFile = useLangFile();

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={onClose}>
      <DialogTitle>{LangFile.DialogConfirmRemoveFieldFromSeason.removeField}</DialogTitle>
      <DialogContent>
        <p>{LangFile.DialogConfirmRemoveFieldFromSeason.removeNotice1}</p>
        <p>{LangFile.DialogConfirmRemoveFieldFromSeason.removeNotice2}</p>
        <p>{LangFile.DialogConfirmRemoveFieldFromSeason.removeNotice3}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{LangFile.DialogConfirmRemoveFieldFromSeason.abort}</Button>
        <Button color="secondary" variant={'contained'} onClick={onConfirm}>
          {LangFile.DialogConfirmRemoveFieldFromSeason.removeField}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmRemoveFieldFromSeason.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DialogConfirmRemoveFieldFromSeason;
