/* ============== //
||  ACTIONTYPES   ||
// ============== */

const SHOW_PROGRESS = 'fieldsense/Progress/SHOW_PROGRESS';
const HIDE_PROGRESS = 'fieldsense/Progress/HIDE_PROGRESS';

/* ============== //
||    ACTIONS    ||
// ============== */

export function showProgress(message) {
  return {
    type: SHOW_PROGRESS,
    payload: message || '',
  };
}

export function hideProgress() {
  return {
    type: HIDE_PROGRESS,
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  shown: false,
  message: '',
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SHOW_PROGRESS:
      state = { ...state, shown: true, message: action.payload };
      break;

    case HIDE_PROGRESS:
      state = { ...state, shown: false, message: '' };
      break;

    default:
      break;
  }

  return state;
}
