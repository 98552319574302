'use strict';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import Styles from 'js/components/UI-Elements/InputFieldUpdatedOnBlur/InputFieldUpdatedOnBlur.module.less';

const InputFieldUpdatedOnBlur = ({
  value,
  onUpdate,
  type,
  onFocus,
  style,
  placeholder,
  disabled,
}) => {
  const inputType = type || 'number';
  const forcedStyle = style || {};

  const [inputValue, setInputValue] = useState(value);
  const inputField = useRef(null);
  const [clearedText, setClearedText] = useState(placeholder);

  useEffect(() => {
    setInputValue(Number.isNaN(value) ? '' : value);
  }, [value]);

  const keyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        inputField.current.blur();
        event.preventDefault();
      }
    },
    [inputField.current]
  );

  const blur = useCallback(() => {
    if (inputValue === '' && clearedText) {
      setInputValue(clearedText);
      setClearedText(null);
      return;
    }

    if (inputType !== 'text') {
      onUpdate(Number(inputValue).toFixed(2));
    } else {
      onUpdate(inputValue);
    }
  }, [onUpdate, inputType, clearedText, inputValue, inputField.current]);

  const clearOnFocus = useCallback(
    (event) => {
      if (onFocus) {
        onFocus();
      }

      if (inputValue !== '') {
        setClearedText(inputValue);
        setInputValue('');
      }
    },
    [onFocus, inputValue]
  );

  return (
    <input
      ref={inputField}
      className={Styles.Root}
      type={inputType}
      value={Number.isNaN(inputValue) ? '' : inputValue}
      onChange={(event) => setInputValue(event.target.value)}
      placeholder={Number.isNaN(clearedText) ? '' : clearedText}
      onFocus={clearOnFocus}
      onKeyUp={keyUp}
      onBlur={blur}
      disabled={disabled}
      style={forcedStyle}
    />
  );
};

export default memo(InputFieldUpdatedOnBlur);
