import { useCallback, useRef, useState } from 'react';
import useEvent from 'js/hooks/useEvent';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

const useIsMouseDown = (minClickDuration = 0, target = window) => {
  const [event, setEvent] = useState(null);
  const [isDown, setisDown] = useState(false);

  const timeout = useRef();

  const downCallback = useCallback(
    throttle((e) => {
      timeout.current = setTimeout(() => {
        setisDown(true);
        setEvent(e);
      }, minClickDuration);
    }, 1000 / 30),
    [minClickDuration]
  );

  const upCallback = useCallback((e) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setEvent(null);
    setisDown(false);
  }, []);

  const moveCallback = useCallback(
    (e) => {
      if (isDown) {
        setEvent(e);
      }
    },
    [isDown]
  );

  useEvent('mousedown', downCallback, target);
  useEvent('mousemove', moveCallback, target);
  useEvent('mouseover', moveCallback, target);
  useEvent('mouseup', upCallback, target);
  useEvent('mouseleave', upCallback, target);

  return isDown && event;
};

useEvent.PropTypes = {
  target: PropTypes.node.isRequired,
};

export default useIsMouseDown;
