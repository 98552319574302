// @flow

import LanguageConstants from 'js/constants/LanguageConstants';
import English from 'js/language/en';
import Danish from 'js/language/da';
import German from 'js/language/de';
import Estonian from 'js/language/et';
import French from 'js/language/fr';
import Finish from 'js/language/fi';
import Swedish from 'js/language/sv';
import Latvian from 'js/language/lv';
import Lithuanian from 'js/language/lt';

import denmark from 'style/images/icons/denmark.svg';
import uk from 'style/images/icons/uk.svg';
import france from 'style/images/icons/france.svg';
import germany from 'style/images/icons/germany.svg';
import finland from 'style/images/icons/finland.svg';
import estonia from 'style/images/icons/estonia.svg';
import sweden from 'style/images/icons/sweden.svg';
import latvia from 'style/images/icons/latvia.svg';
import lithuania from 'style/images/icons/lithuania.svg';

import merge from 'lodash.merge';

export type LanguageFile =
  | $Exact<typeof English>
  | $Exact<typeof Danish>
  | $Exact<typeof German>
  | $Exact<typeof Estonian>
  | $Exact<typeof French>
  | $Exact<typeof Finish>
  | $Exact<typeof Swedish>
  | $Exact<typeof Latvian>
  | $Exact<typeof Lithuanian>;

export function getLanguageFile(newLanguage): LanguageFile {
  let lang = newLanguage || getBrowserLanguage();

  let file = English;

  switch (lang) {
    case LanguageConstants.DANISH:
      file = Danish;
      break;
    case LanguageConstants.GERMAN:
      file = German;
      break;
    case LanguageConstants.ENGLISH:
      file = English;
      break;
    case LanguageConstants.FRENCH:
      file = French;
      break;
    case LanguageConstants.ESTONIAN:
      file = Estonian;
      break;
    case LanguageConstants.FINNISH:
      file = Finish;
      break;
    case LanguageConstants.SWEDISH:
      file = Swedish;
      break;
    case LanguageConstants.LATVIAN:
      file = Latvian;
      break;
    case LanguageConstants.LITHUANIAN:
      file = Lithuanian;
      break;
    default:
      file = English;
  }

  // Use _.merge to recursively merge the intended language file into the English (default) language file.
  // English keys will be copied to the empty object {}
  // Translated keys will then overwrite the English keys

  return merge({}, English, file);
}

export function getBrowserLanguage() {
  const lang = navigator.language || navigator.userLanguage;
  const shortCode = lang.substr(0, 2);
  const supported = Object.values(LanguageConstants).includes(shortCode);
  return supported ? shortCode : LanguageConstants.ENGLISH;
}

export const getLanguageIcon = (lang) => {
  switch (lang) {
    case LanguageConstants.DANISH:
      return denmark;
    case LanguageConstants.GERMAN:
      return germany;
    case LanguageConstants.ENGLISH:
      return uk;
    case LanguageConstants.FRENCH:
      return france;
    case LanguageConstants.ESTONIAN:
      return estonia;
    case LanguageConstants.FINNISH:
      return finland;
    case LanguageConstants.SWEDISH:
      return sweden;
    case LanguageConstants.LATVIAN:
      return latvia;
    case LanguageConstants.LITHUANIAN:
      return lithuania;
    default:
      return uk;
  }
};
