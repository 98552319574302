import { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

function useMapEvent(map, eventType, handler) {
  const savedHandler = useRef();
  const listenerRef = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const memoListener = useCallback((e) => {
    savedHandler.current(e);
  }, []);

  useEffect(() => {
    if (map) {
      if (listenerRef.current) {
        google.maps.event.removeListener(listenerRef.current);
      }

      listenerRef.current = map.addListener(eventType, memoListener);

      return () => {
        if (listenerRef.current) {
          google.maps.event.removeListener(listenerRef.current);
        }
      };
    }
  }, [map]);
}

useMapEvent.PropTypes = {
  dataLayer: PropTypes.object.isRequired,
  eventType: PropTypes.oneOf([
    'bounds_changed',
    'center_changed',
    'click',
    'dblclick',
    'drag',
    'dragend',
    'dragstart',
    'heading_changed',
    'idle',
    'maptypeid_changed',
    'mousemove',
    'mouseout',
    'mouseover',
    'projection_changed',
    'resize',
    'rightclick',
    'tilesloaded',
    'tilt_changed',
    'zoom_changed',
  ]).isRequired,
  handler: PropTypes.func.isRequired,
};

export default useMapEvent;
