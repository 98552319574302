import * as d3 from 'd3';
import React, { memo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useD3locale } from 'js/context/LanguageContext';
import useBreakpoint from 'js/hooks/useBreakpoint';
import { useGraph } from 'js/context/GraphContext';
import ManualFeatureConstants from '../../../constants/ManualFeatureConstants';

const GraphAxis = ({ onTickClicked }) => {
  const { height, width, svg, datasets, xMap, yLeftMap, yRightMap, xTicks } = useGraph();
  const d3locale = useD3locale();
  const locale = d3.timeFormatLocale(d3locale);

  const breakpoint = useBreakpoint();

  const hourFormat = breakpoint === 'sm' ? '%H' : '%H:%S';

  let formatMillisecond = locale.format('.%L'),
    formatSecond = locale.format(':%S'),
    formatMinute = locale.format('%H:%M'),
    formatHour = locale.format(hourFormat),
    formatDay = locale.format('%a %d'),
    formatWeek = locale.format('%b %d'),
    formatMonth = locale.format('%B'),
    formatYear = locale.format('%Y');

  /* eslint-disable no-nested-ternary */
  function multiFormat(date) {
    return (
      d3.timeSecond(date) < date
        ? formatMillisecond
        : d3.timeMinute(date) < date
          ? formatSecond
          : d3.timeHour(date) < date
            ? formatMinute
            : d3.timeDay(date) < date
              ? formatHour
              : d3.timeMonth(date) < date
                ? d3.timeWeek(date) < date
                  ? formatDay
                  : formatWeek
                : d3.timeYear(date) < date
                  ? formatMonth
                  : formatYear
    )(date);
  }

  /* eslint-enable no-nested-ternary */

  useLayoutEffect(() => {
    if (xMap) {
      let xAxis = d3
        .axisBottom(xMap)
        .tickFormat(multiFormat)
        .ticks(xTicks || 0);

      svg
        .select('.x.axis')
        .call(xAxis)
        .attr('transform', `translate(0 ${height})`)
        .style('font-family', 'Roboto, Sans-Serif');

      if (ManualFeatureConstants.ALLOW_GRAPH_X_AXIS_CLICK && onTickClicked) {
        svg
          .select('.x.axis')
          .selectAll('.tick')
          .style('cursor', 'pointer')
          .on('click', (d) => {
            onTickClicked(d);
          });
      }
    }

    if (yLeftMap) {
      let datasetLeft = Object.values(datasets).find((dataset) => dataset.axis === 'left');

      if (datasetLeft) {
        let yAxisLeft = d3.axisLeft(yLeftMap).tickFormat(function (d, idx, ticks) {
          if (idx === ticks.length - 1) {
            return datasetLeft.unit || d;
          }
          return d;
        });

        let axis = svg.select('.y.axis.left').call(yAxisLeft);

        axis
          .selectAll('text')
          // .attr("fill", datasetLeft.color)
          .style('font-family', 'Roboto, Sans-Serif');
      }
    }

    if (yRightMap) {
      let datasetRight = Object.values(datasets).find((dataset) => dataset.axis === 'right');

      if (datasetRight) {
        let yAxisRight = d3.axisRight(yRightMap).tickFormat(function (d, idx, ticks) {
          if (idx === ticks.length - 1) {
            return datasetRight.unit || d;
          }
          return d;
        });

        let axis = svg
          .select('.y.axis.right')
          .call(yAxisRight)
          .attr('transform', `translate(${width} 0)`);

        axis
          .selectAll('text')
          // .attr("fill", datasetRight.color)
          .style('font-family', 'Roboto, Sans-Serif');
      }
    }
  }, [svg, datasets, xMap, yLeftMap, yRightMap, xTicks, height, width, onTickClicked]);

  if (height < 0) {
    return null;
  }

  return (
    <g className="graph-axis">
      <g className="x axis" />
      {yLeftMap && <g className="y axis left" />}
      {yRightMap && <g className="y axis right" />}
    </g>
  );
};

GraphAxis.propTypes = {
  /** Callback function fired when user clicks on a tick */
  onTickClicked: PropTypes.func,
};

export default memo(GraphAxis);
