'use strict';

import WebAPIUtils from 'js/WebAPIUtils';

const GET_DATA_INTEGRATIONS = 'fieldsense/DataIntegrations/GET_DATA_INTEGRATIONS';

/* ============== //
 ||     UTILS     ||
 // ============== */

export const isDataIntegrationEnabled = (dataIntegrations, integration) => {
  if (!dataIntegrations || !Array.isArray(dataIntegrations)) {
    return false;
  }
  return dataIntegrations.find((i) => i.name === integration && i.connected) !== undefined;
};

/* ============== //
 ||    ACTIONS    ||
 // ============== */

export function getDataIntegrations(farmId) {
  return {
    type: GET_DATA_INTEGRATIONS,
    payload: WebAPIUtils.getIntegrations(farmId),
  };
}

/* ============== //
 ||   CONSTANTS   ||
 // ============== */

export const DATA_INTEGRATION_CLAAS_TELEMATICS = 'CLAAS TELEMATICS';

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  dataIntegrations: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case GET_DATA_INTEGRATIONS + '_FULFILLED': {
      state = { ...state, dataIntegrations: action.payload.integrations };
      break;
    }
    default:
      break;
  }
  return state;
}
