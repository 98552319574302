import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import PrescriptionSplitView from 'js/components/Prescription/PrescriptionSplitView/PrescriptionSplitView';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import MapCanvas from '../../../components/MapObjects/MapCanvas/MapCanvas.jsx';

import {
  isSatelliteLayer,
  isSurveyLayer,
  isYieldLayer,
} from 'js/components/Prescription/PrescriptionJob';

import { PRESCRIPTION_INTERVAL_COLORS } from 'js/constants/PrescriptionConstants';
import MapCanvasClickable, {
  canvasToMap,
} from 'js/components/MapObjects/MapCanvas/MapCanvasClickable';
import MapFloatPanel from 'js/components/MapView/MapFloatPanel';
import PrescriptionOverrideList from 'js/components/Prescription/PrescriptionOverrideList/PrescriptionOverrideList';
import { connect } from 'react-redux';
import DataLayer from '../../DataLayer/DataLayer';
import FeaturePolygon from '../../DataLayer/FeaturePolygon';
import { yellow } from '@material-ui/core/colors';
import MapLegend from '../../MapLegend/MapLegend';
import WebAPIUtils from '../../../WebAPIUtils';
import { useFarm } from '../../../context/AccountContext';
import { useHookRef } from '../../../hooks/useHookRef';
import {
  getLayerConfig,
  calculateIntervals,
  calculateIntervalAreas,
  initializeOverrides,
} from '../PrescriptionUtils';
import { Box } from '@material-ui/core';
import MapCanvasMiniatureContainer from '../../MapObjects/MapCanvas/MapCanvasMiniatureContainer';
import ViewModeConstants from '../../../constants/ViewModeConstants';
import HeightMapToggle from '../../MapObjects/HeightMap/HeightMapToggle';
import GoogleMap from '../../MapObjects/GoogleMap/GoogleMap';
import HeightMap from '../../MapObjects/HeightMap/HeightMap';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../../hooks/useFirebaseAnalytics';
import * as turf from '@turf/turf';
import {
  iterateRawValues,
  iterateValidOverrides,
  iterateValidValues,
} from '../../../helpers/SurveyUtils';
import { useSelectedSurvey, useSurveyLayerViewCapabilities } from '../../../context/SurveyContext';
import {
  Action,
  useActionSnackbarContext,
} from '../../ActionSnackbarHandler/ActionSnackbarHandler';
import { useLangFile } from '../../../context/LanguageContext';
import { getClassificationValues } from '../../../reducers/SurveyReducer';
import PrescriptionClassificationChangeDialog from '../Dialogs/PrescriptionClassificationChangeDialog';
import { YIELD_LAYERS } from '../../../constants/YieldLayer';
import YieldSelector from './YieldSelector';
import VariableColorScale from '../../MapLegend/VariableColorScale';
import { DATA_INTEGRATION_YIELD } from '../../../constants/YieldLayer';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';
import { useMultipleJobs } from '../PrescriptionJobContext.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MetaTypes } from '../PrescriptionJob.js';
import { SURVEY_LAYERS } from '../../../constants/SurveyLayers.js';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrescriptionRecalculateDialog from '../Dialogs/PrescriptionRecalculateDialog';
import { SATELLITE_LAYERS } from '../../../constants/SatelliteLayers.js';
import { setDate } from '../../../reducers/FieldReducer.js';

const tinycolor = require('tinycolor2');

const boundsToPolygon = (bounds) => {
  return [
    [bounds.west, bounds.north],
    [bounds.east, bounds.north],
    [bounds.east, bounds.south],
    [bounds.west, bounds.south],
    [bounds.west, bounds.north], // end in the start point
  ];
};

const validJob = (job: PrescriptionJob) => {
  const invalidIntervals = job.intervals.filter((interval) => {
    return (
      Number.isNaN(interval.min) ||
      Number.isNaN(interval.max) ||
      !Number.isFinite(interval.min) ||
      !Number.isFinite(interval.max)
    );
  });

  return invalidIntervals.length <= 0;
};

const RGB_COLORS = PRESCRIPTION_INTERVAL_COLORS.map((hex) => tinycolor(hex).toRgb());

export function getIntervalColorFromPixel(value, intervals) {
  let color;

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    if (value >= interval.min) {
      color = RGB_COLORS[i];
    }
  }
  return color;
}

export function getIntervalColorForClassificationIndex(index) {
  let color;
  if (index < RGB_COLORS.length) {
    color = RGB_COLORS[index];
  }
  return color;
}

function setPixel(imageData, x, y, color) {
  const { r, g, b, a } = color;
  const index = (x + y * imageData.width) * 4;
  imageData.data[index] = r;
  imageData.data[index + 1] = g;
  imageData.data[index + 2] = b;
  imageData.data[index + 3] = Math.floor(a * 255);
}

const metaTypeToInitialLayerSelection = {
  [MetaTypes.FERTILIZING]: [],
  [MetaTypes.SEEDING]: [],
  [MetaTypes.LIME]: [SURVEY_LAYERS.HUMUS, SURVEY_LAYERS.CLAY],
  [MetaTypes.SPRAYING]: [],
  [MetaTypes.SPOT_SPRAYING]: [],
  [MetaTypes.LEGACY]: [],
};

const mapStateToProps = (store) => ({
  fields: store.field.fields,
  selectedFieldDates: store.field.selectedFieldDates,
  images: store.field.images,
  viewMode: store.control.viewMode,
  overlays: store.overlay.overlays,
  selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,
  dataIntegrations: store.integrations.dataIntegrations,
  selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
  selectedDate: store.field.date,
});

const PrescriptionSplitViewContainer = ({
  dispatch,
  isOverriding,
  fields,
  viewMode,
  selectedFieldDates,
  overrideBrushValue,
  overrideBrushSize,
  onOverridesChanged,
  enableSurveys,
  enableNdvi,
  classificationsEnabled,
  setClassificationsEnabled,
  variationsEnabled,
  setVariationsEnabled,
  overlays,
  selectedSurveyClassificationValues,
  setClassificationsReset,
  renderSatelliteDateHandler,
  dataIntegrations,
  setSourceLayer,
  sourceLayer,
  selectedDate,
  selectedSurveyReferenceValues,
  updatePrescriptionJob,
}) => {
  const LangFile = useLangFile();
  const analytics = useFirebaseAnalytics();
  const { prescriptionJob } = usePrescriptionJob();
  const { addAction } = useActionSnackbarContext();
  const { showCurrentJob, hasMultipleJobs } = useMultipleJobs();
  const [shouldShowCurrentJob, setShowCurrentJob] = showCurrentJob;

  const tabValue = shouldShowCurrentJob ? 0 : 1;

  const handleTabChange = (tabValue) => {
    const tabToJob = {
      0: true,
      1: false,
    };

    setShowCurrentJob(tabToJob[tabValue]);
  };

  const farm = useFarm();
  const farmId = useHookRef(farm.farmId);
  const legendRef = useRef();
  const bounds = useRef(prescriptionJob.bounds);
  const date = useRef(prescriptionJob.date);
  const field = useRef(fields.get(prescriptionJob.fieldId));
  const [imageURL, setImageURL] = useState(null);
  const clickPolygon = useRef(boundsToPolygon(bounds.current));

  const [sourceValues, setSourceValues] = useState(prescriptionJob.values);
  const [canvasSize, setCanvasSize] = useState({
    height: sourceValues.length,
    width: sourceValues[0].length,
  });

  const [referenceValues, setReferenceValues] = useState({
    [prescriptionJob.layer]: null,
  });
  const [enableClassification, setEnableClassification] = useState(classificationsEnabled);

  const [multipleSelections, setMultipleSelections] = useState(
    metaTypeToInitialLayerSelection[prescriptionJob.metaType]
  );
  const [showHeightMapLeft, setShowHeightMapLeft] = useState(false);
  const [showHeightMapRight, setShowHeightMapRight] = useState(false);
  const [shouldScaleLatitude, setShouldScaleLatitude] = useState(false);
  const [showClassificationDialog, setShowClassificationDialog] = useState(false);
  const [variableColorScaleObject, setVariableColorScaleObject] = useState();
  const [showRecalculateDialog, setShowRecalculateDialog] = useState(false);

  const survey = useSelectedSurvey();

  const enableYield = dataIntegrations.some(
    (d) => d.name.toLowerCase() === DATA_INTEGRATION_YIELD && d.connected
  );
  const [dateForMap, setDateForMap] = useState(null);

  const layerCapabilities = useSurveyLayerViewCapabilities(
    sourceLayer,
    ViewModeConstants.PRESCRIPTION
  );
  const disableClassificationToggle =
    prescriptionJob.jobId &&
    prescriptionJob.layerType &&
    layerCapabilities.enableCustomClassification;

  useEffect(() => {
    setSourceLayer(prescriptionJob.layer);
  }, [prescriptionJob.layer]);

  useEffect(() => {
    if (isSurveyLayer(sourceLayer)) {
      const config = getLayerConfig(sourceLayer);
      const url = config.getImageUrl(
        prescriptionJob.assets,
        classificationsEnabled,
        variationsEnabled,
        ViewModeConstants.PRESCRIPTION
      );
      setImageURL(url);
      setShouldScaleLatitude(true);
    } else if (isSatelliteLayer(sourceLayer)) {
      if (prescriptionJob && prescriptionJob.assets) {
        setImageURL(prescriptionJob.assets[sourceLayer]);
        setShouldScaleLatitude(false);
      }
    }
  }, [sourceLayer, classificationsEnabled, variationsEnabled, prescriptionJob]);

  useEffect(() => {
    const match =
      typeof prescriptionJob.assets[sourceLayer] === 'string'
        ? prescriptionJob.assets[sourceLayer].match(/(\d{4}-\d{2}-\d{2})/)
        : null;
    let currentSelectedDate;
    if (match !== null) {
      currentSelectedDate = new Date(match[0]);
      dispatch(setDate(match[0]));
    }
    if (referenceValues[sourceLayer] == null || (match !== null && selectedDate !== match[0])) {
      new Promise(async (resolve, reject) => {
        const values = await WebAPIUtils.getLayerValues(
          farmId.current,
          field.current.fieldId,
          currentSelectedDate ? currentSelectedDate : prescriptionJob.date,
          sourceLayer,
          survey,
          prescriptionJob.jobId,
          null,
          prescriptionJob.layer
        );
        if (values != null) {
          resolve({
            [sourceLayer]: values,
          });
          return;
        }

        if (isYieldLayer(sourceLayer)) {
          return;
        }
        const layerName = getLayerConfig(sourceLayer).getName(LangFile);
        addAction(
          new Action(
            `failed-to-load-values-${sourceLayer}`,
            LangFile.PrescriptionContainer.failedToLoadValues.replace('%s', layerName),
            'warning',
            'filled'
          )
        );
      }).then((res) => {
        setReferenceValues((current) => {
          let result = { ...current };
          if (res != null) {
            result = { ...result, ...res };
          }

          const selectedLayers = Object.keys(result).filter(
            (l) => l !== sourceLayer && multipleSelections.includes(l)
          );

          if (
            multipleSelections.includes(YIELD_LAYERS.YIELD) &&
            !selectedLayers.includes(YIELD_LAYERS.YIELD)
          ) {
            const index = multipleSelections.indexOf(YIELD_LAYERS.YIELD);
            selectedLayers.splice(index, 0, YIELD_LAYERS.YIELD);
          }

          // only select valid/loaded layers
          setMultipleSelections(selectedLayers);

          return result;
        });
      });
    } else if (multipleSelections.includes(sourceLayer)) {
      setMultipleSelections(
        Object.keys(referenceValues).filter(
          (l) => l !== sourceLayer && multipleSelections.includes(l)
        )
      );
    }

    if (!classificationsEnabled || !layerCapabilities.enableCustomClassification) {
      return;
    }

    if (selectedSurveyClassificationValues[sourceLayer] == null) {
      dispatch(
        getClassificationValues(
          farmId.current,
          field.current.fieldId,
          date.current,
          sourceLayer,
          survey,
          prescriptionJob.jobId
        )
      );
    }

    const missingClassifications = multipleSelections.filter(
      (layer) => !selectedSurveyClassificationValues[layer]
    );
    missingClassifications.map(async (layer, idx, arr) => {
      if (selectedSurveyClassificationValues[layer] == null) {
        dispatch(
          getClassificationValues(
            farmId.current,
            field.current.fieldId,
            date.current,
            layer,
            survey,
            prescriptionJob.jobId
          )
        );
      }
    });
  }, [
    sourceLayer,
    referenceValues,
    classificationsEnabled,
    multipleSelections,
    selectedSurveyClassificationValues,
    imageURL,
  ]);

  const fetchMissingReferenceValues = (selections: string[], missingValues: string[]) => {
    if (isSatelliteLayer(sourceLayer)) {
      missingValues.forEach((layer) => {
        if (isSatelliteLayer(layer)) {
          setReferenceValues((current) => {
            return { ...current, ...{ [layer]: prescriptionJob.values } };
          });
        }
      });
    } else {
      Promise.all(
        missingValues.map(async (layer) => {
          const values = await WebAPIUtils.getLayerValues(
            farmId.current,
            field.current.fieldId,
            date.current,
            layer,
            survey,
            prescriptionJob.jobId,
            null,
            prescriptionJob.layer
          );

          if (values != null) {
            return {
              [layer]: values,
            };
          }
          if (isYieldLayer(layer)) {
            return;
          }

          const layerName = getLayerConfig(layer).getName(LangFile);
          addAction(
            new Action(
              `failed-to-load-values-${layer}`,
              LangFile.PrescriptionContainer.failedToLoadValues.replace('%s', layerName),
              'warning',
              'filled'
            )
          );
        })
      ).then((res) => {
        setReferenceValues((current) => {
          let result = { ...current };

          res.forEach((item) => {
            if (item != null) {
              result = { ...result, ...item };
            }
          });

          // only select valid/loaded layers

          const selectedLayers = Object.keys(result).filter(
            (l) => l !== sourceLayer && selections.includes(l)
          );
          if (
            selections.includes(YIELD_LAYERS.YIELD) &&
            !selectedLayers.includes(YIELD_LAYERS.YIELD)
          ) {
            const index = selections.indexOf(YIELD_LAYERS.YIELD);
            selectedLayers.splice(index, 0, YIELD_LAYERS.YIELD);
          }

          setMultipleSelections(selectedLayers);

          return result;
        });
      });
    }
  };

  const missing = multipleSelections.filter(
    (layer) => !referenceValues[layer] && !isYieldLayer(layer)
  );

  if (missing.length > 0) {
    fetchMissingReferenceValues(multipleSelections, missing);
  }

  const handleSetShowHeightMapLeft = useCallback((value) => {
    setShowHeightMapLeft(value);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_HEIGHT_MAP_LEFT, { shown: value });
  }, []);

  const handleSetShowHeightMapRight = useCallback((value) => {
    setShowHeightMapRight(value);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_HEIGHT_MAP_RIGHT, { shown: value });
  }, []);

  const onVariationsEnabledChanged = useCallback((value) => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SHOW_DETAILS, { shown: value });

    setVariationsEnabled(value);
    setClassificationsEnabled((curr) => {
      if (curr && value) {
        return false;
      }
      return curr;
    });
  }, []);

  const onClassificationsEnabledChanged = useCallback((value) => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SHOW_DEMAND, { shown: value });

    if (layerCapabilities.enableCustomClassification) {
      setEnableClassification(value);
      setShowClassificationDialog(true);
    } else {
      setChangeDemandClassification(value);
    }
  }, []);

  const onHideClassificationChangeDialog = useCallback(() => {
    setShowClassificationDialog(false);
  }, []);

  const onApplyClassificationChange = useCallback(() => {
    setShowClassificationDialog(false);
    setChangeDemandClassification(enableClassification);
    setClassificationsReset(true);
  }, [enableClassification]);

  const setChangeDemandClassification = (change) => {
    setClassificationsEnabled(change);
    setVariationsEnabled((curr) => {
      if (curr && change) {
        return false;
      }
      return curr;
    });
  };

  const recalculatePrescription = () => {
    const values = referenceValues[sourceLayer];
    setSourceValues(values);

    setCanvasSize({
      height: values.length,
      width: values[0].length,
    });

    let newJob = { ...prescriptionJob, layer: sourceLayer, values: values };

    newJob.intervals = calculateIntervals(newJob);
    newJob = calculateIntervalAreas(newJob);
    newJob.overrides = undefined;
    newJob.overrides = initializeOverrides(newJob);

    if (validJob(newJob)) {
      updatePrescriptionJob(newJob, true);
    }
  };

  const handleYieldReferenceValues = useCallback((values) => {
    if (values != null) {
      setReferenceValues((current) => {
        return { ...current, ...{ [sourceLayer]: values } };
      });

      return;
    }
  });

  const handleMultipleSelectionChanged = useCallback(
    (selections) => {
      setMultipleSelections(selections);
      if (selections.length > 0) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_MULTIPLE_SELECT, {
          selections: selections.join(', '),
        });

        const missing = selections.filter((layer) => !referenceValues[layer]);

        if (missing.length <= 0) {
          return;
        }

        fetchMissingReferenceValues(selections, missing);
      }
    },
    [referenceValues, prescriptionJob, LangFile, sourceLayer]
  );

  const drawPrescription = useCallback(
    (context: CanvasRenderingContext2D) => {
      const values = prescriptionJob.values;
      const intervals = prescriptionJob.intervals;
      const height = values.length;
      const width = values[0].length;
      const imageData = context.createImageData(width, height);

      setCanvasSize({
        height: values.length,
        width: values[0].length,
      });

      if (
        layerCapabilities.enableCustomClassification &&
        classificationsEnabled &&
        selectedSurveyClassificationValues[sourceLayer]
      ) {
        const fineMappings = selectedSurveyClassificationValues[sourceLayer]['mappings']['FINE'];
        const values = selectedSurveyClassificationValues[sourceLayer]['values'];
        iterateRawValues(values, (x, y, value) => {
          const classification = Object.keys(fineMappings).find((key) =>
            fineMappings[key].includes(value)
          );
          const index = intervals.findIndex((interval) => {
            return interval.classification === classification;
          });
          const color = getIntervalColorForClassificationIndex(index);
          if (color) {
            setPixel(imageData, x, y, color);
          }
        });
      } else {
        iterateValidValues(values, (x, y, value) => {
          const color = getIntervalColorFromPixel(value, intervals);
          if (color == null) {
            return;
          }
          setPixel(imageData, x, y, color);
        });
      }

      context.putImageData(imageData, 0, 0, 0, 0, width, height);
    },
    [prescriptionJob, dateForMap]
  );

  const drawOverrides = useCallback(
    (context: CanvasRenderingContext2D) => {
      const overrides = prescriptionJob.overrides;
      const groups = prescriptionJob.overrideAreas;

      const height = overrides.length;
      const width = overrides[0].length;

      const imageData = context.createImageData(width, height);

      iterateValidOverrides(overrides, (x, y, value) => {
        const group = groups[value];

        if (group) {
          const color = tinycolor(group.color).toRgb();
          setPixel(imageData, x, y, color);
        }
      });

      context.putImageData(imageData, 0, 0, 0, 0, width, height);
    },
    [prescriptionJob.overrides, prescriptionJob.overrideAreas]
  );

  const getContainedPixels = useCallback((boxPoly) => {
    const isContained = (x, y) => {
      const { lat, lng } = canvasToMap(y, x, sourceValues, bounds.current);
      const point = turf.point([lng, lat]);
      return turf.booleanPointInPolygon(point, boxPoly);
    };

    let startX = null,
      startY = null,
      endX = null,
      endY = null;

    iterateRawValues(sourceValues, (x, y) => {
      if (isContained(x, y)) {
        if (startX === null) {
          startX = x;
        }
        if (startY === null) {
          startY = y;
        }
        endX = Math.max(endX, x);
        endY = Math.max(endY, y);
      }
    });

    return { startX, startY, endX, endY };
  }, []);

  const onOverridesCanvasClicked = useCallback(
    (pixelXY, latLng, boxPoly) => {
      const values = sourceValues;
      const nextOverrides = prescriptionJob.overrides.map((row) => row.slice(0));

      const { startX, startY, endX, endY } = getContainedPixels(boxPoly);

      for (let x = startX; x < endX; x++) {
        for (let y = startY; y < endY; y++) {
          const valueRow = values[y];
          const value = valueRow && valueRow[x];

          if (value !== null && value > 0) {
            nextOverrides[y][x] = overrideBrushValue;
          }
        }
      }

      onOverridesChanged(nextOverrides);
    },
    [onOverridesChanged, overrideBrushValue, prescriptionJob.overrides]
  );

  const onOverridesCanvasHovered = useCallback(
    (boxPoly, ctx, width, height) => {
      const { startX, startY, endX, endY } = getContainedPixels(boxPoly);

      const prevFill = ctx.fillStyle;

      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = 'rgba(35,35,200,0.2)';
      ctx.fillRect(startX, startY, endX - startX, endY - startY);
      ctx.fillStyle = prevFill;
    },
    [onOverridesChanged, overrideBrushValue, prescriptionJob.overrides]
  );

  const recalculatePrescriptionDisabled = () => {
    return (
      (isSatelliteLayer(sourceLayer) && sourceLayer !== SATELLITE_LAYERS.VITALITY_NDVI) ||
      prescriptionJob.layer === sourceLayer ||
      isYieldLayer(sourceLayer)
    );
  };

  return (
    <PrescriptionSplitView
      overrideMode={isOverriding}
      overrideBrushValue={overrideBrushValue}
      bounds={bounds.current}>
      {(leftMap) => (
        <Fragment>
          <MapFloatPanel zIndex={0} top={0} left={0} right={0} bottom={0}>
            <GoogleMap mapType={'transparent'} disableInteraction={true} syncWithMap={leftMap}>
              <HeightMap shown={showHeightMapLeft} overlays={overlays} />
            </GoogleMap>
          </MapFloatPanel>

          <MapFloatPanel zIndex={200} top={8} left={8}>
            <MapLegend
              viewMode={viewMode}
              showSatelliteImagery={isSatelliteLayer(sourceLayer)}
              showSoilSurveys={isSurveyLayer(sourceLayer)}
              showOtherLayers={isYieldLayer(sourceLayer)}
              selectedFieldHasImage={enableNdvi}
              selectedFieldHasSurvey={enableSurveys}
              selectedLayer={sourceLayer}
              surveys={[{ surveyId: 'teasta', newImages: prescriptionJob.images }]}
              values={referenceValues[sourceLayer]}
              dates={selectedFieldDates}
              renderDateHandler={() => {
                if (isSatelliteLayer(sourceLayer)) {
                  return <> {renderSatelliteDateHandler()}</>;
                }
                if (
                  isYieldLayer(sourceLayer) &&
                  prescriptionJob.assets[YIELD_LAYERS.YIELD].yieldImages
                ) {
                  return (
                    <YieldSelector
                      setImageURL={setImageURL}
                      setVariableColorScaleObject={setVariableColorScaleObject}
                      setReferenceValues={handleYieldReferenceValues}
                    />
                  );
                }
              }}
              networkEnabled={false}
              variationsEnabled={variationsEnabled}
              classificationsEnabled={classificationsEnabled}
              surveyClassificationValues={selectedSurveyClassificationValues}
              onSetSatelliteImageType={setSourceLayer}
              onSetSurveyType={setSourceLayer}
              onVariationsEnabledChanged={onVariationsEnabledChanged}
              onClassificationsEnabledChanged={onClassificationsEnabledChanged}
              enableMultipleSelect={true}
              multipleSelections={multipleSelections}
              onMultipleSelectChanged={handleMultipleSelectionChanged}
              disableDemandClassification={disableClassificationToggle}
              enableYield={enableYield}
              selectedFieldHasYield={!!prescriptionJob.assets[YIELD_LAYERS.YIELD]}
              onSelectOtherType={setSourceLayer}
              renderVariableColorScale={() => {
                if (isYieldLayer(sourceLayer)) {
                  return (
                    <VariableColorScale
                      tooltip={LangFile.MapLegend.yieldScaleTooltip}
                      variableColorScaleObject={variableColorScaleObject}
                      unit={LangFile.MapLegend.yieldUnit}
                    />
                  );
                }
              }}
            />

            <PrescriptionClassificationChangeDialog
              newState={enableClassification}
              onCancel={onHideClassificationChangeDialog}
              onApply={onApplyClassificationChange}
              open={showClassificationDialog}
            />

            <Box
              ref={legendRef}
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              style={{ pointerEvents: 'none' }}
            />
          </MapFloatPanel>

          <MapFloatPanel zIndex={200} top={8} right={8}>
            <HeightMapToggle
              onToggle={handleSetShowHeightMapLeft}
              showHeightMap={showHeightMapLeft}
            />
          </MapFloatPanel>

          <DataLayer
            setStyle={(feature) => {
              return {
                strokeColor: yellow['A400'],
                fillOpacity: 0,
                clickable: false,
              };
            }}>
            <FeaturePolygon coords={field.current.polygon.coordinates} />
          </DataLayer>
          <MapCanvasMiniatureContainer
            viewMode={viewMode}
            legendRef={legendRef.current}
            isOverriding={isOverriding}
            imageURL={imageURL}
            field={field.current}
            selectedLayer={sourceLayer}
            multipleSelections={multipleSelections}
            classificationsEnabled={classificationsEnabled}
            onMultipleSelectChanged={handleMultipleSelectionChanged}
            variationsEnabled={variationsEnabled}
            values={referenceValues}
            classificationValues={selectedSurveyClassificationValues}
          />

          <MapCanvas
            zIndex={2}
            clipPathPolygon={field.current.polygon.coordinates[0]}
            updateDependencies={[drawOverrides]}
            bounds={bounds.current}
            width={canvasSize.width}
            height={canvasSize.height}
            onDraw={drawOverrides}
          />

          {isOverriding && (
            <MapCanvasClickable
              zIndex={6}
              shouldScaleLatitude={shouldScaleLatitude}
              clipPathPolygon={field.current.polygon.coordinates[0]}
              bounds={bounds.current}
              width={canvasSize.width}
              height={canvasSize.height}
              overrideBrushSize={overrideBrushSize}
              clickPolygon={clickPolygon.current}
              onClick={onOverridesCanvasClicked}
              onHover={onOverridesCanvasHovered}
            />
          )}
        </Fragment>
      )}

      {(rightMap) => (
        <Fragment>
          {hasMultipleJobs && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              position={'absolute'}
              zIndex="1"
              top="8px"
              right="8px">
              <Box
                display="flex"
                alignItems="center"
                bgcolor="white"
                borderRadius="4px"
                padding="0 8px"
                marginBottom="8px">
                <InfoOutlinedIcon style={{ marginRight: '8px' }} fontSize="large" />
                <p>{LangFile.PrescriptionSplitViewContainer.multipleJobs.info}</p>
              </Box>
              <Box bgcolor="white">
                <Tabs value={tabValue} onChange={(event, newValue) => handleTabChange(newValue)}>
                  <Tab label={LangFile.PrescriptionSplitViewContainer.multipleJobs.currentJob} />
                  <Tab label={LangFile.PrescriptionSplitViewContainer.multipleJobs.futureJob} />
                </Tabs>
              </Box>
            </Box>
          )}

          <MapFloatPanel zIndex={200} top={8} right={8}>
            <HeightMapToggle
              onToggle={handleSetShowHeightMapRight}
              showHeightMap={showHeightMapRight}
            />
          </MapFloatPanel>

          <MapFloatPanel zIndex={0} top={0} left={0} right={0} bottom={0}>
            <GoogleMap mapType={'transparent'} disableInteraction={true} syncWithMap={rightMap}>
              <HeightMap shown={showHeightMapRight} overlays={overlays} />
            </GoogleMap>
          </MapFloatPanel>

          <MapFloatPanel zIndex={2} top={8} left={8}>
            {enableSurveys && prescriptionJob.metaType !== MetaTypes.LIME && (
              <>
                <Box minWidth={180} maxWidth={180}>
                  <Button
                    style={{ fontSize: 'x-small' }}
                    disabled={recalculatePrescriptionDisabled()}
                    onClick={() => setShowRecalculateDialog(true)}
                    variant={'contained'}>
                    <Box display={'flex'} mt={0.5} mb={0.5}>
                      <RefreshIcon fontSize="small" />{' '}
                      <div style={{ marginLeft: '8px' }}>
                        {LangFile.PrescriptionRecalculate.recalculatePrescription}
                      </div>
                    </Box>
                  </Button>
                </Box>
                <PrescriptionRecalculateDialog
                  onCancel={() => setShowRecalculateDialog(false)}
                  open={showRecalculateDialog}
                  onRecalculate={() => {
                    setShowRecalculateDialog(false);
                    recalculatePrescription();
                  }}
                />
              </>
            )}
          </MapFloatPanel>
          <DataLayer
            setStyle={(feature) => {
              return {
                strokeColor: yellow['A400'],
                fillOpacity: 0,
                clickable: false,
              };
            }}>
            <FeaturePolygon coords={field.current.polygon.coordinates} />
          </DataLayer>

          <MapCanvas
            updateDependencies={[drawPrescription]}
            clipPathPolygon={field.current.polygon.coordinates[0]}
            bounds={bounds.current}
            width={canvasSize.width}
            height={canvasSize.height}
            onDraw={drawPrescription}
          />

          <MapCanvas
            updateDependencies={[drawOverrides]}
            clipPathPolygon={field.current.polygon.coordinates[0]}
            bounds={bounds.current}
            width={canvasSize.width}
            height={canvasSize.height}
            onDraw={drawOverrides}
          />

          {isOverriding && (
            <Fragment>
              <MapCanvasClickable
                clipPathPolygon={field.current.polygon.coordinates[0]}
                bounds={bounds.current}
                shouldScaleLatitude={shouldScaleLatitude}
                width={canvasSize.width}
                height={canvasSize.height}
                overrideBrushSize={overrideBrushSize}
                clickPolygon={clickPolygon.current}
                onClick={onOverridesCanvasClicked}
                onHover={onOverridesCanvasHovered}
              />

              <MapFloatPanel left={8} top={16}>
                <PrescriptionOverrideList onOverridesUpdated={onOverridesChanged} />
              </MapFloatPanel>
            </Fragment>
          )}
        </Fragment>
      )}
    </PrescriptionSplitView>
  );
};

PrescriptionSplitViewContainer.propTypes = {
  isOverriding: PropTypes.bool,
  enableNdvi: PropTypes.bool,
  enableSurveys: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  setClassificationsEnabled: PropTypes.func,
  setClassificationsReset: PropTypes.func,
  variationsEnabled: PropTypes.bool,
  setVariationsEnabled: PropTypes.func,
  overrideBrushValue: PropTypes.number,
  overrideBrushSize: PropTypes.number,
  onOverridesChanged: PropTypes.func,
  renderSatelliteDateHandler: PropTypes.func,
  setSourceLayer: PropTypes.func,
  sourceLayer: PropTypes.string,
  showCurrentJob: PropTypes.bool,
  updatePrescriptionJob: PropTypes.func,
};

PrescriptionSplitViewContainer.defaultProps = {};

export default memo(connect(mapStateToProps)(PrescriptionSplitViewContainer));
