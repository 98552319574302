import * as d3 from 'd3';
import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useGraph } from 'js/context/GraphContext';

const GraphArea = ({ datasetName }) => {
  const { datasets, xMap, yLeftMap, yRightMap } = useGraph();
  const areaRef = useRef(null);
  const d3Area = useRef(null);

  const dataset = datasets && datasets[datasetName];
  const yMap = dataset && dataset.axis === 'left' ? yLeftMap : yRightMap;

  useEffect(() => {
    if (dataset && areaRef.current) {
      d3Area.current = d3
        .area()
        .curve(d3.curveCatmullRom)
        .x0((d) => xMap(d.x))
        .x1((d) => xMap(d.x))
        .y0((d) => yMap(d.ymin || 0))
        .y1((d) => yMap(d.y));

      d3.select(areaRef.current)
        .style('fill', dataset.color)
        .style('stroke', dataset.color)
        .attr('d', d3Area.current(dataset.points));
    }
  }, [dataset, xMap, yMap, areaRef.current]);

  return (
    <g>
      <path ref={areaRef} />
    </g>
  );
};

GraphArea.propTypes = {
  /**
   * Select which data entry to display. Simply write the property name of the data entry to display.
   *
   * If this is not provided, the graphline will select a property based on which child index this has.
   */
  datasetName: PropTypes.string,
};

export default memo(GraphArea);
