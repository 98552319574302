import moment from 'moment';
import ManualFeatureConstants from '../../../constants/ManualFeatureConstants';

export const getPointSegments = (points, resolution, timeThreshold) => {
  let segments = [];

  let currentSegment = {
    valid: null,
    points: [points[0]],
  };

  // Create basic segments
  for (let i = 1; i < points.length; i++) {
    let previousPoint = currentSegment.points[currentSegment.points.length - 1];
    let point = points[i];

    if (previousPoint.y !== null) {
      // this is valid segment
      let rawDiffError = moment(point.x).diff(moment(previousPoint.x), 'minutes') > timeThreshold;

      if (point.y !== null && !rawDiffError) {
        // valid segment continues
        currentSegment.points.push(point);
      } else {
        // valid segment ended, start new invalid segment
        currentSegment.valid = true; // make sure the valid property is set correctly
        segments.push(currentSegment);

        currentSegment = {
          valid: false,
          points: [point],
        };
      }
    } else {
      // this is a null segment
      if (point.y === null) {
        // null segment continues
        currentSegment.points.push(point);
      } else {
        // null segment ended, start new valid segment
        currentSegment.valid = false;
        segments.push(currentSegment);

        currentSegment = {
          valid: true,
          points: [point],
        };
      }
    }

    // Add the last segment to the list
    if (i === points.length - 1) {
      currentSegment.valid = currentSegment.points[currentSegment.points.length - 1].y !== null;
      segments.push(currentSegment);
    }
  }

  // Simplify invalid segments to a simple 2-point LERP.
  segments.forEach((segment, index) => {
    if (!segment.valid) {
      if (index > 0) {
        let previousSegment = segments[index - 1];

        segment.points = [
          previousSegment.points[previousSegment.points.length - 1],
          segment.points[segment.points.length - 1],
        ];

        segment.valid = ManualFeatureConstants.ENABLE_WEATHER_MAKEUP; // If set to true, the segment will be shown

        if (index < segments.length - 1) {
          let nextSegment = segments[index + 1];

          segment.points = [segment.points[0], nextSegment.points[0]];
        } else {
          let firstPoint = segment.points[0];
          let lastPoint = segment.points[segment.points.length - 1];

          segment.points = [{ ...firstPoint }, { ...lastPoint, y: firstPoint.y }];
        }
      }
    }
  });

  return segments;
};
