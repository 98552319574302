import React, { useContext } from 'react';
import SettingsConstants from 'js/constants/SettingsConstants';

const SettingsContext = React.createContext({});

export const SettingsProvider = SettingsContext.Provider;
export const SettingsConsumer = SettingsContext.Consumer;

export function useSettings() {
  return useContext(SettingsContext);
}

export function useFarmSettings() {
  let settings = useSettings();
  return settings && settings.farmSettings;
}

export function useUserSettings() {
  let settings = useSettings();
  return settings && settings.userSettings;
}

export function useHasSetting(setting) {
  let farmSettings = useFarmSettings();
  let userSettings = useUserSettings();

  if (!setting) {
    return false;
  }

  return (
    (farmSettings && farmSettings.global && farmSettings.global[setting]) ||
    (userSettings && userSettings[setting])
  );
}

export function useGDPRConsent() {
  let userSettings = useUserSettings();

  if (!userSettings) {
    return false;
  }

  let setting = userSettings[SettingsConstants.USER_GDPR_CONSENT];

  let result = false;
  if (setting) {
    let consentForm = typeof setting === 'string' ? JSON.parse(setting) : setting;
    let consent = consentForm && consentForm.consent;

    result = (typeof consent === 'string' && consent.toLowerCase() === 'true') || consent === true;
  } else {
    result = false;
  }

  return result;
}

export function useGpsFixLocation(label) {
  let globalSettings = useFarmSettings().global;
  let latLngString =
    globalSettings && globalSettings[SettingsConstants.FARM_WEATHER_STATION_LOCATION(label)];

  if (!latLngString || latLngString.trim() === '') {
    return null;
  }

  let latLngParts = latLngString.split(',');
  let lat = latLngParts[0];
  let lng = latLngParts[1];

  if (lat && lng) {
    return new google.maps.LatLng(Number(lat), Number(lng));
  }

  return null;
}
