/* ============== //
||      TYPES     ||
// ============== */

import { FIELD_STATISTICS_COLUMNS } from '../components/FieldStatistics/FieldStatisticsColumns';

const SET_SAVED_COLUMNS = 'fieldsense/StatisticsReducer/SET_SAVED_COLUMNS';

/* ============== //
||     ACTIONS    ||
// ============== */

export function setSavedColumns(savedColumns) {
  return {
    type: SET_SAVED_COLUMNS,
    payload: savedColumns,
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

export const DEFAULT_COLUMNS = [
  FIELD_STATISTICS_COLUMNS.AREA,
  FIELD_STATISTICS_COLUMNS.CROP,
  FIELD_STATISTICS_COLUMNS.CROP_VARIETY,
  FIELD_STATISTICS_COLUMNS.SOWING_DATE,
];

const initState = {
  savedColumns: DEFAULT_COLUMNS,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SET_SAVED_COLUMNS: {
      state = { ...state, savedColumns: action.payload };
      break;
    }

    default:
      break;
  }

  return state;
}
