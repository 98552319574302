import React, { memo } from 'react';
import logo from 'style/images/brand/cropline_c.png';
import logoName from 'style/images/brand/cropline_logo.png';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const Logo = ({ size, variant }) => {
  if (variant === 'full') {
    return (
      <Box display={'flex'} width={'100%'} justifyContent={'center'}>
        <img src={logoName} style={{ maxWidth: '100%', maxHeight: size }} />
      </Box>
    );
  } else {
    let style = {
      background: `url(${logo}) no-repeat center`,
      backgroundSize: '100% 100%',
    };

    return <Box width={size} height={size} style={style} />;
  }
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['full', 'short']),
  size: PropTypes.any,
};

export default memo(Logo);
