'use strict';

export default {
  FUNGUS: 'FUNGUS',
  WEED: 'WEED',
  PESTS: 'PESTS',
  OBSTACLE: 'OBSTACLE',
  SOIL: 'SOIL',
  WINDOW: 'WINDOW',
  OTHER: 'OTHER',
};
