import React, { memo, useEffect, useState } from 'react';
import GraphSystem from 'js/components/visualization/Graph/GraphSystem';
import GraphLine from 'js/components/visualization/Graph/GraphLine';
import GraphArea from 'js/components/visualization/Graph/GraphArea';
import GraphHoverDisplay from 'js/components/visualization/Graph/GraphHoverDisplay';
import SatelliteSource from 'js/model/SatelliteSource';

import moment from 'moment-timezone';
import { blue, purple } from '@material-ui/core/colors';
import * as d3 from 'd3';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { Analysis, VisibleTooltips } from 'js/constants/NdviAnalysisConstants';
import GraphVerticalLimitLine from '../visualization/Graph/GraphVerticalLimitLine';
import { useLangFile } from '../../context/LanguageContext';
import seedlingImage from 'style/images/analysis/seedling.svg';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { getSatelliteShorthandKey } from '../Prescription/PrescriptionJob';

const sortByDate = (a, b) => {
  if (a.date > b.date) {
    return 1;
  }
  if (b.date > a.date) {
    return -1;
  }
  return 0;
};

const generateData = (data, year, date, satelliteSource, imageType, fieldSeasonsMap, onClick) => {
  data = data.filter(
    (element) => element.source === satelliteSource || satelliteSource === SatelliteSource.BOTH
  );
  // Sort the data
  data = data.sort(sortByDate);
  // Then remove all dublicate entries
  data = data.filter(
    (element, idx, ary) =>
      idx === 0 ||
      idx === data.length - 1 ||
      element.source === SatelliteSource.SENTINEL ||
      (element.date !== ary[idx - 1].date && element.date !== ary[idx + 1].date)
  );

  const sentinel2Color = blue['A400'];
  const landsatColor = purple['A700'];
  const meanColor =
    satelliteSource === SatelliteSource.SENTINEL || satelliteSource === SatelliteSource.BOTH
      ? sentinel2Color
      : landsatColor;

  let ndviMean = data.map((element) => ({
    x: moment(element.date).valueOf(),
    y: Math.max(element.mean, 0),
    selected: element.date === date,
    onClick: () => onClick(element.date),
    overrideColor:
      satelliteSource === SatelliteSource.BOTH && element.source === SatelliteSource.LANDSAT
        ? landsatColor
        : undefined,
  }));
  ndviMean = ndviMean.filter((element) => moment(element.x).isSame(year + '-01-01', 'year'));

  let stddev = data.map((element) => ({
    x: moment(element.date).valueOf(),
    y: Math.max(element.stddev, 0),
  }));
  stddev = stddev.filter((element) => moment(element.x).isSame(year + '-01-01', 'year'));

  let minMax = data.map((element) => ({
    x: moment(element.date).valueOf(),
    y: Math.max(element.max, 0),
    ymin: Math.max(element.min, 0),
  }));
  minMax = minMax.filter((element) => moment(element.x).isSame(year + '-01-01', 'year'));
  const unit =
    imageType === SATELLITE_LAYERS.VITALITY_NDRE || imageType === SATELLITE_LAYERS.VARIATIONS_NDRE
      ? getSatelliteShorthandKey(SATELLITE_LAYERS.VITALITY_NDRE).toLowerCase()
      : getSatelliteShorthandKey(SATELLITE_LAYERS.VITALITY_NDVI).toLowerCase();

  return {
    [Analysis.MEAN]: {
      name: Analysis.MEAN,
      unit: unit,
      points: ndviMean,
      showCircle: true,
      color: meanColor,
      type: 'line',
      axis: 'left',
      showTooltip: VisibleTooltips[Analysis.MEAN],
    },
    [Analysis.STDDEV]: {
      name: Analysis.STDDEV,
      unit: unit,
      points: stddev,
      showCircle: false,
      color: 'rgba(255, 180, 0, 0.5)',
      type: 'area',
      axis: 'left',
      showTooltip: VisibleTooltips[Analysis.STDDEV],
    },
    [Analysis.SPAN]: {
      name: Analysis.SPAN,
      unit: unit,
      points: minMax,
      showCircle: false,
      color: 'rgba(126, 186, 242, 0.2)',
      type: 'area',
      axis: 'left',
      showTooltip: VisibleTooltips[Analysis.SPAN],
    },
  };
};

const margins = { top: 20, right: 20, bottom: 20, left: 20 };
const domainY = [0, 1];
const ticks = d3.timeMonth.every(1);

const AnalysisGraph = ({
  selectedFieldStatistics,
  year,
  date,
  satelliteSource,
  setDate,
  imageType,
  fieldSeasonsMap,
}) => {
  const LangFile = useLangFile();
  const [state, setState] = useState(null);

  useEffect(() => {
    if (selectedFieldStatistics && year && date && satelliteSource) {
      let datasets = generateData(
        selectedFieldStatistics,
        year,
        date,
        satelliteSource,
        imageType,
        fieldSeasonsMap,
        (date) => setDate(date)
      );
      let domainX = [moment(year + '-01-01'), moment(year + '-12-31')];

      setState({
        datasets,
        domainX,
      });
    }
  }, [satelliteSource, date, year, selectedFieldStatistics, fieldSeasonsMap]);

  if (!state) {
    return (
      <Box mb={1} pl={3} height={'100%'} width={'100%'} display={'flex'} flex={'1 1 auto'}>
        <Skeleton width={'100%'} height={'100%'} />
      </Box>
    );
  }

  const renderVerticalLimitLines = (year, fieldSeasonsMap) => {
    const elements = [];
    if (fieldSeasonsMap[year]) {
      fieldSeasonsMap[year].forEach((fieldSeason, index) => {
        const sowing = moment(fieldSeason.sowing);
        if (sowing.year() === Number(year)) {
          let dataSet = {
            title: LangFile.CropType[fieldSeason.crop],
            icon: seedlingImage,
            x: sowing.valueOf(),
            color: '#000',
          };

          elements.push(<GraphVerticalLimitLine dataset={dataSet} key={3 + index} />);
        }
      });
    }
    return elements;
  };

  return (
    <Box
      mb={1}
      pl={3}
      pt={1}
      height={'100%'}
      width={'100%'}
      display={'flex'}
      flex={'1 1 auto'}
      style={{ boxSizing: 'border-box' }}
    >
      <GraphSystem
        datasets={state.datasets}
        xTicks={ticks}
        margin={margins}
        domainLeftY={domainY}
        minExtentLeftY={1}
        spaceTopLeftY={0}
        domainX={state.domainX}
      >
        {renderVerticalLimitLines(year, fieldSeasonsMap)}

        <GraphArea datasetName={Analysis.SPAN} key={0} displayDots={true} />

        <GraphArea datasetName={Analysis.STDDEV} key={1} />

        <GraphLine displayDots={true} datasetName={Analysis.MEAN} key={2} />

        <GraphHoverDisplay cursorIsPointer={true} />
      </GraphSystem>
    </Box>
  );
};

export default memo(AnalysisGraph);
