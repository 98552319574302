import * as d3 from 'd3';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import GraphTooltip from './GraphTooltip';
import { useGraph } from 'js/context/GraphContext';
import { useGraphHover } from 'js/context/GraphHoverContext';
import throttle from 'lodash.throttle';

// Used by the mouse hover to focus on closest point
const bisectLeft = d3.bisector((d) => d.x || d).left;
const bisectRight = d3.bisector((d) => d.x || d).right;

const GraphHoverDisplay = ({ hideTooltip, cursorIsPointer, getColor }) => {
  const { xMap, yLeftMap, yRightMap, allPoints, datasets, width, height } = useGraph();
  const { setHoveredItems } = useGraphHover();

  const hoverDisplay = useRef(null);
  const [focus, setFocus] = useState(null);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  useEffect(() => {
    setFocus(null);
  }, [datasets]);

  const mouseClick = useCallback(() => {
    if (focus) {
      let values = Object.values(focus);

      if (values.length > 0) {
        values.forEach((value) => {
          if (value.onClick) {
            value.onClick();
          }
        });
      }
    }
  }, [focus]);

  const mouseOver = useCallback(
    throttle(() => {
      if (!displayTooltip) {
        setDisplayTooltip(true);
      }
    }, 50),
    [displayTooltip]
  );

  const mouseOut = useCallback(() => {
    setHoveredItems({});
    setDisplayTooltip(false);
  }, [setHoveredItems]);

  const updateHover = useCallback(
    throttle((values) => {
      setFocus(values);
      setHoveredItems(values);
    }, 50),
    []
  );

  const mouseMove = useCallback(
    (event) => {
      event.persist();

      if (!xMap) {
        return;
      }

      if (!displayTooltip) {
        setDisplayTooltip(true);
      }

      // First find the x value of the closest entry
      let svgLeft = hoverDisplay.current ? hoverDisplay.current.getBoundingClientRect().left : 0;
      let x = event.clientX - Math.max(svgLeft, 0);

      // Find the upper and lower bound points
      let x0 = xMap.invert(x);
      let i = bisectLeft(allPoints, x0, 1);
      let d0 = allPoints[i - 1];
      let d1 = allPoints[i];

      // Ensure that we are not out of bounds, this can happen at the edges.
      if (!d0 || !d1) {
        return;
      }

      // Figure out if it is the lower or upper point which is closest
      let chosenX = x0 - d0 > d1 - x0 ? d1 : d0;

      // Find relevant y values to display
      let values = {};
      Object.keys(datasets).forEach((key) => {
        let dataset = datasets[key];
        if (dataset) {
          let points = dataset.points;

          let xIndex = bisectRight(points, chosenX, 1) - 1;
          let point = points[xIndex];

          if (point) {
            if (!dataset.pointWidth || point.x + dataset.pointWidth >= chosenX) {
              values[key] = point;
            }
          }
        }
      });

      // Update state
      updateHover(values);
    },
    [
      displayTooltip,
      setDisplayTooltip,
      hoverDisplay.current,
      updateHover,
      xMap,
      bisectLeft,
      bisectRight,
      allPoints,
      datasets,
    ]
  );

  let focused = focus && Object.values(focus)[0];
  let xPos = focused ? focused.x : 0;
  let customColor = typeof getColor === 'function';

  return (
    <g className="hover-display">
      {displayTooltip && focus && (
        <g className="focus">
          {Object.keys(focus).map((dataPropName, idx) => {
            let dataset = datasets[dataPropName];

            if (!dataset) {
              return null;
            }

            let yMap = dataset.axis === 'left' ? yLeftMap : yRightMap;
            let point = focus[dataPropName];
            let color = customColor ? getColor(point.y, dataset) : dataset.color;

            if (point.y === null) {
              return null;
            }

            return dataset.showCircle && !point.selected ? (
              <circle
                key={idx}
                fill={color}
                stroke={color}
                style={{
                  pointerEvents: 'none',
                }}
                transform={'translate(' + xMap(point.x) + ',' + yMap(point.y) + ')'}
                r={4}
              />
            ) : null;
          })}

          <line
            style={{
              stroke: '#000',
              opacity: 0.8,
              pointerEvents: 'none',
            }}
            transform={'translate(' + xMap(xPos) + ',0)'}
            y1={0}
            y2={height}
          />
        </g>
      )}

      {!hideTooltip && displayTooltip && focus && (
        <GraphTooltip focus={focus} getColor={getColor} />
      )}

      {width > 0 && (
        <rect
          ref={hoverDisplay}
          width={width}
          height={height}
          className={'mouse-hover-display-div'}
          style={{
            fill: 'none',
            pointerEvents: 'all',
            cursor: cursorIsPointer ? 'pointer' : '',
          }}
          onMouseEnter={mouseOver}
          onMouseLeave={mouseOut}
          onMouseMove={mouseMove}
          onClick={mouseClick}
        />
      )}
    </g>
  );
};

GraphHoverDisplay.propTypes = {};

export default memo(GraphHoverDisplay);
