'use strict';
import React, { Fragment } from 'react';

import ViewModeConstants from 'js/constants/ViewModeConstants';

import { authenticateFarm, fetchFarms } from 'js/reducers/FarmReducer';
import { selectField } from 'js/reducers/FieldReducer';
import { setShowUserMenu, setViewMode, setShowFarmSettings } from 'js/reducers/ControlReducer';

import { connect } from 'react-redux';

import SettingsDrawer from './SettingsDrawer';
import { logout, impersonationLogin, getUser } from 'js/reducers/UserReducer';
import { impersonateUser, changePassword } from 'js/reducers/CredentialReducer';
import { startSync } from 'js/reducers/SyncReducer';
import { getOwnerFarm } from 'js/helpers/FarmUtils';
import AppSettingsDialog from '../AppSettings/AppSettingsDialog';
import { KEYS, setKeyValue } from '../../reducers/AppSettingsReducer';

@connect((store) => {
  return {
    user: store.user.user,
    farm: store.farm.farm,
    farms: store.farm.farms,
    otherFarms: store.farm.otherFarms,
    showUserMenu: store.control.showUserMenu,
    permissions: store.permissions,
    language: store.language.language,
    accumulation: store.weather.accumulation,
    appSettings: store.appSettings,
  };
})
export default class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAppSettingsDialog: false,
    };
  }

  logOut = () => {
    this.props.dispatch(logout());
  };

  handleUserClose = () => {
    this.props.dispatch(setShowUserMenu(false));
  };

  handleUserTap = (event) => {
    event.preventDefault();
    this.props.dispatch(setShowUserMenu(true));
  };

  onImpersonate = (username, password, userId) => {
    this.props.dispatch(
      impersonationLogin(username, password, () => {
        this.props
          .dispatch(impersonateUser(userId))
          .then((impersonateResponse) => {
            let token = 'Bearer ' + impersonateResponse.value.accessToken;

            this.props.dispatch(getUser(token)).then(() => {
              this.props.dispatch(fetchFarms(userId)).then((farmResult) => {
                let farm = getOwnerFarm(farmResult.action.payload);

                this.props
                  .dispatch(authenticateFarm(farm))
                  .then(() => {
                    this.onImpersonationCompleted(userId, farm);
                  })
                  .catch(() => {
                    console.error('Impersonation failed');
                  });
              });
            });
          })
          .catch(() => this.props.dispatch(logout()));
      })
    );
  };

  onImpersonationCompleted = (userId, farm) => {
    this.props.dispatch(setShowUserMenu(false));
    this.props.dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    this.props.dispatch(selectField(null));
    this.props.dispatch(
      startSync(
        userId,
        farm.farmId,
        this.props.language,
        true,
        true,
        this.props.accumulation,
        this.props.appSettings.measures
      )
    );
  };

  updatePassword = (newPass, callback) => {
    this.props.dispatch(changePassword(this.props.user.id, newPass, callback));
  };

  displayFarmSettings = () => {
    this.props.dispatch(setShowFarmSettings(true));
  };

  onEditAppSettings = () => {
    this.setState({ showAppSettingsDialog: true });
  };

  onTemperatureUnitChanged = (value) => {
    this.props.dispatch(setKeyValue(KEYS.MEASURE_UNIT_TEMPERATURE, value));
  };

  onWindUnitChanged = (value) => {
    this.props.dispatch(setKeyValue(KEYS.MEASURE_UNIT_WIND, value));
  };

  onRainUnitChanged = (value) => {
    this.props.dispatch(setKeyValue(KEYS.MEASURE_UNIT_RAIN, value));
  };

  render() {
    let appSettings = this.props.appSettings;
    let temperatureUnit = appSettings.measures[KEYS.MEASURE_UNIT_TEMPERATURE];
    let windUnit = appSettings.measures[KEYS.MEASURE_UNIT_WIND];
    let rainUnit = appSettings.measures[KEYS.MEASURE_UNIT_RAIN];

    return (
      <Fragment>
        <SettingsDrawer
          LangFile={this.props.LangFile}
          permissions={this.props.permissions}
          logOut={this.logOut}
          handleUserClose={this.handleUserClose}
          handleUserTap={this.handleUserTap}
          user={this.props.user}
          farm={this.props.farm}
          otherFarms={this.props.otherFarms}
          changeFarm={this.props.changeFarm}
          open={this.props.showUserMenu}
          updatePassword={this.updatePassword}
          onDisplayFarmSettings={this.displayFarmSettings}
          onEditAppSettings={this.onEditAppSettings}
          onImpersonate={this.onImpersonate}
        ></SettingsDrawer>

        <AppSettingsDialog
          onDismiss={() => this.setState({ showAppSettingsDialog: false })}
          onTemperatureUnitChanged={this.onTemperatureUnitChanged}
          onWindUnitChanged={this.onWindUnitChanged}
          onRainUnitChanged={this.onRainUnitChanged}
          open={this.state.showAppSettingsDialog}
          temperatureUnit={temperatureUnit}
          rainUnit={rainUnit}
          windUnit={windUnit}
        />
      </Fragment>
    );
  }
}
