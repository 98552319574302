import React, { Fragment, memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Canvas from './Canvas';
import { drawImageURL } from './MapCanvas';

import Styles from './MapCanvasMiniature.module.less';
import clsx from 'clsx';

const MapCanvasMiniature = (props: MapCanvasMiniature.propTypes) => {
  const context = useRef();
  const canvas = useRef();
  const [dimensions, setDimensions] = useState();
  const [loading, setLoading] = useState(true);

  const draw = useCallback(
    (ctx: CanvasRenderingContext2D, cvs: HTMLCanvasElement) => {
      setLoading(true);
      context.current = ctx;
      canvas.current = cvs;

      drawImageURL(ctx, cvs, props.imageURL, props.maxWidth, props.maxHeight).then((d) => {
        setDimensions(d);
        setLoading(false);
      });
    },
    [props.maxWidth, props.maxHeight, props.imageURL]
  );

  const drawTarget = useCallback(
    (ctx: CanvasRenderingContext2D, cvs: HTMLCanvasElement) => {
      if (dimensions && props.showTarget) {
        let { width, height, ratio } = dimensions;

        cvs.width = width;
        cvs.height = height;

        ctx.clearRect(0, 0, width, height);

        if (props.target) {
          let { x, y } = props.target;
          ctx.fillRect(x * ratio - 2, y * ratio - 2, 5, 5);
        }
      }
    },
    [props.target, dimensions]
  );

  return (
    <Fragment>
      <Box
        position={'relative'}
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        className={clsx({ [Styles.Error]: props.error, [Styles.Loading]: loading })}>
        <Canvas drawDependencies={[props.imageURL, props.imageURL]} onDraw={draw} />

        {dimensions && props.showTarget && (
          <Box
            position={'absolute'}
            maxHeight={dimensions.height}
            maxWidth={dimensions.width}
            top={0}
            left={0}
            right={0}
            bottom={0}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Canvas drawDependencies={[props.target]} onDraw={drawTarget} />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

MapCanvasMiniature.propTypes = {
  imageURL: PropTypes.string,
  target: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  error: PropTypes.bool,
  showTarget: PropTypes.bool,
};

export default memo(MapCanvasMiniature);
