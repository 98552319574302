import { memo, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { getDataIntegrations } from '../../reducers/DataIntegrationsReducer';
import { useIsLoggedIn } from '../../context/AccountContext';

const mapStateToProps = (store) => {
  return {
    farm: store.farm.farm,
  };
};

const DataIntegrationsContainer = ({ dispatch, farm }) => {
  const loggedIn = useIsLoggedIn();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getDataIntegrations(farm.farmId));
    }
  }, [loggedIn, farm]);

  return null;
};

export default memo(connect(mapStateToProps)(DataIntegrationsContainer));
