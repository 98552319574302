/*
    Document   : wms.js
    Created on : Feb 16, 2011, 3:25:27 PM
    Author     : "Gavin Jackson <Gavin.Jackson@csiro.au>"

    Refactored code from http://lyceum.massgis.state.ma.us/wiki/doku.php?id=googlemapsv3:home
*/

import NumberUtils from 'js/helpers/NumberUtils';

const TILE_SIZE = 256;

function degreesToRadians(deg) {
  return deg * (Math.PI / 180);
}

function radiansToDegrees(rad) {
  return rad / (Math.PI / 180);
}

export function MercatorProjection() {
  this.pixelOrigin_ = new google.maps.Point(TILE_SIZE / 2, TILE_SIZE / 2);
  this.pixelsPerLonDegree_ = TILE_SIZE / 360;
  this.pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
}

MercatorProjection.prototype.latLngToTileCoordinates = function (latLng, zoom) {
  let scale = 1 << zoom;

  let worldCoordinate = this.fromLatLngToPoint(latLng);

  return new google.maps.Point(
    Math.floor((worldCoordinate.x * scale) / TILE_SIZE),
    Math.floor((worldCoordinate.y * scale) / TILE_SIZE)
  );
};

MercatorProjection.prototype.fromLatLngToPoint = function (latLng, optPoint) {
  const point = optPoint || new google.maps.Point(0, 0);

  const origin = this.pixelOrigin_;
  point.x = origin.x + latLng.lng() * this.pixelsPerLonDegree_;
  // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
  // 89.189.  This is about a third of a tile past the edge of the world tile.
  const siny = NumberUtils.limit(Math.sin(degreesToRadians(latLng.lat())), -0.9999, 0.9999);
  point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -this.pixelsPerLonRadian_;
  return point;
};

MercatorProjection.prototype.fromDivPixelToLatLng = function (pixel, zoom) {
  const origin = this.pixelOrigin_;
  const scale = Math.pow(2, zoom);
  const lng = (pixel.x / scale - origin.x) / this.pixelsPerLonDegree_;
  const latRadians = (pixel.y / scale - origin.y) / -this.pixelsPerLonRadian_;
  const lat = radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
  return new google.maps.LatLng(lat, lng);
};

MercatorProjection.prototype.fromDivPixelToSphericalMercator = function (pixel, zoom) {
  const latLng = this.fromDivPixelToLatLng(pixel, zoom);
  return this.fromlatLngToSphericalMercator(latLng);
};

MercatorProjection.prototype.fromlatLngToSphericalMercator = function (latLng) {
  const r = 6378137.0;
  const x = r * degreesToRadians(latLng.lng());
  const latRad = degreesToRadians(latLng.lat());
  const y = (r / 2) * Math.log((1 + Math.sin(latRad)) / (1 - Math.sin(latRad)));

  return new google.maps.Point(x, y);
};
