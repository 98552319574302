import React, { memo, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { useSeasonContext } from '../../context/SeasonContext';
import Season from '../../model/Season';
import { formatSeason } from '../../helpers/SeasonUtils';
import { useLangFile } from '../../context/LanguageContext';
import { useHookRef } from '../../hooks/useHookRef';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import WebAPIUtils, { delayedPromise } from '../../WebAPIUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import FieldSeason from '../../model/FieldSeason';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import Styles from './SelectSeasonFormControl.module.less';

const SelectSeasonFormControl = (props: SelectSeasonFormControl.propTypes) => {
  const LangFile = useLangFile();
  const { seasons } = useSeasonContext();
  const seasonsRef = useHookRef(seasons);
  const [value, setValue] = useState(props.value || -1);
  const [anchorEl, setAnchorEl] = useState(null);
  const callback = useHookRef(props.onChange);
  const [fieldSeasons, setFieldSeasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue((current) => {
      if (props.value && props.value !== current) {
        return props.value;
      }
      return current;
    });
  }, [props.value]);

  useEffect(() => {
    if (anchorEl && props.fieldId) {
      setLoading(true);
      delayedPromise(WebAPIUtils.getFieldSeasons(props.fieldId, seasonsRef.current), 400)
        .then((result) => {
          flushSync(() => {
            setFieldSeasons(result);
          });

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    }
  }, [anchorEl, props.fieldId]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value) => {
    // force synchronous update of the DOM before changing the data model.
    // this avoids errors with transitions in MUI.
    flushSync(() => {
      setAnchorEl(null);
    });

    callback.current(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setError(false);
    setLoading(false);
  };

  let validSeasons = seasons;

  if (anchorEl) {
    let connectedSeasons = fieldSeasons.map((fs: FieldSeason) => fs.seasonId);
    validSeasons = validSeasons.filter((season: Season) => connectedSeasons.includes(season.id));
  }

  let enabledSeasons = validSeasons
    .filter((season: Season) => season.enabled)
    .sort((a, b) => formatSeason(b, LangFile).localeCompare(formatSeason(a, LangFile)));

  if (props.customSeasons) {
    enabledSeasons = enabledSeasons.filter((s) => props.customSeasons.includes(s.id));
  }

  const options = [
    { value: -1, label: LangFile.SelectSeasonFormControl.setSeason },
    ...enabledSeasons.map((season) => ({
      value: season.id,
      label: formatSeason(season, LangFile),
    })),
  ];

  let option = options.find((o) => o.value === value);

  if (!option) {
    option = options[0];
  }

  return (
    <>
      <List component="nav" aria-label={LangFile.SelectSeasonFormControl.season} dense={true}>
        <Paper variant={'outlined'}>
          <ListItem
            button={true}
            dense={true}
            aria-haspopup="true"
            aria-controls="season-menu"
            aria-label={LangFile.SelectSeasonFormControl.season}
            onClick={handleClickListItem}>
            <ListItemText
              primary={option.label}
              primaryTypographyProps={{ color: value === -1 ? 'secondary' : 'initial' }}
            />
          </ListItem>
        </Paper>
      </List>

      <Menu
        id="season-menu"
        classes={{
          paper: Styles.Paper,
        }}
        transitionDuration={0}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={48}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {loading && (
          <MenuItem button={false} disabled={true} dense={true} key={'loading'}>
            <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress size={'1rem'} />
            </Box>
          </MenuItem>
        )}

        {!loading && error && (
          <MenuItem button={false} disabled={true} dense={true} key={'loading'}>
            <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Typography color={'secondary'} variant={'caption'} className={Styles.Text}>
                {LangFile.SelectSeasonFormControl.errorMessage}
              </Typography>
            </Box>
          </MenuItem>
        )}

        {!loading &&
          !error &&
          options.map((option, idx) => (
            <MenuItem
              key={option.value}
              dense={true}
              disableRipple={true}
              button={true}
              disabled={idx === 0}
              selected={option.value === value}
              onClick={(event) => handleMenuItemClick(event, option.value)}>
              <ListItemText className={Styles.Text}>{option.label}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

SelectSeasonFormControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  fieldId: PropTypes.number,
  customSeasons: PropTypes.array,
};

export default memo(SelectSeasonFormControl);
