import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import { DatePicker } from '@material-ui/pickers';
import { useLangFile } from 'js/context/LanguageContext';
import IconButton from '@material-ui/core/IconButton';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { voidFunc } from 'js/constants/PropTypeUtils';
import { blueGrey, grey } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    // '& button': {
    //   color: theme.palette.common.white,
    // },
    justifyContent: 'center',
  },
  picker: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  pickerInput: {
    textAlign: 'center',
    cursor: 'pointer',
    // color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    '& fieldset': {
      border: 'none',
    },
    '& legend': {
      display: 'none',
    },
    '& input': {
      textAlign: 'center',
      cursor: 'pointer',
      padding: [[0, 0]],
      fontSize: '0.9rem',
    },
    '&.small': {
      minWidth: 100,
    },
  },
  buttonWrapper: {
    width: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: theme.spacing(1) / 2,
  },
});

const DateHandler = ({ classes, date, dates, handleDateSet }) => {
  const LangFile = useLangFile();

  const [value, setValue] = useState(date);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  useEffect(() => {
    if (dates) {
      let currentIndex = dates.indexOf(date);
      let hasNext = Boolean(dates[currentIndex + 1]);
      let hasPrevious = Boolean(dates[currentIndex - 1]);
      setHasNext(hasNext);
      setHasPrevious(hasPrevious);
    }
  }, [date, dates]);

  const leftArrowPress = useCallback(() => {
    let newIndex = dates.indexOf(date) - 1;
    let newDate = dates[newIndex];

    if (newDate) {
      handleDateSet(newDate, false);
    }
  }, [date, dates, handleDateSet]);

  const rightArrowPress = useCallback(() => {
    let newIndex = dates.indexOf(date) + 1;
    let newDate = dates[newIndex];

    if (newDate) {
      handleDateSet(newDate, true);
    }
  }, [date, dates, handleDateSet]);

  useEffect(() => {
    if (dates) {
      setMin(moment(dates[0]).toDate());
      setMax(moment(dates[dates.length - 1]).toDate());
    }
  }, [dates]);

  useEffect(() => {
    if (date) {
      setValue(moment(date).toDate());
    }
  }, [date]);

  const disableDate = useCallback(
    (date) => {
      return dates.indexOf(moment(date).format('YYYY-MM-DD')) === -1;
    },
    [dates]
  );

  const handleSetCalendarOpen = (val) => () => {
    setCalendarOpen(val);
  };

  let small = window.innerWidth < 768;
  let format = 'DD-MM-YYYY';

  if (!date || !dates || (Array.isArray(dates) && dates.length === 0)) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Tooltip title={!hasPrevious ? LangFile.DateHandler.noPrevious : LangFile.DateHandler.change}>
        <Box mr={1} className={classes.buttonWrapper}>
          <IconButton disabled={!hasPrevious} onClick={leftArrowPress} className={classes.button}>
            <ChevronLeft htmlColor={hasPrevious ? blueGrey['900'] : grey['200']} />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title={LangFile.DateHandler.pickDate}>
        <div>
          <DatePicker
            className={classes.picker}
            InputLabelProps={{ shrink: small }}
            open={calendarOpen}
            onOpen={handleSetCalendarOpen(true)}
            onClose={handleSetCalendarOpen(false)}
            PopoverProps={{
              PaperProps: {
                style: {
                  maxHeight: 600,
                },
              },
            }}
            InputProps={{
              onClick: handleSetCalendarOpen(true),
              classes: {
                root: classes.pickerInput,
              },
            }}
            readOnly={true}
            inputProps={{ readOnly: true, 'data-lpignore': true, autoComplete: 'off' }}
            autoOk={true}
            disableFuture={true}
            variant="inline"
            inputVariant="outlined"
            disabled={dates.length <= 0}
            initialFocusedDate={value}
            value={value}
            onChange={(date) => handleDateSet(date)}
            shouldDisableDate={disableDate}
            minDate={min}
            maxDate={max}
            format={format}
          />
        </div>
      </Tooltip>
      <Tooltip title={!hasNext ? LangFile.DateHandler.noNext : LangFile.DateHandler.change}>
        <Box ml={1} className={classes.buttonWrapper}>
          <IconButton disabled={!hasNext} onClick={rightArrowPress} className={classes.button}>
            <ChevronRight htmlColor={hasNext ? blueGrey['900'] : grey['200']} />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

DateHandler.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dates: PropTypes.array,
  handleDateSet: PropTypes.func,
};

DateHandler.defaultProps = {
  handleDateSet: voidFunc,
  dates: [],
};

export default withStyles(styles)(DateHandler);
