'use strict';

/* ============== //
 ||     TYPES     ||
 // ============== */

import { getLayersFromService } from 'js/helpers/WMSLoader';
import { DATAFORDELER_SERVICE_URL } from '../helpers/HeightMapUtils';

const FETCH_OVERLAYS = 'fieldsense/Overlay/FETCH_OVERLAYS';
const SET_SHOW_HEIGHT_MAP = 'fieldsense/Overlay/SET_SHOW_HEIGHT_MAP';
/* ============== //
 ||    ACTIONS    ||
 // ============== */

export function fetchOverlays() {
  return {
    type: FETCH_OVERLAYS,
    payload: () => getOverlays(),
  };
}

export function setShowHeightMap(show) {
  return {
    type: SET_SHOW_HEIGHT_MAP,
    payload: show,
  };
}

/* ============== //
 ||    HELPERS    ||
 // ============== */

async function getOverlays() {
  return new Promise((resolve, reject) => {
    getLayersFromService(DATAFORDELER_SERVICE_URL).then(
      (results) => {
        let overlays = results.map((layer, index, arr) =>
          mapServiceLayerToOverlay(layer, index, arr.length)
        );
        resolve(overlays);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function mapServiceLayerToOverlay(layer, index, layerCount) {
  let overlay = { ...layer };
  overlay.order = layerCount - index;
  overlay.key = index;
  overlay.visible = false;
  overlay.opacity = 1;
  return overlay;
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  overlays: [],
  showHeightMap: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case FETCH_OVERLAYS + '_FULFILLED': {
      state = { ...state, overlays: [...action.payload] };
      break;
    }

    case SET_SHOW_HEIGHT_MAP: {
      state = { ...state, showHeightMap: action.payload };
      break;
    }

    default:
      break;
  }

  return state;
}
