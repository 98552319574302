import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  Box,
  Container,
} from '@material-ui/core';

import { useLangFile } from 'js/context/LanguageContext';

const SuspendStationDialog = ({ shown, onClose, onYes, onNo }) => {
  const LangFile = useLangFile();

  return (
    <Dialog open={shown} maxWidth={'md'} fullWidth onClose={onClose}>
      <DialogTitle>
        <Box display={'flex'} flexDirection={'column'} textAlign={'center'} pt={4}>
          <Box color={'secondary.main'} fontWeight={500} fontSize={27}>
            {LangFile.SuspendStationDialog.title1}
          </Box>
          <Box fontWeight={500}>{LangFile.SuspendStationDialog.title2}</Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box display={'flex'} flexDirection={'column'}>
          <Box px={2} pb={3} textAlign={'center'} display={'flex'} flexDirection={'column'}>
            <Box>{LangFile.SuspendStationDialog.description1}</Box>
            <Box>{LangFile.SuspendStationDialog.description2}</Box>
            <Box>{LangFile.SuspendStationDialog.description3}</Box>
          </Box>

          <Container maxWidth={'sm'}>
            <Box my={1}>
              <Button
                variant={'contained'}
                size={'large'}
                fullWidth
                color={'primary'}
                onClick={onNo}
              >
                <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                  <Box fontWeight={600} fontSize={20}>
                    {LangFile.SuspendStationDialog.no}
                  </Box>
                  <Box fontWeight={400}>{LangFile.SuspendStationDialog.noDescription}</Box>
                </Grid>
              </Button>
            </Box>

            <Box my={1}>
              <Button
                variant={'outlined'}
                size={'large'}
                fullWidth
                color={'secondary'}
                onClick={onYes}
              >
                <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                  <Box fontWeight={600} fontSize={20}>
                    {LangFile.SuspendStationDialog.yes}
                  </Box>
                  <Box fontWeight={400}>{LangFile.SuspendStationDialog.yesDescription}</Box>
                </Grid>
              </Button>
            </Box>
          </Container>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button variant={'outlined'} onClick={onClose}>
            {LangFile.SuspendStationDialog.back}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

SuspendStationDialog.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};

export default SuspendStationDialog;
