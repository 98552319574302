// @flow

import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useGoogleMap, useGoogleMapDOM } from 'js/context/GoogleMapContext';
import useShiftKeyDown from 'js/hooks/useShiftKeyDown';
import useMapEvent from 'js/components/DataLayer/hooks/useMapEvent';
import { useHookRef } from 'js/hooks/useHookRef';
import useIsMouseDown from 'js/hooks/useIsMouseDown';
import Portal from '@material-ui/core/Portal';
import Box from '@material-ui/core/Box';
import useEvent from 'js/hooks/useEvent';
import { blue } from '@material-ui/core/colors';
import { voidFunc } from 'js/constants/PropTypeUtils';
import { Outline } from 'js/model/Outline';
import { polygon, bboxPolygon, booleanPointInPolygon, centroid } from '@turf/turf';
import { getRect } from 'js/helpers/MapsUtils';

const MapMarqueeSelect = (props: MapMarqueeSelect.propTypes) => {
  const googleMap = useGoogleMap();
  const googleMapDOM = useGoogleMapDOM();
  const shiftDown = useShiftKeyDown();
  const mouseDown = useHookRef(useIsMouseDown());

  const [points, setPoints] = useState([]);
  const parentRect = googleMapDOM && googleMapDOM.getBoundingClientRect();
  const rect = getRect(googleMap, googleMapDOM, points);

  const selectables = useRef([]);

  useEffect(() => {
    if (props.outlines) {
      selectables.current = props.outlines.map((outline: Outline) => {
        let coords = [[...outline.coordinates.map((c) => [c.lng, c.lat])]];
        let poly = polygon(coords);
        let point = centroid(poly);

        return {
          outline,
          poly,
          point,
        };
      });
    } else {
      selectables.current = [];
    }
  }, [props.outlines]);

  useEffect(() => {
    googleMap.setOptions({ draggable: !shiftDown });
    googleMapDOM.style.cursor = shiftDown ? 'crosshair' : 'grab';
  }, [shiftDown, googleMap]);

  useMapEvent(
    googleMap,
    'mousemove',
    useCallback(
      ({ latLng }) => {
        if (shiftDown && mouseDown.current) {
          // Only keep track of the first and last point during the drag motion
          setPoints((curr) => {
            if (curr.length === 0) {
              return [latLng];
            } else {
              return [curr[0], latLng];
            }
          });
        }
      },
      [shiftDown]
    )
  );

  const evaluatePoints = useCallback(
    (callback) => {
      if (points.length === 2) {
        let first = points[0];
        let last = points[1];

        let minX = Math.min(first.lng(), last.lng());
        let minY = Math.min(first.lat(), last.lat());
        let maxX = Math.max(first.lng(), last.lng());
        let maxY = Math.max(first.lat(), last.lat());

        let selectionPoly = bboxPolygon([minX, minY, maxX, maxY], { name: 'selection' });
        let selected = selectables.current
          .filter(({ point }) => booleanPointInPolygon(point, selectionPoly))
          .map(({ outline }) => outline);

        callback(selected);
      } else {
        if (typeof props.onHighlight === 'function') {
          props.onHighlight([]);
        }
      }
    },
    [points, props.outlines, props.onHighlight]
  );

  useEffect(() => {
    if (typeof props.onHighlight === 'function') {
      evaluatePoints(props.onHighlight);
    }
  }, [points, props.onHighlight]);

  useEvent(
    'mouseup',
    useCallback(
      (e) => {
        evaluatePoints(props.onSelect);
        // Reset the selection
        setPoints([]);
      },
      [points, props.onSelect, props.onHighlight]
    )
  );

  let boxProps = rect &&
    parentRect && {
      left: rect.topLeft.x + parentRect.x,
      top: rect.topLeft.y + parentRect.y,
      width: `${rect.bottomRight.x - rect.topLeft.x}px`,
      height: `${rect.bottomRight.y - rect.topLeft.y}px`,
    };

  return (
    <Fragment>
      {boxProps && (
        <Portal>
          <Box
            {...boxProps}
            border={`solid 1px ${blue['A700']}`}
            position={'absolute'}
            bgcolor={blue['100']}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{ opacity: 0.5, pointerEvents: 'none' }}
          ></Box>
        </Portal>
      )}
    </Fragment>
  );
};

MapMarqueeSelect.propTypes = {
  outlines: PropTypes.array,
  onSelect: PropTypes.func,
  onHighlight: PropTypes.func,
};

MapMarqueeSelect.defaultProps = {
  outlines: [],
  onSelect: voidFunc,
};

export default memo(MapMarqueeSelect);
