'use strict';

import React, { Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import MapView from './MapView';
import { getImagesForDate } from 'js/helpers/StateInterpreters';
import { selectOutline } from 'js/reducers/AddFieldReducer';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import FieldSettingsDialog from 'js/components//FieldInfo/FieldSettingsDialog';
import { drawMagicSelection } from 'js/reducers/NoteReducer';
import DialogConfirmDeleteField from 'js/components/FieldInfo/DialogConfirmDeleteField';

import { setViewMode } from 'js/reducers/ControlReducer';

import {
  deleteField,
  updateFieldName,
  selectField,
  createUpdateFieldSeason,
  connectFieldToWeatherStation,
  changeImageType,
  setShowSatelliteImagery,
} from 'js/reducers/FieldReducer';
import { useFarm, useUser } from 'js/context/AccountContext';
import EditCropDialog from 'js/components/FieldInfo/EditCropDialog';
import { selectMultipleOutlines } from '../../reducers/AddFieldReducer';
import { getClickedFields } from '../../helpers/MapsUtils';
import DuplicateFieldsDialog from './DuplicateFieldsDialog';
import { saveSelectedLayer, setShowSoilSurveys } from '../../reducers/SurveyReducer';
import { removeFieldFromSeason } from '../../reducers/SeasonReducer';
import { useSelectedSeason } from '../../context/SeasonContext';
import DialogConfirmRemoveFieldFromSeason from '../FieldInfo/DialogConfirmRemoveFieldFromSeason';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import { useLangFile } from '../../context/LanguageContext';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';

const EXPECTED_ERROR_SOILOPTIX_CONNECTED = 'Soiloptix connected';

const mapStoreToProps = (store) => ({
  fields: store.field.fields,
  showSatelliteImagery: store.field.showSatelliteImagery,
  date: store.field.date,
  images: store.field.images,
  imageType: store.field.imageType,
  selectedField: store.field.selectedField,
  outlines: store.addField.outlines,
  selectedOutlines: store.addField.selectedOutlines,
  viewMode: store.control.viewMode,
  goToFieldsEvent: store.control.goToFieldsEvent,
  weatherStations: store.weather.stations,
  fieldsLoaded: store.field.fieldsLoaded,
  networkSensor: store.weatherNetwork.networkSensor,
  showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
  showWeatherStations: store.weather.showWeatherStations,
  showSoilSurveys: store.survey.showSoilSurveys,
  variationsEnabled: store.survey.variationsEnabled,
  classificationsEnabled: store.survey.classificationsEnabled,
  surveys: store.survey.surveys,
  selectedLayer: store.survey.selectedLayer,
  isDrawingField: store.addField.isDrawing,
  isAddingFields: store.addField.isAddingFields,
  isImportingFields: store.addField.isImportingFields,
  isMagicSelecting: store.note.magicWand.isSelecting,
  overlays: store.overlay.overlays,
  showHeightMap: store.overlay.showHeightMap,
});

const MapViewContainer = (props) => {
  const {
    dispatch,
    viewMode,
    isMagicSelecting,
    images,
    imageType,
    selectedField,
    date,
    weatherStations,
  } = props;
  const { fields, outlines, fieldsLoaded, showWeatherStations, showWeatherNetwork } = props;
  const { networkSensor, selectedOutlines, showSatelliteImagery, goToFieldsEvent } = props;
  const { isDrawingField, isAddingFields, isImportingFields } = props;
  const {
    showSoilSurveys,
    surveys,
    selectedLayer,
    variationsEnabled,
    classificationsEnabled,
    overlays,
    showHeightMap,
  } = props;

  const LangFile = useLangFile();
  const { addAction } = useActionSnackbarContext();

  const [deletingField, setDeletingField] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [removingField, setRemovingField] = useState(null);
  const [confirmRemove, setConfirmRemove] = useState(false);

  const [showSettings, setShowSettings] = useState(false);
  const [editCrop, setEditCrop] = useState(false);
  const [duplicateFields, setDuplicateFields] = useState(null);

  const analytics = useFirebaseAnalytics();
  const user = useUser();
  const farm = useFarm();
  const selectedSeason = useSelectedSeason();

  const gaUserId = user && 'User: ' + user.id;
  const currentFieldImages = getImagesForDate(date, images, imageType);
  const openVRMModule = useCallback(() => dispatch(setViewMode(ViewModeConstants.PRESCRIPTION)));
  const onClickAway = useCallback(
    () => !showSettings && dispatch(selectField(null)),
    [showSettings]
  );
  const handleOnSettingsClicked = useCallback(() => setShowSettings(true));
  const handleSelectOutline = useCallback((outline) => dispatch(selectOutline(outline)));
  const handleSelectMultipleOutlines = useCallback((outlines) =>
    dispatch(selectMultipleOutlines(outlines))
  );

  const handleOnDelete = useCallback(
    (field) => {
      setShowSettings(false);
      setDeletingField(field);
      setConfirmDelete(true);
    },
    [gaUserId]
  );

  const handleOnRemoveFromSeason = useCallback((field) => {
    setShowSettings(false);
    setRemovingField(field);
    setConfirmRemove(true);
  }, []);

  const handleOnConfirmRemoveFromSeason = useCallback(() => {
    dispatch(removeFieldFromSeason(removingField.fieldId, selectedSeason.id)).then(() => {
      dispatch(selectField(null));
      dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    });

    handleCloseConfirmRemoveField();
  }, [selectedSeason, removingField]);

  const handleOnConfirmDelete = useCallback(() => {
    dispatch(deleteField(deletingField))
      .then(() => {
        addAction(
          new Action(
            'field-deleted-successfully' + deletingField.fieldId,
            LangFile.MapViewContainer.fieldDeletedSuccessfully,
            'success',
            'success',
            'filled'
          )
        );

        dispatch(selectField(null));
        dispatch(setViewMode(ViewModeConstants.OVERVIEW));

        handleCloseConfirmDeleteField();
      })
      .catch((e) => {
        let response = e.response;
        if (response) {
          let msg = response.data;
          let statusCode = response.status;

          if (statusCode === 409) {
            if (msg === EXPECTED_ERROR_SOILOPTIX_CONNECTED) {
              addAction(
                new Action(
                  'soiloptix-connected-' + deletingField.fieldId,
                  LangFile.MapViewContainer.errorSoilOptixConnected,
                  'error',
                  'filled'
                )
              );
            }
          } else {
            addAction(
              new Action(
                'unexpected-error',
                LangFile.MapViewContainer.errorUnexpected.replace('%d', statusCode),
                'error',
                'filled'
              )
            );
          }
        }
      });
  }, [deletingField]);

  const handleCloseConfirmDeleteField = useCallback(() => {
    setConfirmDelete(false);
    setDeletingField(null);
  }, []);

  const handleCloseConfirmRemoveField = useCallback(() => {
    setConfirmRemove(false);
    setRemovingField(null);
  }, []);

  const handleCloseSettingsDialog = useCallback(() => {
    handleCloseConfirmDeleteField();
    handleCloseConfirmRemoveField();
    setShowSettings(false);
  }, [handleCloseConfirmDeleteField, handleCloseConfirmRemoveField]);

  const openAnalysisModule = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.ANALYSIS, 'analysis'));
    dispatch(saveSelectedLayer());
  }, []);

  const handleUpdateFieldName = useCallback((fieldName, fieldId) => {
    dispatch(updateFieldName(fieldName, fieldId));
  }, []);

  const onSelectField = useCallback(
    (field, event) => {
      if (isMagicSelecting) {
        dispatch(drawMagicSelection(field, event.latLng));
      } else {
        let fieldsClicked = getClickedFields(fields, event);

        if (fieldsClicked.length > 1) {
          setDuplicateFields(fieldsClicked);
        } else {
          dispatch(selectField(field));
        }
      }
    },
    [isMagicSelecting, fields]
  );

  const onEditCrop = useCallback(() => {
    setEditCrop(true);
  }, []);

  const onCancelEditCrop = useCallback(() => {
    setEditCrop(false);
  }, []);

  const onCropSaved = useCallback(({ fieldId, seasonId, crop, sowing, cropVariation }) => {
    // important to return for the component to chain on the Promise
    const _sowing = crop !== 'NONE' ? sowing : null;
    const _cropVariation = crop !== 'NONE' ? cropVariation : null;
    return dispatch(createUpdateFieldSeason(fieldId, seasonId, crop, _sowing, _cropVariation));
  }, []);

  const onChangeWeatherStation = useCallback(
    (deviceId) => {
      // important to return for the component to chain on the Promise
      return dispatch(connectFieldToWeatherStation(selectedField.fieldId, deviceId));
    },
    [selectedField]
  );

  const onCloseDuplicateFieldDialog = useCallback(() => {
    setDuplicateFields(null);
  }, []);

  const onBottomOpen = useCallback(
    (open) => {
      if (open && !showSatelliteImagery) {
        const type = Object.keys(SATELLITE_LAYERS)[0];
        dispatch(changeImageType(type));
        dispatch(setShowSatelliteImagery(true));
        dispatch(setShowSoilSurveys(false));
      }
    },
    [showSatelliteImagery]
  );

  const onSelectDuplicateField = (field) => {
    dispatch(selectField(field));
    onCloseDuplicateFieldDialog();
  };

  const goToPrescriptionArchive = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.FD_ARCHIVE);
    dispatch(setViewMode(ViewModeConstants.PRESCRIPTION_ARCHIVE));
  }, []);

  const goToFieldStatistics = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.FIELD_STATISTICS));
  }, []);

  const goToMachineData = useCallback(() => {
    const link = 'https://cropline.exatrek.de./login';
    window.open(link, '_blank');
  }, []);

  let disableFieldInteraction = isDrawingField || isAddingFields || isImportingFields;

  return (
    <Fragment>
      <MapView
        date={date}
        outlines={outlines}
        viewMode={viewMode}
        imageType={imageType}
        showSatelliteImagery={showSatelliteImagery}
        disableFieldInteraction={disableFieldInteraction}
        onClickAway={onClickAway}
        fieldsLoaded={fieldsLoaded}
        networkSensor={networkSensor}
        openVRMModule={openVRMModule}
        onSelectField={onSelectField}
        openNotesModule={openAnalysisModule}
        isMagicSelecting={isMagicSelecting}
        isAddingsFields={isAddingFields}
        isImportingFields={isImportingFields}
        selectedOutlines={selectedOutlines}
        onSelectOutline={handleSelectOutline}
        onSelectMultipleOutlines={handleSelectMultipleOutlines}
        openAnalysisModule={openAnalysisModule}
        showWeatherNetwork={showWeatherNetwork}
        currentFieldImages={currentFieldImages}
        showWeatherStations={showWeatherStations}
        weatherStations={weatherStations}
        goToFieldsEvent={goToFieldsEvent}
        onSettingsClicked={handleOnSettingsClicked}
        showSoilSurveys={showSoilSurveys}
        variationsEnabled={variationsEnabled}
        classificationsEnabled={classificationsEnabled}
        surveys={surveys}
        selectedLayer={selectedLayer}
        onBottomOpen={onBottomOpen}
        onPrescriptionArchiveClicked={goToPrescriptionArchive}
        onFieldStatisticsClicked={goToFieldStatistics}
        showHeightMap={showHeightMap}
        overlays={overlays}
        onMachineDataClicked={goToMachineData}
      />

      {confirmDelete && (
        <DialogConfirmDeleteField
          open={confirmDelete}
          onConfirm={handleOnConfirmDelete}
          onClose={handleCloseConfirmDeleteField}
        />
      )}

      {confirmRemove && (
        <DialogConfirmRemoveFieldFromSeason
          open={confirmRemove}
          onConfirm={handleOnConfirmRemoveFromSeason}
          onClose={handleCloseConfirmRemoveField}
        />
      )}

      {showSettings && (
        <FieldSettingsDialog
          user={user}
          farm={farm}
          shown={showSettings}
          weatherStations={weatherStations}
          field={selectedField}
          onDelete={handleOnDelete}
          onRemoveFromSeason={handleOnRemoveFromSeason}
          onEditCrop={onEditCrop}
          onChangeWeatherStation={onChangeWeatherStation}
          onUpdateFieldName={handleUpdateFieldName}
          onClose={handleCloseSettingsDialog}
        />
      )}

      {editCrop && (
        <EditCropDialog
          shown={editCrop}
          field={selectedField}
          onSave={onCropSaved}
          onClose={onCancelEditCrop}
        />
      )}

      {duplicateFields && (
        <DuplicateFieldsDialog
          surveys={surveys}
          duplicateFields={duplicateFields}
          onClose={onCloseDuplicateFieldDialog}
          onFieldSelect={onSelectDuplicateField}
          onFieldSettings={(f) => {
            onSelectDuplicateField(f);
            handleOnSettingsClicked();
          }}
        />
      )}
    </Fragment>
  );
};

export default connect(mapStoreToProps)(MapViewContainer);
