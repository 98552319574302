import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function useScreenPosition(map, latLng) {
  const [position, setPosition] = useState(null);

  let overlay = useRef(new google.maps.OverlayView());

  useEffect(() => {
    overlay.current.draw = () => {
      let proj = overlay.current.getProjection();
      if (proj) {
        setPosition(proj.fromLatLngToContainerPixel(latLng));
      }
    };

    overlay.current.remove = () => {};
    overlay.current.setMap(map);

    return () => {
      overlay.current.setMap(null);
    };
  }, [latLng, map]);

  return position;
}

useScreenPosition.PropTypes = {
  map: PropTypes.object.isRequired,
  point: PropTypes.object,
};

export default useScreenPosition;
