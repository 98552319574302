'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_1-3" data-name="Layer 1">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <path d="M16,21.3H14.5a.74.74,0,0,0-.8.8v1.5a.74.74,0,0,0,.8.8H16a.74.74,0,0,0,.8-.8V22.1A.74.74,0,0,0,16,21.3Z" />
            <path d="M15.3,4.5a6.91,6.91,0,0,0-6.9,6.9,3.75,3.75,0,0,0,.1,1.1.76.76,0,0,0,1,.6l1.4-.5a.66.66,0,0,0,.5-.8v-.5a3.8,3.8,0,0,1,7.6,0c0,1.3-.8,1.9-2.1,3.1s-2.8,2.3-3.2,4.5c-.1.5.3.8.8.8H16a.76.76,0,0,0,.8-.6c.2-.9.9-1.5,2.1-2.4,1.4-1.1,3.3-2.6,3.3-5.4A6.83,6.83,0,0,0,15.3,4.5Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
