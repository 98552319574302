import React, { useEffect, useRef, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { TextField, Box } from '@material-ui/core';

import { grey } from '@material-ui/core/colors';

import { WEATHER_STATION_SERIAL } from 'js/constants/RegexConstants';

const styles = (theme) => ({
  root: {
    width: 'auto',
  },
  serialInput: {
    maxWidth: 80,
    textTransform: 'uppercase',
    '&input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    '&input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
  serialInputDisabled: {
    backgroundColor: grey[200],
    borderColor: grey[400],
  },
});

const INPUTS = [
  { type: 'text', accept: 'ACDFHLRUS' },
  { type: 'text', accept: 'ACDFHLRUS' },
  { type: 'tel', accept: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
  { type: 'tel', accept: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
  { type: 'text', accept: 'ACDFHLRUS' },
  { type: 'text', accept: 'ACDFHLRUS' },
  { type: 'tel', accept: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
  { type: 'tel', accept: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
];

const WeatherStationSerialInput = ({ classes, onEntry, initialValue }) => {
  const serialInputRefs = useRef({});
  const [serialInput, setSerialInput] = useState(initialValue.split(''));

  useEffect(() => {
    let serial = serialInput.join('');
    let validSerial = serial.match(WEATHER_STATION_SERIAL);

    if (validSerial) {
      onEntry(serial);
    }
  }, [serialInput]);

  const isIndexValid = (idx, char) => {
    if (char === '') {
      return false;
    }

    let input = INPUTS[idx];

    if (input.type === 'text') {
      return input.accept.includes(char);
    } else if (input.type === 'tel') {
      return input.accept.indexOf(Number(char)) !== -1;
    } else {
      return false;
    }
  };

  const setValueAt = (idx, val) => {
    let newValue = [...serialInput];
    newValue[idx] = val;
    setSerialInput(newValue);
  };

  return (
    <Box display={'flex'} flexDirection={'row'}>
      {INPUTS.map((inputType, idx) => {
        let val = idx < serialInput.length ? serialInput[idx] : '';

        return (
          <Box mr={idx < INPUTS.length - 1 ? 1 : 0} key={`serial-${idx}`}>
            <TextField
              inputRef={(r) => {
                if (r) {
                  serialInputRefs.current = { ...serialInputRefs.current, [idx]: r };
                }
              }}
              autoFocus={idx === 0}
              onFocus={() => {
                let input = serialInputRefs.current[idx];
                if (input) {
                  input.select();
                }
              }}
              margin={'normal'}
              variant={'outlined'}
              value={val}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                  setValueAt(idx, '');

                  let previous = serialInputRefs.current[idx - 1];
                  if (previous) {
                    previous.focus();
                  }
                }
              }}
              onKeyUp={(e) => {
                if (
                  e.key !== 'Backspace' &&
                  e.key !== 'Delete' &&
                  e.key !== 'Tab' &&
                  e.key !== 'Shift'
                ) {
                  if (isIndexValid(idx, serialInput[idx])) {
                    let next = serialInputRefs.current[idx + 1];
                    if (next) {
                      next.focus();
                    } else {
                      serialInputRefs.current[idx].blur();
                    }
                  }
                }
              }}
              onChange={(e) => {
                let val = e.target.value;
                let chars = val.trim();
                let char = chars.length > 0 ? chars.slice(0, 1).toUpperCase() : '';

                if (isIndexValid(idx, char)) {
                  setValueAt(idx, char);
                }
              }}
              inputProps={{
                tabIndex: idx,
              }}
              InputProps={{
                type: inputType.type,
                classes: {
                  root: classes.serialInput,
                  disabled: classes.serialInputDisabled,
                },
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

WeatherStationSerialInput.propTypes = {
  onEntry: PropTypes.func,
  initialValue: PropTypes.string,
};

WeatherStationSerialInput.defaultProps = {
  initialValue: '',
};

export default withStyles(styles)(WeatherStationSerialInput);
