// Helper function to login.
// This function simply selects the correct farm among all the farms returned for the user.
export function getOwnerFarm(farms) {
  let farm = farms[0];

  farms.forEach((f) => {
    if (f.role === 'owner') {
      farm = f;
    }
  });

  return farm;
}

export function getOtherFarms(farm, farms) {
  return [...farms].filter((f) => f.farmId !== farm.farmId);
}
