// @flow

import React, { memo, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import useIsMouseDown from '../../../hooks/useIsMouseDown';

const FloatingTempButton = (props: FloatingTempButton.propTypes) => {
  const rootRef = useRef();
  const hoverRef = useRef();

  const [shown, setShown] = useState(false);

  const isMouseDown = useIsMouseDown(240);

  useEffect(() => {
    let container = rootRef.current.closest('div');
    hoverRef.current = container;

    let mouseEnterListener = (e) => {
      setShown(true);
    };

    let mouseLeaveListener = (e) => {
      setShown(false);
    };

    container.addEventListener('mouseenter', mouseEnterListener);
    container.addEventListener('mouseleave', mouseLeaveListener);

    // Remove event listener on cleanup
    return () => {
      container.removeEventListener('mouseenter', mouseEnterListener);
      container.removeEventListener('mouseleave', mouseLeaveListener);
    };
  }, [rootRef.current]);

  let style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 3,
  };

  if (props.position === 'top') {
    style.top = '25%';
    style.transform += ' translateY(-50%)';
  } else if (props.position === 'bottom') {
    style.bottom = '25%';
    style.transform += ' translateY(50%)';
  } else {
    style.top = '50%';
    style.transform += ' translateY(-50%)';
  }

  return (
    <span style={style} ref={rootRef}>
      <Zoom in={shown && !isMouseDown}>
        <Tooltip title={props.tooltip} placement={props.tooltipPosition}>
          <Fab size="small" onClick={props.onClick}>
            <Box color={props.iconColor}>{props.icon}</Box>
          </Fab>
        </Tooltip>
      </Zoom>
    </span>
  );
};

FloatingTempButton.propTypes = {
  icon: PropTypes.any,
  iconColor: PropTypes.any,
  tooltip: PropTypes.any,
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['top', 'bottom']),
  tooltipPosition: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

export default memo(FloatingTempButton);
