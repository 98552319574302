// For now at least we do not care about rejections in the reducer. Rejections just means do nothing.
// However we handle the caught errors here in the middleware.
// Hence we can also here take specific actions for given errors.
export default function PromiseRejectionMiddleware() {
  return (next) => (action) => {
    if (!isPromise(action.payload)) {
      return next(action);
    }
    // Dispatch initial pending promise, but catch any errors
    return next(action).catch((error) => {
      console.warn(
        `Caught unhandled promise rejection at middleware with reason: ${JSON.stringify(error.message)}.`
      );
      return error;
    });
  };
}

function isPromise(value) {
  if (value !== null && typeof value === 'object') {
    return value && typeof value.then === 'function';
  }

  return false;
}
