// @flow

import React, { memo, useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import DateKeyboardHandler from 'js/components/DateHandler/DateKeyboardHandler';
import moment from 'moment';
import { setDate } from 'js/reducers/FieldReducer';

const mapStateToProps = (state) => {
  return {
    isDrawingField: state.addField.isDrawing,
    isDrawingNote: state.note.isDrawing,
    viewMode: state.control.viewMode,
    isPlacingMarker: state.note.isPlacingMarker,
    searchOpen: state.control.searchOpen,
    loggedIn: state.credential.loggedIn,
    date: state.field.date,
    dates: state.field.dates,
    selectedFieldDates: state.field.selectedFieldDates,
    keyboardShortcutsEnabled: state.control.keyboardShortcutsEnabled,
  };
};

const DateKeyboardHandlerContainer = ({
  dispatch,
  date,
  dates,
  selectedFieldDates,
  isPlacingMarker,
  isDrawingField,
  isDrawingNote,
  viewMode,
  searchOpen,
  loggedIn,
  keyboardShortcutsEnabled,
}) => {
  const [allowDateChange, setAllowDateChange] = useState(true);

  const handleDateSet = useCallback((date) => {
    dispatch(setDate(moment(date).format('YYYY-MM-DD')));
  }, []);

  useEffect(() => {
    let allow = !(
      isDrawingField ||
      isDrawingNote ||
      isPlacingMarker ||
      viewMode === ViewModeConstants.PRESCRIPTION ||
      viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE ||
      searchOpen ||
      !loggedIn ||
      !keyboardShortcutsEnabled
    );
    setAllowDateChange(allow);
  }, [
    isDrawingField,
    isDrawingNote,
    viewMode,
    isPlacingMarker,
    searchOpen,
    loggedIn,
    keyboardShortcutsEnabled,
  ]);

  return (
    <DateKeyboardHandler
      viewMode={viewMode}
      date={date}
      dates={dates}
      enabled={allowDateChange}
      onDateChanged={handleDateSet}
      selectedFieldDates={selectedFieldDates}
    />
  );
};

DateKeyboardHandlerContainer.propTypes = {};

export default memo(connect(mapStateToProps)(DateKeyboardHandlerContainer));
