'use strict';

import HelpJSON from 'docs/help-articles.json';

export const WEATHER_NETWORK_HELP_ARTICLE = 'WEATHER_NETWORK_HELP_ARTICLE';
export const VRM_OVERRIDE_HELP_ARTICLE = 'VRM_OVERRIDE_HELP_ARTICLE';

/* ============== //
 ||     TYPES     ||
 // ============== */

const SET_HELP_ARTICLES_SEEN = 'fieldsense/Help/SET_HELP_ARTICLES_SEEN';
const SHOW_HELP_DIALOG = 'fieldsense/Help/SHOW_HELP_DIALOG';
const HIDE_HELP_DIALOG = 'fieldsense/Help/HIDE_HELP_DIALOG';

/* ============== //
 ||    ACTIONS    ||
 // ============== */

export function showHelpDialog(feature) {
  return {
    type: SHOW_HELP_DIALOG,
    payload: feature,
  };
}

export function hideHelpDialog() {
  return {
    type: HIDE_HELP_DIALOG,
  };
}

export function setHelpArticleSeen(articleID) {
  return {
    type: SET_HELP_ARTICLES_SEEN,
    payload: articleID,
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  currentDialog: null,
  helpArticles: {
    [WEATHER_NETWORK_HELP_ARTICLE]: HelpJSON.network,
    [VRM_OVERRIDE_HELP_ARTICLE]: HelpJSON.vrm_overrides,
  },
  seenHelpArticleIDs: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SHOW_HELP_DIALOG: {
      state = { ...state, currentDialog: action.payload };
      break;
    }

    case HIDE_HELP_DIALOG: {
      state = { ...state, currentDialog: null };
      break;
    }

    case SET_HELP_ARTICLES_SEEN: {
      let id = action.payload;

      state = { ...state, seenHelpArticleIDs: [...state.seenHelpArticleIDs, id] };
      break;
    }

    default:
      break;
  }

  return state;
}
