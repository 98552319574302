import LanguageConstants from './LanguageConstants';

export default {
  [LanguageConstants.DANISH]: {
    currency: ['DKK'],
    date: '%d-%m-%Y',
    dateTime: '%a %e %b %X %Y',
    days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    decimal: '.',
    grouping: ['3'],
    months: [
      'Januar',
      'Februar',
      'Marts',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    periods: ['Formiddag', 'Eftermiddag'],
    shortDays: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Maj',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dec',
    ],
    thousands: ',',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.ENGLISH]: {
    currency: ['€'],
    date: '%Y-%m-%d',
    dateTime: '%a %b %e %X %Y',
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    decimal: '.',
    grouping: ['3'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    periods: ['AM', 'PM'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    thousands: ',',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.GERMAN]: {
    currency: ['€'],
    date: '%d-%m-%Y',
    dateTime: '%a %b %e %X %Y',
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    decimal: ',',
    grouping: ['3'],
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    periods: ['AM'],
    shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    shortMonths: [
      'Jan',
      'Feb',
      'Mrz',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
    thousands: '.',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.FINNISH]: {
    currency: ['€'],
    days: ['sunnuntai', 'maanantai', 'tiistai', 'keskiviiko', 'torstai', 'perjantai', 'lauantai'],
    decimal: '.',
    grouping: ['3'],
    months: [
      'tammikuu',
      'helmikuu',
      'maaliskuu',
      'huhtikuu',
      'toukokuu',
      'kesäkuu',
      'heinäkuu',
      'elokuu',
      'syyskuu',
      'lokakuu',
      'marraskuu',
      'joulukuu',
    ],
    periods: ['AM'],
    shortDays: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
    shortMonths: [
      'tam',
      'hel',
      'maal',
      'huh',
      'tou',
      'kes',
      'hei',
      'elo',
      'syys',
      'loka',
      'mar',
      'jou',
    ],
    thousands: ',',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.ESTONIAN]: {
    currency: ['€'],
    date: '%d-%m-%Y',
    dateTime: '%a %b %e %X %Y',
    days: ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'],
    decimal: '.',
    grouping: ['3'],
    months: [
      'Jaanuar',
      'Veebruar',
      'Märts',
      'Aprill',
      'Mai',
      'Juuni',
      'Juuli',
      'August',
      'September',
      'Oktoober',
      'November',
      'Detsember',
    ],
    periods: ['AM'],
    shortDays: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
    shortMonths: [
      'Jan',
      'Veb',
      'Märts',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Okt',
      'Nov',
      'Dets',
    ],
    thousands: ',',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.SWEDISH]: {
    currency: ['SEK'],
    date: '%Y-%m-%d',
    dateTime: '%a %b %e %X %Y',
    days: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
    decimal: '.',
    grouping: ['3'],
    months: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'Oktober',
      'November',
      'December',
    ],
    periods: ['Förmiddag', 'Eftermiddag'],
    shortDays: ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'lör'],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Maj',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dec',
    ],
    thousands: ',',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.LATVIAN]: {
    currency: ['€'],
    date: '%Y-%m-%d',
    dateTime: '%a %b %e %X %Y',
    days: [
      'Svētdiena',
      'Pirmdiena',
      'Otrdiena',
      'Trešdiena',
      'Cetrtdiena',
      'Piektdiena',
      'Sestdiena',
    ],
    decimal: '.',
    grouping: ['3'],
    months: [
      'Janvāris',
      'Februāris',
      'Marts',
      'Aprīlis',
      'Maijs',
      'Jūnijs',
      'Jūlijs',
      'Augusts',
      'Septembris',
      'Oktobris',
      'Novembris',
      'Decembris',
    ],
    periods: ['no rīta', 'pēcpusdiena'],
    shortDays: ['Sv', 'Pirm.', 'O.', 'T', 'C', 'Piekt.', 'Sest.'],
    shortMonths: [
      'Janv.',
      'Febr.',
      'Marts',
      'Apr.',
      'Maijs',
      'Jūn.',
      'Jūl.',
      'Aug.',
      'Sept.',
      'Okt.',
      'Nov.',
      'Dec.',
    ],
    thousands: ',',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
  [LanguageConstants.LITHUANIAN]: {
    currency: ['€'],
    date: '%Y-%m-%d',
    dateTime: '%a %b %e %X %Y',
    days: [
      'Sekmadienis',
      'Pirmadienis',
      'Antradienis',
      'Trečiadienis',
      'Ketvirtadienis',
      'Penktadienis',
      'Šeštadienis',
    ],
    decimal: '.',
    grouping: ['3'],
    months: [
      'Sausis',
      'Vasaris',
      'Kovas',
      'Balandis',
      'Gegužė',
      'Birželis',
      'Liepa',
      'Rugpjūtis',
      'Rugsėjis',
      'Spalis',
      'Lapkritis',
      'Gruodis',
    ],
    periods: ['ryto', 'po pietų'],
    shortDays: ['S', 'P', 'A', 'T', 'K', 'Pn', 'Š'],
    shortMonths: [
      'Sausis',
      'Vasaris',
      'Kovas',
      'Balandis',
      'Gegužė',
      'Birželis',
      'Liepa',
      'Rugpjūtis',
      'Rugsėjis',
      'Spalis',
      'Lapkritis',
      'Gruodis',
    ],
    thousands: ',',
    time: '%H:%M:%S',
    week: {
      dow: '1',
    },
  },
};
