import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import BottomDrawer from 'js/components/BottomDrawer/BottomDrawer';
import TimeWindowSlider from 'js/components/WeatherNetwork/TimeWindowSlider/TimeWindowSlider';
import { setTemporalEndIndex } from 'js/reducers/WeatherNetworkReducer';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import { useHasFeatures } from 'js/context/PermissionContext';
import FeatureConstants from 'js/constants/FeatureConstants';
import TimeWindowPlayer from 'js/components/WeatherNetwork/TimeWindowSlider/TimeWindowPlayer';
import { useLangFile } from 'js/context/LanguageContext';
import NetworkSensor from 'js/model/network/NetworkSensor';
import useMapEvent from 'js/components/DataLayer/hooks/useMapEvent';
import { CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import NumberUtils from 'js/helpers/NumberUtils';
import { useGoogleMap } from 'js/context/GoogleMapContext';

const styles = (theme) => ({
  paper: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    width: '100%',
  },
});

const mapStateToProps = (store) => {
  return {
    viewMode: store.control.viewMode,
    temporalStartIndex: store.weatherNetwork.temporalStartIndex,
    temporalEndIndex: store.weatherNetwork.temporalEndIndex,
    temporalDates: store.weatherNetwork.temporalDates,
    region: store.weatherNetwork.region,
    ticksPerHour: store.weatherNetwork.ticksPerHour,
    loadingRegion: store.weatherNetwork.loadingRegion,
    networkSensor: store.weatherNetwork.networkSensor,
    showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
    level: store.weatherNetwork.level,
    regionError: store.weatherNetwork.regionError,
    isDrawingField: store.addField.isDrawing,
    isMagicSelecting: store.note.magicWand.isSelecting,
    isAddingFields: store.addField.isAddingFields,
    isImportingFields: store.addField.isImportingFields,
    isDrawingNote: store.note.isDrawing,
    syncing: store.sync.syncing,
  };
};

const mapHasClusters = (googleMap, clusters) => {
  let bounds = googleMap.getBounds();

  for (let i = 0; i < clusters.length; i++) {
    let loc = clusters[i].location;
    let latLng = new google.maps.LatLng(loc.latitude, loc.longitude);

    if (bounds.contains(latLng)) {
      return true;
    }
  }
  return false;
};

const WeatherNetworkDrawerContainer = ({
  dispatch,
  classes,
  loadingRegion,
  showWeatherNetwork,
  networkSensor,
  temporalStartIndex,
  temporalEndIndex,
  temporalDates,
  region,
  viewMode,
  isDrawingField,
  isMagicSelecting,
  isAddingFields,
  isImportingFields,
  isDrawingNote,
  regionError,
  ticksPerHour,
}) => {
  const LangFile = useLangFile();
  const googleMap = useGoogleMap();
  const [hasData, setHasData] = useState(false);

  let maxIndex = temporalDates ? temporalDates.length - 1 : 0;
  let minIndex = ticksPerHour - 1;

  const checkData = useCallback(() => {
    if (region && googleMap) {
      setHasData(mapHasClusters(googleMap, region.clusters));
    } else {
      setHasData(false);
    }
  }, [region, googleMap]);

  useEffect(checkData, [region, showWeatherNetwork]);

  useMapEvent(googleMap, 'idle', checkData);

  const onTemporalEndIndexChanged = useCallback(
    (index) => {
      let newIndex = NumberUtils.limit(index, minIndex, maxIndex);

      if (newIndex !== temporalEndIndex) {
        dispatch(setTemporalEndIndex(newIndex));
      }
    },
    [temporalEndIndex, minIndex, maxIndex]
  );

  const featureEnabled = useHasFeatures([FeatureConstants.LEGACY_WEATHER]);

  if (!featureEnabled) {
    return null;
  }

  let hidden =
    viewMode !== ViewModeConstants.OVERVIEW ||
    isDrawingNote ||
    isMagicSelecting ||
    isDrawingField ||
    isAddingFields ||
    isImportingFields;
  let shown =
    !hidden &&
    showWeatherNetwork &&
    (networkSensor === NetworkSensor.RAIN_24H ||
      networkSensor === NetworkSensor.AIR_TEMP_24H ||
      networkSensor === NetworkSensor.SOIL_TEMP_24H);
  let noClusters = !regionError && !loadingRegion && !hasData;

  return (
    <Fragment>
      <BottomDrawer
        shown={shown}
        initiallyShown={shown}
        maxHeight={'auto'}
        fillMaxHeight={true}
        backgroundColor={'transparent'}
        elevation={0}
      >
        {loadingRegion && (
          <Paper className={classes.paper} elevation={2} square>
            <CircularProgress variant={'indeterminate'} color={'primary'} size={32} />
          </Paper>
        )}

        {!region && !loadingRegion && !hasData && (
          <Paper className={classes.paper} elevation={2} square>
            <Typography variant={'h6'}>
              {LangFile.WeatherNetworkDrawerContainer.noClusters}
            </Typography>
          </Paper>
        )}

        {!loadingRegion && regionError && (
          <Paper className={classes.paper} elevation={2} square>
            <Typography variant={'h6'}>
              {LangFile.WeatherNetworkDrawerContainer.regionError}
            </Typography>
          </Paper>
        )}

        {!loadingRegion && !regionError && !noClusters && (
          <TimeWindowSlider
            maxIndex={maxIndex}
            minIndex={minIndex}
            regionError={regionError}
            onChanged={onTemporalEndIndexChanged}
            temporalEndIndex={temporalEndIndex}
            temporalStartIndex={temporalStartIndex}
            networkSensor={networkSensor}
            ticksPerHour={ticksPerHour}
            hasData={hasData}
            region={region}
            dates={temporalDates}
          />
        )}

        {!loadingRegion && !regionError && !noClusters && (
          <TimeWindowPlayer
            index={temporalEndIndex}
            onChange={onTemporalEndIndexChanged}
            maxIndex={maxIndex}
          />
        )}
      </BottomDrawer>
    </Fragment>
  );
};

export default memo(withStyles(styles)(connect(mapStateToProps)(WeatherNetworkDrawerContainer)));
