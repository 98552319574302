import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';

import { grey } from '@material-ui/core/colors';

import SettingsConstants from 'js/constants/SettingsConstants';

import { useLangFile } from 'js/context/LanguageContext';
import WeatherStationSettingsListItem from './WeatherStationSettingsListItem';
import EditTextDialog from 'js/components/Modals/EditTextDialog';
import { getDeviceLabel } from 'js/helpers/StateInterpreters';
import { useFarmSettings } from 'js/context/SettingsContext';
import { WEATHER_DRAWER_HEIGHT, WEATHER_DRAWER_HEIGHT_SMALL } from 'js/constants/WeatherConstants';
import Box from '@material-ui/core/Box';
import { ReactSortable } from 'react-sortablejs';
import useLowScreen from '../../../hooks/useLowScreen';

function mapOrder(array, order, key) {
  if (order) {
    array.sort(function (a, b) {
      let A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  return array;
}

const styles = (theme) => ({
  root: {
    flexBasis: '20%',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    maxHeight: WEATHER_DRAWER_HEIGHT,
    padding: [[0, theme.spacing(1)]],
  },
  header: {
    textAlign: 'start',
    color: grey['900'],
    fontWeight: 400,
    fontSize: 22,
    marginBottom: 0,
    marginTop: 8,
    padding: [[0, 8]],
  },

  updatedText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '@media (max-width: 1024px), (max-height: 800px)': {
    header: {
      fontSize: 11,
    },
    namePrimary: {
      fontSize: 12,
    },
    namePrimaryEdit: {
      fontSize: 12,
      paddingRight: theme.spacing(1),
    },
    updatedText: {
      fontSize: 9,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  '@media (max-height: 900px)': {
    root: {
      maxHeight: WEATHER_DRAWER_HEIGHT_SMALL,
    },
  },
});

export const SORTING_LOCAL_STORAGE_KEY = 'fieldsense/weather/devices/sorting';

const WeatherStationSelector = ({
  classes,
  onUpdateGlobalFarmSetting,
  selectStation,
  selectedStation,
  stations,
  handleShowGpsError,
  handleOnFixGpsError,
  onManageConnections,
}) => {
  const LangFile = useLangFile();
  const farmSettings = useFarmSettings();
  const lowScreen = useLowScreen();

  const [list, setList] = useState([]); // initialized by effect below

  const [renameStation, setRenameStation] = useState(null);

  useEffect(() => {
    if (stations) {
      // Remove retired weather stations from the list
      let newList = [...stations.filter((s) => s.status !== 'retired')];

      // Get the station order from local storage
      let json = localStorage.getItem(SORTING_LOCAL_STORAGE_KEY);

      if (json != null && json.length > 0) {
        let order = JSON.parse(json);

        // Sort the list
        newList = mapOrder(newList, order, 'identifier');
      }

      // Map to the object interface used by react-sortablejs
      let sortableItems = newList.map((s) => ({ id: s.identifier, station: s }));

      // Update state
      setList(sortableItems);
    }
  }, [stations, farmSettings]);

  useEffect(() => {
    if (list.length > 0 && !selectedStation) {
      selectStation(list[0].station.identifier);
    }
  }, [selectedStation, list]);

  const handleUpdateName = useCallback(
    (id, value) => {
      let entry = SettingsConstants.FARM_WEATHER_STATION_ALIAS(id);
      let name = value.length > 0 ? value : id;

      onUpdateGlobalFarmSetting(entry, name);
    },
    [onUpdateGlobalFarmSetting]
  );

  const handleSortingChanged = useCallback(
    (newList) => {
      if (newList && newList.length > 0) {
        let order = JSON.stringify(newList.map(({ id }) => id));

        // save the ordering to local storage
        localStorage.setItem(SORTING_LOCAL_STORAGE_KEY, order);

        setList(newList);
      }
    },
    [farmSettings]
  );

  return (
    <Fragment>
      <Grid
        container
        direction={'column'}
        wrap={'nowrap'}
        className={`${classes.root} narrow-scrollbar`}
      >
        <Grid item xs={12}>
          {!lowScreen && (
            <Box mt={1} minHeight={48} display={'flex'} alignItems={'center'}>
              <Typography noWrap variant={'h5'}>
                {LangFile.WeatherStationSelector.title}
              </Typography>
            </Box>
          )}

          <ReactSortable list={list} setList={handleSortingChanged} tag={'span'}>
            {list.map(({ id, station }) => (
              <WeatherStationSettingsListItem
                key={id}
                station={station}
                onRename={setRenameStation}
                onSetLocation={handleOnFixGpsError}
                selectStation={selectStation}
                selectedStation={selectedStation}
                onManageConnections={onManageConnections}
                onShowGpsError={handleShowGpsError}
              />
            ))}
          </ReactSortable>
        </Grid>
      </Grid>

      {renameStation && (
        <EditTextDialog
          title={LangFile.WeatherStationSelector.renameWeatherStation}
          open={Boolean(renameStation)}
          value={getDeviceLabel(renameStation.identifier, farmSettings)}
          defaultValue={renameStation.identifier}
          onUpdate={(newName) => handleUpdateName(renameStation.identifier, newName)}
          onClose={() => setRenameStation(null)}
        />
      )}
    </Fragment>
  );
};

export default memo(withStyles(styles)(WeatherStationSelector));
