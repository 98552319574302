'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let prop = { props, viewBox: '0, 0, 40, 40' };
  return (
    <SvgIcon {...prop}>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M27.259,17.393a10.1,10.1,0,1,0,9.856,10.1A9.992,9.992,0,0,0,27.259,17.393Zm0,18.11a8,8,0,1,1,7.805-8A7.909,7.909,0,0,1,27.259,35.5Z"
          transform="translate(-8.06 -7.832)"
        />
        <path
          className="a"
          d="M25.328,17.681a1.041,1.041,0,0,0,1.025-1.051V11.154a1.026,1.026,0,1,0-2.051,0v5.476A1.025,1.025,0,0,0,25.328,17.681Z"
          transform="translate(-6.129 -10.103)"
        />
        <path
          className="a"
          d="M25.328,34.326A1.041,1.041,0,0,0,24.3,35.376v5.476a1.026,1.026,0,1,0,2.051,0V35.376A1.041,1.041,0,0,0,25.328,34.326Z"
          transform="translate(-6.129 -2.557)"
        />
        <path
          className="a"
          d="M18.335,19.933a.993.993,0,0,0,.731.3,1.047,1.047,0,0,0,.731-.3,1.073,1.073,0,0,0,0-1.484L16,14.562a1.011,1.011,0,0,0-1.448,0,1.073,1.073,0,0,0,0,1.484Z"
          transform="translate(-8.941 -8.81)"
        />
        <path
          className="a"
          d="M33.136,31.695a1.011,1.011,0,0,0-1.448,0,1.073,1.073,0,0,0,0,1.484l3.781,3.874a.993.993,0,0,0,.731.3,1.047,1.047,0,0,0,.731-.3,1.073,1.073,0,0,0,0-1.484Z"
          transform="translate(-4.145 -3.472)"
        />
        <path
          className="a"
          d="M17.5,25.353A1.041,1.041,0,0,0,16.473,24.3H11.129a1.051,1.051,0,0,0,0,2.1h5.344A1.041,1.041,0,0,0,17.5,25.353Z"
          transform="translate(-10.103 -5.68)"
        />
        <path
          className="a"
          d="M40.7,24.292H35.351a1.051,1.051,0,0,0,0,2.1H40.7a1.051,1.051,0,0,0,0-2.1Z"
          transform="translate(-3.323 -5.683)"
        />
        <path
          className="a"
          d="M32.415,20.232a1.047,1.047,0,0,0,.731-.3l3.781-3.874a1.073,1.073,0,0,0,0-1.484,1.011,1.011,0,0,0-1.448,0L31.7,18.446a1.073,1.073,0,0,0,0,1.484A1,1,0,0,0,32.415,20.232Z"
          transform="translate(-4.142 -8.807)"
        />
        <path
          className="a"
          d="M18.335,31.695l-3.781,3.874a1.073,1.073,0,0,0,0,1.484.993.993,0,0,0,.731.3,1.047,1.047,0,0,0,.731-.3L19.8,33.179a1.073,1.073,0,0,0,0-1.484A1.027,1.027,0,0,0,18.335,31.695Z"
          transform="translate(-8.941 -3.472)"
        />
        <g transform="translate(14.624 16.928)">
          <path
            className="a"
            d="M25.681,23.011v3.664a1.9,1.9,0,0,1-.551,1.445,2.122,2.122,0,0,1-1.525.525,2.164,2.164,0,0,1-1.512-.512,1.834,1.834,0,0,1-.564-1.418v-3.7h1.115v3.664a1.1,1.1,0,0,0,.256.8.95.95,0,0,0,.7.25c.628,0,.948-.341.961-1.011v-3.7Z"
            transform="translate(-21.529 -23.011)"
          />
          <path
            className="a"
            d="M27.594,27.187l1.23-4.176h1.243l-1.884,5.555H27.018l-1.884-5.555h1.243Z"
            transform="translate(-20.52 -23.011)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
