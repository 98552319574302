import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGraph } from 'js/context/GraphContext';
import moment from 'moment';
import { getPointSegments } from './GraphLineUtils';
import ManualFeatureConstants from '../../../constants/ManualFeatureConstants';
import { getDatasetMaxGapMinutes } from '../../../helpers/WeatherUtils';

const getSegments = (dataset) => {
  let points = dataset.points.filter((p) => moment(p.x).isSameOrBefore(moment()));
  let maxDiff = getDatasetMaxGapMinutes(dataset);

  return getPointSegments(points, dataset.resolution, maxDiff);
};

const GraphLineSegmented = ({ overrideDatasets, datasetName }) => {
  const { datasets, xMap, yLeftMap, yRightMap } = useGraph();
  const [segments, setSegments] = useState(null);

  const data = overrideDatasets || datasets;
  const dataset = data && data[datasetName];
  const yAxis = dataset && dataset.axis === 'left' ? yLeftMap : yRightMap;

  useEffect(() => {
    if (dataset) {
      setSegments(getSegments(dataset));
    }
  }, [dataset]);

  return (
    <g>
      {segments &&
        segments
          .filter((segment) => segment.valid)
          .map((segment, idx) => {
            let points = segment.points.map((p) => `${xMap(p.x)},${yAxis(p.y)}`).join(' ');
            let firstLast = [segment.points[0], segment.points[segment.points.length - 1]];

            return (
              <g key={idx}>
                <polyline
                  key={datasetName + ' segment ' + idx}
                  className="line"
                  fill={'none'}
                  strokeWidth={2}
                  stroke={dataset.color}
                  points={points}
                  opacity={dataset.opacity}
                />

                {ManualFeatureConstants.ENABLE_WEATHER_MAKEUP === false &&
                  firstLast.map((point, idx) => (
                    <circle
                      cx={xMap(point.x)}
                      cy={yAxis(point.y)}
                      r={point.selected ? 5 : 3}
                      key={`point-${idx}-${point.x + '-' + point.y}`}
                      fill={dataset.color}
                    ></circle>
                  ))}
              </g>
            );
          })}
    </g>
  );
};

GraphLineSegmented.propTypes = {
  /**
   * Select which data entry to display. Simply write the property name of the data entry to display.
   *
   * If this is not provided, the graphline will select a property based on which child index this has.
   */
  datasetName: PropTypes.string,
  /**
   * Override the object of datasets provided by the GraphProvider.
   */
  overrideDatasets: PropTypes.object,
};

GraphLineSegmented.defaultProps = {
  axis: 'left',
};

export default memo(GraphLineSegmented);
