'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let cls1 = {
    fill: '#fff',
  };

  return (
    <SvgIcon {...props} viewBox="0 0 31 31">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_3" data-name="Layer 3">
            <circle style={cls1} cx="15.3" cy="15.3" r="15.3" />
            <g id="Layer_1-3" data-name="Layer 1">
              <path d="M22.1,19.8a6.46,6.46,0,0,0-1.9.4l-.1,2a1.72,1.72,0,0,0,.6,1.5,2.17,2.17,0,0,0,3,0,1.74,1.74,0,0,0,.5-1.5l-.2-2A6.46,6.46,0,0,0,22.1,19.8Z" />
              <path d="M16,18a5.71,5.71,0,0,1,.9-3.2,7.8,7.8,0,0,0-2.2-.3,9.37,9.37,0,0,0-3.4.7l-.4,5a4,4,0,0,0,1,2.9,3.63,3.63,0,0,0,2.8,1.2,4.2,4.2,0,0,0,2.8-1.2,3.74,3.74,0,0,0,1-2.5c-.1,0-.2.1-.4.1C17.7,20.7,16,20.5,16,18Z" />
              <path d="M17.8,13.7A5.89,5.89,0,0,1,22,12a3.18,3.18,0,0,1,1.4.2,8.73,8.73,0,0,0-8.8-8.4,8.83,8.83,0,0,0-8.9,8.8c0,1.8.6,2.4,1.5,2.4,1.6,0,4.3-1.7,7.4-1.7A8,8,0,0,1,17.8,13.7Z" />
              <path d="M22.1,13.4a4.69,4.69,0,0,0-4.7,4.7c0,1,.3,1.3.8,1.3.9,0,2.3-.9,4-.9s3.1.9,3.9.9c.5,0,.8-.3.8-1.3A4.85,4.85,0,0,0,22.1,13.4Z" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
