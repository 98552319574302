/* ============== //
||      TYPES     ||
// ============== */

import WebAPIUtils from 'js/WebAPIUtils';
import { UPLOAD_ERROR, SOURCE, PROPERTIES } from 'js/components/SoilSamples/SoilSampleConstants';
import * as SoilSampleParser from 'js/components/SoilSamples/SoilSampleParser';

const SET_SHOW_SOIL_SAMPLES = 'fieldsense/SoilSampleReducer/SET_SHOW_SOIL_SAMPLES';
const UPLOAD_SOIL_SAMPLE_ZIP = 'fieldsense/SoilSampleReducer/UPLOAD_SOIL_SAMPLE_ZIP';
const GET_ALL_SOIL_SAMPLES = 'fieldsense/SoilSampleReducer/GET_ALL_SOIL_SAMPLES';
const SET_SOIL_SAMPLE_SHOWN = 'fieldsense/SoilSampleReducer/SET_SOIL_SAMPLE_SHOWN';
const RESET_UPLOAD = 'fieldsense/SoilSampleReducer/RESET_UPLOAD';
const SET_FETCHING_FILE = 'fieldsense/SoilSampleReducer/SET_FETCHING_FILE';
const SET_SELECTED_PROPERTY = 'fieldsense/SoilSampleReducer/SET_SELECTED_PROPERTY';

/* ============== //
||     ACTIONS    ||
// ============== */
export function setSoilSampleShown(sample, shown, features) {
  return async (dispatch) => {
    let id = sample.id;
    let source = sample.source;

    let sampleFeatures = features[id];

    if (shown && sampleFeatures === undefined) {
      dispatch(setFetchingFile(id, true));
      let zip = await WebAPIUtils.fetchSoilSampleFile(id);
      let file = await SoilSampleParser.parseShapefileResponse(zip);
      dispatch(setFetchingFile(id, false));

      if (source === SOURCE.GPS_AGRO) {
        sampleFeatures = SoilSampleParser.parseGPSAgro(file);
      } else if (source === SOURCE.LMO) {
        sampleFeatures = SoilSampleParser.parseLMO(file);
      }
    }

    return dispatch({
      type: SET_SOIL_SAMPLE_SHOWN,
      payload: id,
      meta: {
        shown: shown,
        features: sampleFeatures,
      },
    });
  };
}
export function getAllSoilSamples(farmId) {
  return {
    type: GET_ALL_SOIL_SAMPLES,
    payload: WebAPIUtils.getAllSoilSamples(farmId),
  };
}
export function setShowSoilSamples(show) {
  return {
    type: SET_SHOW_SOIL_SAMPLES,
    payload: show,
  };
}
export function uploadSoilSampleZip(file, fileName, source, farmId, progressCallback) {
  return {
    type: UPLOAD_SOIL_SAMPLE_ZIP,
    payload: WebAPIUtils.postSoilSampleZip(file, fileName, source, farmId, progressCallback),
  };
}
export function resetUpload() {
  return {
    type: RESET_UPLOAD,
  };
}
export function setFetchingFile(sampleId, fetching) {
  return {
    type: SET_FETCHING_FILE,
    payload: sampleId,
    meta: {
      fetching: fetching,
    },
  };
}

export function setSelectedProperty(property) {
  return {
    type: SET_SELECTED_PROPERTY,
    payload: property,
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  samples: [],
  features: {}, // {sampleId --> [Feature]}
  shownSampleIds: [],
  fetchingFiles: [],
  showSoilSamples: false,
  importedFile: null,
  source: null,
  uploading: false,
  uploadError: null,
  uploadSuccess: false,
  selectedProperty: PROPERTIES.SOILTYPE,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SET_SHOW_SOIL_SAMPLES: {
      state = { ...state, showSoilSamples: action.payload };
      break;
    }

    case SET_SOIL_SAMPLE_SHOWN: {
      let id = action.payload;
      let shown = action.meta.shown;
      let sampleFeatures = action.meta.features;

      let shownSampleIds;
      if (shown) {
        shownSampleIds = [...state.shownSampleIds, id];
      } else {
        shownSampleIds = [...state.shownSampleIds].filter((sampleId) => sampleId !== id);
      }

      let features = { ...state.features };
      features[id] = sampleFeatures;

      state = { ...state, shownSampleIds: shownSampleIds, features: features };
      break;
    }

    case SET_FETCHING_FILE: {
      let fetching = action.meta.fetching;
      let id = action.payload;

      let fetchingFiles;
      if (fetching) {
        fetchingFiles = [...state.fetchingFiles, id];
      } else {
        fetchingFiles = [...state.fetchingFiles].filter((sampleId) => sampleId !== id);
      }

      state = { ...state, fetchingFiles: fetchingFiles };
      break;
    }

    case GET_ALL_SOIL_SAMPLES + '_FULFILLED': {
      state = { ...state, samples: action.payload };
      break;
    }

    case GET_ALL_SOIL_SAMPLES + '_REJECTED': {
      state = { ...state, samples: [] };
      break;
    }

    case UPLOAD_SOIL_SAMPLE_ZIP + '_PENDING': {
      state = { ...state, uploading: true };

      break;
    }

    case UPLOAD_SOIL_SAMPLE_ZIP + '_FULFILLED': {
      let uploadedSample = action.payload;
      let samples = state.samples || [];

      state = {
        ...state,
        uploading: false,
        uploadSuccess: true,
        samples: [...samples, uploadedSample],
      };

      break;
    }

    case UPLOAD_SOIL_SAMPLE_ZIP + '_REJECTED': {
      let response = action.payload.response;
      let status = response.status;
      let error;

      if (status === 500) {
        error = UPLOAD_ERROR.SERVER_ERROR;
      } else if (status === 413) {
        error = UPLOAD_ERROR.FILE_TOO_BIG;
      } else if (status === 400) {
        error = UPLOAD_ERROR.INVALID_FILE_FORMAT;
      } else {
        error = UPLOAD_ERROR.UNEXPECTED;
      }

      state = { ...state, uploading: false, uploadError: error, uploadSuccess: false };

      break;
    }

    case RESET_UPLOAD: {
      state = { ...state, uploading: false, uploadError: null, uploadSuccess: false };
      break;
    }

    case SET_SELECTED_PROPERTY: {
      state = { ...state, selectedProperty: action.payload };
      break;
    }

    default:
      break;
  }

  return state;
}
