import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default (props) => {
  let prop = { props, viewBox: '0, 0, 9.789, 9.789' };
  return (
    <SvgIcon {...prop}>
      <path
        d="M9.789,4.895a4.895,4.895 0,1 1,-4.895 -4.895A4.894,4.894 0,0 1,9.789 4.895ZM6.955,4.325 L5.526,5.815l-0,-3.6a0.473,0.473 0,0 0,-0.474 -0.474l-0.316,-0a0.473,0.473 0,0 0,-0.474 0.474l-0,3.6l-1.429,-1.49a0.474,0.474 0,0 0,-0.677 -0.008l-0.215,0.217a0.472,0.472 0,0 0,-0 0.669L4.559,7.822a0.472,0.472 0,0 0,0.669 -0L7.852,5.203a0.472,0.472 0,0 0,-0 -0.669l-0.215,-0.217A0.477,0.477 0,0 0,6.952 4.325Z"
        stroke="0.6"
      />
    </SvgIcon>
  );
};
