import { useEffect, useRef } from 'react';

export const useHookRef = (hook) => {
  const ref = useRef(hook);

  useEffect(() => {
    ref.current = hook;
  }, [hook]);

  return ref;
};
