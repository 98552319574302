import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';

const styles = (theme) => ({
  fab: {
    position: 'absolute',
    top: -24,
    right: 24,
    transform: 'translate(0, -100%)',
  },
});

const TimeWindowPlayer = ({ classes, index, onChange, maxIndex }) => {
  let player = useRef(-1);
  let [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (playing) {
      player.current = setInterval(() => {
        if (index >= maxIndex) {
          setPlaying(false);
        } else {
          onChange(index + 1);
        }
      }, 250);
    } else {
      clearInterval(player.current);
    }
    return () => {
      clearInterval(player.current);
    };
  }, [index, maxIndex, playing]);

  return (
    <Fab
      size={'large'}
      color={'secondary'}
      className={classes.fab}
      onClick={() => setPlaying(!playing)}
    >
      {playing ? <Pause /> : <PlayArrow />}
    </Fab>
  );
};

TimeWindowPlayer.propTypes = {
  index: PropTypes.number,
  maxIndex: PropTypes.number,
  onChange: PropTypes.func,
};

export default withStyles(styles)(TimeWindowPlayer);
