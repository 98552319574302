import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  addFields,
  featureCollectionsToOutlines,
  fetchOutlines,
  fetchOutlinesByWFS,
  importFieldsByCVR,
  removeOutlines,
  selectAll,
  selectNone,
  setIsAddingFields,
  setIsDrawingField,
  setIsImportingFields,
  setOutlines,
} from 'js/reducers/AddFieldReducer';
import { useGoogleMap } from 'js/context/GoogleMapContext';
import { setShowAddFieldsDialog, setViewMode } from 'js/reducers/ControlReducer';
import { hideProgress, showProgress } from 'js/reducers/ProgressReducer';
import { addHectare } from 'js/reducers/FarmReducer';
import { fetchDates, fetchFields, selectField, updateFieldSeasons } from 'js/reducers/FieldReducer';
import { useLangFileRef } from 'js/context/LanguageContext';
import { useFarm } from 'js/context/AccountContext';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import ErrorModal from 'js/components/ErrorModal';
import AddFields from 'js/components/AddFields/AddFields';
import { fitToOutlines } from 'js/helpers/MapsUtils';
import { useHookRef } from '../../hooks/useHookRef';
import ProgressLoadingModal from 'js/components/Modals/ProgressLoadingModal';
import { useSelectedSeason } from 'js/context/SeasonContext';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { getFarmFields } from '../../reducers/FieldReducer';
import { getFilteredOutlinesSoft } from '../../helpers/MapsUtils';
import MapFarmFieldConnect from '../MapObjects/MapFarmFieldConnect';
import FieldSeason from '../../model/FieldSeason';
import { CropType } from '../../constants/CropConstants';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import Box from '@material-ui/core/Box';

const ensureProperZoom = (map) => {
  let minZoom = 14;
  if (map.getZoom() < minZoom) {
    map.setZoom(minZoom);
  }
};

const mapStateToProps = (store) => {
  return {
    fields: store.field.fields,
    farmFields: store.field.farmFields,
    outlines: store.addField.outlines,
    fieldsToAdd: store.addField.selectedOutlines,
    totalSizeToAdd: store.addField.totalSizeToAdd,
    showAddFieldsDialog: store.control.showAddFieldsDialog,
    isDrawingField: store.addField.isDrawing,
    isAddingFields: store.addField.isAddingFields,
    isImportingFields: store.addField.isImportingFields,
    language: store.language.language,
  };
};

const AddFieldsContainer = ({
  dispatch,
  outlines,
  fields,
  farmFields,
  fieldsToAdd,
  totalSizeToAdd,
  showAddFieldsDialog,
  isDrawingField,
  isAddingFields,
  isImportingFields,
  language,
}) => {
  const analytics = useFirebaseAnalytics();
  const LangFileRef = useLangFileRef();
  const LangFile = LangFileRef.current;
  const { addAction } = useActionSnackbarContext();

  const farm = useFarm();
  const selectedSeason = useSelectedSeason();
  const googleMap = useHookRef(useGoogleMap());
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [progress, setProgress] = useState(null);
  const [fieldsToConnect, setFieldsToConnect] = useState([]);

  const disconnectedFields =
    farmFields && fields && farmFields.filter((f) => fields.get(f.fieldId) == null);

  const onDrawField = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_DRAW);

    dispatch(showProgress(LangFileRef.current.AddFieldsContainer.loadingExistingFields));
    dispatch(getFarmFields(farm.farmId)).then(() => {
      dispatch(hideProgress());
      dispatch(setIsDrawingField(true));
      dispatch(setShowAddFieldsDialog(false));
    });
  }, [farm]);

  const onCloseError = useCallback(() => {
    setShowError(false);
  }, []);

  const applyAddFields = useCallback(() => {
    setShowConfirm(false);

    dispatch(showProgress(LangFileRef.current.AddFieldsContainer.addingFields));

    const fieldSeasons = fieldsToConnect.map((fieldId) => {
      return new FieldSeason(fieldId, CropType.NONE, null);
    });

    dispatch(updateFieldSeasons(selectedSeason.id, fieldSeasons))
      .then(() => {
        setFieldsToConnect([]);
      })
      .catch((e) => {
        console.error(e);
      });

    dispatch(addFields(fieldsToAdd, farm.farmId, selectedSeason.id))
      .then(() => {
        dispatch(addHectare(totalSizeToAdd));
        dispatch(fetchFields(selectedSeason.id));
        dispatch(fetchDates(farm.farmId));
      })
      .catch((e) => {
        console.error(e);
      })
      .then(() => {
        dispatch(removeOutlines());
        dispatch(hideProgress());

        dispatch(setIsImportingFields(false));
        dispatch(setIsAddingFields(false));
        dispatch(setIsDrawingField(false));
      });
  }, [fieldsToAdd, farm, totalSizeToAdd, selectedSeason, fieldsToConnect]);

  const showOutlines = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_OUTLINES);
    dispatch(setIsAddingFields(true));

    ensureProperZoom(googleMap.current);

    let bounds = googleMap.current.getBounds().toJSON();

    dispatch(showProgress(LangFileRef.current.AddFieldsContainer.loadingExistingFields));
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    dispatch(selectField(null));
    dispatch(setShowAddFieldsDialog(false));

    dispatch(getFarmFields(farm.farmId)).then((result) => {
      let farmFields = result.value;
      let existingFields = [...fields.values(), ...farmFields];

      dispatch(showProgress(LangFileRef.current.AddFieldsContainer.outlines));
      dispatch(fetchOutlines(bounds, existingFields)).then(
        () => {
          dispatch(hideProgress());
          setShowConfirm(true);
        },
        () => {
          setShowError(true);
        }
      );
    });
  }, [fields, farm]);

  const onShowProgress = useCallback((show, message) => {
    if (show) {
      dispatch(showProgress(message));
    } else {
      dispatch(hideProgress());
    }
  }, []);

  const showShapefileOutlines = useCallback(
    (featureCollections) => {
      let outlines = featureCollectionsToOutlines(featureCollections);
      let existingFields = [...fields.values()]; // we dont filter check the existing, disconnected fields
      let filtered = getFilteredOutlinesSoft(outlines, existingFields);

      dispatch(hideProgress());

      if (filtered.length > 0) {
        dispatch(setIsImportingFields(true));
        dispatch(setViewMode(ViewModeConstants.OVERVIEW));
        dispatch(selectField(null));
        dispatch(setOutlines(filtered));
        dispatch(setShowAddFieldsDialog(false));

        setShowConfirm(true);
      } else {
        if (disconnectedFields == null || disconnectedFields.length === 0) {
          dispatch(setViewMode(ViewModeConstants.OVERVIEW));
          dispatch(setIsImportingFields(false));
          dispatch(setIsAddingFields(false));
          dispatch(setIsDrawingField(false));

          let msg = (
            <Box>
              <Box>{LangFile.AddFieldsContainer.noNewFieldsFound}</Box>
              <Box>{LangFile.AddFieldsContainer.returnedToOverview}</Box>
            </Box>
          );

          addAction(new Action('no-new-fields-found-leaving', msg, 'error', 'filled'));
        } else {
          addAction(
            new Action(
              'no-new-fields-found',
              LangFile.AddFieldsContainer.noNewFieldsFound,
              'info',
              'filled'
            )
          );
        }
      }
    },
    [fields]
  );

  const onDismissAddFields = useCallback(() => {
    dispatch(setShowAddFieldsDialog(false));
  }, []);

  const selectAllOutlines = useCallback(() => {
    if (fieldsToAdd.length >= outlines.length) {
      dispatch(selectNone());
    } else {
      dispatch(selectAll());
    }
  }, [fieldsToAdd, outlines]);

  const leaveAddFieldsProcess = useCallback(() => {
    setShowConfirm(false);
    dispatch(removeOutlines());
    dispatch(hideProgress());
    dispatch(setIsImportingFields(false));
    dispatch(setIsAddingFields(false));
    dispatch(setIsDrawingField(false));
  }, []);

  const handleLoadCVR = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_IMPORT);

    dispatch(setIsImportingFields(true));
    dispatch(selectField(null));
    dispatch(setShowAddFieldsDialog(false));

    setProgress({ shown: true, message: LangFileRef.current.AddFieldsContainer.importingFields });

    dispatch(importFieldsByCVR(farm.cvr, fields)).then(() => {
      setProgress(null);
      setShowConfirm(true);
    });
  }, [farm.cvr, fields]);

  const handleLoadOutlinesFromWFS = useCallback(() => {
    // analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_IMPORT);

    dispatch(setIsAddingFields(true));

    ensureProperZoom(googleMap.current);

    let bounds = googleMap.current.getBounds().toJSON();
    dispatch(showProgress(LangFileRef.current.AddFieldsContainer.loadingExistingFields));
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    dispatch(selectField(null));
    dispatch(setShowAddFieldsDialog(false));

    dispatch(getFarmFields(farm.farmId)).then((result) => {
      let farmFields = result.value;
      let existingFields = [...fields.values(), ...farmFields];

      dispatch(showProgress(LangFileRef.current.AddFieldsContainer.outlines));
      dispatch(fetchOutlinesByWFS(bounds, existingFields, 'se'))
        .then(
          () => {
            setShowConfirm(true);
          },
          () => {
            setShowError(true);
            dispatch(setIsAddingFields(false));
          }
        )
        .then(() => {
          dispatch(hideProgress());
        });
    });
  }, [farm.cvr, fields, language]);

  const handleConnectFieldClick = useCallback((field) => {
    setFieldsToConnect((current) => {
      if (current.includes(field.fieldId)) {
        return current.filter((item) => item !== field.fieldId);
      }
      return [...current, field.fieldId];
    });
  }, []);

  useEffect(() => {
    if (outlines && outlines.length > 0 && googleMap.current) {
      fitToOutlines(outlines, googleMap.current);
    }
  }, [outlines]);

  return (
    <Fragment>
      <ErrorModal
        shown={showError}
        title={LangFile.AddFieldsContainer.selectField.title}
        message={LangFile.AddFieldsContainer.selectField.message}
        onClose={onCloseError}
      />

      <AddFields
        fields={fields}
        fieldsToAdd={fieldsToAdd}
        fieldsToConnect={fieldsToConnect}
        outlines={outlines}
        loadCVR={handleLoadCVR}
        loadOutlinesFromWFS={handleLoadOutlinesFromWFS}
        totalSizeToAdd={totalSizeToAdd}
        setShowProgress={onShowProgress}
        onDrawField={onDrawField}
        showOutlines={showOutlines}
        showShapefileOutlines={showShapefileOutlines}
        selectAllOutlines={selectAllOutlines}
        applyAddFields={applyAddFields}
        leaveAddFieldsProcess={leaveAddFieldsProcess}
        showDialog={showAddFieldsDialog}
        onDismiss={onDismissAddFields}
        showConfirmationPopover={showConfirm}
        showSpecialText={isImportingFields}
        language={language}
      />

      <ProgressLoadingModal progress={progress} />

      {/* Fields not connected to the current season */}
      {(isDrawingField || isAddingFields) &&
        disconnectedFields.length > 0 &&
        disconnectedFields.map((field, idx) => (
          <MapFarmFieldConnect
            disabled={isDrawingField}
            selected={fieldsToConnect.includes(field.fieldId)}
            key={idx}
            field={field}
            onClick={handleConnectFieldClick}
          />
        ))}
    </Fragment>
  );
};

export default memo(connect(mapStateToProps)(AddFieldsContainer));
