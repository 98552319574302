import React, { memo, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import WeatherHistoryPeriodSelector from 'js/components/WeatherDrawer/WeatherHistoryPeriodSelector';
import WindGraph from 'js/components/WeatherDrawer/WindGraph';
import WeatherGraphs from 'js/components/WeatherDrawer/WeatherGraphs';
import { useLangFile } from 'js/context/LanguageContext';
import {
  WEATHER_DRAWER_HEIGHT,
  WEATHER_DRAWER_HEIGHT_SMALL,
  WEATHER_START_DATE,
  WeatherSensor,
} from 'js/constants/WeatherConstants';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { red } from '@material-ui/core/colors';
import { WeatherSensorDecimals } from '../../constants/WeatherConstants';
import useLowScreen from 'js/hooks/useLowScreen';
import DateRangeInput from 'js/components/FormControls/DateRangeInput';
import {
  getWeatherSensorUnit,
  useMeasureSettings,
} from '../../context/AppSettings/AppSettingsContext';

const WeatherHistory = ({
  weatherSensor,
  weatherData,
  station,
  onPeriodChanged,
  onSelectPeriodPreset,
  period,
  loadingReadings,
  onDisableShortcuts,
  onEnableShortcuts,
  onTickClicked,
  enableWindGust,
  enableSprayConditions,
}) => {
  const LangFile = useLangFile();
  const measureSettings = useMeasureSettings();

  const minDate = moment(station.dataStart || WEATHER_START_DATE);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    if (weatherData) {
      let data = Object.values(weatherData)[0];

      if (data) {
        let since = moment(data.since);
        let until = moment(data.until);

        setStartDate(since);
        setEndDate(until);
      }
    }
  }, [weatherData]);

  let graphArea;

  if (loadingReadings || !weatherData) {
    graphArea = <Skeleton variant="rect" width={'100%'} height={'100%'} />;
  } else {
    let sensors = Object.keys(weatherData);

    let noData = true;

    sensors.forEach((sensor) => {
      let validValues = weatherData[sensor].values.filter((entry) => entry.value !== null);

      if (validValues) {
        noData = false;
      }
    });

    if (noData) {
      graphArea = (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}
          color={red['A700']}
        >
          <Typography variant={'caption'}>{LangFile.WeatherHistory.noDataAvailable}</Typography>
        </Box>
      );
    } else {
      if (weatherSensor === WeatherSensor.WIND) {
        graphArea = (
          <WindGraph
            enableWindGust={enableWindGust}
            enableSprayConditions={enableSprayConditions}
            onTickClicked={onTickClicked}
            weatherData={weatherData}
          />
        );
      } else {
        graphArea = <WeatherGraphs weatherData={weatherData} onTickClicked={onTickClicked} />;
      }
    }
  }

  let accumulatedRain = (measureSettings) => {
    if (loadingReadings || !weatherData || !measureSettings) {
      return null;
    }

    let data = weatherData[Object.keys(weatherData)[0]];
    let accumulated = data && data.accumulated;
    let rainUnit = getWeatherSensorUnit(measureSettings, WeatherSensor.RAIN);

    if (accumulated) {
      return `${Number(data.accumulated).toFixed(3)} ${rainUnit} ${LangFile.WeatherHistory.accumulated}`;
    } else {
      return null;
    }
  };

  let accumulatedGDD = () => {
    let sensor = WeatherSensor.GDD;

    if (loadingReadings || !weatherData) {
      return null;
    }

    let data = weatherData[sensor];

    if (!data) {
      return null;
    }

    let decimals = WeatherSensorDecimals[sensor];
    let unit = getWeatherSensorUnit(measureSettings, sensor);
    let last = data.values[data.values.length - 1];
    let value = last ? Number(last.value).toFixed(decimals) : '-';

    return `${value} ${unit}`;
  };

  let low = useLowScreen();
  let height = low ? WEATHER_DRAWER_HEIGHT_SMALL : WEATHER_DRAWER_HEIGHT;

  return (
    <Box
      pl={2}
      display={'flex'}
      flexDirection={'column'}
      flexWrap={'nowrap'}
      height={height}
      width={'100%'}
      maxHeight={height}
    >
      {!low && (
        <Box
          mt={1}
          display={'flex'}
          flexWrap={'nowrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          minHeight={48}
        >
          <Typography noWrap variant={'h5'}>
            {LangFile.WeatherHistory.title}
          </Typography>
        </Box>
      )}

      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
        <Box maxWidth={'75%'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <DateRangeInput
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            onBlur={onEnableShortcuts}
            onFocus={onDisableShortcuts}
            onChanged={onPeriodChanged}
          />

          <WeatherHistoryPeriodSelector period={period} onSelectPreset={onSelectPeriodPreset} />
        </Box>

        <Box mr={2}>
          <Typography variant={'caption'}>
            {loadingReadings ? (
              <Skeleton width={128} height={24} />
            ) : (
              accumulatedRain(measureSettings) || accumulatedGDD()
            )}
          </Typography>
        </Box>
      </Box>

      <Box mb={1} mt={low ? 0 : 1} mr={2} height={'100%'} display={'flex'} flex={'1 1 auto'}>
        {graphArea}
      </Box>
    </Box>
  );
};

WeatherHistory.propTypes = {
  weatherSensor: PropTypes.string,
  weatherData: PropTypes.object,
  station: PropTypes.object,
  period: PropTypes.string,
  loadingReadings: PropTypes.bool,
  enableWindGust: PropTypes.bool,
  enableSprayConditions: PropTypes.bool,
  onPeriodChanged: PropTypes.func,
  onSelectPeriodPreset: PropTypes.func,
  onEnableShortcuts: PropTypes.func,
  onDisableShortcuts: PropTypes.func,
  onTickClicked: PropTypes.func,
};

WeatherHistory.defaultProps = {};

export default memo(WeatherHistory);
