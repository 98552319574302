export const TableColumns = Object.freeze({
  jobName: 'jobName',
  jobType: 'jobType',
  layer: 'layer',
  inputDate: 'inputDate',
  crop: 'crop',
  metaType: 'metaType',
  createdDate: 'createdDate',
});

const desc = (objectA, objectB, orderBy) => {
  let a = objectA[orderBy];
  let b = objectB[orderBy];

  if (orderBy === TableColumns.createdDate) {
    return new Date(b) - new Date(a);
  }

  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const getComparator = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};
