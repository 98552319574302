import React, { Fragment, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { useLangFile } from 'js/context/LanguageContext';
import CircularProgress from '@material-ui/core/CircularProgress';

const PrescriptionSaveDialog = ({ open, onCancel, onRecalculate }) => {
  const LangFile = useLangFile();

  return (
    <Fragment>
      <Dialog open={Boolean(open)} fullWidth maxWidth={'md'}>
        <DialogTitle> {LangFile.PrescriptionRecalculate.recalculatePrescription}</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {LangFile.PrescriptionRecalculate.recalculateInfo}
            <br />
            {LangFile.PrescriptionRecalculate.areYouSure}
          </Box>
        </DialogContent>
        <DialogActions>
          <Fragment>
            <Button onClick={onCancel} color={'default'} variant={'outlined'}>
              {LangFile.PrescriptionRecalculate.cancel}
            </Button>

            <Button onClick={onRecalculate} color={'primary'} variant={'contained'}>
              {LangFile.PrescriptionRecalculate.recalculate}
            </Button>
          </Fragment>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(PrescriptionSaveDialog);

PrescriptionSaveDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onRecalculate: PropTypes.func.isRequired,
};
