'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let prop = { props, viewBox: '0, 0, 24, 16' };

  return (
    <SvgIcon {...prop}>
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 12.253906 1.128906 C 14.34375 1.128906 16.433594 1.128906 18.527344 1.128906 C 18.5625 1.386719 18.609375 1.644531 18.667969 1.902344 C 18.8125 2.558594 18.964844 3.210938 19.121094 3.863281 C 19.195312 4.207031 19.265625 4.546875 19.328125 4.886719 C 19.722656 4.902344 20.117188 4.925781 20.507812 4.964844 C 21.003906 5.011719 21.402344 5.238281 21.695312 5.648438 C 21.886719 5.941406 22.046875 6.25 22.183594 6.574219 C 22.175781 6.582031 22.171875 6.585938 22.164062 6.589844 C 20.792969 5.765625 19.34375 5.582031 17.816406 6.042969 C 16.5 6.492188 15.492188 7.335938 14.792969 8.566406 C 14.679688 8.773438 14.582031 8.984375 14.5 9.203125 C 12.238281 9.402344 9.976562 9.597656 7.710938 9.785156 C 7.691406 9.78125 7.667969 9.78125 7.644531 9.777344 C 6.667969 8.773438 5.492188 8.371094 4.121094 8.574219 C 2.890625 8.824219 1.957031 9.503906 1.320312 10.613281 C 1.136719 10.125 0.980469 9.628906 0.859375 9.121094 C 0.796875 8.875 0.773438 8.625 0.789062 8.375 C 0.8125 8.207031 0.871094 8.054688 0.964844 7.917969 C 1.277344 7.4375 1.679688 7.050781 2.164062 6.761719 C 2.71875 6.4375 3.304688 6.183594 3.917969 6 C 5.203125 5.636719 6.503906 5.40625 7.832031 5.308594 C 8.25 5.273438 8.671875 5.246094 9.09375 5.226562 C 9.804688 5.203125 10.511719 5.179688 11.222656 5.15625 C 11.273438 5.085938 11.316406 5.011719 11.351562 4.929688 C 11.40625 4.785156 11.457031 4.636719 11.496094 4.488281 C 11.753906 3.367188 12.003906 2.246094 12.253906 1.128906 Z M 12.253906 1.128906"
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 16.53125 0.0742188 C 17.359375 0.0390625 18.175781 0.125 18.980469 0.332031 C 19.046875 0.355469 19.09375 0.398438 19.121094 0.464844 C 19.207031 0.707031 19.128906 0.855469 18.886719 0.914062 C 16.492188 0.933594 14.101562 0.941406 11.707031 0.933594 C 11.746094 0.632812 11.910156 0.453125 12.199219 0.402344 C 13.644531 0.246094 15.085938 0.140625 16.53125 0.0742188 Z M 16.53125 0.0742188 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(99.607843%,99.607843%,99.607843%)',
          fillOpacity: 1,
        }}
        d="M 13.113281 1.515625 C 14.632812 1.511719 16.148438 1.515625 17.667969 1.519531 C 17.796875 1.550781 17.882812 1.628906 17.917969 1.761719 C 18.09375 2.828125 18.269531 3.894531 18.445312 4.957031 C 17.828125 4.984375 17.226562 5.109375 16.648438 5.339844 C 16.25 5.507812 15.902344 5.761719 15.613281 6.089844 C 15.402344 6.359375 15.195312 6.632812 14.996094 6.90625 C 14.648438 7.347656 14.207031 7.640625 13.675781 7.785156 C 13.15625 7.917969 12.628906 7.980469 12.09375 7.972656 C 12.089844 7.132812 12.09375 6.292969 12.101562 5.457031 C 12.382812 4.222656 12.660156 2.984375 12.929688 1.75 C 12.949219 1.636719 13.011719 1.558594 13.113281 1.515625 Z M 13.113281 1.515625 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(99.215686%,99.215686%,98.823529%)',
          fillOpacity: 1,
        }}
        d="M 9.144531 5.953125 C 9.222656 5.9375 9.289062 5.960938 9.339844 6.023438 C 9.652344 6.445312 9.933594 6.894531 10.183594 7.363281 C 10.203125 7.527344 10.132812 7.597656 9.976562 7.578125 C 9.949219 7.5625 9.921875 7.542969 9.902344 7.515625 C 9.664062 7.054688 9.390625 6.621094 9.070312 6.214844 C 9.019531 6.105469 9.042969 6.019531 9.144531 5.953125 Z M 9.144531 5.953125 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(99.215686%,99.215686%,98.823529%)',
          fillOpacity: 1,
        }}
        d="M 9.894531 5.953125 C 9.941406 5.949219 9.988281 5.957031 10.03125 5.972656 C 10.058594 6.003906 10.089844 6.035156 10.117188 6.0625 C 10.417969 6.480469 10.691406 6.917969 10.933594 7.378906 C 10.9375 7.53125 10.863281 7.597656 10.714844 7.578125 C 10.691406 7.5625 10.671875 7.546875 10.652344 7.53125 C 10.410156 7.0625 10.128906 6.617188 9.808594 6.203125 C 9.765625 6.089844 9.796875 6.007812 9.894531 5.953125 Z M 9.894531 5.953125 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 23.992188 10.902344 C 23.992188 11.132812 23.992188 11.363281 23.992188 11.59375 C 23.84375 13.140625 23.148438 14.351562 21.917969 15.226562 C 21.203125 15.699219 20.417969 15.957031 19.570312 15.992188 C 19.476562 15.992188 19.382812 15.992188 19.289062 15.992188 C 17.878906 15.914062 16.71875 15.320312 15.8125 14.210938 C 14.847656 12.886719 14.582031 11.425781 15.023438 9.824219 C 15.492188 8.402344 16.402344 7.40625 17.75 6.832031 C 18.839844 6.421875 19.9375 6.417969 21.03125 6.816406 C 22.347656 7.359375 23.253906 8.316406 23.746094 9.6875 C 23.875 10.085938 23.957031 10.488281 23.992188 10.902344 Z M 23.992188 10.902344 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(99.607843%,99.607843%,99.607843%)',
          fillOpacity: 1,
        }}
        d="M 17.65625 13.324219 C 17.589844 13.246094 17.519531 13.175781 17.441406 13.117188 C 16.835938 12.410156 16.621094 11.589844 16.789062 10.65625 C 17.027344 9.710938 17.578125 9.050781 18.445312 8.671875 C 19.480469 8.308594 20.410156 8.5 21.234375 9.238281 C 22.007812 10.03125 22.25 10.976562 21.964844 12.066406 C 21.695312 12.882812 21.179688 13.464844 20.414062 13.816406 C 19.441406 14.195312 18.542969 14.054688 17.722656 13.398438 C 17.695312 13.375 17.675781 13.351562 17.65625 13.324219 Z M 17.65625 13.324219 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 4.9375 15.992188 C 4.859375 15.992188 4.777344 15.992188 4.695312 15.992188 C 3.515625 15.910156 2.605469 15.359375 1.96875 14.332031 C 1.457031 13.386719 1.382812 12.40625 1.742188 11.386719 C 2.1875 10.304688 2.960938 9.613281 4.066406 9.320312 C 5.339844 9.070312 6.410156 9.4375 7.277344 10.421875 C 7.925781 11.246094 8.167969 12.1875 8 13.242188 C 7.765625 14.355469 7.15625 15.167969 6.171875 15.683594 C 5.777344 15.863281 5.367188 15.96875 4.9375 15.992188 Z M 4.9375 15.992188 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 14.277344 9.480469 C 14.320312 9.476562 14.359375 9.480469 14.398438 9.492188 C 14.097656 10.402344 14.035156 11.328125 14.203125 12.273438 C 14.28125 12.699219 14.410156 13.109375 14.585938 13.503906 C 12.609375 13.53125 10.628906 13.554688 8.648438 13.574219 C 8.894531 12.417969 8.710938 11.34375 8.09375 10.351562 C 8.007812 10.21875 7.914062 10.09375 7.8125 9.976562 C 7.804688 9.964844 7.804688 9.949219 7.804688 9.9375 C 9.96875 9.824219 12.125 9.671875 14.277344 9.480469 Z M 14.277344 9.480469 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 19.25 9.976562 C 19.957031 9.941406 20.417969 10.273438 20.625 10.972656 C 20.714844 11.59375 20.503906 12.066406 19.988281 12.390625 C 19.402344 12.65625 18.894531 12.554688 18.453125 12.078125 C 18.148438 11.667969 18.085938 11.222656 18.265625 10.738281 C 18.460938 10.300781 18.789062 10.046875 19.25 9.976562 Z M 19.25 9.976562 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(99.607843%,99.607843%,99.215686%)',
          fillOpacity: 1,
        }}
        d="M 4.578125 10.695312 C 5.363281 10.65625 5.96875 10.972656 6.390625 11.652344 C 6.722656 12.292969 6.722656 12.933594 6.390625 13.574219 C 5.921875 14.320312 5.261719 14.625 4.402344 14.492188 C 3.582031 14.261719 3.097656 13.71875 2.949219 12.855469 C 2.882812 12.195312 3.082031 11.640625 3.542969 11.1875 C 3.84375 10.921875 4.1875 10.757812 4.578125 10.695312 Z M 4.578125 10.695312 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 1.105469 11.027344 C 1.117188 11.027344 1.125 11.027344 1.132812 11.035156 C 0.78125 11.917969 0.726562 12.820312 0.972656 13.738281 C 0.738281 13.769531 0.523438 13.722656 0.328125 13.59375 C 0.265625 13.539062 0.214844 13.476562 0.175781 13.40625 C 0.132812 13.25 0.128906 13.09375 0.160156 12.9375 C 0.273438 12.417969 0.441406 11.917969 0.671875 11.445312 C 0.789062 11.273438 0.933594 11.132812 1.105469 11.027344 Z M 1.105469 11.027344 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(0,0,0)',
          fillOpacity: 1,
        }}
        d="M 4.617188 11.636719 C 5.160156 11.589844 5.527344 11.828125 5.707031 12.355469 C 5.804688 12.898438 5.613281 13.285156 5.140625 13.523438 C 4.667969 13.683594 4.289062 13.5625 3.992188 13.15625 C 3.765625 12.738281 3.789062 12.335938 4.074219 11.953125 C 4.226562 11.792969 4.40625 11.6875 4.617188 11.636719 Z M 4.617188 11.636719 "
      />
      <path
        style={{
          stroke: 'None',
          fillRule: 'evenodd',
          fill: 'rgb(88.627451%,89.019608%,81.960784%)',
          fillOpacity: 1,
        }}
        d="M 17.441406 13.117188 C 17.519531 13.175781 17.589844 13.246094 17.65625 13.324219 C 17.574219 13.265625 17.503906 13.195312 17.441406 13.117188 Z M 17.441406 13.117188 "
      />
    </SvgIcon>
  );
};
