'use strict';

export default {
  OVERVIEW: 'OVERVIEW',
  WEATHER: 'WEATHER',
  ANALYSIS: 'ANALYSIS',
  PRESCRIPTION: 'PRESCRIPTION',
  PRESCRIPTION_ARCHIVE: 'PRESCRIPTION_ARCHIVE',
  MANAGE_CROPS: 'MANAGE_CROPS',
  MANAGE_WEATHER_STATION_FIELD_CONNECTIONS: 'MANAGE_WEATHER_STATION_FIELD_CONNECTIONS',
  FIELD_STATISTICS: 'FIELD_STATISTICS',
};
