'use strict';

import moment from 'moment';

export default class SourceTiff {
  constructor(tiffDate, weight) {
    this.tiffDate = moment(tiffDate).format('YYYY-MM-DD'); // String
    this.weight = weight; // Int, 0-100
  }
}
