function MapCanvasOverlayProto(map, bounds, width, height, onAdded) {
  this.map = map;
  this.bounds = bounds;
  this.width = width;
  this.height = height;
  this.onAdded = onAdded;
  this.canvas = null;
  this.ctx = null;
  this.zIndex = 0;

  this.setMap(map);
}

export function MapCanvasOverlay(map, bounds, width, height, zIndex, onAdded) {
  // Subclass the OverlayView
  MapCanvasOverlayProto.prototype = new google.maps.OverlayView();

  // noinspection JSUnusedGlobalSymbols
  MapCanvasOverlayProto.prototype.onAdd = function () {
    let canvas = document.createElement('canvas');
    canvas.className = 'MapCanvasOverlay';
    canvas.style.position = 'absolute';
    canvas.style.zIndex = this.zIndex;
    canvas.height = this.height;
    canvas.width = this.width;
    let ctx = canvas.getContext('2d');

    this.canvas = canvas;
    this.ctx = ctx;

    // 'mapPane' is the lowest pane and is above the tiles. It may not receive DOM events. (Pane 0).
    // 'overlayLayer' contains polylines, polygons, ground overlays and tile layer overlays. It may not receive DOM events. (Pane 1).
    // 'markerLayer' contains markers. It may not receive DOM events. (Pane 2).
    // 'overlayMouseTarget' contains elements that receive DOM events. (Pane 3).
    // 'floatPane' contains the info window. It is above all map overlays. (Pane 4).

    let panes = this.getPanes();
    panes.overlayLayer.appendChild(canvas);

    if (typeof this.onAdded === 'function') {
      this.onAdded(ctx, canvas);
    }
  };

  MapCanvasOverlayProto.prototype.isInVisibleRegion = function () {
    return true;
  };

  // noinspection JSUnusedGlobalSymbols
  MapCanvasOverlayProto.prototype.draw = function () {
    if (!this.isInVisibleRegion()) {
      this.hide();
      return;
    }

    this.show();

    let overlayProjection = this.getProjection();
    let sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
    let ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

    let width = ne.x - sw.x;
    let height = sw.y - ne.y;

    let canvas = this.canvas;

    if (!canvas) {
      return;
    }

    canvas.style.left = sw.x + 'px';
    canvas.style.top = ne.y + 'px';
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
  };

  MapCanvasOverlayProto.prototype.remove = function () {
    if (this.canvas) {
      let parent = this.canvas.parentNode;

      if (parent) {
        parent.removeChild(this.canvas);
      }
    }

    this.canvas = null;
  };

  // noinspection JSUnusedGlobalSymbols
  MapCanvasOverlayProto.prototype.onRemove = function () {
    this.remove();
  };

  MapCanvasOverlayProto.prototype.hide = function () {
    if (this.canvas) {
      this.canvas.style.visibility = 'hidden';
    }
  };

  MapCanvasOverlayProto.prototype.show = function () {
    if (this.canvas) {
      this.canvas.style.visibility = 'visible';
    }
  };

  MapCanvasOverlayProto.prototype.toggle = function () {
    if (this.canvas) {
      if (this.canvas.style.visibility === 'hidden') {
        this.show();
      } else {
        this.hide();
      }
    }
  };

  // noinspection JSUnusedGlobalSymbols
  MapCanvasOverlayProto.prototype.toggleDOM = function () {
    if (this.getMap()) {
      // Note: setMap(null) calls OverlayView.onRemove()
      this.setMap(null);
    } else {
      this.setMap(this.map);
    }
  };

  return new MapCanvasOverlayProto(map, bounds, width, height, onAdded);
}
