import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import ClipIcon from '@material-ui/icons/Transform';
import MergeIcon from '@material-ui/icons/MergeType';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationItem from '@material-ui/core/BottomNavigationAction';
import grey from '@material-ui/core/colors/grey';

const styles = (theme) => ({
  root: {
    zIndex: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
  },
  nav: {
    width: 'auto',
  },
  button: {
    margin: [[0, 20]],
    pointerEvents: 'all',
    '&:hover:not(.disabled)': {
      backgroundColor: grey['200'],
    },
    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
});

class PolygonToolbar extends Component {
  state = {
    hoveredIndex: null,
  };

  handleMouseEnter = (index, active) => {
    if (active) {
      this.props.onMouseEnter();
      this.setState({ hoveredIndex: index });
    }
  };

  handleMouseLeave = () => {
    this.props.onMouseLeave();
    this.setState({ hoveredIndex: null });
  };

  render() {
    let {
      classes,
      LangFile,
      acIndex,
      actions,
      selectedMarker,
      deleteHandler,
      undoHandler,
      redoHandler,
      resetHandler,
      polygons,
      markers,
      toggleIsSlicing,
      isSlicingPolygon,
      isMagicSelecting,
    } = this.props;

    const undoIsActive = (acIndex > 0 && !isSlicingPolygon) || (acIndex >= 0 && isMagicSelecting);
    const redoIsActive = acIndex + 1 < actions.length && (!isSlicingPolygon || isMagicSelecting);
    const deleteIsActive =
      polygons[0] && polygons[0].path.length > 2 && selectedMarker && !isSlicingPolygon;
    const resetIsActive =
      (markers && markers.length > 0 && !isSlicingPolygon) || (acIndex >= 0 && isMagicSelecting);
    const sliceIsActive = polygons[0] && !isSlicingPolygon && polygons.length < 2;
    const mergeIsActive = polygons.length > 1;

    return (
      <div className={classes.root}>
        <BottomNavigation className={classes.nav} value={this.state.hoveredIndex} showLabels>
          <BottomNavigationItem
            onClick={() => {
              deleteHandler();
              if (!deleteIsActive) {
                this.handleMouseLeave();
              }
            }}
            icon={<DeleteIcon color={deleteIsActive ? 'primary' : 'inherit'} />}
            classes={{
              label: classes.label,
              root: `${classes.button} ${!deleteIsActive ? 'disabled' : ''}`,
            }}
            label={LangFile.PolygonToolbar.deleteButton}
            onMouseEnter={() => this.handleMouseEnter(0, deleteIsActive)}
            onMouseLeave={() => this.handleMouseLeave(deleteIsActive)}
            disabled={!deleteIsActive}
          />

          <BottomNavigationItem
            onClick={() => {
              undoHandler();
              if (!undoIsActive) {
                this.handleMouseLeave();
              }
            }}
            icon={<UndoIcon color={undoIsActive ? 'primary' : 'inherit'} />}
            label={LangFile.PolygonToolbar.undoButton}
            classes={{
              label: classes.label,
              root: `${classes.button} ${!undoIsActive ? 'disabled' : ''}`,
            }}
            onMouseEnter={() => this.handleMouseEnter(1, undoIsActive)}
            onMouseLeave={() => this.handleMouseLeave()}
            disabled={!undoIsActive}
          />

          <BottomNavigationItem
            onClick={() => {
              redoHandler();
              if (!redoIsActive) {
                this.handleMouseLeave();
              }
            }}
            icon={<RedoIcon color={redoIsActive ? 'primary' : 'inherit'} />}
            label={LangFile.PolygonToolbar.redoButton}
            classes={{
              label: classes.label,
              root: `${classes.button} ${!redoIsActive ? 'disabled' : ''}`,
            }}
            onMouseEnter={() => this.handleMouseEnter(2, redoIsActive)}
            onMouseLeave={() => this.handleMouseLeave()}
            disabled={!redoIsActive}
          />

          <BottomNavigationItem
            onClick={() => {
              resetHandler();
              if (!resetIsActive) {
                this.handleMouseLeave();
              }
            }}
            icon={<HistoryIcon color={resetIsActive ? 'primary' : 'inherit'} />}
            classes={{
              label: classes.label,
              root: `${classes.button} ${!resetIsActive ? 'disabled' : ''}`,
            }}
            label={LangFile.PolygonToolbar.resetButton}
            onMouseEnter={() => this.handleMouseEnter(3, resetIsActive)}
            onMouseLeave={() => this.handleMouseLeave()}
            disabled={!resetIsActive}
          />

          {this.props.extended && (
            <BottomNavigationItem
              onClick={() => {
                toggleIsSlicing();
                if (!sliceIsActive) {
                  this.handleMouseLeave();
                }
              }}
              selectedindex={this.state.hoveredIndex}
              icon={<ClipIcon color={sliceIsActive ? 'primary' : 'inherit'} />}
              classes={{
                label: classes.label,
                root: `${classes.button} ${!sliceIsActive ? 'disabled' : ''}`,
              }}
              onMouseEnter={() => this.handleMouseEnter(4, sliceIsActive)}
              onMouseLeave={() => this.handleMouseLeave()}
              label="Split"
              disabled={!sliceIsActive}
            />
          )}

          {this.props.extended && (
            <BottomNavigationItem
              onClick={() => {
                this.props.toggleIsMergingHandler();
                if (!mergeIsActive) {
                  this.handleMouseLeave();
                }
              }}
              selectedindex={this.state.hoveredIndex}
              icon={<MergeIcon color={mergeIsActive ? 'primary' : 'inherit'} />}
              classes={{
                label: classes.label,
                root: `${classes.button} ${!mergeIsActive ? 'disabled' : ''}`,
              }}
              onMouseEnter={() => this.handleMouseEnter(5, mergeIsActive)}
              onMouseLeave={() => this.handleMouseLeave()}
              label="Merge"
              disabled={!mergeIsActive}
            />
          )}
        </BottomNavigation>
      </div>
    );
  }
}

export default withStyles(styles)(PolygonToolbar);
