import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Switch,
  Tooltip,
} from '@material-ui/core';
import Styles from './MapLegendContainer.module.less';
import { ArrowDropDown, Cloud, Satellite } from '@material-ui/icons';
import TractorIconBlack from '../Icons/TractorIconBlack';
import InlineColorScale from 'js/components/UI-Elements/InlineColorScale';
import { getImageTypeColorScale } from 'js/constants/ColorMaps';
import { useLangFile } from 'js/context/LanguageContext';
import { voidFunc } from 'js/constants/PropTypeUtils';
import BioTech from '../Icons/BioTech';
import { getScale } from '../../helpers/WeatherNetworkUtils';
import { useMeasureSettings } from '../../context/AppSettings/AppSettingsContext';
import WeatherNetworkMenu from '../MapLayerList/WeatherNetworkMenu';
import FeatureConstants from '../../constants/FeatureConstants';
import { grey } from '@material-ui/core/colors';
import SurveyLayerList from '../Surveys/SurveyLayerList';
import ListItem from '@material-ui/core/ListItem';
import style from '../LayerDrawer/LayerHeaderItem.module.less';
import type { LAYER_CONFIG } from '../../model/surveys/SurveyLayerConfig';
import { getMinMaxValues } from '../../helpers/SurveyUtils';
import DemandScale from '../DemandScale/DemandScale';
import ViewModeConstants from '../../constants/ViewModeConstants';
import { getLayerConfig } from '../Prescription/PrescriptionUtils';
import SatelliteLayerList from '../SatelliteLayerList/SatelliteLayerList';
import { useColoringStrategy } from '../../model/surveys/useColoringStrategy';
import { SURVEY_LAYERS } from '../../constants/SurveyLayers';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { YIELD_LAYERS } from '../../constants/YieldLayer';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { isSatelliteLayer, isSurveyLayer } from '../Prescription/PrescriptionJob';
import {
  isLayerValid,
  useSelectedSurvey,
  useSurveyLayerViewCapabilities,
  useSurveyViewCapabilities,
} from '../../context/SurveyContext';
import OtherLayerList from '../OtherLayerList/OtherLayerList';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import YieldLayerList from '../Prescription/YieldLayerList';

export const MapLegendToggle = ({
  primaryText,
  secondaryText,
  disabled,
  onToggle,
  disabledTooltip,
  tooltip,
  toggled,
  helpText,
}: MapLegendToggle.propTypes) => {
  const handleChange = useCallback(() => {
    onToggle(!toggled);
  }, [onToggle, toggled]);

  return (
    <List dense disablePadding>
      <ListItem
        dense
        button={true}
        onClick={!disabled ? handleChange : undefined}
        disabled={disabled}
        style={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          secondaryTypographyProps={{ color: 'secondary' }}
        />
        {helpText && helpText !== '' && (
          <Tooltip title={helpText} placement="right">
            <HelpOutlineIcon />
          </Tooltip>
        )}

        <ListItemSecondaryAction style={{ right: 0 }}>
          <Tooltip
            title={disabled ? disabledTooltip : tooltip}
            classes={{ tooltip: style.tooltip }}
            placement={'right'}>
            <span>
              <Switch
                size={'small'}
                color={'primary'}
                onChange={handleChange}
                checked={toggled}
                disabled={disabled}
              />
            </span>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

MapLegendToggle.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  disabledTooltip: PropTypes.string,
  tooltip: PropTypes.string,
  toggled: PropTypes.bool,
};

const MapLegendEmptyState = ({
  showSatelliteImagery,
  networkEnabled,
  showSoilSurveys,
  showOtherLayers,
}: MapLegendEmptyState.propTypes) => {
  const LangFile = useLangFile();
  if (!(showSatelliteImagery || showSoilSurveys || showOtherLayers) && !networkEnabled) {
    return (
      <Box
        p={1}
        width={1}
        fontSize={'0.8rem'}
        color={grey['500']}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        boxSizing={'border-box'}
        textAlign={'center'}>
        {LangFile.MapLegend.selectData}
      </Box>
    );
  }
  return null;
};

MapLegendEmptyState.propTypes = {
  showSatelliteImagery: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  networkEnabled: PropTypes.bool,
  showOtherLayers: PropTypes.bool,
};

const MapLegendDropdownTitle = ({
  controls,
  showSatelliteImagery,
  handleMenuOpened,
  networkEnabled,
  networkSensor,
  selectedLayer,
  showSoilSurveys,
  showOtherLayers,
}: MapLegendDropdownTitle.propTypes) => {
  const LangFile = useLangFile();

  // TITLE
  let title = LangFile.MapLegend.mapLayers.none;
  if (selectedLayer) {
    const layerConfig = getLayerConfig(selectedLayer);
    if (layerConfig) {
      title = layerConfig.getName(LangFile);
      const shortHand = layerConfig.getShorthand(LangFile);
      if (shortHand) {
        title = title + ` (${shortHand})`;
      }
    }
  } else if (networkEnabled) {
    title = LangFile.WeatherNetworkMenu.networkSensor[networkSensor];
  }

  // EMPTY STATE
  if (!(showSatelliteImagery || showSoilSurveys || showOtherLayers || networkEnabled)) {
    return null;
  }

  // PRIMARY STATE
  return (
    <MenuList dense={true}>
      <MenuItem
        aria-controls={controls}
        disableGutters={true}
        aria-haspopup="true"
        onClick={handleMenuOpened}>
        <Box
          display={'inline-flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
          px={1}>
          {showSatelliteImagery && <Satellite fontSize="small" />}
          {showSoilSurveys && <BioTech fontSize="small" />}
          {networkEnabled && <Cloud fontSize="small" />}
          {showOtherLayers && <TractorIconBlack fontSize="small" />}

          <Box
            px={1}
            width={1}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}>
            {title}
          </Box>

          <ArrowDropDown fontSize="small" />
        </Box>
      </MenuItem>
    </MenuList>
  );
};

MapLegendDropdownTitle.propTypes = {
  controls: PropTypes.string,
  showSatelliteImagery: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  networkEnabled: PropTypes.bool,
  handleMenuOpened: PropTypes.func,
  selectedLayer: PropTypes.string,
  networkSensor: PropTypes.string,
  showOtherLayers: PropTypes.bool,
};

function MapLegendFieldsMenu({
  id,
  anchorEl,
  enableHeightmap,
  selectedLayer,
  onClose,
  renderDateHandler,
  values,
  surveys,
  dates,
  viewMode,
  showSatelliteImagery,
  selectedFieldHasImage,
  selectedFieldHasSurvey,
  multipleSelections,
  enableMultipleSelect,
  onReferencesChanged,
  handleSelectSatelliteType,
  showSoilSurveys,
  handleSelectSurveyType,
  handleSelectYieldType,
  onVariationsEnabledChanged,
  variationsEnabled,
  onClassificationsEnabledChanged,
  statisticsEnabled,
  onStatisticsEnabledChanged,
  classificationsEnabled,
  surveyClassificationValues,
  disableDemandClassification,
  showHeightMap,
  onSelectOtherType,
  enableYield,
  showOtherLayers,
  renderVariableColorScale,
  selectedFieldHasYield,
}: MapLegendFieldsMenu.propTypes) {
  const LangFile = useLangFile();
  const layerConfig: LAYER_CONFIG = getLayerConfig(selectedLayer);
  const isSingleField =
    viewMode === ViewModeConstants.ANALYSIS || viewMode === ViewModeConstants.PRESCRIPTION;
  const coloringStrategy = useColoringStrategy(
    selectedLayer,
    viewMode,
    classificationsEnabled,
    variationsEnabled,
    values
  );
  const surveyLayerViewCapabilities = useSurveyLayerViewCapabilities(selectedLayer, viewMode);
  const disableChangeVariations =
    surveyLayerViewCapabilities &&
    (!surveyLayerViewCapabilities.enableRaw || !surveyLayerViewCapabilities.enableVariations);

  let disableChangeClassification =
    surveyLayerViewCapabilities &&
    (!surveyLayerViewCapabilities.enableRaw || !surveyLayerViewCapabilities.enableClassifications);
  if (surveyLayerViewCapabilities && surveyLayerViewCapabilities.enableCustomClassification) {
    if (disableDemandClassification) {
      disableChangeClassification = disableDemandClassification;
    } else {
      disableChangeClassification = !surveyLayerViewCapabilities.enableClassifications;
    }
  }
  const surveyScale = coloringStrategy.scale;
  let minPercent = 0;
  let maxPercent = 100;

  if (layerConfig && values) {
    if (!variationsEnabled) {
      let { min, max } = getMinMaxValues(values, selectedLayer === SURVEY_LAYERS.FI_SOIL_CLASS);
      if (coloringStrategy.isDiscrete) {
        const length = coloringStrategy.scale.length;
        const minBucket = coloringStrategy.getBucket(min);
        const maxBucket = coloringStrategy.getBucket(max);

        if (minBucket && maxBucket) {
          minPercent = (minBucket.index / length) * 100;
          maxPercent = (Math.min(maxBucket.index + 1, length) / length) * 100;
        }
      } else {
        if (selectedLayer === SURVEY_LAYERS.JB || selectedLayer === SURVEY_LAYERS.FI_SOIL_CLASS) {
          max = max + 1;
        }

        minPercent = layerConfig.mapValue(min, 0, 100);
        maxPercent = layerConfig.mapValue(max, 0, 100);
      }
    }
  }

  const disableSingleFieldSatelliteImages =
    (viewMode === ViewModeConstants.PRESCRIPTION || viewMode === ViewModeConstants.ANALYSIS) &&
    !selectedFieldHasImage;
  const disableOverviewSatelliteImages =
    viewMode === ViewModeConstants.OVERVIEW && dates.length === 0;
  const disableSatelliteImages =
    disableOverviewSatelliteImages || disableSingleFieldSatelliteImages;
  const disableSurveys =
    !surveys || surveys.length === 0 || (isSingleField && !selectedFieldHasSurvey);
  const showMinMax = isSingleField && (selectedFieldHasImage || selectedFieldHasSurvey);

  let satelliteTooltip = null;
  if (selectedLayer === SATELLITE_LAYERS.VITALITY_NDVI) {
    satelliteTooltip = LangFile.MapLegend.vitalityNDVIScaleTooltip;
  } else if (selectedLayer === SATELLITE_LAYERS.VITALITY_NDRE) {
    satelliteTooltip = LangFile.MapLegend.vitalityNDREScaleTooltip;
  } else if (
    selectedLayer === SATELLITE_LAYERS.VARIATIONS_NDVI ||
    selectedLayer === SATELLITE_LAYERS.VARIATIONS_NDRE
  ) {
    satelliteTooltip = LangFile.MapLegend.variationsScaleTooltip;
  }

  return (
    <>
      <Menu
        classes={{ paper: Styles.menuPaper }}
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}>
        {!(viewMode === ViewModeConstants.PRESCRIPTION && !selectedFieldHasImage) && (
          <div>
            <SatelliteLayerList
              items={
                viewMode === ViewModeConstants.PRESCRIPTION
                  ? Object.keys(SATELLITE_LAYERS).filter(
                      (layer) =>
                        layer !== SATELLITE_LAYERS.VITALITY_NDRE &&
                        layer !== SATELLITE_LAYERS.VARIATIONS_NDRE
                    )
                  : Object.keys(SATELLITE_LAYERS)
              }
              disableAll={disableSatelliteImages}
              selectedLayer={selectedLayer}
              multipleSelections={multipleSelections}
              enableMultipleSelect={enableMultipleSelect}
              onReferencesChanged={onReferencesChanged}
              onLayerChanged={handleSelectSatelliteType}
            />
          </div>
        )}

        {!(viewMode === ViewModeConstants.PRESCRIPTION && !selectedFieldHasSurvey) && (
          <div>
            <SurveyLayerList
              viewMode={viewMode}
              disableAll={disableSurveys}
              selectedLayer={selectedLayer}
              isSingleField={isSingleField}
              multipleSelections={multipleSelections}
              enableMultipleSelect={enableMultipleSelect}
              onReferencesChanged={onReferencesChanged}
              onLayerChanged={handleSelectSurveyType}
            />
          </div>
        )}

        {enableHeightmap && (
          <div>
            <OtherLayerList showHeightMap={showHeightMap} onClick={onSelectOtherType} />
          </div>
        )}

        {viewMode === ViewModeConstants.PRESCRIPTION && enableYield && (
          <div>
            <YieldLayerList
              viewMode={viewMode}
              items={selectedFieldHasYield ? Object.keys(YIELD_LAYERS) : []}
              selectedLayer={selectedLayer}
              multipleSelections={multipleSelections}
              enableMultipleSelect={enableMultipleSelect}
              onReferencesChanged={onReferencesChanged}
              onLayerChanged={handleSelectYieldType}
            />
          </div>
        )}
      </Menu>

      {(showSatelliteImagery || showSoilSurveys || showOtherLayers) && (
        <>
          <Divider />
          <Box
            py={1}
            px={1}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            {showSatelliteImagery && (
              <>
                <InlineColorScale
                  scale={getImageTypeColorScale(selectedLayer)}
                  tooltip={satelliteTooltip}
                  disableStepHover={
                    selectedLayer === SATELLITE_LAYERS.VARIATIONS_NDVI ||
                    selectedLayer === SATELLITE_LAYERS.VARIATIONS_NDRE
                  }
                />

                <Box px={1} pt={1}>
                  {renderDateHandler()}
                </Box>
              </>
            )}

            {showOtherLayers && renderVariableColorScale && (
              <>
                {renderVariableColorScale()}

                <Box px={1} pt={1}>
                  {renderDateHandler()}
                </Box>
              </>
            )}

            {!classificationsEnabled && showSoilSurveys && (
              <InlineColorScale
                minPercent={minPercent}
                maxPercent={maxPercent}
                showMinMax={showMinMax}
                scale={surveyScale}
                continuous={!variationsEnabled}
                showLabel={selectedLayer === SURVEY_LAYERS.FI_SOIL_CLASS}
                decimals={coloringStrategy.tooltipDecimals}
                unit={layerConfig.unit}
                tooltip={LangFile.MapLegend.surveyTooltip}
              />
            )}

            {classificationsEnabled && (
              <DemandScale
                showAll={viewMode !== ViewModeConstants.OVERVIEW}
                isCustomClassification={surveyLayerViewCapabilities.enableCustomClassification}
                layer={selectedLayer}
                surveyClassificationValues={surveyClassificationValues}
              />
            )}
          </Box>
          <Divider />

          {showSoilSurveys && (
            <>
              {isSingleField && (
                <MapLegendToggle
                  tooltip={LangFile.MapLegend.variationsTooltip}
                  disabledTooltip={LangFile.MapLegend.disabledTooltip}
                  disabled={disableChangeVariations}
                  onToggle={onVariationsEnabledChanged}
                  primaryText={LangFile.MapLegend.variations}
                  toggled={variationsEnabled}
                />
              )}

              <MapLegendToggle
                tooltip={LangFile.MapLegend.classificationTooltip}
                disabledTooltip={LangFile.MapLegend.disabledTooltip}
                disabled={disableChangeClassification}
                onToggle={onClassificationsEnabledChanged}
                primaryText={LangFile.MapLegend.classifications}
                toggled={classificationsEnabled}
                helpText={LangFile.MapLegend.classificationHelpText}
              />

              {showSoilSurveys && viewMode === ViewModeConstants.ANALYSIS && (
                <MapLegendToggle
                  tooltip={LangFile.MapLegend.statisticsTooltip}
                  disabledTooltip={LangFile.MapLegend.disabledTooltip}
                  disabled={disableSurveys}
                  onToggle={onStatisticsEnabledChanged}
                  primaryText={LangFile.MapLegend.statistics}
                  toggled={statisticsEnabled}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

MapLegendFieldsMenu.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.any,
  selectedLayer: PropTypes.string,
  onClose: PropTypes.func,
  renderDateHandler: PropTypes.func,
  viewMode: PropTypes.string,
  showSatelliteImagery: PropTypes.bool,
  images: PropTypes.array,
  surveys: PropTypes.array,
  values: PropTypes.array,
  multipleSelections: PropTypes.array,
  enableMultipleSelect: PropTypes.bool,
  onReferencesChanged: PropTypes.func,
  handleSelectSatelliteType: PropTypes.func,
  handleSelectYieldType: PropTypes.func,
  showSoilSurveys: PropTypes.bool,
  handleSelectSurveyType: PropTypes.func,
  onVariationsEnabledChanged: PropTypes.func,
  variationsEnabled: PropTypes.bool,
  onClassificationsEnabledChanged: PropTypes.func,
  classificationsEnabled: PropTypes.bool,
  onStatisticsEnabledChanged: PropTypes.func,
  statisticsEnabled: PropTypes.bool,
  surveyClassificationValues: PropTypes.object,
  disableDemandClassification: PropTypes.bool,
  showHeightMap: PropTypes.bool,
  onSelectOtherType: PropTypes.func,
  showOtherLayers: PropTypes.bool,
  renderVariableColorScale: PropTypes.func,
};

function MapLegendWeatherMenu({
  id,
  anchorEl,
  onClose,
  networkSensor,
  onSetNetworkSensor,
  networkEnabled,
}: MapLegendWeatherMenu.propTypes) {
  const LangFile = useLangFile();
  const measureSettings = useMeasureSettings();

  if (!networkEnabled) {
    return null;
  }

  return (
    <>
      <Menu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <div>
          <WeatherNetworkMenu
            networkSensor={networkSensor}
            setNetworkSensor={onSetNetworkSensor}
            requiresFeatures={[FeatureConstants.LEGACY_WEATHER]}
          />
        </div>
      </Menu>

      <Divider />

      <Box p={1} width={'100%'} style={{ boxSizing: 'border-box' }}>
        <InlineColorScale
          scale={[...getScale(networkSensor, measureSettings)].reverse()}
          tooltip={LangFile.MapLegend.networkTooltip}
          continuous
        />
      </Box>
    </>
  );
}

MapLegendWeatherMenu.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  networkSensor: PropTypes.string,
  onSetNetworkSensor: PropTypes.func,
  networkEnabled: PropTypes.bool,
};

const MapLegend = ({
  renderDateHandler,
  enableHeightmap,
  onSetSatelliteImageType,
  onSetSurveyType,
  showSatelliteImagery,
  showSoilSurveys,
  showOtherLayers,
  variationsEnabled,
  classificationsEnabled,
  selectedLayer,
  onSetNetworkSensor,
  onVariationsEnabledChanged,
  onClassificationsEnabledChanged,
  networkEnabled,
  networkSensor,
  enableMultipleSelect,
  multipleSelections,
  onMultipleSelectChanged,
  selectedFieldHasImage,
  selectedFieldHasSurvey,
  viewMode,
  values,
  dates,
  surveys,
  onOpenFirebaseEvent,
  statisticsEnabled,
  onStatisticsEnabledChanged,
  surveyClassificationValues,
  disableDemandClassification,
  showHeightMap,
  onSelectOtherType,
  enableYield,
  selectedFieldHasYield,
  renderVariableColorScale,
}: MapLegend.propTypes) => {
  const analytics = useFirebaseAnalytics();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElWeather, setAnchorElWeather] = useState(null);
  const surveyViewCapabilities = useSurveyViewCapabilities();
  const selectedSurvey = useSelectedSurvey();

  const logSelectedLayer = useCallback(
    (layer) => {
      const isSurvey = isSurveyLayer(layer);
      const isSatellite = isSatelliteLayer(layer);

      if (viewMode === ViewModeConstants.OVERVIEW) {
        analytics.logEvent(FIREBASE_EVENTS.OVERVIEW_ML_LAYER, { layer, isSurvey, isSatellite });
      } else if (viewMode === ViewModeConstants.ANALYSIS) {
        analytics.logEvent(FIREBASE_EVENTS.ANALYSIS_ML_LAYER, { layer, isSurvey, isSatellite });
      } else if (viewMode === ViewModeConstants.PRESCRIPTION) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_ML_LAYER, { layer, isSurvey, isSatellite });
      }
    },
    [viewMode]
  );

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElWeather(null);
  };

  const handleMenuOpened = (event) => {
    setAnchorEl(event.currentTarget);
    analytics.logEvent(onOpenFirebaseEvent);
  };

  const handleOpenWeather = (event) => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_NETWORK_LAYER_SELECTOR);
    setAnchorElWeather(event.currentTarget);
  };

  const handleSelectSatelliteType = (type) => {
    logSelectedLayer(type);
    onSetSatelliteImageType(type);
    handleClose();
  };

  const handleSelectYieldType = (type) => {
    logSelectedLayer(type);
    onSelectOtherType(type);
    handleClose();
  };

  const handleSetNetworkSensor = (sensor) => {
    onSetNetworkSensor(sensor);
    handleClose();
  };

  const handleSelectSurveyType = (layer) => {
    logSelectedLayer(layer);

    if (
      classificationsEnabled &&
      !isLayerValid(layer, selectedSurvey, viewMode, surveyViewCapabilities, true, false)
    ) {
      onClassificationsEnabledChanged(false);
    }

    if (layer === SURVEY_LAYERS.ALTITUDE) {
      onVariationsEnabledChanged(true);
    } else if (
      variationsEnabled &&
      !isLayerValid(layer, selectedSurvey, viewMode, surveyViewCapabilities, false, true)
    ) {
      onVariationsEnabledChanged(false);
    }

    onSetSurveyType(layer);

    if (!enableMultipleSelect) {
      handleClose();
    }
  };

  return (
    <Box pt={0} position={'relative'} minWidth={180} maxWidth={180}>
      <Paper elevation={2} className={Styles.paper}>
        <MapLegendEmptyState
          showSatelliteImagery={showSatelliteImagery}
          networkEnabled={networkEnabled}
          showSoilSurveys={showSoilSurveys}
          showOtherLayers={showOtherLayers}
        />

        <MapLegendDropdownTitle
          controls="map-legend-fields-menu"
          selectedLayer={selectedLayer}
          showSatelliteImagery={showSatelliteImagery}
          showSoilSurveys={showSoilSurveys}
          showOtherLayers={showOtherLayers}
          networkEnabled={false}
          networkSensor={networkSensor}
          handleMenuOpened={handleMenuOpened}
        />

        <MapLegendFieldsMenu
          id={'map-legend-fields-menu'}
          anchorEl={anchorEl}
          onClose={handleClose}
          enableHeightmap={enableHeightmap}
          enableYield={enableYield}
          selectedFieldHasYield={selectedFieldHasYield}
          selectedLayer={selectedLayer}
          dates={dates}
          values={values}
          surveys={surveys}
          renderDateHandler={renderDateHandler}
          handleSelectSatelliteType={handleSelectSatelliteType}
          handleSelectSurveyType={handleSelectSurveyType}
          handleSelectYieldType={handleSelectYieldType}
          onVariationsEnabledChanged={onVariationsEnabledChanged}
          onClassificationsEnabledChanged={onClassificationsEnabledChanged}
          viewMode={viewMode}
          showSatelliteImagery={showSatelliteImagery}
          multipleSelections={multipleSelections}
          enableMultipleSelect={enableMultipleSelect}
          onReferencesChanged={onMultipleSelectChanged}
          showSoilSurveys={showSoilSurveys}
          selectedFieldHasImage={selectedFieldHasImage}
          selectedFieldHasSurvey={selectedFieldHasSurvey}
          variationsEnabled={variationsEnabled}
          classificationsEnabled={classificationsEnabled}
          statisticsEnabled={statisticsEnabled}
          onStatisticsEnabledChanged={onStatisticsEnabledChanged}
          surveyClassificationValues={surveyClassificationValues}
          disableDemandClassification={disableDemandClassification}
          showHeightMap={showHeightMap}
          onSelectOtherType={onSelectOtherType}
          showOtherLayers={showOtherLayers}
          renderVariableColorScale={renderVariableColorScale}
        />
      </Paper>
      <Box pt={1} />
      <Paper elevation={2} className={Styles.paper}>
        <MapLegendDropdownTitle
          controls="map-legend-weather-menu"
          selectedLayer={null}
          showSatelliteImagery={false}
          showSoilSurveys={false}
          networkSensor={networkSensor}
          networkEnabled={networkEnabled}
          handleMenuOpened={handleOpenWeather}
        />

        <MapLegendWeatherMenu
          id={'map-legend-weather-menu'}
          anchorEl={anchorElWeather}
          onClose={handleClose}
          networkSensor={networkSensor}
          onSetNetworkSensor={handleSetNetworkSensor}
          networkEnabled={networkEnabled}
        />
      </Paper>
    </Box>
  );
};

MapLegend.propTypes = {
  renderDateHandler: PropTypes.func,
  onSetSatelliteImageType: PropTypes.func,
  onSetSurveyType: PropTypes.func,
  showSatelliteImagery: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  showOtherLayers: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  selectedLayer: PropTypes.string,
  values: PropTypes.array,
  surveys: PropTypes.array,
  onSetNetworkSensor: PropTypes.func,
  onVariationsEnabledChanged: PropTypes.func,
  onClassificationsEnabledChanged: PropTypes.func,
  networkEnabled: PropTypes.bool,
  networkSensor: PropTypes.string,
  enableMultipleSelect: PropTypes.bool,
  multipleSelections: PropTypes.array,
  onMultipleSelectChanged: PropTypes.func,
  selectedFieldHasImage: PropTypes.bool,
  selectedFieldHasSurvey: PropTypes.bool,
  onOpenFirebaseEvent: PropTypes.oneOf([
    FIREBASE_EVENTS.OVERVIEW_ML_OPEN,
    FIREBASE_EVENTS.ANALYSIS_ML_OPEN,
  ]),
  surveyClassificationValues: PropTypes.object,
  disableDemandClassification: PropTypes.bool,
  showHeightMap: PropTypes.bool,
  onSelectOtherType: PropTypes.func,
  renderVariableColorScale: PropTypes.func,
};

MapLegend.defaultProps = {
  onSetSatelliteImageType: voidFunc,
  onSetSurveyType: voidFunc,
  onSetNetworkSensor: voidFunc,
  onVariationsEnabledChanged: voidFunc,
  onClassificationsEnabledChanged: voidFunc,
  enableMultipleSelect: false,
  multipleSelections: [],
  onMultipleSelectChanged: voidFunc,
  renderDateHandler: voidFunc,
  onSelectOtherType: voidFunc,
};

export default memo(MapLegend);
