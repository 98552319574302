import React, { memo } from 'react';
import { connect } from 'react-redux';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import NavigationMenuContainer from 'js/components/Toolbar/NavigationMenuContainer';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import Styles from './ToolbarContainer.module.less';

const mapStateToProps = (store) => {
  return {
    isDrawingField: store.addField.isDrawing,
    isImportingFields: store.addField.isImportingFields,
    isAddingFields: store.addField.isAddingFields,
    isMagicSelecting: store.note.magicWand.isSelecting,
    isDrawingNote: store.note.isDrawing,
    viewMode: store.control.viewMode,
    isPlacingMarker: store.note.isPlacingMarker,
    keyboardShortcutsEnabled: store.control.keyboardShortcutsEnabled,
  };
};

const ToolbarContainer = ({
  children,
  viewMode,
  isDrawing,
  isMagicSelecting,
  isDrawingField,
  isAddingFields,
  isImportingFields,
  isPlacingMarker,
  isDrawingNote,
  disableLayers,
  keyboardShortcutsEnabled,
}) => {
  if (
    !(viewMode === ViewModeConstants.OVERVIEW || viewMode === ViewModeConstants.ANALYSIS) ||
    isDrawing ||
    isAddingFields ||
    isImportingFields ||
    isMagicSelecting ||
    isDrawingField ||
    isPlacingMarker ||
    isDrawingNote
  ) {
    return null;
  }

  return (
    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} className={Styles.root}>
      <Box zIndex={1} className={Styles.childContainer}>
        {!disableLayers && children}
      </Box>

      <NavigationMenuContainer keyboardShortcutsEnabled={keyboardShortcutsEnabled} />
    </Box>
  );
};

ToolbarContainer.propTypes = {
  menuId: PropTypes.string,
  disableLayers: PropTypes.bool,
  keyboardShortcutsEnabled: PropTypes.bool,
};

ToolbarContainer.defaultProps = {
  disableLayers: false,
  keyboardShortcutsEnabled: true,
};

export default memo(connect(mapStateToProps)(ToolbarContainer));
