import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useEvent from 'js/hooks/useEvent';

function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(null);

  const theme = useTheme();
  const breakpoints = theme.breakpoints.values;

  const evaluateBreakpoint = useCallback(() => {
    let result = Object.keys(breakpoints)
      .reverse()
      .find((key) => {
        return window.innerWidth >= breakpoints[key];
      });

    setBreakpoint(result);
  }, []);

  useEffect(() => {
    evaluateBreakpoint();
  }, []);

  useEvent('resize', evaluateBreakpoint);

  return breakpoint;
}

export default useBreakpoint;
