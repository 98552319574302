import React, { Fragment, memo, useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { voidFunc } from '../../constants/PropTypeUtils';
import { Dialog, DialogContent, Box } from '@material-ui/core';
import StyleConstants from 'js/StyleConstants';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useLangFile } from 'js/context/LanguageContext';
import Grid from '@material-ui/core/Grid';
import { Lock, PermIdentity } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  form: {
    height: '100%',
    padding: '20px 40px',
  },
  slide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  icon: {
    color: StyleConstants.palette.primary.main,
  },
  textField: {
    margin: [[theme.spacing(1), 0]],
    width: '100%',
    color: grey['500'],
  },
  button: {
    margin: [[theme.spacing(2), 0]],
  },
  cancel: {
    fontSize: '14px',
    fontWeight: '400',
  },
  error: {
    textAlign: 'center',
    color: 'red',
    fontSize: 'small',
  },
});

const DataIntegrationPopup = ({ open, onClose, classes, onSubmit, showError, integrationName }) => {
  const LangFile = useLangFile();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      return;
    }
    handleClearContent();
    setLoading(false);
  }, [open]);

  useEffect(() => {
    if (showError) {
      setLoading(false);
    }
  }, [showError]);

  const handleUsernameChanged = useCallback((e) => {
    const newValue = e.target.value.trim();

    setUsername(newValue);
  }, []);

  const handlePasswordChanged = useCallback((e) => {
    const newValue = e.target.value.trim();

    setPassword(newValue);
  }, []);

  const handleOnSubmit = useCallback(
    (e) => {
      setLoading(true);
      e.preventDefault();
      onSubmit(username, password);
    },
    [username, password]
  );

  const handleClearContent = () => {
    setUsername('');
    setPassword('');
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <form className={classes.form} onSubmit={handleOnSubmit}>
            <div className={classes.slide}>
              <h2>
                {LangFile.DataIntegrationLoginForm.description}
                {LangFile.DataIntegrationDialog.integrations[integrationName]}
              </h2>

              <Grid container direction={'row'} wrap={'nowrap'}>
                <Grid item container xs={1} justify={'center'} alignItems={'center'}>
                  <PermIdentity className={classes.icon} />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    disabled={loading}
                    error={showError}
                    autoFocus
                    fullWidth
                    className={'remove-autofill-color'}
                    classes={{ root: classes.textField }}
                    variant={'outlined'}
                    placeholder={LangFile.DataIntegrationLoginForm.username}
                    value={username}
                    onChange={handleUsernameChanged}
                  />
                </Grid>
              </Grid>

              <Grid container direction={'row'} wrap={'nowrap'}>
                <Grid item container xs={1} justify={'center'} alignItems={'center'}>
                  <Lock className={classes.icon} />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    disabled={loading}
                    fullWidth
                    error={showError}
                    className={'remove-autofill-color'}
                    classes={{ root: classes.textField }}
                    variant={'outlined'}
                    type="password"
                    placeholder={LangFile.LoginForm.password}
                    label={''}
                    value={password}
                    onChange={handlePasswordChanged}
                    onKeyDown={(e) => (e.key === 'Enter' ? handleOnSubmit(e) : '')}
                  />
                </Grid>
              </Grid>
              {showError && (
                <Grid container direction={'row'} wrap={'nowrap'}>
                  <Grid item xs={12}>
                    <div className={classes.error}>{LangFile.DataIntegrationDialog.loginError}</div>
                  </Grid>
                </Grid>
              )}
              <Grid container direction={'row'} wrap={'nowrap'}>
                <Grid item xs={12}>
                  <Button
                    disabled={loading}
                    type="submit"
                    onKeyDown={(e) => (e.key === 'Enter' ? handleOnSubmit(e) : '')}
                    fullWidth
                    className={classes.button}
                    variant={'contained'}
                    color={'primary'}>
                    {LangFile.LoginForm.login}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
          <Box pt={0.5} align={'center'}>
            <Button onClick={onClose} disableRipple className={classes.cancel}>
              {LangFile.DataIntegrationDialog.cancel}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DataIntegrationPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  intergration: PropTypes.object,
  onSubmit: PropTypes.func,
  showError: PropTypes.bool,
  integrationName: PropTypes.string,
};

DataIntegrationPopup.defaultProps = {
  onClose: voidFunc,
  onSubmit: voidFunc,
  open: false,
  intergration: {},
  showError: false,
};
export default memo(withStyles(styles)(DataIntegrationPopup));
