import React, { memo } from 'react';

import Portal from '@material-ui/core/Portal';

import styles from 'js/components/Prescription/FullScreenPortalPaper.module.less';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const FullScreenPortalPaper = ({ children }) => {
  return (
    <Portal>
      <Box className={styles.Root}>
        <Paper elevation={1} className={styles.Paper} square={true}>
          {children}
        </Paper>
      </Box>
    </Portal>
  );
};

FullScreenPortalPaper.propTypes = {};

FullScreenPortalPaper.defaultProps = {};

export default memo(FullScreenPortalPaper);
