// @flow

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useLangFile } from 'js/context/LanguageContext';
import List from '@material-ui/core/List';
import { voidFunc } from 'js/constants/PropTypeUtils';

import SubList from './SubList';
import { getLayerConfig } from 'js/components/Prescription/PrescriptionUtils';
import { ListItemSecondaryAction } from '@material-ui/core';
import MapLegendLayerListItem from '../MapLegend/MapLegendLayerListItem';

const SatelliteLayerList = ({
  items,
  selectedLayer,
  onLayerChanged,
  onReferencesChanged,
  enableMultipleSelect,
  multipleSelections,
  disableAll,
}) => {
  const LangFile = useLangFile();

  const isLayerSelected = useCallback(
    (layerType) => {
      if (layerType === selectedLayer) {
        return true;
      }

      if (enableMultipleSelect) {
        return multipleSelections.includes(layerType);
      } else {
        return layerType === selectedLayer;
      }
    },
    [selectedLayer, enableMultipleSelect, multipleSelections]
  );

  const handleOnClick = useCallback(
    (layer) => {
      if (layer !== selectedLayer) {
        onLayerChanged(layer);

        if (enableMultipleSelect) {
          if (multipleSelections.includes(layer)) {
            onReferencesChanged(multipleSelections.filter((item) => item !== layer));
          }
        }
      }
    },
    [selectedLayer, onLayerChanged, enableMultipleSelect]
  );

  const handleOnCheckboxClick = useCallback(
    (layer) => {
      if (enableMultipleSelect) {
        let layers = [...multipleSelections];

        if (layers.includes(layer)) {
          layers = layers.filter((item) => item !== layer);
        } else {
          layers.push(layer);
        }

        onReferencesChanged(layers);
      }
    },
    [selectedLayer, onLayerChanged]
  );

  return (
    <List dense disablePadding>
      <SubList
        title={LangFile.SatelliteLayerList.title}
        items={items}
        renderItem={(layer) => (
          <MapLegendLayerListItem
            layer={layer}
            disabled={disableAll}
            enableMultipleSelect={enableMultipleSelect && layer !== selectedLayer}
            isSelected={isLayerSelected(layer)}
            onCheckboxClick={handleOnCheckboxClick}
            onClick={handleOnClick}
            renderListItemSecondayAction={() => (
              <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
                <img
                  src={getLayerConfig(layer).icon}
                  style={{ width: 25, height: 25, marginRight: -8 }}
                  alt={''}
                />
              </ListItemSecondaryAction>
            )}
          />
        )}
      />
    </List>
  );
};

SatelliteLayerList.propTypes = {
  items: PropTypes.array,
  selectedLayer: PropTypes.string,
  onLayerChanged: PropTypes.func,
  onReferencesChanged: PropTypes.func,
  enableMultipleSelect: PropTypes.bool,
  multipleSelections: PropTypes.array,
  disableAll: PropTypes.bool,
};

SatelliteLayerList.defaultProps = {
  items: [],
  onLayerChanged: voidFunc,
  onReferencesChanged: voidFunc,
};

export default memo(SatelliteLayerList);
