'use strict';

export default {
  FARM_WEATHER_STATION_ALIAS: (deviceId) => 'weather.device.' + deviceId + '.alias',
  FARM_WEATHER_STATION_LOCATION: (deviceId) => 'weather.device.' + deviceId + '.location',
  USER_GDPR_CONSENT: 'privacy.gdpr.consent',
  WHAT_IS_NEW_WEATHER_2018: 'whatsnew.weather2018',
  SINGLE_FIELD_ANALYSIS: 'analysis.field.single',
  FARM_NAME: 'farm.name',
};

export const isGdprValid = (gdpr) => {
  if (typeof gdpr === 'object') {
    return true;
  } else {
    return gdpr && (gdpr.indexOf('""') !== -1 || gdpr.indexOf('":"') !== -1);
  }
};
