import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const SurveyStatisticsDataTable = ({ columns, items }) => {
  return (
    <Box>
      <Table className={'statistics-table'}>
        <TableHead key={'header'}>
          <TableRow key={'headerRow'}>
            {columns.map((column, index) => (
              <TableCell
                key={'headerCell' + index}
                align="left"
                padding="none"
                style={{ width: '33%' }}
              >
                {column.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key={'body'}>{items}</TableBody>
      </Table>
    </Box>
  );
};

SurveyStatisticsDataTable.propTypes = {
  columns: PropTypes.array,
  items: PropTypes.array,
};

export default memo(SurveyStatisticsDataTable);
