import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import clsx from 'clsx';
import { grey, orange } from '@material-ui/core/colors';

const styles = (theme) => ({
  warningStyles: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${orange['A400']} !important`,
    },
  },
  emptyStyles: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray !important',
    },
  },
  formGroup: {
    padding: theme.spacing(1),
    position: 'relative',
    marginTop: 0,
    marginBottom: 48,
    boxSizing: 'border-box',
    '&.disablePadding': {
      padding: 0,
    },
  },
  errorWrapper: {
    position: 'absolute',
    bottom: -theme.spacing(2),
    left: theme.spacing(2),
    minHeight: 12,
    '& p': {
      color: '#f44336',
    },
  },
  warningWrapper: {
    position: 'absolute',
    bottom: -theme.spacing(2),
    left: theme.spacing(2),
    minHeight: 12,
    '& p': {
      color: orange['A400'],
    },
  },
  helperText: {
    bottom: 0,
    transform: 'translateY(90%)',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  label: {
    fontSize: '1rem',
  },
  textInput: {
    fontSize: '1rem',
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    title: {
      fontSize: '1rem',
    },
    label: {
      fontSize: '0.75rem',
    },
  },
});

export const TEXT_FORM_STATE = {
  EMPTY: 'EMPTY',
  VALID: 'VALID',
  WARNING: 'WARNING',
  INVALID: 'INVALID',
};

const TextFormStateControl = ({
  classes,
  className,
  children,
  value,
  variant,
  defaultValue,
  onError,
  onChange,
  disablePadding,
  title,
  label,
  placeholder,
  autoFocus,
  titleClassName,
  onUpdate,
  type,
  endAdornment,
  currentState,
  helperText,
  errorStates,
  isDisabled,
  ...rest
}) => {
  const textField = useRef(null);
  const [currentValue, setCurrentValue] = useState(defaultValue || '');
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (value !== currentValue || !updated) {
      setCurrentValue(isValid(currentState(value)) ? value : defaultValue || '');
      setUpdated(true);
    }
  }, [value]);

  useEffect(() => {
    onError(!isValid(currentState(currentValue)));
  }, [currentValue, onError]);

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (textField.current) {
          textField.current.blur();
        }
      }
    },
    [textField.current]
  );

  const handleChange = useCallback(
    (val) => {
      setCurrentValue(val);

      if (typeof onChange === 'function') {
        onChange(val);
      }
    },
    [onChange]
  );

  const handleUpdate = useCallback(() => {
    if (type === 'number') {
      if (Number(currentValue) === Number(value)) {
        return;
      }
    } else {
      if (currentValue.trim() === '' && defaultValue) {
        setCurrentValue(defaultValue);
        onUpdate(defaultValue);
        return;
      }

      if (currentValue === value) {
        return;
      }
    }

    onUpdate(type === 'number' ? Number(currentValue) : currentValue.trim());
  }, [type, value, currentValue, defaultValue]);

  const renderEndAdornment = () => {
    let adornment = endAdornment;

    if (!adornment) {
      return null;
    }

    return (
      <InputAdornment
        position="end"
        disablePointerEvents
        className={classes.textInput}
        style={{ whiteSpace: 'nowrap' }}>
        {adornment}
      </InputAdornment>
    );
  };

  const isValid = (current) => {
    if (current === null) {
      return false;
    }
    return !errorStates.some((state) => state === current.state);
  };

  const showError = (current) => {
    if (current === null) {
      return true;
    }
    if (current.state === TEXT_FORM_STATE.WARNING) {
      return true;
    }
    return !isValid(current);
  };

  const getStyle = (current) => {
    if (current.state === TEXT_FORM_STATE.WARNING) {
      return classes.warningStyles;
    } else if (current.state === TEXT_FORM_STATE.EMPTY) {
      return classes.emptyStyles;
    }
    return null;
  };

  const getErrorText = (current) => {
    return current.message;
  };

  const current = currentState(currentValue);
  const error = showError(current);
  const errorText = getErrorText(current);

  return (
    <FormControl
      component="fieldset"
      className={`${className} ${classes.formGroup} ${disablePadding ? 'disablePadding' : ''}`}
      fullWidth
      disabled={isDisabled}
      margin={'dense'}>
      {title && (
        <Typography className={`${classes.title} ${titleClassName}`} variant={'h6'}>
          {title}
        </Typography>
      )}
      {label && (
        <FormLabel component="label" className={classes.label}>
          {label}
        </FormLabel>
      )}
      <FormGroup>
        <TextField
          {...rest}
          disabled={isDisabled}
          className={getStyle(current)}
          error={error}
          autoFocus={autoFocus}
          value={currentValue}
          placeholder={`${placeholder || defaultValue}`}
          inputProps={{
            onKeyUp: onKeyUp,
            ref: textField,
          }}
          InputProps={{
            endAdornment: renderEndAdornment(),
            classes: { input: classes.textInput },
          }}
          onChange={(event) => handleChange(event.target.value)}
          onBlur={handleUpdate}
          type={type}
          margin="dense"
          variant={variant}></TextField>
      </FormGroup>
      {children}
      <div
        className={clsx({
          [classes.warningWrapper]: current.state === TEXT_FORM_STATE.WARNING,
          [classes.errorWrapper]: current.state !== TEXT_FORM_STATE.WARNING,
          [classes.helperText]: true,
        })}>
        {helperText && (
          <FormHelperText filled={true} style={{ color: grey['700'] }}>
            {helperText}
          </FormHelperText>
        )}
        {error && errorText && <FormHelperText>{errorText}</FormHelperText>}
      </div>
    </FormControl>
  );
};

export default memo(withStyles(styles)(TextFormStateControl));

TextFormStateControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.string,
  type: PropTypes.string,
  disablePadding: PropTypes.bool,
  titleClassName: PropTypes.string,
  currentState: PropTypes.func,
  errorStates: PropTypes.arrayOf(String),
  helperText: PropTypes.string,
  isDisabled: PropTypes.bool,
};

TextFormStateControl.defaultProps = {
  type: 'text',
  onError: () => {},
  onChange: () => {},
  variant: 'outlined',
  isDisabled: false,
};
