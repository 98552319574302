import * as turf from '@turf/turf';
import simplify from 'simplify-js';

function mapToRange(minVal, maxVal, range, pos) {
  return minVal + ((maxVal - minVal) / range) * pos;
}

function latLngToPixel(upperLeftLatLng, lowerRightLatLng, pixelWidth, pixelHeight, latLng) {
  const latMin = upperLeftLatLng[0];
  const latRange = lowerRightLatLng[0] - latMin;
  const lngMin = lowerRightLatLng[1];
  const lngRange = upperLeftLatLng[1] - lngMin;
  const latPartial = (latLng[0] - latMin) / latRange;
  const lngPartial = (latLng[1] - lngMin) / lngRange;

  const x = Math.floor(lngPartial * pixelWidth);
  const y = Math.floor(latPartial * pixelHeight);
  return [x, y];
}

function connectedPixels(data, row, col, color, threshold) {
  const surroundingPixels = (posString) => {
    let pos = posString.split('_');
    pos = pos.map((element) => Number(element));

    const above = [pos[0] + 1, pos[1]];
    const below = [pos[0] - 1, pos[1]];
    const left = [pos[0], pos[1] - 1];
    const right = [pos[0], pos[1] + 1];

    let res = [above, below, left, right];
    res = res.filter(
      (element) =>
        element[0] >= 0 &&
        element[0] < data.length &&
        element[1] >= 0 &&
        element[1] < data[0].length
    );

    return res;
  };

  let finished = false;
  let matches = new Set([row + '_' + col]);
  let found = new Set([row + '_' + col]);

  while (!finished) {
    let newMatches = new Set();
    for (let m of matches) {
      let pixels = surroundingPixels(m);
      for (let p of pixels) {
        const entry = p[0] + '_' + p[1];
        const r = data[p[0]][p[1]][0][0];
        const g = data[p[0]][p[1]][0][1];
        const b = data[p[0]][p[1]][0][2];
        const match =
          r >= color[0][0] - threshold &&
          r <= color[0][0] + threshold &&
          g >= color[0][1] - threshold &&
          g <= color[0][1] + threshold &&
          b >= color[0][2] - threshold &&
          b <= color[0][2] + threshold;
        if (match && !found.has(entry)) {
          newMatches.add(entry);
          found.add(entry);
        }
      }
    }

    if (newMatches.size === 0) {
      finished = true;
    }
    matches = newMatches;
  }

  return [...found].map((posString) => {
    let pos = posString.split('_');
    pos = pos.map((element) => Number(element));
    return pos;
  });
}

export default function magic(bounds, fieldPolygon, url, threshold, latLng) {
  return new Promise((resolve, reject) => {
    const borderPoints = fieldPolygon.coordinates[0].map((p) => [p[0], p[1]]);
    const border = turf.polygon([borderPoints]);

    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    var img = document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.src = url + '?' + new Date().getTime(); // append a timestamp to avoid browser caching of images, since it will fuck up cross-origin loading of images.

    img.onload = function () {
      const c = document.createElement('canvas');
      c.width = img.width;
      c.height = img.height;
      const context = c.getContext('2d');
      context.drawImage(img, 0, 0, c.width, c.height);

      const data = context.getImageData(0, 0, c.width, c.height).data;

      // Datamatrix is a matrix with shape [row][col][channel]
      const dataMatrix = [];
      for (let i = 0; i < data.length; i = i + 4) {
        // Insert a new row
        if ((i / 4) % c.width === 0) {
          dataMatrix.push([]);
        }

        // Find the current row we are adding to
        const y = dataMatrix.length - 1;

        // Add the color to the current row
        dataMatrix[y].push([[data[i], data[i + 1], data[i + 2], data[i + 3]]]);
      }
      const rowCol = latLngToPixel(
        [ne.lat(), ne.lng()],
        [sw.lat(), sw.lng()],
        img.width,
        img.height,
        [latLng.lat(), latLng.lng()]
      );

      const color = dataMatrix[rowCol[1]][rowCol[0]];

      const res = connectedPixels(dataMatrix, rowCol[1], rowCol[0], color, threshold);
      const coords = [];

      for (let p of res) {
        const yInc = mapToRange(ne.lat(), sw.lat(), c.height, p[0]);
        const xInc = mapToRange(sw.lng(), ne.lng(), c.width, p[1]);
        coords.push({ x: xInc, y: yInc });
      }

      const turfPoints = coords
        .map((s) => turf.point([s.y, s.x]))
        .filter((p) => turf.inside(turf.flip(p), border));

      const turfPoly = turf.featureCollection(turfPoints);
      const turfPolygonPoints = turf.convex(turfPoly, { concavity: 0.1 });

      if (turfPolygonPoints) {
        const mappedData = turfPolygonPoints.geometry.coordinates[0].map((coord) => ({
          x: coord[0],
          y: coord[1],
        }));
        const testing = simplify(mappedData, 0.000000001);
        const simplifiedPolyPaths = testing
          .map((coord) => new google.maps.LatLng(coord.x, coord.y))
          .slice(1);
        resolve(simplifiedPolyPaths);
      } else {
        reject(new Error('Invalid magic selection'));
      }
    };
  });
}
