import React, { useContext } from 'react';

const GraphHoverContext = React.createContext({});

export const GraphHoverProvider = GraphHoverContext.Provider;
export const GraphHoverConsumer = GraphHoverContext.Consumer;

export function useGraphHover() {
  return useContext(GraphHoverContext);
}
