// @flow

import React, { memo, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { formatSeason } from 'js/helpers/SeasonUtils';
import { useLangFile } from 'js/context/LanguageContext';
import Styles from './SeasonSelector.module.less';
import { useSeasonContext } from 'js/context/SeasonContext';
import Typography from '@material-ui/core/Typography';
import { AddCircle, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import {
  ButtonBase,
  ClickAwayListener,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SeasonSelectorListItem from './SeasonSelectorListItem';
import Season from '../../model/Season';

import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MUIAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const Accordion = withStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },
  expanded: {},
  disabled: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MUIAccordionSummary);

const SeasonSelector = (props: SeasonSelector.propTypes) => {
  const LangFile = useLangFile();
  const { seasons, selectedSeason } = useSeasonContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showArchivedSeasons, setShowArchivedSeasons] = useState(false);
  const divRef = useRef();
  const open = Boolean(anchorEl);

  const handleClick = useCallback(() => {
    setAnchorEl(divRef.current);
  }, [anchorEl]);

  const handleListItemClick = useCallback(
    (seasonId) => {
      props.onChange(seasonId);
    },
    [props.onChange]
  );

  const handleListItemEditClick = useCallback(
    (season) => {
      handleClickAway();
      props.onEdit(season);
    },
    [props.onEdit]
  );

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleAddNewClick = useCallback(() => {
    handleClickAway();
    props.onAddNew();
  }, [props.onAddNew]);

  const handleRestoreSeasonClick = useCallback(
    (season) => {
      props.handleRestoreSeasonClick(season);
    },
    [props.handleRestoreSeasonClick]
  );

  if (!selectedSeason) {
    return null;
  }

  let enabledSeasons = seasons
    .filter((season: Season) => season.enabled)
    .sort((a, b) => formatSeason(b, LangFile).localeCompare(formatSeason(a, LangFile)));
  let archivedSeasons = seasons
    .filter((season: Season) => !season.enabled)
    .sort((a, b) => formatSeason(b, LangFile).localeCompare(formatSeason(a, LangFile)));

  const SeasonsCustomPopper = () =>
    open && ( // having the "open" check here prevents evaluation of the remaining JSX if it's false
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          style={{ zIndex: 1300, minWidth: 300 }}
          open={true}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <Paper>
            <Box className={Styles.PopoverContainer}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box fontSize={'0.9rem'} display={'flex'} alignItems={'center'}>
                  {LangFile.SeasonSelector.seasons}
                </Box>

                {!props.selectOnly && (
                  <Button
                    disableRipple
                    className={Styles.AddNewButton}
                    onClick={handleAddNewClick}
                    color="primary"
                    component="span"
                  >
                    <Box fontSize={'0.9rem'}>{LangFile.SeasonSelector.addNew}</Box>
                    <Box width={'4px'} />
                    <AddCircle fontSize={'small'} />
                  </Button>
                )}
              </Box>
              <List className={Styles.List} dense={true}>
                {enabledSeasons.map((season: Season) => {
                  return (
                    <SeasonSelectorListItem
                      key={season.id}
                      season={season}
                      isSelected={season.id === selectedSeason.id}
                      disableEdit={props.selectOnly}
                      onClick={handleListItemClick}
                      onEditClick={handleListItemEditClick}
                    />
                  );
                })}
              </List>

              {!props.selectOnly && (
                <Accordion
                  square
                  expanded={showArchivedSeasons}
                  disabled={archivedSeasons.length === 0}
                  onChange={() => setShowArchivedSeasons(!showArchivedSeasons)}
                  elevation={0}
                  className={Styles.Accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{ root: Styles.AccordionSummaryRoot }}
                  >
                    <Box fontSize={'0.9rem'} color={grey['500']}>
                      {LangFile.SeasonSelector.archivedSeasons} (
                      {seasons.filter((season: Season) => !season.enabled).length})
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={Styles.AccordionDetails}>
                    <List className={Styles.List} dense>
                      {archivedSeasons.map((season: Season) => {
                        return (
                          <ListItem dense key={season.id}>
                            <ListItemText
                              disableTypography
                              primary={formatSeason(season, LangFile)}
                            />
                            <ListItemSecondaryAction className={Styles.restoreButton}>
                              <Button
                                size={'small'}
                                color={'primary'}
                                onClick={() => handleRestoreSeasonClick(season)}
                              >
                                {LangFile.SeasonSelector.restore}
                              </Button>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Paper>
        </Popper>
      </ClickAwayListener>
    );

  return (
    <>
      <ButtonBase>
        <Box ref={divRef} display="flex" onClick={handleClick} className={Styles.root} p={0.5}>
          <Typography>{formatSeason(selectedSeason, LangFile)}</Typography>
          <Box pl={1} />
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Box>
      </ButtonBase>
      <SeasonsCustomPopper />
    </>
  );
};

SeasonSelector.propTypes = {
  color: PropTypes.oneOf(['white', 'black']),
  selectOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onAddNew: PropTypes.func,
  onEdit: PropTypes.func,
  handleRestoreSeasonClick: PropTypes.func,
};

SeasonSelector.defaultProps = {
  color: 'black',
};

export default memo(SeasonSelector);
