import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import { useLangFile } from '../../../context/LanguageContext';
import DateHandler from '../../DateHandler/DateHandler';
import SelectSeasonFormControl from '../../UI-Elements/SelectSeasonFormControl';
import { setCurrentJob } from 'js/reducers/PrescriptionReducer';
import { setYieldColorIntervals, YIELD_LAYERS } from '../../../constants/YieldLayer';
import moment from 'moment-timezone';

const getLatestImage = (yieldImages: Array) => {
  const sortedYieldInfo = yieldImages.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  return sortedYieldInfo[0];
};

const YieldSelector = ({ setImageURL, setVariableColorScaleObject, setReferenceValues }) => {
  const LangFile = useLangFile();
  const { prescriptionJob } = usePrescriptionJob();
  const images = [...prescriptionJob.assets[YIELD_LAYERS.YIELD].yieldImages];

  const [seasonId, setSeasonId] = useState(prescriptionJob.seasonId);
  const [seasons, setSeasons] = useState([prescriptionJob.seasonId]);
  const [dates, setDates] = useState();
  const [date, setDate] = useState();

  const setPrescriptionJob = useCallback((changes, recalculate) => {
    dispatch(setCurrentJob({ ...changes, saved: false }, recalculate));
  });

  useEffect(() => {
    if (
      !prescriptionJob ||
      !prescriptionJob.assets ||
      !prescriptionJob.assets[YIELD_LAYERS.YIELD]
    ) {
      return;
    }

    const groupedBySeason = Map.groupBy(images, ({ seasonId }) => seasonId);
    const seasonIds = [...groupedBySeason.keys()];
    setSeasons(seasonIds);
    const selectedSeason = Math.max(...Object.values(seasonIds));
    setDeafualtDateForSeason(selectedSeason);
    setSeasonId(selectedSeason);
  }, []);

  useEffect(() => {
    if (
      !seasonId ||
      !date ||
      !prescriptionJob ||
      !prescriptionJob.assets ||
      !prescriptionJob.assets[YIELD_LAYERS.YIELD]
    ) {
      return;
    }

    const image = images.find((i) => i.seasonId == seasonId && i.date == date);

    if (!image) {
      return;
    }

    setYieldColorIntervals(image.colorIntervals);
    setImageURL(image.url);
    setReferenceValues(image.values);
    const intervals = image.colorIntervals.sort((a, b) => a.min - b.min);
    setVariableColorScaleObject(intervals);
  }, [prescriptionJob, date]);

  const setDeafualtDateForSeason = (seasonId) => {
    const seasonImages = images.filter((i) => i.seasonId == seasonId);
    const yieldDates = seasonImages.map((i) => i.date);
    setDates(yieldDates);
    const latestSeasonImage = getLatestImage(seasonImages);
    setDate(latestSeasonImage.date);
  };

  const handleYieldDateSet = useCallback((date) => {
    const dateComponent = moment(date).format('YYYY-MM-DD');
    setDate(dateComponent);
  }, []);

  const handleSeasonChanged = useCallback(
    (seasonId) => {
      setDeafualtDateForSeason(seasonId);
      setSeasonId(seasonId);
    },
    [prescriptionJob.assets]
  );

  return (
    <>
      <SelectSeasonFormControl
        value={seasonId}
        onChange={handleSeasonChanged}
        fieldId={prescriptionJob.fieldId}
        customSeasons={seasons}
      />
      {<DateHandler date={date} dates={dates} handleDateSet={handleYieldDateSet} />}
    </>
  );
};

YieldSelector.propTypes = {
  setImageURL: PropTypes.func,
  setVariableColorScaleObject: PropTypes.func,
  setReferenceValues: PropTypes.func,
};

YieldSelector.defaultProps = {};

export default YieldSelector;
