'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let prop = { props, viewBox: '0, 0, 28, 28' };
  return (
    <SvgIcon {...prop}>
      <g transform="translate(0 0)">
        <path
          d="M32.905,14.026a13.177,13.177,0,0,0-16.942-1.711A13.94,13.94,0,0,0,11.02,29.009a13.354,13.354,0,0,0,15.014,8.224,13.669,13.669,0,0,0,10.8-13.484A13.83,13.83,0,0,0,32.905,14.026ZM23.417,36.621A12.735,12.735,0,0,1,10.855,23.749,12.735,12.735,0,0,1,23.417,10.877,12.735,12.735,0,0,1,35.979,23.749,12.735,12.735,0,0,1,23.417,36.621Z"
          transform="translate(-9.998 -9.998)"
        />
        <path
          d="M23.619,11.914a11.866,11.866,0,0,0-11.7,11.994A11.866,11.866,0,0,0,23.619,35.9a11.866,11.866,0,0,0,11.7-11.994A11.866,11.866,0,0,0,23.619,11.914Zm0,23.111A10.985,10.985,0,0,1,12.77,23.908,10.985,10.985,0,0,1,23.619,12.792,10.985,10.985,0,0,1,34.468,23.908,11,11,0,0,1,23.619,35.025Z"
          transform="translate(-10.2 -10.158)"
        />
        <path
          d="M31.659,19.657l-.017-.035a.46.46,0,0,0-.025-.039,9.059,9.059,0,0,0-3.186-3.265.421.421,0,0,0-.038-.026l-.033-.017a8.68,8.68,0,0,0-8.812,0l-.033.017-.038.026a9.059,9.059,0,0,0-3.186,3.265.434.434,0,0,0-.025.039c-.006.011-.012.023-.017.035a9.279,9.279,0,0,0,.02,9.064.429.429,0,0,0,.637.127l1.182-.7a.444.444,0,0,0,.008-.77.42.42,0,0,0-.437.01L16.8,27.9a8.364,8.364,0,0,1-.86-3.285h.984a.44.44,0,0,0,0-.877h-.984a8.315,8.315,0,0,1,.86-3.285l.854.506a.423.423,0,0,0,.572-.168.445.445,0,0,0-.144-.592l-.853-.505a8.188,8.188,0,0,1,2.346-2.4l.493.874a.424.424,0,0,0,.752.009.448.448,0,0,0-.01-.448l-.494-.875a7.812,7.812,0,0,1,3.206-.881v1.008a.428.428,0,0,0,.856,0V15.964a7.812,7.812,0,0,1,3.206.881l-.494.875a.448.448,0,0,0-.01.448.424.424,0,0,0,.752-.009l.493-.874a8.189,8.189,0,0,1,2.346,2.4l-.853.505a.444.444,0,0,0-.008.77.42.42,0,0,0,.437-.01l.854-.506a8.315,8.315,0,0,1,.86,3.285h-.984a.426.426,0,0,0-.387.213.448.448,0,0,0,0,.451.426.426,0,0,0,.387.213h.984A8.387,8.387,0,0,1,31.1,27.9l-.855-.506a.42.42,0,0,0-.437-.01.444.444,0,0,0,.008.77l1.236.733a.419.419,0,0,0,.325.044.429.429,0,0,0,.26-.2,9.286,9.286,0,0,0,.017-9.063Z"
          transform="translate(-10.533 -10.421)"
        />
        <path
          d="M27,22.067a.423.423,0,0,0-.582.17l-.661,1.215a1.264,1.264,0,0,0-1.131.373,1.337,1.337,0,0,0-.347,1.165l-3.549,2.1a.444.444,0,0,0-.008.77.42.42,0,0,0,.437-.01l3.548-2.1a1.261,1.261,0,0,0,1.158.276,1.3,1.3,0,0,0,.882-.817l1.358.021h.006a.434.434,0,0,0,.428-.432A3.081,3.081,0,0,0,27,22.069ZM25.54,25.188a.429.429,0,0,1-.4-.286.446.446,0,0,1,.121-.485.42.42,0,0,1,.485-.054h0a.441.441,0,0,1,.22.383s0,0,0,.005a.434.434,0,0,1-.426.437Zm1.221-.858a1.325,1.325,0,0,0-.254-.449l.432-.795a2.205,2.205,0,0,1,.708,1.257Z"
          transform="translate(-11.105 -10.999)"
        />
        <path
          d="M26.267,33.966H23.281a.44.44,0,0,0,0,.877h2.986a.44.44,0,0,0,0-.877Z"
          transform="translate(-11.355 -11.996)"
        />
        <path
          d="M25.764,32.929a.44.44,0,0,0,0-.877H23.919a.44.44,0,0,0,0,.877Z"
          transform="translate(-11.422 -11.836)"
        />
      </g>
    </SvgIcon>
  );
};
