'use strict';

import moment from 'moment-timezone';

import LanguageConstants from 'js/constants/LanguageConstants';

import { getBrowserLanguage } from 'js/helpers/LanguageUtils';

/* ============== //
 ||   CONSTANTS   ||
 // ============== */

export const DATE_FORMAT_LONG_DANISH = {
  picker: 'D. MMMM YYYY',
  standard: 'D. MMMM YYYY',
};

export const DATE_FORMAT_LONG_ENGLISH = {
  picker: 'MMMM D, YYYY',
  standard: 'MMMM D, YYYY',
};

/* ============== //
 ||     TYPES     ||
 // ============== */

export const SET_APP_LANGUAGE = 'fieldsense/LanguageReducer/SET_APP_LANGUAGE';

/* ============== //
 ||    ACTIONS    ||
 // ============== */

export function setAppLanguage(language) {
  return {
    type: SET_APP_LANGUAGE,
    payload: language,
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const defaultLang = getBrowserLanguage();

const initState = {
  language: defaultLang,
  dateFormatLong: DATE_FORMAT_LONG_DANISH,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SET_APP_LANGUAGE: {
      let language = action.payload;
      let dateFormatLong =
        language === LanguageConstants.DANISH ? DATE_FORMAT_LONG_DANISH : DATE_FORMAT_LONG_ENGLISH;
      state = { ...state, language: language, dateFormatLong: dateFormatLong };
      break;
    }

    default:
      break;
  }

  moment.locale(state.language);

  return state;
}
