import React from 'react';
import { Landscape } from '@material-ui/icons';
import type { LAYER_CONFIG } from '../model/surveys/SurveyLayerConfig';
import type { LanguageFile } from '../helpers/LanguageUtils';
import NumberUtils from 'js/helpers/NumberUtils';

export const YIELD_LAYER = {
  YIELD: 'YIELD',
};

export const YIELD_LAYER_LIST = Object.freeze([YIELD_LAYER.YIELD]);

export const YIELD_LAYERS = Object.freeze({
  YIELD: 'YIELD',
});

let YIELD_COLOR_INTERVALS;

export const setYieldColorIntervals = (interval) => {
  YIELD_COLOR_INTERVALS = interval;
};

export const getYieldColorIntervals = () => {
  return YIELD_COLOR_INTERVALS;
};

export const getYieldLayerConfig = (layer: YieldLayer): LAYER_CONFIG => {
  // VALUE MAPPING

  const minValue = 0;
  const maxValue = 1;

  const mapValue = (val, outMin, outMax) => {
    return NumberUtils.limitMap(val, minValue, maxValue, outMin, outMax);
  };

  const reverseMapValue = (val, inMin, inMax) => {
    return NumberUtils.limitMap(val, inMin, inMax, minValue, maxValue);
  };

  return {
    getName: (LangFile: LanguageFile) => LangFile.YieldLayer[layer],
    mapValue: mapValue,
    reverseMapValue: reverseMapValue,
    getShorthand: (LangFile: LanguageFile) => null,
  };
};

export type YieldLayer = $Keys<typeof YIELD_LAYER>;

export const DATA_INTEGRATION_YIELD = 'exatrek';
