import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  GridList,
  GridListTile,
  GridListTileBar,
  Paper,
} from '@material-ui/core';

import grey from '@material-ui/core/colors/grey';

const styles = (theme) => ({
  title: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  tileEnabled: {
    padding: 4,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'black',
    },
  },
  tileDisabled: {
    padding: 4,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  tileCta: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '16pt',
    fontWeight: 500,
    color: 'white',
    userSelect: 'none',
  },
  backgroundDiv: {
    width: 'calc(100%)',
    height: '100%',
    backgroundColor: grey['300'],
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  titleBar: {
    background: 'none',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  icon: {
    color: 'rgb(255,255,255)',
    padding: 12,
    userSelect: 'none',
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    root: {
      padding: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
    },
  },
});

const MultipleSelectDialog = ({ classes, options, open, actions, callToAction, children }) => {
  const renderOption = (option, index) => {
    let { icon, cta, title, subtitle, onClick, color, img, enabled } = option;

    let image = enabled && img ? <img src={img} alt={title} /> : null;
    let actionIcon = enabled && icon ? <div className={classes.icon}>{icon}</div> : null;
    let clickHandler = enabled ? onClick : () => {};

    let style = {};
    if (enabled && color) {
      style.backgroundColor = color;

      if (image) {
        style.opacity = 0.3;
      }
    }

    return (
      <GridListTile
        className={enabled ? classes.tileEnabled : classes.tileDisabled}
        key={`(${index} - ${option.title})`}
        onClick={clickHandler}
        cols={1}
      >
        <Paper className={classes.backgroundDiv} style={style} elevation={enabled ? 2 : 0} square>
          <div className={classes.tileCta}>{cta}</div>
        </Paper>
        {image}
        <GridListTileBar
          className={classes.titleBar}
          title={title}
          subtitle={subtitle}
          actionIcon={actionIcon}
        />
      </GridListTile>
    );
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={Boolean(open)}>
      <DialogTitle className={classes.title}>{callToAction}</DialogTitle>
      <DialogContent className={classes.root}>
        <GridList
          cellHeight={160}
          spacing={8}
          cols={2}
          className={classes.gridList}
          style={{ margin: 0 }}
        >
          {options !== undefined ? options.map(renderOption) : null}
        </GridList>
        {children}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

MultipleSelectDialog.propTypes = {
  /** An array of option-objects that define each cell in the grid */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** The icon object to display above the title */
      icon: PropTypes.object.isRequired,
      /** The cta to display near the top of the tile */
      cta: PropTypes.string.isRequired,
      /** The title to display on the button */
      title: PropTypes.string.isRequired,
      /** Text to display below the title */
      subtitle: PropTypes.string,
      /** The callback to handle clicks on the option */
      onClick: PropTypes.func.isRequired,
      /** An optional image to show behind the title bar*/
      img: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** An optional color to show behind the title bar*/
      color: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** Controls whether or not the option will be selectable */
      enabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  /** Controls whether or not to show the dialog */
  open: PropTypes.bool,
  /** The actions to show in the bottom right corner of the dialog */
  actions: PropTypes.array,
  /** The call to action (title) to display */
  callToAction: PropTypes.string.isRequired,
  /** Additional JSX object to show below the options */
  children: PropTypes.object,
};

export default memo(withStyles(styles)(MultipleSelectDialog));
