import Fungus from 'js/components/Icons/NoteTypes/Fungus';
import Weed from 'js/components/Icons/NoteTypes/Weed';
import Pests from 'js/components/Icons/NoteTypes/Pests';
import Obstacle from 'js/components/Icons/NoteTypes/Obstacle';
import Soil from 'js/components/Icons/NoteTypes/Soil';
import Window from 'js/components/Icons/NoteTypes/Window';
import Other from 'js/components/Icons/NoteTypes/Other';

export const NoteTypes = {
  FUNGUS: 'FUNGUS',
  WEED: 'WEED',
  PESTS: 'PESTS',
  OBSTACLE: 'OBSTACLE',
  SOIL: 'SOIL',
  WINDOW: 'WINDOW',
  OTHER: 'OTHER',
};

export const getNoteTypeIcon = (noteType) => {
  switch (noteType) {
    case NoteTypes.FUNGUS:
      return Fungus;
    case NoteTypes.WEED:
      return Weed;
    case NoteTypes.PESTS:
      return Pests;
    case NoteTypes.OBSTACLE:
      return Obstacle;
    case NoteTypes.SOIL:
      return Soil;
    case NoteTypes.WINDOW:
      return Window;
    case NoteTypes.OTHER:
      return Other;
    default:
      return Other;
  }
};

export const getNoteTypeName = (LangFile, noteType) => {
  return LangFile.NoteTypes[noteType].name;
};
