import React, { memo } from 'react';

import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

const StepActions = ({ children, justifyContent }) => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={justifyContent} p={2} mt={1}>
      {children}
    </Box>
  );
};

StepActions.propTypes = {
  justifyContent: PropTypes.string,
};

StepActions.defaultProps = {
  justifyContent: 'flex-end',
};

export default memo(StepActions);
