'use strict';

import WebAPIUtils from 'js/WebAPIUtils';
import FeatureConstants from 'js/constants/FeatureConstants';

/* ============== //
 ||     TYPES     ||
 // ============== */

const GET_PERMISSIONS = 'fieldsense/Permission/GET_PERMISSIONS';

/* ============== //
 ||     ACTIONS    ||
 // ============== */

export function getPermissions(id) {
  return {
    type: GET_PERMISSIONS,
    payload: WebAPIUtils.getFarmPermissions(id),
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  user: {},
  farm: {
    [FeatureConstants.LEGACY_WEATHER]: false,
    [FeatureConstants.LEGACY_SATELLITE]: false,
  },
  loaded: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case GET_PERMISSIONS + '_FULFILLED': {
      let payload = action.payload;
      let user = {};
      let farm = {};

      if (payload) {
        farm = { ...payload.features };
      }

      state = { ...state, user: user, farm: farm, loaded: true };
      break;
    }
    case GET_PERMISSIONS + '_REJECTED': {
      state = { ...state, loaded: true };
      break;
    }
    default:
      break;
  }
  return state;
}
