import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { PressureTrend, WeatherSensor } from '../../constants/WeatherConstants';
import { blueGrey, grey } from '@material-ui/core/colors';
import {
  getWeatherSensorUnit,
  useMeasureSettings,
} from '../../context/AppSettings/AppSettingsContext';
import { withStyles } from '@material-ui/core/styles';
import BarometerIcon from '../Icons/Weather/Barometer';
import PressureDown from '../Icons/Weather/PressureDown';
import PressureUp from '../Icons/Weather/PressureUp';

const styles = (theme) => ({
  root: {
    color: blueGrey['800'],
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    overflow: 'visible',
    position: 'relative',
    border: `${grey[200]} 3px solid`,
    boxSizing: 'border-box',
    '&:hover:not(.selected)': {
      background: grey['200'],
      borderColor: grey['400'],
    },
    '& *': {
      userSelect: 'none',
    },
  },

  icon: {
    '& svg': {
      fontSize: 48,
    },
  },

  value: {
    fontSize: 28,
    minHeight: 28,
    padding: [[theme.spacing(1), 0]],
  },

  bottom: {
    fontSize: 14,
    '& svg': {
      fontSize: 14,
    },
  },

  '@media (max-width: 1600px), (max-height: 800px)': {
    icon: {
      '& svg': {
        fontSize: 28,
      },
    },

    value: {
      fontSize: 13,
      minHeight: 18,
      padding: [[theme.spacing(1) / 2, 0]],
    },

    bottom: {
      fontSize: 11,
      '& svg': {
        fontSize: 11,
      },
    },
  },
});

const PressureWeatherInfoBox = (props) => {
  let { classes, station, selected, color, onChangeType } = props;

  const measureSettings = useMeasureSettings();
  const current = station.current;

  let valueString = '-';
  let content = undefined;

  if (current) {
    if (current.pressure) {
      valueString = Number(current.pressure).toFixed(0);
    }

    if (current.pressureTrend) {
      if (current.pressureTrend === PressureTrend.RISING) {
        content = (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <PressureUp />
            <Box mr={0.5} />
            {getWeatherSensorUnit(measureSettings, WeatherSensor.PRESSURE)}
          </Box>
        );
      } else {
        content = (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <PressureDown />
            <Box mr={0.5} />
            {getWeatherSensorUnit(measureSettings, WeatherSensor.PRESSURE)}
          </Box>
        );
      }
    }
  }

  return (
    <Box
      width={1}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      className={`${classes.root} ${selected ? 'selected' : ''}`}
      onClick={() => {
        onChangeType(WeatherSensor.PRESSURE);
      }}
      style={{ borderColor: selected ? color : '' }}
    >
      <div className={classes.icon}>{<BarometerIcon />}</div>

      <div>
        {<div className={classes.value}>{valueString}</div>}
        {<div className={classes.bottom}>{content}</div>}
      </div>
    </Box>
  );
};

PressureWeatherInfoBox.propTypes = {
  /** The specific station to show the current wind conditions for*/
  station: PropTypes.shape({
    /** It is required that the station has a current property, which is the object containing the current readings*/
    current: PropTypes.object,
  }),
  onChangeType: PropTypes.func,
  selected: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default memo(withStyles(styles)(PressureWeatherInfoBox));
