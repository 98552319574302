import { Box, Fab } from '@material-ui/core';
import ShortcutIcon from '../Icons/ShortcutIcon';
import React from 'react';

const FieldStatisticsShortcutCell = ({ onClick }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      height={'100%'}
      pl={2}
    >
      <Fab size={'small'} onClick={onClick}>
        <ShortcutIcon fontSize={'small'} />
      </Fab>
    </Box>
  );
};

export default FieldStatisticsShortcutCell;
