import { indigo, red, yellow, brown, lightGreen, purple } from '@material-ui/core/colors';
import NumberUtils from 'js/helpers/NumberUtils';
let tinycolor = require('tinycolor2');

export function getPhColor(value) {
  let low = red;
  let high = indigo;
  let base = low['500'];

  switch (Number(Number(value).toFixed(0))) {
    case 0: {
      return { primary: low['900'], contrast: 'white', base: base };
    }
    case 1: {
      return { primary: low['800'], contrast: 'white', base: base };
    }
    case 2: {
      return { primary: low['600'], contrast: 'white', base: base };
    }
    case 3: {
      return { primary: low['500'], contrast: 'white', base: base };
    }
    case 4: {
      return { primary: low['400'], contrast: 'white', base: base };
    }
    case 5: {
      return { primary: low['300'], contrast: 'black', base: base };
    }
    case 6: {
      return { primary: low['200'], contrast: 'black', base: base };
    }
    case 7: {
      return { primary: 'white', contrast: 'black', base: base };
    }
    case 8: {
      return { primary: high['200'], contrast: 'black', base: base };
    }
    case 9: {
      return { primary: high['300'], contrast: 'white', base: base };
    }
    case 10: {
      return { primary: high['400'], contrast: 'white', base: base };
    }
    case 11: {
      return { primary: high['500'], contrast: 'white', base: base };
    }
    case 12: {
      return { primary: high['600'], contrast: 'white', base: base };
    }
    case 13: {
      return { primary: high['800'], contrast: 'white', base: base };
    }
    default: {
      return { primary: high['900'], contrast: 'white', base: base };
    }
  }
}

export function getSoilTypeColor(value, min, max) {
  return getColor(brown['400'], value, min, max);
}

export function getPotassiumColor(value, min, max) {
  return getColor(yellow['A700'], value, min, max);
}

export function getMagnesiumColor(value, min, max) {
  return getColor(purple['A400'], value, min, max);
}

export function getPhosphorusColor(value, min, max) {
  return getColor(lightGreen['A400'], value, min, max);
}

function getColor(baseColor, value, min, max) {
  let color = tinycolor(baseColor);
  let val = NumberUtils.map(value, min, max, 0, 10);

  switch (Number(Number(val).toFixed(0))) {
    case 0: {
      return { primary: 'white', contrast: 'black', base: baseColor };
    }
    case 1: {
      return { primary: color.lighten(40), contrast: 'black', base: baseColor };
    }
    case 2: {
      return { primary: color.lighten(30), contrast: 'black', base: baseColor };
    }
    case 3: {
      return { primary: color.lighten(20), contrast: 'black', base: baseColor };
    }
    case 4: {
      return { primary: color.lighten(10), contrast: 'black', base: baseColor };
    }
    case 5: {
      return { primary: color, contrast: 'black', base: baseColor };
    }
    case 6: {
      return { primary: color.darken(10), contrast: 'white', base: baseColor };
    }
    case 7: {
      return { primary: color.darken(15), contrast: 'white', base: baseColor };
    }
    case 8: {
      return { primary: color.darken(20), contrast: 'white', base: baseColor };
    }
    case 9: {
      return { primary: color.darken(25), contrast: 'white', base: baseColor };
    }
    default: {
      return { primary: color.darken(30), contrast: 'white', base: baseColor };
    }
  }
}
