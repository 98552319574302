import React, { Fragment, memo, useCallback, useState } from 'react';

import { connect } from 'react-redux';

import {
  resetRequestState,
  retireWeatherStation,
  setShowReplaceDialog,
  setShowSuspendDialog,
} from 'js/reducers/ReplaceStationReducer';
import { fetchWeatherStations } from 'js/reducers/WeatherReducer';

import SuspendStationDialog from 'js/components/ReplaceStation/Suspend/SuspendStationDialog';
import SuspendStationStepsDialog from 'js/components/ReplaceStation/Suspend/SuspendStationStepsDialog';
import { useMeasureSettings } from '../../../context/AppSettings/AppSettingsContext';

const mapStateToProps = (store) => {
  return {
    showSuspendDialog: store.replaceStation.showSuspendDialog,
    farm: store.farm.farm,
    station: store.weather.selectedStation,
    accumulation: store.weather.accumulation,
    error: store.replaceStation.error,
    success: store.replaceStation.success,
    loading: store.replaceStation.loading,
  };
};

const SuspendStationStepsDialogContainer = ({
  dispatch,
  farm,
  station,
  accumulation,
  showSuspendDialog,
  loading,
  error,
  success,
}) => {
  const measureSettings = useMeasureSettings();
  const [suspend, setSuspend] = useState(false);

  const handleSuspendCancelClicked = useCallback(() => {
    setSuspend(false);
    dispatch(resetRequestState());
  }, []);

  // Suspend Dialog Buttons
  const handleSuspendYesClicked = useCallback(() => {
    dispatch(setShowSuspendDialog(false));
    setSuspend(true);
  }, []);

  const handleSuspendNoClicked = useCallback(() => {
    dispatch(setShowSuspendDialog(false));
    dispatch(setShowReplaceDialog(true));
  }, []);

  // Closing Dialogs
  const handleSuspendStationClosed = useCallback(() => dispatch(setShowSuspendDialog(false)), []);

  const handleSuspend = useCallback(
    (id, dismountDate) => {
      dispatch(retireWeatherStation(id, farm.farmId, dismountDate));
    },
    [farm]
  );

  // Replacement finished
  const handleSuspensionFinished = useCallback(() => {
    handleSuspendCancelClicked();
    dispatch(fetchWeatherStations(farm.farmId, accumulation, measureSettings));
    dispatch(resetRequestState());
  }, [farm, accumulation, measureSettings]);

  if (!station) {
    return null;
  }

  return (
    <Fragment>
      <SuspendStationDialog
        shown={showSuspendDialog}
        onClose={handleSuspendStationClosed}
        onNo={handleSuspendNoClicked}
        onYes={handleSuspendYesClicked}
      />

      {suspend && (
        <SuspendStationStepsDialog
          station={station}
          loading={loading}
          error={error}
          success={success}
          onSuspend={handleSuspend}
          onCancel={handleSuspendCancelClicked}
          onFinish={handleSuspensionFinished}
        />
      )}
    </Fragment>
  );
};

export default memo(connect(mapStateToProps)(SuspendStationStepsDialogContainer));
