import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  },
});

const WeatherStationIndicatorColumn = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(WeatherStationIndicatorColumn);
