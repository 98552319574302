export const CropType = {
  _SELECT: '_SELECT',
  MIXED_SPRING: 'MIXED_SPRING',
  RYE_SPRING: 'RYE_SPRING',
  WHEAT_SPRING: 'WHEAT_SPRING',
  WHEAT_BREAD_SPRING: 'WHEAT_BREAD_SPRING',
  BARLEY_SPRING: 'BARLEY_SPRING',
  OAT_SPRING: 'OAT_SPRING',
  TRITICALE_SPRING: 'TRITICALE_SPRING',
  DINKEL_WHEAT_SPRING: 'DINKEL_WHEAT_SPRING',
  OAT_WINTER: 'OAT_WINTER',
  RYE_WINTER: 'RYE_WINTER',
  RYE_HYBRID_WINTER: 'RYE_HYBRID_WINTER',
  WHEAT_WINTER: 'WHEAT_WINTER',
  WHEAT_BREAD_WINTER: 'WHEAT_BREAD_WINTER',
  BARLEY_WINTER: 'BARLEY_WINTER',
  TRITICALE_WINTER: 'TRITICALE_WINTER',
  DINKEL_WHEAT_WINTER: 'DINKEL_WHEAT_WINTER',
  CORN: 'CORN',
  CORN_GRAIN: 'CORN_GRAIN',
  VEGETABLES: 'VEGETABLES',
  SPINACH: 'SPINACH',
  CABBAGE: 'CABBAGE',
  CARROTS: 'CARROTS',
  POTATOES: 'POTATOES',
  POTATOES_EATING: 'POTATOES_EATING',
  POTATOES_STARCH: 'POTATOES_STARCH',
  BEETS: 'BEETS',
  BEETS_SUGAR: 'BEETS_SUGAR',
  BEETS_FEED: 'BEETS_FEED',
  BEANS: 'BEANS',
  PEAS: 'PEAS',
  RAPESEED_SPRING: 'RAPESEED_SPRING',
  RAPESEED_WINTER: 'RAPESEED_WINTER',
  BEANS_SOY: 'BEANS_SOY',
  BEANS_HORSE: 'BEANS_HORSE',
  LEGUMES_MIXED: 'LEGUMES_MIXED',
  GRASS_PERMANENT: 'GRASS_PERMANENT',
  GRASS_OR_FORAGE: 'GRASS_OR_FORAGE',
  CLOVER: 'CLOVER',
  CLOVER_WHITE: 'CLOVER_WHITE',
  CLOVER_RED: 'CLOVER_RED',
  SEED_GRASS: 'SEED_GRASS',
  LUPINS: 'LUPINS',
  GRASS_LUCERNE: 'GRASS_LUCERNE',
  GRASS_GROUND_FESCUE: 'GRASS_GROUND_FESCUE',
  GRASS_BLUEGRASS: 'GRASS_BLUEGRASS',
  GRASS_FESTULOLIUM: 'GRASS_FESTULOLIUM',
  GRASS_RED_FESCUE: 'GRASS_RED_FESCUE',
  GRASS_MEADOW_FESCUE: 'GRASS_MEADOW_FESCUE',
  GRASS_CAT: 'GRASS_CAT',
  GRASS_TIMOTHY: 'GRASS_TIMOTHY',
  GRASS_RYEGRASS: 'GRASS_RYEGRASS',
  BUCKWHEAT: 'BUCKWHEAT',
  SEEDS_GARDEN: 'SEEDS_GARDEN',
  FOREST: 'FOREST',
  WILLOW: 'WILLOW',
  WHOLECROP_SILAGE: 'WHOLECROP_SILAGE',
  HEMP: 'HEMP',
  QUINOA: 'QUINOA',
  OTHER: 'OTHER',
  NONE: 'NONE',
};

export const CropTypeColor = {
  [CropType.MIXED_SPRING]: '#FFB300',
  [CropType.RYE_SPRING]: '#FFC107',
  [CropType.WHEAT_SPRING]: '#FFCA28',
  [CropType.WHEAT_BREAD_SPRING]: '#FFCA28',
  [CropType.BARLEY_SPRING]: '#FFE082',
  [CropType.OAT_SPRING]: '#FFF8E1',
  [CropType.TRITICALE_SPRING]: '#FFA726',
  [CropType.DINKEL_WHEAT_SPRING]: '#FFCC80',
  [CropType.OAT_WINTER]: '#006064',
  [CropType.RYE_WINTER]: '#00ACC1',
  [CropType.RYE_HYBRID_WINTER]: '#00ACC1',
  [CropType.WHEAT_WINTER]: '#26C6DA',
  [CropType.WHEAT_BREAD_WINTER]: '#26C6DA',
  [CropType.BARLEY_WINTER]: '#80DEEA',
  [CropType.TRITICALE_WINTER]: '#B2EBF2',
  [CropType.DINKEL_WHEAT_WINTER]: '#E0F7FA',
  [CropType.CORN]: '#FF7043',
  [CropType.CORN_GRAIN]: '#FFAB91',
  [CropType.VEGETABLES]: '#CDDC39',
  [CropType.SPINACH]: '#DCE775',
  [CropType.CABBAGE]: '#F0F4C3',
  [CropType.CARROTS]: '#FFEB3B',
  [CropType.POTATOES]: '#8D6E63',
  [CropType.POTATOES_EATING]: '#BCAAA4',
  [CropType.POTATOES_STARCH]: '#D7CCC8',
  [CropType.BEETS]: '#EF5350',
  [CropType.BEETS_SUGAR]: '#EF9A9A',
  [CropType.BEETS_FEED]: '#FFCDD2',
  [CropType.BEANS]: '#283593',
  [CropType.PEAS]: '#5E35B1',
  [CropType.RAPESEED_SPRING]: '#9575CD',
  [CropType.RAPESEED_WINTER]: '#B39DDB',
  [CropType.BEANS_SOY]: '#EDE7F6',
  [CropType.BEANS_HORSE]: '#D1C4E9',
  [CropType.LEGUMES_MIXED]: '#9575CD',
  [CropType.GRASS_PERMANENT]: '#2E7D32',
  [CropType.GRASS_OR_FORAGE]: '#388E3C',
  [CropType.CLOVER]: '#43A047',
  [CropType.CLOVER_WHITE]: '#4CAF50',
  [CropType.CLOVER_RED]: '#66BB6A',
  [CropType.SEED_GRASS]: '#81C784',
  [CropType.LUPINS]: '#A5D6A7',
  [CropType.GRASS_LUCERNE]: '#DCEDC8',
  [CropType.GRASS_GROUND_FESCUE]: '#E8F5E9',
  [CropType.GRASS_BLUEGRASS]: '#689F38',
  [CropType.GRASS_FESTULOLIUM]: '#7CB342',
  [CropType.GRASS_RED_FESCUE]: '#8BC34A',
  [CropType.GRASS_MEADOW_FESCUE]: '#9CCC65',
  [CropType.GRASS_CAT]: '#AED581',
  [CropType.GRASS_TIMOTHY]: '#C5E1A5',
  [CropType.GRASS_RYEGRASS]: '#DCEDC8',
  [CropType.BUCKWHEAT]: '#1E88E5',
  [CropType.SEEDS_GARDEN]: '#2196F3',
  [CropType.FOREST]: '#42A5F5',
  [CropType.WILLOW]: '#64B5F6',
  [CropType.WHOLECROP_SILAGE]: '#90CAF9',
  [CropType.HEMP]: '#BBDEFB',
  [CropType.QUINOA]: '#E3F2FD',
  [CropType.OTHER]: '#B0BEC5',
  [CropType.NONE]: '#607D8B',
};
