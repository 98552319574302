import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Fullscreen from '@material-ui/icons/Fullscreen';
import Fab from '@material-ui/core/Fab';
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { useLangFile } from 'js/context/LanguageContext';
import { useGoogleMap } from 'js/context/GoogleMapContext';

const styles = (theme) => ({
  root: {
    pointerEvents: 'none',
    display: 'inline-flex',
    zIndex: 2,
  },
  fab: {
    margin: [[0, 4]],
    width: 36,
    height: 36,
    pointerEvents: 'all',
    transition: 'transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    transformOrigin: 'center top',
    backgroundColor: theme.palette.common.white,
  },
});

const NavigationMenu = (props) => {
  const { classes, fields, weatherStations, onGoToFields, showSearch } = props;
  const googleMap = useGoogleMap();
  const LangFile = useLangFile();

  let zoomIn = () => {
    googleMap.setZoom(googleMap.zoom + 1);
  };

  let zoomOut = () => {
    googleMap.setZoom(googleMap.zoom - 1);
  };

  if (!googleMap) {
    return null;
  }

  let goToLabel = LangFile.NavigationMenu.noAssets;
  if (fields.size > 0) {
    goToLabel = LangFile.NavigationMenu.goToFields;
  } else if (weatherStations.size > 0) {
    goToLabel = LangFile.NavigationMenu.goToWeatherStation;
  }

  return (
    <div className={classes.root}>
      <Tooltip title={LangFile.NavigationMenu.zoomIn}>
        <Fab className={classes.fab} size="small" onClick={zoomIn}>
          <Add style={{ fill: '#000' }} />
        </Fab>
      </Tooltip>

      <Tooltip title={LangFile.NavigationMenu.zoomOut}>
        <Fab className={classes.fab} size="small" onClick={zoomOut}>
          <Remove style={{ fill: '#000' }} />
        </Fab>
      </Tooltip>

      <Tooltip title={goToLabel || LangFile.NavigationMenu.noAssets}>
        <div>
          <Fab className={classes.fab} size="small" onClick={onGoToFields}>
            <Fullscreen style={{ fill: '#000' }} />
          </Fab>
        </div>
      </Tooltip>

      <Tooltip title={LangFile.NavigationMenu.find}>
        <Fab className={classes.fab} size="small" onClick={showSearch}>
          <Search style={{ fill: '#000' }} />
        </Fab>
      </Tooltip>
    </div>
  );
};

NavigationMenu.propTypes = {
  fields: PropTypes.object,
  weatherStations: PropTypes.array,
  showSearch: PropTypes.func,
  onGoToFields: PropTypes.func,
};

export default withStyles(styles)(NavigationMenu);
