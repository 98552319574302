import React, { useCallback, memo, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { useLangFile } from 'js/context/LanguageContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  Paper,
} from '@material-ui/core';

import style from './PrescriptionOverrideList.module.less';
import ListSubheader from '@material-ui/core/ListSubheader';
import EditTextDialog from '../../Modals/EditTextDialog';
import { useHookRef } from '../../../hooks/useHookRef';
import {
  Action,
  useActionSnackbarContext,
} from '../../ActionSnackbarHandler/ActionSnackbarHandler';
import { usePrescriptionJob } from '../PrescriptionJobContext';

const PrescriptionOverrideList = ({ onOverridesUpdated }) => {
  const LangFile = useLangFile();
  const { addAction } = useActionSnackbarContext();
  const [editValue, setEditValue] = useState(null);
  const [deleteOverride, setDeleteOverride] = useState(null);
  const oldValueRef = useHookRef(editValue);
  const { prescriptionJob } = usePrescriptionJob();

  let unitString;
  switch (prescriptionJob.unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = `${LangFile.PrescriptionSettings.unit.liters.short} / ha`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `${LangFile.PrescriptionSettings.unit.pieces.short} / m^2`;
      break;
    default:
      unitString = `${LangFile.PrescriptionSettings.unit.kilogram.short} / ha`;
      break;
  }

  const handleDeleteOverride = useCallback(() => {
    let nextOverrides = prescriptionJob.overrides.map((row) =>
      row.map((value) => {
        if (Number(value) === Number(deleteOverride)) {
          return -1;
        }
        return value;
      })
    );

    onOverridesUpdated(nextOverrides);
    setDeleteOverride(null);
  }, [deleteOverride, prescriptionJob.overrides, onOverridesUpdated]);

  const onEdit = useCallback((group) => {
    setEditValue(group.value);
  }, []);

  const onConfirmEditValue = useCallback(
    (newValueString) => {
      let newValue = Number(newValueString);
      let oldValue = Number(oldValueRef.current);

      if (Number.isNaN(newValue)) {
        console.error('Invalid value provided');
        addAction(
          new Action(
            'prescription-override-invalid-value',
            LangFile.PrescriptionOverrideList.invalidValue,
            'error',
            'filled'
          )
        );
      } else {
        let nextOverrides = prescriptionJob.overrides.map((row) =>
          row.map((value) => {
            if (value === oldValue) {
              return newValue;
            }
            return value;
          })
        );

        onOverridesUpdated(nextOverrides);
      }

      setEditValue(null);
    },
    [prescriptionJob.overrides, onOverridesUpdated]
  );

  const onCloseEdit = useCallback(() => {
    setEditValue(null);
  }, []);

  const onCloseDelete = useCallback(() => {
    setDeleteOverride(null);
  }, []);

  return (
    <Paper elevation={2} className={style.Paper}>
      <List
        dense
        className={'narrow-scrollbar'}
        subheader={
          <ListSubheader component="div">
            {LangFile.PrescriptionOverrideList.subheader}
          </ListSubheader>
        }
      >
        {Object.keys(prescriptionJob.overrideAreas).length === 0 ? (
          <ListItem dense>
            <ListItemText
              primary={LangFile.PrescriptionOverrideList.emptyListPrimary}
              secondary={LangFile.PrescriptionOverrideList.emptyListSecondary}
            />
          </ListItem>
        ) : null}
        {Object.keys(prescriptionJob.overrideAreas).map((key, idx) => {
          let group = prescriptionJob.overrideAreas[key];
          let value = Number(group.value);
          let area = group.area;
          let color = group.color;

          let deleteHandler = () => {
            setDeleteOverride(value);
          };
          let editHandler = () => onEdit(group);

          return (
            <Tooltip
              key={`group-${value}`}
              title={LangFile.PrescriptionOverrideList.editPrescriptionValue}
            >
              <ListItem button={true} onClick={editHandler}>
                <ListItemIcon>
                  <Box bgcolor={color} width={16} height={16} />
                </ListItemIcon>
                <ListItemText
                  primary={`${Number(value).toFixed(2)} ${unitString}, ${Number(area).toFixed(2)} ha`}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={LangFile.PrescriptionOverrideList.delete}>
                    <IconButton onClick={deleteHandler} size={'small'}>
                      <DeleteIcon fontSize={'small'} />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>

      <EditTextDialog
        title={LangFile.PrescriptionOverrideList.editPrescriptionValue}
        open={editValue != null}
        value={editValue}
        defaultValue={editValue}
        type={'number'}
        placeholder={editValue ? `${editValue} ${unitString}` : ''}
        onUpdate={onConfirmEditValue}
        onClose={onCloseEdit}
      />

      <Dialog fullWidth maxWidth={'sm'} open={deleteOverride != null} onClose={onCloseDelete}>
        <DialogTitle>{LangFile.PrescriptionOverrideList.confirmationRequired}</DialogTitle>
        <DialogContent>
          <p>{LangFile.PrescriptionOverrideList.areYouSure}</p>
          <p>{LangFile.PrescriptionOverrideList.cannotBeUndone}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDelete}>{LangFile.PrescriptionOverrideList.cancel}</Button>
          <Button color="secondary" variant={'contained'} onClick={handleDeleteOverride}>
            {LangFile.PrescriptionOverrideList.confirmDelete}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default memo(PrescriptionOverrideList);

PrescriptionOverrideList.propTypes = {
  onOverridesUpdated: PropTypes.func,
};
