import React, { memo } from 'react';
import PropTypes from 'prop-types';

import RainIcon from 'js/components/Icons/Weather/RainIcon.js';
import HumidityIcon from 'js/components/Icons/Weather/HumidityIcon.js';
import {
  HUMIDITY_COLOR,
  LUMINOSITY_COLOR,
  PRESSURE_COLOR,
  RAIN_ACCUMULATION,
  RAIN_COLOR,
  UV_COLOR,
  WEATHER_DRAWER_HEIGHT,
  WEATHER_DRAWER_HEIGHT_SMALL,
  WeatherSensor,
  WIND_COLOR,
} from 'js/constants/WeatherConstants';

import UVIcon from 'js/components/Icons/Weather/UVIcon';
import LightCondition, { LIGHT_CONDITION } from 'js/model/LightCondition';
import WeatherInfoBox from 'js/components/WeatherDrawer/WeatherInfoBox';
import TemperatureInfoBox from 'js/components/WeatherDrawer/TemperatureInfoBox';
import { useLangFile } from 'js/context/LanguageContext';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import RainInfoSettings from 'js/components/WeatherDrawer/RainInfoSettings/RainInfoSettings';
import useLowScreen from 'js/hooks/useLowScreen';
import WindWeatherInfoBox from './WindWeatherInfoBox';
import {
  getWeatherSensorUnit,
  useMeasureSettings,
} from '../../context/AppSettings/AppSettingsContext';
import PressureWeatherInfoBox from './PressureWeatherInfoBox';

const CurrentWeather = ({
  changeType,
  weatherSensor,
  station,
  accumulation,
  onAccumulationChanged,
  enableWindGust,
  onEnableWindGustChanged,
  enableSprayConditions,
  onEnableSprayConditionsChanged,
}) => {
  const LangFile = useLangFile();
  const measureSettings = useMeasureSettings();

  if (!station) {
    return null;
  }

  const determineWeather = (current) => {
    let condition = LIGHT_CONDITION.UNKNOWN;

    if (current && current.forecast) {
      condition = LightCondition.fromCloudCover(current.forecast.cloudCover, LangFile);
    }

    return {
      icon: condition.icon,
      text: condition.text(LangFile),
    };
  };

  const getUVIndex = (current) => {
    if (current) {
      if (current.uv) {
        return current.uv;
      } else if (current.forecast && current.forecast.uv) {
        return current.forecast.uv;
      }
    }
    return Number.NaN;
  };

  let current = station.current;
  let currentWeather = determineWeather(current);
  let lowScreen = useLowScreen();
  let height = lowScreen ? WEATHER_DRAWER_HEIGHT_SMALL : WEATHER_DRAWER_HEIGHT;

  return (
    <Box
      pl={2}
      pr={1}
      display={'flex'}
      flexDirection={'column'}
      flexWrap={'nowrap'}
      height={height}
      width={'100%'}
      maxHeight={height}
    >
      {!lowScreen && (
        <Box
          mt={1}
          display={'flex'}
          flexWrap={'nowrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          minHeight={48}
        >
          <Typography noWrap variant={'h5'}>
            {LangFile.CurrentWeather.current}
          </Typography>
        </Box>
      )}
      <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} flex={'1 1 auto'}>
        <Box
          width={1 / 3}
          p={1}
          pl={0}
          onClick={() => changeType(WeatherSensor.COMBINED_TEMP)}
          position={'relative'}
        >
          <TemperatureInfoBox
            latest={current}
            selected={weatherSensor === WeatherSensor.COMBINED_TEMP}
          />
        </Box>
        <Box display={'flex'} flexDirection={'column'} width={1}>
          <Box
            width={1}
            height={0.5}
            pt={1}
            pb={0.5}
            display={'flex'}
            flexWrap={'nowrap'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <WindWeatherInfoBox
              station={station}
              onChangeType={changeType}
              weatherSensor={weatherSensor}
              enableWindGust={enableWindGust}
              enableSprayConditions={enableSprayConditions}
              onEnableSprayConditionsChanged={onEnableSprayConditionsChanged}
              onEnableWindGustChanged={onEnableWindGustChanged}
              selected={weatherSensor === WeatherSensor.WIND}
              color={WIND_COLOR}
            />
            <Box pr={1} />
            <WeatherInfoBox
              icon={<HumidityIcon />}
              label={getWeatherSensorUnit(measureSettings, WeatherSensor.HUMIDITY)}
              tooltip={LangFile.CurrentWeather.showHistory}
              value={current ? current.humidity : Number.NaN}
              decimals={0}
              onClick={() => changeType(WeatherSensor.HUMIDITY)}
              selected={weatherSensor === WeatherSensor.HUMIDITY}
              color={HUMIDITY_COLOR}
            />
            <Box pr={1} />
            <WeatherInfoBox
              icon={<RainIcon />}
              label={getWeatherSensorUnit(measureSettings, WeatherSensor.RAIN)}
              decimals={2}
              tooltip={LangFile.CurrentWeather.showHistory}
              value={current ? current.rainAcc : Number.NaN}
              onClick={() => changeType(WeatherSensor.RAIN)}
              selected={weatherSensor === WeatherSensor.RAIN}
              settings={
                <RainInfoSettings
                  accumulation={accumulation}
                  onAccumulationChanged={onAccumulationChanged}
                />
              }
              color={RAIN_COLOR}
            />
          </Box>
          <Box
            width={1}
            height={0.5}
            pt={0.5}
            pb={1}
            display={'flex'}
            flexWrap={'nowrap'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <WeatherInfoBox
              icon={currentWeather.icon}
              value={currentWeather.text}
              tooltip={LangFile.CurrentWeather.showHistory}
              onClick={() => changeType(WeatherSensor.LUX)}
              selected={weatherSensor === WeatherSensor.LUX}
              color={LUMINOSITY_COLOR}
            />
            <Box pr={1} />
            <PressureWeatherInfoBox
              station={station}
              onChangeType={changeType}
              selected={weatherSensor === WeatherSensor.PRESSURE}
              color={PRESSURE_COLOR}
            />
            <Box pr={1} />
            <WeatherInfoBox
              icon={<UVIcon />}
              label={LangFile.CurrentWeather.index}
              decimals={1}
              tooltip={LangFile.CurrentWeather.showHistory}
              value={getUVIndex(current)}
              onClick={() => changeType(WeatherSensor.UV)}
              selected={weatherSensor === WeatherSensor.UV}
              color={UV_COLOR}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(CurrentWeather);

CurrentWeather.propTypes = {
  /** The specific station to show the current weather for*/
  station: PropTypes.shape({
    /** It is required that the station has a latest property, which is the object containing the latest readings*/
    current: PropTypes.object,
  }),
  /** Set the width of the current weather display*/
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Set the height of the current weather display*/
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accumulation: PropTypes.oneOf(Object.values(RAIN_ACCUMULATION)),
  onAccumulationChanged: PropTypes.func,
  enableWindGust: PropTypes.bool,
  onEnableWindGustChanged: PropTypes.func,
  enableSprayConditions: PropTypes.bool,
  onEnableSprayConditionsChanged: PropTypes.func,
};
