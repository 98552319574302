import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AIR_TEMP_COLOR, SOIL_TEMP_COLOR } from 'js/constants/WeatherConstants';
import Thermometer from 'js/components/Icons/Weather/Thermometer';
import { grey } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import { useLangFile } from '../../context/LanguageContext';

const styles = (theme) => ({
  root: {
    '&:not(.selected):hover': {
      '& .soil, & .air': {
        background: grey['200'],
        borderColor: grey['400'],
      },
    },
    '& *': {
      userSelect: 'none',
    },
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: '100%',
  },
  air: {
    borderColor: grey['200'],
    borderTopStyle: 'solid',
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  soil: {
    borderColor: grey['200'],
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },

  label: {
    margin: theme.spacing(1),
    fontSize: 14,
    fontWeight: 300,
  },

  valueLarge: {
    fontSize: 35,
    padding: 0,
    fontWeight: 400,
  },

  tempIcon: {
    position: 'absolute',
    zIndex: 2,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  '@media (max-height: 900px), (max-width: 1368px)': {
    label: {
      fontSize: 14,
    },
    valueLarge: {
      fontSize: 22,
    },
  },
  '@media (max-height: 800px), (max-width: 1024px)': {
    column1: {
      padding: theme.spacing(1) / 2,
    },
    label: {
      fontSize: 9,
    },
    valueLarge: {
      fontSize: 16,
    },
  },
});

const TemperatureInfoBox = (props) => {
  const LangFile = useLangFile();

  let { classes, selected, latest } = props;

  let airTemp = latest && latest.airTemp != null ? `${Number(latest.airTemp).toFixed(1)}°` : '-';
  let soilTemp = latest && latest.soilTemp != null ? `${Number(latest.soilTemp).toFixed(1)}°` : '-';

  return (
    <Grid
      item
      container
      direction={'column'}
      wrap={'nowrap'}
      className={`${classes.root} ${selected ? 'selected' : ''}`}
    >
      <Grid
        item
        xs={12}
        container
        direction={'column'}
        justify={'center'}
        alignItems={'center'}
        className={`${classes.air} air`}
        style={{ borderColor: selected ? AIR_TEMP_COLOR : '' }}
      >
        <div className={classes.label}>{LangFile.TemperatureInfoBox.airLabel}</div>
        <div className={classes.valueLarge}>{airTemp}</div>
      </Grid>

      <div className={classes.tempIcon}>
        <Thermometer />
      </div>

      <Grid
        item
        xs={12}
        container
        direction={'column'}
        justify={'center'}
        alignItems={'center'}
        className={`${classes.soil} soil`}
        style={{ borderColor: selected ? SOIL_TEMP_COLOR : '' }}
      >
        <div className={classes.valueLarge}>{soilTemp}</div>
        <div className={classes.label}>{LangFile.TemperatureInfoBox.soilLabel}</div>
      </Grid>
    </Grid>
  );
};

TemperatureInfoBox.propTypes = {
  latest: PropTypes.object,
  selected: PropTypes.bool,
};

export default withStyles(styles)(TemperatureInfoBox);
