import WebAPIUtils from 'js/WebAPIUtils.js';

import { setShowUserMenu } from 'js/reducers/ControlReducer';
import { getOwnerFarm } from 'js/helpers/FarmUtils';
import { SET_APP_LANGUAGE } from './LanguageReducer';

/* ============== //
||  ACTIONTYPES   ||
// ============== */

export const AUTHENTICATE_USER = 'fieldsense/UserReducer/AUTHENTICATE_USER';
export const IMPERSONATION_LOG_IN = 'fieldsense/User/IMPERSONATION_LOG_IN';
export const USER_LOG_OUT = 'fieldsense/User/USER_LOG_OUT';
export const IMPERSONATION_FARM_AUTH = 'fieldsense/User/IMPERSONATION_FARM_AUTH';
const GET_USER = 'fieldsense/UserReducer/GET_USER';

/* ============== //
||    HELPERS     ||
// ============== */

async function getUserTokenPromise(username, password) {
  let tokenObject = await WebAPIUtils.getUserToken(username, password);
  let token = 'Bearer ' + tokenObject.accessToken;
  let user = await WebAPIUtils.getUser(token);

  return {
    userId: user.id,
    role: tokenObject.role,
    token: token,
    refreshToken: tokenObject.refreshToken,
    user: user,
  };
}

async function getFarmToken(token, user) {
  if (!user) {
    user = await WebAPIUtils.getUser(token);
  }

  let farms = await WebAPIUtils.getFarms(user.id);

  let { farm, otherFarms } = getOwnerFarm(farms);

  let farmTokenObject = await WebAPIUtils.getFarmToken(token, farm.farmId);

  let farmToken = 'Bearer ' + farmTokenObject.accessToken;

  return {
    token: farmToken,
    refreshToken: farmTokenObject.refreshToken,
    user: user,
    farm: farm,
    otherFarms: otherFarms,
  };
}

/* ============== //
||    ACTIONS    ||
// ============== */

export function authenticateUser(username, password, rememberMe) {
  return {
    type: AUTHENTICATE_USER,
    payload: getUserTokenPromise(username, password),
    meta: {
      rememberMe: rememberMe,
    },
  };
}

export function getUser(token) {
  return {
    type: GET_USER,
    payload: WebAPIUtils.getUser(token),
    meta: {
      token: token,
    },
  };
}

export function getUserInformation() {
  return {
    type: GET_USER,
    payload: WebAPIUtils.getUserInfo(),
  };
}

export function impersonationLogin(username, pswd, doneCallback) {
  return (dispatch) => {
    return dispatch({
      type: IMPERSONATION_LOG_IN,
      payload: getUserTokenPromise(username, pswd),
    })
      .then(() => doneCallback())
      .catch(() => doneCallback());
  };
}

export function impersonationFarmAuth(token) {
  return {
    type: IMPERSONATION_FARM_AUTH,
    payload: getFarmToken(token),
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(setShowUserMenu(false));

    return dispatch({
      type: USER_LOG_OUT,
      payload: null,
    });
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  user: {},
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case AUTHENTICATE_USER + '_FULFILLED':
      state = { ...state, user: action.payload.user };
      break;

    case GET_USER + '_FULFILLED':
      state = { ...state, user: action.payload };
      break;

    case IMPERSONATION_LOG_IN + '_FULFILLED':
      state = { ...state, user: action.payload.user };
      break;

    case IMPERSONATION_FARM_AUTH + '_FULFILLED':
      state = { ...state, user: action.payload.user };
      break;

    case SET_APP_LANGUAGE:
      state = { ...state, user: { ...state.user, language: action.payload } };
      break;

    default:
      break;
  }

  return state;
}
