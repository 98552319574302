import moment from 'moment/moment';
import WebAPIUtils from 'js/WebAPIUtils';
import WeatherRainRegion from 'js/model/network/WeatherRainRegion';
import NetworkSensor from '../model/network/NetworkSensor';
import { mapNetworkClusterMeasurement } from '../helpers/MeasureUtils';

/* ============== //
||      TYPES     ||
// ============== */

/* ============== //
||     ACTIONS    ||
// ============== */

const SET_NETWORK_SENSOR = 'fieldsense/WeatherNetworkReducer/SET_NETWORK_SENSOR';
const SET_SHOW_WEATHER_NETWORK = 'fieldsense/WeatherNetworkReducer/SET_SHOW_WEATHER_NETWORK';
const SET_TEMPORAL_STATS_INDEX = 'fieldsense/WeatherNetworkReducer/SET_TEMPORAL_STATS_INDEX';
const GET_WEATHER_REGION = 'fieldsense/WeatherNetworkReducer/GET_WEATHER_REGION';

export function setNetworkSensor(sensor) {
  return {
    type: SET_NETWORK_SENSOR,
    payload: sensor,
  };
}

export function setShowWeatherNetwork(show) {
  return {
    type: SET_SHOW_WEATHER_NETWORK,
    payload: show,
  };
}

export function setTemporalEndIndex(index) {
  return {
    type: SET_TEMPORAL_STATS_INDEX,
    payload: index,
  };
}

export function getWeatherRegion(farmId, sensor, level, bounds, measureSettings) {
  return {
    type: GET_WEATHER_REGION,
    payload: WebAPIUtils.fetchWeatherRegion(farmId, sensor, level, bounds).then((result) => {
      // Make sure all cluster values are mapped according to the user preferences in AppSettings

      if (result && result.clusters) {
        result.clusters = result.clusters.map((cluster) => {
          return mapNetworkClusterMeasurement(cluster, sensor, measureSettings);
        });
      }

      return result;
    }),
    meta: {
      sensor: sensor,
      level: level,
      bounds: bounds,
    },
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  temporalDates: null,
  temporalStartIndex: null,
  temporalEndIndex: null,
  region: null,
  ticksPerHour: null,
  showWeatherNetwork: false,
  networkSensor: NetworkSensor.RAIN_24H,
  level: 1,
  regionError: false,
  currentBounds: null,
  loadingRegion: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case GET_WEATHER_REGION + '_PENDING': {
      state = { ...state, regionError: false, loadingRegion: true };
      break;
    }

    case GET_WEATHER_REGION + '_REJECTED': {
      state = { ...state, regionError: true, loadingRegion: false };
      break;
    }

    case GET_WEATHER_REGION + '_FULFILLED': {
      let data = action.payload;
      let sensor = action.meta.sensor;
      let level = action.meta.level;
      let bounds = action.meta.bounds;

      // Create the WeatherRainRegion just... because. Won't help much with type safety but at least it will help us
      // remember how the model is stitched together.
      let region = new WeatherRainRegion(
        data.sensor,
        data.windowStart,
        data.windowEnd,
        data.windowSizeMinutes,
        data.windowStepMinutes,
        data.clusters
      );
      let endDate = moment(region.windowEnd);
      let dates = [];

      // This ticksPerHour is added to the end of the slider to allow us to show the last window (which extends multiple steps beyond the last index).
      let ticksPerHour = region.windowSizeMinutes / region.windowStepMinutes;

      // If there are clusters in the requested region
      if (region.clusters.length > 0) {
        let firstCluster = region.clusters[0];

        const getTimeSeriesData = (cluster) => {
          if (cluster.max && cluster.max.length > 0) {
            return cluster.max;
          } else if (cluster.min && cluster.min.length > 0) {
            return cluster.min;
          } else if (cluster.median && cluster.median.length > 0) {
            return cluster.median;
          }
          return [];
        };

        if (firstCluster) {
          const timeSeriesData = getTimeSeriesData(firstCluster);
          if (Array.isArray(timeSeriesData)) {
            // Create the date-range for the slider
            // We start off with the end-date (the newest date) in the array and thus slice off the first index in the list of dates.
            // We then subtract the window-step size (in minutes) between each measurement.
            dates = timeSeriesData.slice(1).reduce(
              (acc, curr, idx) => {
                return [...acc, acc[idx].clone().subtract(region.windowStepMinutes, 'minutes')];
              },
              [endDate]
            );

            let lastDate = dates[dates.length - 1];

            for (let i = 1; i < ticksPerHour; i++) {
              dates.push(moment(lastDate).subtract(i * 10, 'minutes'));
            }

            // Lastly, the dates are reversed to match the way we interact with the data - from the back/newest.
            dates = dates.reverse();
          }
        }
      }

      // Define the slider brush
      let temporalStartIndex, temporalEndIndex;

      // If we had selected a date already
      if (state.temporalEndIndex) {
        // If it is not the last index it means the user has moved the slider
        if (state.temporalEndIndex !== dates.length - 1) {
          // Find the date that corresponds to the selected index
          let date = state.temporalDates[state.temporalEndIndex];
          let index =
            date &&
            dates.filter((d) => d !== null).findIndex((d) => d.valueOf() === date.valueOf());
          if (index) {
            temporalEndIndex = index;
          }
        }
      }

      // If we failed to initialize the date index based on previous state, then default to the newest.
      if (!temporalEndIndex) {
        let last = dates.length - 1;
        temporalEndIndex = Math.max(0, last);
      }

      temporalStartIndex = temporalEndIndex - ticksPerHour + 1;

      state = {
        ...state,
        currentBounds: { ...bounds },
        region: region,
        networkSensor: sensor,
        temporalEndIndex: temporalEndIndex,
        temporalStartIndex: temporalStartIndex,
        temporalDates: dates,
        level: level,
        regionError: false,
        ticksPerHour: ticksPerHour,
        loadingRegion: false,
      };
      break;
    }
    case SET_NETWORK_SENSOR: {
      if (action.payload !== state.networkSensor) {
        state = {
          ...state,
          networkSensor: action.payload,
          region: null,
          temporalEndIndex: null,
          temporalDates: null,
        };
      }
      break;
    }
    case SET_TEMPORAL_STATS_INDEX: {
      let endIndex = parseInt(action.payload);
      let startIndex = endIndex - state.ticksPerHour + 1;

      state = { ...state, temporalEndIndex: endIndex, temporalStartIndex: startIndex };
      break;
    }

    case SET_SHOW_WEATHER_NETWORK: {
      state = { ...state, showWeatherNetwork: action.payload };
      break;
    }

    default:
      break;
  }

  return state;
}
