import { useFarm } from '../../context/AccountContext';
import React, { Fragment, memo, useCallback } from 'react';
import { disableSeason, updateSeason } from '../../reducers/SeasonReducer';
import CreateUpdateSeason from './CreateUpdateSeason';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import { useSeasonContext } from '../../context/SeasonContext';

const parseDateFormat = 'YYYY-MM-DD';

const UpdateSeasonContainer = ({ dispatch, show, seasonToEdit, onDismiss }) => {
  const farm = useFarm();
  const farmId = farm && farm.farmId;
  const { seasons } = useSeasonContext();

  const onUpdateSeasonClose = useCallback(() => {
    onDismiss();
  }, [onDismiss]);

  const onUpdateSeason = useCallback(
    (name, startDate, endDate) => {
      if (farmId) {
        onDismiss();
        const startDateString = startDate.format(parseDateFormat);
        const endDateString = endDate.format(parseDateFormat);
        dispatch(updateSeason(farmId, seasonToEdit.id, name, startDateString, endDateString));
      }
    },
    [seasonToEdit, onDismiss]
  );

  const onArchiveSeason = useCallback(() => {
    if (farmId) {
      dispatch(disableSeason(farmId, seasonToEdit.id));
      onDismiss();
    }
  }, [seasonToEdit, onDismiss]);

  return (
    <Fragment>
      <CreateUpdateSeason
        showDialog={show}
        enableArchiveButton={seasons.filter((s) => s.enabled).length > 1}
        seasonToEdit={seasonToEdit}
        onDismiss={onUpdateSeasonClose}
        onPrimary={onUpdateSeason}
        onArchive={onArchiveSeason}
      />
    </Fragment>
  );
};

UpdateSeasonContainer.propTypes = {
  show: PropTypes.bool,
  seasonToEdit: PropTypes.object,
  onDismiss: PropTypes.func,
};

export default memo(connect()(UpdateSeasonContainer));
