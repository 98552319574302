// @flow

import React, { useContext } from 'react';
import Field from '../model/Field';

const FieldContext = React.createContext({});

export const FieldProvider = FieldContext.Provider;
export const FieldConsumer = FieldContext.Consumer;

export function useFieldContext(): {
  fields: Map<number, Field>,
  farmFields: Array<Field>,
  selectedField: Field,
  filteredFields: Map<number, Field>,
} {
  return useContext(FieldContext);
}

export function useSelectedField(): Field {
  let state = useFieldContext();
  return state && state.selectedField;
}

export function useUnconnectedFields(): Field {
  let state = useFieldContext();
  if (state) {
    return state.farmFields.filter((f: Field) => !state.fields.has(f.fieldId));
  }
  return [];
}
