'use strict';

import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogTitle } from '@material-ui/core';
import { useLangFile } from 'js/context/LanguageContext';
import PropTypes from 'prop-types';

const ErrorModal = ({ shown, title, message, onClose }) => {
  const LangFile = useLangFile();

  const actions = [
    <Button autoFocus key={'errorBtn'} color="primary" onClick={onClose} variant="contained">
      {LangFile.ErrorModal.close}
    </Button>,
  ];

  return (
    <Dialog open={shown} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  shown: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default ErrorModal;
