import React, { memo, Fragment } from 'react';

import PrescriptionScale from 'js/components/Prescription/DraggableIntervals/PrescriptionScale/PrescriptionScale';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import PropTypes from 'prop-types';
import PrescriptionClassificationScale from '../DraggableIntervals/PrescriptionScale/PrescriptionClassificationScale';

const PrescriptionScaleContainer = ({
  classificationsEnabled,
  variationsEnabled,
  isCustomClassification,
}) => {
  const { prescriptionJob } = usePrescriptionJob();

  return (
    <Fragment>
      {!(classificationsEnabled && isCustomClassification) && (
        <PrescriptionScale
          layer={prescriptionJob.layer}
          values={prescriptionJob.values}
          intervals={prescriptionJob.intervals}
          overrideAreas={prescriptionJob.overrideAreas}
          classificationsEnabled={classificationsEnabled}
          variationsEnabled={variationsEnabled}
        />
      )}

      {classificationsEnabled && isCustomClassification && (
        <PrescriptionClassificationScale
          layer={prescriptionJob.layer}
          intervals={prescriptionJob.intervals}
          overrideAreas={prescriptionJob.overrideAreas}
        />
      )}
    </Fragment>
  );
};

PrescriptionScaleContainer.propTypes = {
  classificationsEnabled: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  isCustomClassification: PropTypes.bool,
};

PrescriptionScaleContainer.defaultProps = {};

export default memo(PrescriptionScaleContainer);
