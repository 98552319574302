import React, { useCallback } from 'react';

import NoteDialog from 'js/components/FieldInfo/NoteDialog';
import {
  createNote,
  updateNote,
  deleteNote,
  deselectNote,
  setIsDrawingNote,
  setIsPlacingMarker,
  createDefaultNote,
  selectNote,
  setEditNoteLocation,
} from 'js/reducers/NoteReducer';

import { connect } from 'react-redux';
import { useLangFile } from 'js/context/LanguageContext';
import { Button, Snackbar } from '@material-ui/core';

const mapStateToProps = (state) => {
  return {
    drawnPolygon: state.note.drawnPolygon,
    selectedNote: state.note.selectedNote,
    showNoteModal: state.note.showNoteModal,
    date: state.field.date,
    field: state.field.selectedField,
    notes: state.note.notes,
    editNoteLocation: state.note.editNoteLocation,
  };
};

const NoteDialogContainer = ({
  dispatch,
  date,
  notes,
  selectedNote,
  showNoteModal,
  drawnPolygon,
  field,
  editNoteLocation,
}) => {
  const LangFile = useLangFile();

  const handleCreateNote = (fieldId, date, polygon, text, type) => {
    dispatch(createNote(fieldId, date, polygon, text, type));
  };

  const handleUpdateNote = (note) => {
    dispatch(updateNote(note));
  };

  const handleDeleteNote = (note, fieldId) => {
    dispatch(deleteNote(note, fieldId));
  };

  const handleCollisionNoteClicked = (note) => {
    dispatch(selectNote(note));
  };

  const handleEditMakerLocation = () => {
    dispatch(setEditNoteLocation(selectedNote));
  };

  const handleOnDismiss = () => {
    dispatch(setIsDrawingNote(false));
    dispatch(setIsPlacingMarker(false));
    dispatch(deselectNote());
  };

  const handleDismissEditNoteLocation = useCallback(() => {
    dispatch(setEditNoteLocation(null));
  }, []);

  if (!showNoteModal) {
    return null;
  }

  return (
    <>
      <NoteDialog
        createNote={handleCreateNote}
        updateNote={handleUpdateNote}
        deleteNote={handleDeleteNote}
        onCollisionNoteClicked={handleCollisionNoteClicked}
        onEditMakerLocation={handleEditMakerLocation}
        activeDate={date}
        notes={notes}
        note={selectedNote || createDefaultNote([])}
        onDismiss={handleOnDismiss}
        shown={showNoteModal && !editNoteLocation}
        noteDrawnPolygon={drawnPolygon}
        field={field}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={editNoteLocation}
        action={
          <Button onClick={handleDismissEditNoteLocation} color={'secondary'}>
            {LangFile.NoteDialog.cancel}
          </Button>
        }
        onClose={handleDismissEditNoteLocation}
        message={LangFile.NoteDialog.editLocationSnackbarMessage}
      />
    </>
  );
};

export default connect(mapStateToProps)(NoteDialogContainer);
