export function getCircularIcon(color, radius) {
  let encoded = window.btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="${-radius} ${-radius} ${2 * radius} ${2 * radius}">
        <circle r="${radius}" cx="${0}" cy="${0}" fill="${color}"/>
    </svg>`);

  return 'data:image/svg+xml;base64,' + encoded;
}

let size3 = 50;
let size4 = 60;
let size5 = 70;

export const defaultClusterStyles = [
  {
    width: size3,
    height: size3,
    url: getCircularIcon('white', size3),
    textColor: 'black',
    textSize: 16,
    overflow: 'hidden',
    borderRadius: '50%',
    opacity: 0.9,
    fontWeight: 500,
    backgroundPosition: 'center bottom',
  },

  {
    width: size4,
    height: size4,
    url: getCircularIcon('white', size4),
    textColor: 'black',
    textSize: 18,
    overflow: 'hidden',
    borderRadius: '50%',
    opacity: 0.9,
    fontWeight: 500,
    backgroundPosition: 'center bottom',
  },
  {
    width: size5,
    height: size5,
    url: getCircularIcon('white', size5),
    textColor: 'black',
    textSize: 20,
    overflow: 'hidden',
    borderRadius: '50%',
    opacity: 0.9,
    fontWeight: 500,
    backgroundPosition: 'center bottom',
  },
];
