import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { useLangFile } from 'js/context/LanguageContext';
import { Alert } from '@material-ui/lab';

import Styles from './PrescriptionClassificationChangeDialog.module.less';

const PrescriptionClassificationChangeDialog = ({ open, onCancel, newState, onApply }) => {
  const LangFile = useLangFile();

  return (
    <Fragment>
      <Dialog open={Boolean(open)} fullWidth maxWidth={'md'}>
        <DialogTitle>{LangFile.PrescriptionClassificationChangeDialog.title}</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Alert severity="error" classes={{ icon: Styles.Icon }}>
              <p>{LangFile.PrescriptionClassificationChangeDialog.info}</p>
              <p>
                {newState && LangFile.PrescriptionClassificationChangeDialog.infoEnable}
                {!newState && LangFile.PrescriptionClassificationChangeDialog.infoDisable}
              </p>
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} width={'100%'}>
            <Button onClick={onApply} color={'secondary'} variant={'outlined'}>
              {newState && LangFile.PrescriptionClassificationChangeDialog.enableAndReset}
              {!newState && LangFile.PrescriptionClassificationChangeDialog.disableAndReset}
            </Button>

            <Box px={1.5} />

            <Button
              onClick={onCancel}
              color={'primary'}
              variant={'contained'}
              style={{ padding: '0 24px' }}
            >
              {LangFile.PrescriptionClassificationChangeDialog.cancel}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(PrescriptionClassificationChangeDialog);

PrescriptionClassificationChangeDialog.propTypes = {
  open: PropTypes.bool,
  newState: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};
