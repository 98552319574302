import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Box, Container } from '@material-ui/core';

const SummaryTable = ({ rows }) => {
  const rootStyle = {
    marginLeft: 0,
    paddingLeft: 0,
  };

  return (
    <Container maxWidth={'xs'} style={rootStyle}>
      <Box my={2} display={'flex'} justifyContent={'flex-start'} flexDirection={'column'}>
        {rows.map((row, idx) => (
          <Box key={idx} display={'flex'} justifyContent={'space-between'} mt={1}>
            <Box>{row.left}</Box>
            <Box>{row.right}</Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

SummaryTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      left: PropTypes.any,
      right: PropTypes.any,
    })
  ),
};

SummaryTable.defaultProps = {};

export default memo(SummaryTable);
