'use strict';

import React, { memo, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ViewModeConstants from 'js/constants/ViewModeConstants';

import SettingsContainer from 'js/components/Settings/SettingsContainer';

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';

import Tooltip from '@material-ui/core/Tooltip';
import { useLangFile } from 'js/context/LanguageContext';
import LanguageSelectorContainer from '../LanguageSelector/LanguageSelectorContainer';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, HelpRounded } from '@material-ui/icons';
import Logo from 'js/components/UI-Elements/Logo';
import SeasonSelector from 'js/components/Seasons/SeasonSelector';
import Box from '@material-ui/core/Box';
import useBreakpoint from '../../hooks/useBreakpoint';

const styles = (theme) => ({
  root: {
    '& > *': {
      userSelect: 'none',
      pointerEvents: 'all',
    },
    pointerEvents: 'all',
    position: 'relative',
    zIndex: 11,
  },
});

const TopBar = ({
  classes,
  viewMode,
  changeFarm,
  goToPreviousViewMode,
  onChangeSeason,
  onAddNewSeason,
  onEditSeason,
  handleRestoreSeasonClick,
}) => {
  const LangFile = useLangFile();

  let breakpoint = useBreakpoint();
  let narrow = breakpoint === 'sm' || breakpoint === 'xs';

  const openVideoPage = useCallback(() => {
    let url = `https://danishagro.dk/cropline/support`;
    window.open(url, '_blank');
  }, []);

  return (
    <div className={classes.root}>
      <AppBar color={'default'} position="static">
        <Toolbar variant="dense" disableGutters>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Box width={1 / 3} display={'flex'} alignItems={'center'} pl={2}>
              {viewMode === ViewModeConstants.ANALYSIS && (
                <Tooltip title={LangFile.TopBar.goToPreviousViewMode}>
                  <IconButton color={'inherit'} onClick={goToPreviousViewMode}>
                    <ArrowBack />
                  </IconButton>
                </Tooltip>
              )}

              {viewMode === ViewModeConstants.OVERVIEW && (
                <Box pl={1}>
                  <SeasonSelector
                    color={'white'}
                    handleRestoreSeasonClick={handleRestoreSeasonClick}
                    onChange={onChangeSeason}
                    onAddNew={onAddNewSeason}
                    onEdit={onEditSeason}
                  />
                </Box>
              )}
            </Box>

            <Box width={1 / 3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Logo variant={narrow ? 'short' : 'full'} size={24} />
            </Box>

            <Box width={1 / 3} display={'flex'} justifyContent={'flex-end'}>
              <LanguageSelectorContainer size={'medium'} />

              <IconButton onClick={openVideoPage}>
                <HelpRounded color={'primary'} />
              </IconButton>

              <SettingsContainer changeFarm={changeFarm} LangFile={LangFile} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

TopBar.propTypes = {
  viewMode: PropTypes.string,
  previousViewMode: PropTypes.string,
  goToPreviousViewMode: PropTypes.func,
  handleRestoreSeasonClick: PropTypes.func,
};

export default memo(withStyles(styles)(TopBar));
