import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import {
  REPLACE_TYPE,
  replaceWeatherStation,
  setReplaceType,
  setShowReplaceDialog,
  resetRequestState,
} from 'js/reducers/ReplaceStationReducer';
import { claimDevice, fetchWeatherStations } from 'js/reducers/WeatherReducer';

import ReplaceStationDialog from 'js/components/ReplaceStation/Replace/ReplaceStationDialog';
import ReplaceStationStepsDialog from 'js/components/ReplaceStation/Replace/ReplaceStationStepsDialog';
import { useMeasureSettings } from '../../../context/AppSettings/AppSettingsContext';
import { ERRORS } from '../../../constants/ReplaceStationConstants';

const mapStateToProps = (store) => {
  return {
    replaceType: store.replaceStation.replaceType,
    error: store.replaceStation.error,
    success: store.replaceStation.success,
    loading: store.replaceStation.loading,
    showReplaceDialog: store.replaceStation.showReplaceDialog,
    showSuspendDialog: store.replaceStation.showSuspendDialog,
    farm: store.farm.farm,
    station: store.weather.selectedStation,
    stations: store.weather.stations,
    accumulation: store.weather.accumulation,
  };
};

const ReplaceStationStepsDialogContainer = ({
  dispatch,
  replaceType,
  farm,
  station,
  stations,
  accumulation,
  showReplaceDialog,
  loading,
  error,
  success,
}) => {
  const measureSettings = useMeasureSettings();
  const [replaceError, setReplaceError] = useState(false);

  useEffect(() => {
    if (showReplaceDialog) {
      if (stations.length === 1) {
        handleReplaceNoClicked();
      }
    }
  }, [showReplaceDialog]);

  const handleReplaceCancelClicked = useCallback(() => {
    dispatch(setReplaceType(null));
    dispatch(resetRequestState());
  }, []);

  // Replace Dialog Buttons
  const handleReplaceYesClicked = useCallback(() => {
    dispatch(setShowReplaceDialog(false));
    dispatch(setReplaceType(REPLACE_TYPE.PICK_WEATHER_STATION));
  }, []);

  const handleReplaceNoClicked = useCallback(() => {
    dispatch(setShowReplaceDialog(false));
    dispatch(setReplaceType(REPLACE_TYPE.NEW_WEATHER_STATION));
  }, []);

  // Closing Dialogs
  const handleReplaceStationClosed = useCallback(() => dispatch(setShowReplaceDialog(false)), []);

  const handleReplace = useCallback(
    async (replacementLabel, dismountDate, mountDate) => {
      // check if we've already claimed the station (user mistake)
      let replacementStation = stations.find((s) => s.label === replacementLabel);

      if (replaceType === REPLACE_TYPE.NEW_WEATHER_STATION && !replacementStation) {
        // claim the station first to get a replacementId
        dispatch(claimDevice(farm.farmId, replacementLabel, mountDate))
          .then((response) => {
            if (response.deviceId && response.claimedAt) {
              // we have the claim on the replacement station, execute the replacement
              dispatch(
                replaceWeatherStation(
                  station.id,
                  farm.farmId,
                  response.deviceId,
                  dismountDate,
                  response.claimedAt
                )
              )
                .then((response) => {
                  // get a new list of weather stations with updates statuses
                  dispatch(fetchWeatherStations(farm.farmId, accumulation, measureSettings));
                })
                .catch((error) => {
                  setReplaceError(ERRORS.UNEXPECTED);
                });
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              setReplaceError(ERRORS.CLAIMING_UNKNOWN_DEVICE);
            } else {
              setReplaceError(ERRORS.UNEXPECTED);
            }
          });
      } else {
        if (replacementStation) {
          // we have the claim on the replacement station, execute the replacement
          dispatch(
            replaceWeatherStation(
              station.id,
              farm.farmId,
              replacementStation.id,
              dismountDate,
              mountDate
            )
          )
            .then((response) => {
              // get a new list of weather stations with updates statuses
              dispatch(fetchWeatherStations(farm.farmId, accumulation, measureSettings));
            })
            .catch((error) => {
              console.error(error);
              setReplaceError(ERRORS.UNEXPECTED);
            });
        }
      }
    },
    [farm, station, stations, replaceType, accumulation, measureSettings]
  );

  // Replacement finished
  const handleReplacementFinished = useCallback(() => {
    handleReplaceCancelClicked();
    dispatch(fetchWeatherStations(accumulation, measureSettings));
  }, [farm, accumulation, measureSettings]);

  const handleResetError = useCallback((errorToReset) => {
    if (errorToReset === ERRORS.CLAIMING_UNKNOWN_DEVICE) {
      setReplaceError(null);
    }
  }, []);

  if (!station) {
    return null;
  }

  return (
    <Fragment>
      <ReplaceStationDialog
        shown={showReplaceDialog}
        onClose={handleReplaceStationClosed}
        onNo={handleReplaceNoClicked}
        onYes={handleReplaceYesClicked}
      />

      {replaceType && (
        <ReplaceStationStepsDialog
          station={station}
          stations={stations}
          onResetError={handleResetError}
          replaceType={replaceType}
          loading={loading}
          error={error || replaceError}
          success={success}
          onReplace={handleReplace}
          onCancel={handleReplaceCancelClicked}
          onFinish={handleReplacementFinished}
        />
      )}
    </Fragment>
  );
};

export default memo(connect(mapStateToProps)(ReplaceStationStepsDialogContainer));
