import React, { Fragment, memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import blue from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import {
  PRESCRIPTION_INTERVAL_COLORS,
  PRESCRIPTION_UNIT,
} from 'js/constants/PrescriptionConstants';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import Box from '@material-ui/core/Box';
import NumberUtils from 'js/helpers/NumberUtils';
import useEvent from 'js/hooks/useEvent';
const tinycolor = require('tinycolor2');

import style from './IntervalVisualizer.module.less';
import { grey } from '@material-ui/core/colors';
import PercentageIncrementer from '../PercentageIncrementer/PercentageIncrementer';
import { SQUARE_METER_COVERSION_VALUE } from '../PrescriptionUtils';

const canSplitInterval = (interval) => {
  return interval.max - interval.min > 0.05;
};

const IntervalVisualizer = ({
  onClick,
  wrapperRect,
  mergingIntervals,
  splittingIntervals,
  mergePrimaryIndex,
  mergeSecondaryIndex,
  splittingIndex,
  onIncrementInterval,
  onDecrementInterval,
  stepSize,
}) => {
  let { prescriptionJob } = usePrescriptionJob();
  let { intervals, maxPrescription, fieldSize } = prescriptionJob;

  const [highlightPrimaryBar, setHighlightPrimaryBar] = useState(null);
  const [highlightSecondaryIndex, setHighlightSecondaryIndex] = useState(null);
  const [mouse, setMouse] = useState(null);

  useEvent(
    'mousemove',
    useCallback(
      (event) => {
        if (mergingIntervals || splittingIntervals) {
          setMouse({ x: event.clientX, y: event.clientY });
        }
      },
      [mergingIntervals, splittingIntervals]
    )
  );

  useEffect(() => {
    // If we don't have a primary target or we are splitting intervals, then don't highlight a secondary target
    if (!highlightPrimaryBar || splittingIntervals || !wrapperRect || !mouse) {
      return;
    }

    let { xPos, width, index } = highlightPrimaryBar;

    let left = wrapperRect.left || 0;
    let x = mouse.x - Math.max(left, 0);

    let middle = xPos + width / 2;

    let previous = index - 1;
    let next = index + 1;
    let hasPrevious = previous >= 0;
    let hasNext = next < intervals.length;

    let secondaryIndex = 0;

    if (x <= middle) {
      if (hasPrevious) {
        secondaryIndex = previous;
      } else {
        secondaryIndex = next;
      }
    } else {
      if (hasNext) {
        secondaryIndex = next;
      } else {
        secondaryIndex = previous;
      }
    }

    setHighlightSecondaryIndex(secondaryIndex);
  }, [mouse, highlightPrimaryBar, splittingIntervals, intervals, wrapperRect]);

  useEffect(() => {
    if (
      (!mergingIntervals && !splittingIntervals) ||
      !mergePrimaryIndex ||
      !splittingIndex ||
      intervals.length === 1
    ) {
      setHighlightPrimaryBar(null);
      setHighlightSecondaryIndex(null);
    }
  }, [mergingIntervals, splittingIntervals, mergePrimaryIndex, splittingIndex, intervals]);

  const handleMouseEnter = useCallback(
    (event, bar) => {
      let interval = intervals[bar.index];

      // Restrictions when merging intervals
      if (mergingIntervals) {
        if (intervals.length === 1) {
          setHighlightPrimaryBar(null);
          setHighlightSecondaryIndex(null);
          return;
        }
      }

      // Restrictions when splitting intervals
      if (splittingIntervals) {
        // Only allow splitting of bars that will not become too small to handle in the editor
        if (!canSplitInterval(interval)) {
          setHighlightPrimaryBar(null);
          setHighlightSecondaryIndex(null);
          return;
        }
      }

      if (mergingIntervals || splittingIntervals) {
        setHighlightPrimaryBar(bar);
        setHighlightSecondaryIndex(null);
      }
    },
    [intervals, mergingIntervals, splittingIntervals]
  );

  const handleMouseLeave = useCallback(() => {
    setHighlightPrimaryBar(null);
    setHighlightSecondaryIndex(null);
  });

  const handleMouseUp = useCallback(
    (event) => {
      event.persist();

      let target = event.currentTarget;

      if (splittingIntervals) {
        if (highlightPrimaryBar) {
          onClick(target, highlightPrimaryBar.index);
        }
      }

      if (mergingIntervals) {
        if (highlightPrimaryBar && highlightSecondaryIndex) {
          onClick(target, highlightPrimaryBar.index, highlightSecondaryIndex);
        }
      }
    },
    [splittingIntervals, highlightPrimaryBar, onClick, mergingIntervals, highlightSecondaryIndex]
  );

  const getIntervalProperties = useCallback(
    (interval) => {
      let centerVal = maxPrescription / fieldSize;
      if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
        centerVal =
          prescriptionJob.maxPrescription /
          (prescriptionJob.fieldSize * SQUARE_METER_COVERSION_VALUE);
      }

      let valueMax = centerVal * 2;
      let value = interval.prescription;

      let intervalsMin = prescriptionJob.intervals[0].min;
      let intervalsMax = prescriptionJob.intervals[prescriptionJob.intervals.length - 1].max;
      let intervalsTotalWidth = intervalsMax - intervalsMin;
      let intervalWidth = interval.max - interval.min;

      // Size and positioning
      let height = NumberUtils.limitMap(value, 0, valueMax, 0, 100);
      let width = NumberUtils.limitMap(intervalWidth, 0, intervalsTotalWidth, 0, 100);
      let left = NumberUtils.limitMap(interval.min, intervalsMin, intervalsMax, 0, 100);

      return {
        value: value,
        width: width,
        height: height,
        left: left,
        bottom: 0,
      };
    },
    [prescriptionJob]
  );

  const getRectStyles = useCallback(
    (idx, interval) => {
      let color = tinycolor(PRESCRIPTION_INTERVAL_COLORS[idx]);
      let error = !canSplitInterval(interval) && splittingIntervals;

      let bgColor = color.clone();
      bgColor.setAlpha(0.1);

      let fgColor = color.clone();

      let backgroundStyle = {
        backgroundColor: bgColor,
        pointerEvents: 'all',
      };

      let foregroundStyle = {
        backgroundColor: fgColor,
        pointerEvents: 'none',
      };

      // If this bar is highlighted as the primary target for merge or split
      let isTargetingPrimary = highlightPrimaryBar && highlightPrimaryBar.index === idx;
      let isMergingPrimary = mergePrimaryIndex === idx;
      let isSplitting = splittingIntervals && splittingIndex === idx;

      let isPrimary = isTargetingPrimary || isMergingPrimary || isSplitting;

      if (splittingIntervals || mergingIntervals) {
        backgroundStyle.backgroundColor = 'none';

        if (error) {
          bgColor = tinycolor(red['200']);
          bgColor.setAlpha(0.1);

          fgColor = tinycolor(grey['200']);

          backgroundStyle.backgroundColor = bgColor;
          foregroundStyle.backgroundColor = fgColor;
        }
      }

      if (isPrimary && !error) {
        bgColor = tinycolor(blue['A100']);
        bgColor.setAlpha(0.7);

        backgroundStyle.backgroundColor = bgColor;
        backgroundStyle.cursor = 'pointer';
      }

      // If this bar is the secondary target for merge or split
      let isTargetingSecondary = highlightPrimaryBar && highlightSecondaryIndex === idx;
      let isMergingSecondary = mergeSecondaryIndex === idx;
      let isSecondary = isTargetingSecondary || isMergingSecondary;

      if (isSecondary && !error) {
        bgColor = tinycolor(blue['A100']);
        bgColor.setAlpha(0.7);

        backgroundStyle.backgroundColor = bgColor;
      }

      return {
        backgroundStyle: backgroundStyle,
        foregroundStyle: foregroundStyle,
        isPrimary: isPrimary,
        isSecondary: isSecondary,
        error: error,
      };
    },
    [
      highlightPrimaryBar,
      highlightSecondaryIndex,
      mergePrimaryIndex,
      mergeSecondaryIndex,
      splittingIntervals,
      splittingIndex,
      mergingIntervals,
    ]
  );

  const renderRectangles = useCallback(
    (idx, properties, styles) => {
      let { width, height, left, bottom } = properties;

      // Get styling properties for the two rectangles
      let { backgroundStyle, foregroundStyle, isPrimary, isSecondary } = styles;

      let interval = intervals[idx];

      // Group properties required by the mouse-listeners
      let bar = {
        index: idx,
        interval: interval,
        left: left,
        bottom: bottom,
        width: width,
        height: height,
      };

      let backgroundRect = (
        <Box
          key={idx + ' background'}
          onMouseEnter={(event) => handleMouseEnter(event, bar)}
          onMouseLeave={() => handleMouseLeave()}
          onMouseUp={(event) => handleMouseUp(event)}
          position={'absolute'}
          left={`${left}%`}
          bottom={`0%`}
          width={`${width}%`}
          height={`100%`}
          style={backgroundStyle}
        >
          <PercentageIncrementer
            idx={idx}
            interval={interval}
            stepSize={stepSize}
            onIncrementInterval={onIncrementInterval}
            onDecrementInterval={onDecrementInterval}
          />
        </Box>
      );

      let foregroundRect = (
        <Box
          key={idx}
          position={'absolute'}
          left={`${left}%`}
          bottom={`${bottom}%`}
          width={`${width}%`}
          height={`${height}%`}
          style={foregroundStyle}
        />
      );

      // Evaluate ordering of the rectangles
      if (isPrimary || isSecondary) {
        return [foregroundRect, backgroundRect];
      } else {
        return [backgroundRect, foregroundRect];
      }
    },
    [
      intervals,
      handleMouseEnter,
      handleMouseLeave,
      handleMouseUp,
      onIncrementInterval,
      onDecrementInterval,
      stepSize,
    ]
  );

  return intervals.map((interval, idx) => {
    let styles = getRectStyles(idx, interval);
    let properties = getIntervalProperties(interval);

    let isSplitting =
      (splittingIntervals && highlightPrimaryBar && highlightPrimaryBar.index === idx) ||
      splittingIndex === idx;
    let splitLeft = properties.left + properties.width / 2;
    return (
      <Fragment key={`interval-visualizer-fragment-${idx}`}>
        {renderRectangles(idx, properties, styles)}

        {isSplitting && (
          <Box bottom={0} top={0} left={`${splitLeft}%`} className={style.SplitLine} />
        )}
      </Fragment>
    );
  });
};

IntervalVisualizer.propTypes = {
  onClick: PropTypes.func,
  onIncrementInterval: PropTypes.func,
  onDecrementInterval: PropTypes.func,
  mergePrimaryIndex: PropTypes.number,
  mergeSecondaryIndex: PropTypes.number,
  splittingIndex: PropTypes.number,
  stepSize: PropTypes.number,
  mergingIntervals: PropTypes.bool,
  splittingIntervals: PropTypes.bool,
  wrapperRect: PropTypes.any,
};

export default memo(IntervalVisualizer);
