import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { WeatherSensor } from '../../constants/WeatherConstants';
import WindInfoSettings from './WindInfoSettings/WindInfoSettings';
import { useLangFile } from '../../context/LanguageContext';
import { blueGrey, grey } from '@material-ui/core/colors';
import { getCurrentSensorReading, getCurrentWindDirection } from 'js/helpers/WeatherUtils';
import {
  getWeatherSensorUnit,
  useMeasureSettings,
} from '../../context/AppSettings/AppSettingsContext';
import { withStyles } from '@material-ui/core/styles';
import WindIcon from '../Icons/Weather/WindIcon';

const styles = (theme) => ({
  root: {
    color: blueGrey['800'],
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    overflow: 'visible',
    position: 'relative',
    border: `${grey[200]} 3px solid`,
    boxSizing: 'border-box',
    '&:hover:not(.selected)': {
      background: grey['200'],
      borderColor: grey['400'],
    },
    '& *': {
      userSelect: 'none',
    },
  },

  icon: {
    '& svg': {
      fontSize: 48,
    },
  },

  settings: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  settingsButton: {
    background: 'white',
    '&:hover': {
      backgroundColor: grey['200'],
    },
  },

  value: {
    fontSize: 28,
    minHeight: 28,
    padding: [[theme.spacing(1), 0]],
  },

  label: {
    fontSize: 14,
  },

  '@media (max-width: 1600px), (max-height: 800px)': {
    icon: {
      '& svg': {
        fontSize: 28,
      },
    },

    value: {
      fontSize: 13,
      minHeight: 18,
      padding: [[theme.spacing(1) / 2, 0]],
    },

    label: {
      fontSize: 11,
    },
  },
});

const WindWeatherInfoBox = (props) => {
  let {
    classes,
    station,
    selected,
    color,
    onChangeType,
    enableWindGust,
    onEnableWindGustChanged,
    enableSprayConditions,
    onEnableSprayConditionsChanged,
  } = props;

  const LangFile = useLangFile();
  const measureSettings = useMeasureSettings();
  const current = station.current;

  let content = undefined;
  let bottomContent = undefined;

  if (current) {
    const wind = getCurrentSensorReading(station, WeatherSensor.WIND) || Number.NaN;
    const gust = getCurrentSensorReading(station, WeatherSensor.WIND_MAX) || Number.NaN;
    if (enableWindGust && !Number.isNaN(gust)) {
      content = (
        <Box display={'flex'} flexDirection={'row'}>
          <Box>{Number(wind).toFixed(1)}</Box>
          <Box pl={1} color={blueGrey['200']}>
            {Number(gust).toFixed(1)}
          </Box>
        </Box>
      );
    } else if (!Number.isNaN(wind)) {
      content = Number(wind).toFixed(1);
    }

    const windDirection =
      getCurrentSensorReading(station, WeatherSensor.WIND_DIRECTION) || Number.NaN;
    if (windDirection) {
      bottomContent = getCurrentWindDirection(windDirection, LangFile);
    }
  }
  return (
    <Box
      width={1}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      className={`${classes.root} ${selected ? 'selected' : ''}`}
      onClick={() => {
        onChangeType(WeatherSensor.WIND);
      }}
      style={{ borderColor: selected ? color : '' }}
    >
      <div className={classes.icon}>{<WindIcon />}</div>

      {selected && (
        <WindInfoSettings
          enableWindgusts={enableWindGust}
          onEnableWindgustsChanged={onEnableWindGustChanged}
          enableSprayConditions={enableSprayConditions}
          onEnableSprayConditionsChanged={onEnableSprayConditionsChanged}
        />
      )}

      <div>
        {<div className={classes.value}>{content}</div>}
        {
          <div className={classes.label}>
            {getWeatherSensorUnit(measureSettings, WeatherSensor.WIND)}
          </div>
        }
        <Box className={classes.label} mt={1}>
          {bottomContent}
        </Box>
      </div>
    </Box>
  );
};

WindWeatherInfoBox.propTypes = {
  /** The specific station to show the current wind conditions for*/
  station: PropTypes.shape({
    /** It is required that the station has a current property, which is the object containing the current readings*/
    current: PropTypes.object,
  }),
  onChangeType: PropTypes.func,
  onEnableWindGustChanged: PropTypes.func,
  onEnableSprayConditionsChanged: PropTypes.func,
  enableWindGust: PropTypes.bool,
  enableSprayConditions: PropTypes.bool,
  selected: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default memo(withStyles(styles)(WindWeatherInfoBox));
