import React, { Fragment, memo, useCallback, useState } from 'react';

import { RAIN_ACCUMULATION, WEATHER_DRAWER_HEIGHT } from 'js/constants/WeatherConstants';

import BottomDrawer from 'js/components/BottomDrawer/BottomDrawer';
import CurrentWeather from 'js/components/WeatherDrawer/CurrentWeather';
import WeatherStationSelector from 'js/components/WeatherDrawer/WeatherStationSelector/WeatherStationSelector';

import { Box, CircularProgress, withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { blue } from '@material-ui/core/colors';
import WeatherHistory from 'js/components/WeatherDrawer/WeatherHistory';
import WeatherMarkers from 'js/components/WeatherDrawer/WeatherMarkers';
import PropTypes from 'prop-types';
import { useLangFileRef, useLanguage } from 'js/context/LanguageContext';
import { getDeviceLabel } from 'js/helpers/StateInterpreters';
import { bulkDownloadExcel, weatherDataToCSV } from '../../helpers/ExportUtils';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import GpsFixContainer from './WeatherStationSelector/GpsError/GpsFixContainer';
import GpsErrorDialog from './WeatherStationSelector/GpsError/GpsErrorDialog';

import { getPresetPeriod } from 'js/helpers/WeatherUtils';
import moment from 'moment';
import { useFarmSettings } from 'js/context/SettingsContext';
import { bulkDownloadCSV } from 'js/helpers/ExportUtils';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { useMeasureSettings } from 'js/context/AppSettings/AppSettingsContext';
import Typography from '@material-ui/core/Typography';
import { useFarm } from '../../context/AccountContext';

const renderErrorState = (classes, LangFile) => {
  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      justify={'center'}
      className={classes.message}
    >
      <div className={classes.messageTitle}>{LangFile.WeatherDrawer.errorTitle}</div>
      <div className={classes.messageDescription}>{LangFile.WeatherDrawer.errorDescription}</div>
      <div className={classes.messageHelp}>
        {LangFile.WeatherDrawer.needHelp} <a href="tel:+4571969776">+45 7196 9776</a>{' '}
        {LangFile.WeatherDrawer.needHelpTime}
      </div>
    </Grid>
  );
};

const renderInactiveState = (classes, LangFile) => {
  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      justify={'center'}
      className={classes.message}
    >
      <div className={classes.messageTitle}>{LangFile.WeatherDrawer.activateTitle}</div>
      <div className={classes.messageDescription}>{LangFile.WeatherDrawer.activateDescription}</div>
      <div className={classes.messageHelp}>
        {LangFile.WeatherDrawer.needHelp} <a href="tel:+4571969776">+45 7196 9776</a>{' '}
        {LangFile.WeatherDrawer.needHelpTime}
      </div>
    </Grid>
  );
};

const styles = (theme) => ({
  message: {
    margin: [[40, 0]],
  },
  messageTitle: {
    fontSize: '20pt',
    fontWeight: 'bold',
  },
  messageDescription: {
    padding: 12,
    fontSize: '14pt',
  },
  messageHelp: {
    padding: 12,
    fontSize: '14pt',
    '& a': {
      color: blue['A400'],
    },
  },
});

const WeatherDrawer = ({
  classes,
  shown,
  dateRangeStart,
  dateRangeEnd,
  error,
  loadingStations,
  loadingReadings,
  stations,
  period,
  selectedStation,
  onSelectPeriodPreset,
  onManageConnections,
  onAccumulationChanged,
  onChangeSensor,
  onChangeDateRange,
  weatherData,
  weatherSensor,
  googleMap,
  displayData,
  onChangeStation,
  onUpdateGlobalFarmSetting,
  onEnableShortcuts,
  onDisableShortcuts,
  accumulation,
  onTickClicked,
  enableWindGust,
  enableSprayConditions,
  onEnableSprayConditionsChanged,
  onEnableWindGustChanged,
}) => {
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFileRef();
  const language = useLanguage();
  const farm = useFarm();
  const farmSettings = useFarmSettings();
  const measureSettings = useMeasureSettings();

  const [fixStationGps, setFixStationGps] = useState(null);
  const [showGpsErrorDialog, setShowGpsErrorDialog] = useState(null);

  const allowDataExport = selectedStation && selectedStation.owner === true;
  // const allowBulkDownload = useHasSetting(FeatureConstants.BULK_DOWNLOAD);
  const allowBulkDownload = true;

  const downloadCSV = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_DOWNLOAD, {
      stationIdentifier: selectedStation.id,
      bulk: allowBulkDownload,
    });

    if (allowBulkDownload) {
      let since = dateRangeStart,
        until = dateRangeEnd;
      if (!dateRangeEnd || !dateRangeStart) {
        let { start, end } = getPresetPeriod(moment(), period);
        since = start;
        until = end;
      }

      bulkDownloadCSV(
        LangFile.current,
        farm.farmId,
        measureSettings,
        selectedStation.id,
        getDeviceLabel(selectedStation.identifier, farmSettings),
        since,
        until
      );
    } else if (weatherData) {
      weatherDataToCSV(measureSettings, weatherData, LangFile.current);
    }
  }, [
    allowBulkDownload,
    measureSettings,
    farm,
    stations,
    selectedStation,
    weatherData,
    dateRangeStart,
    dateRangeEnd,
    farmSettings,
  ]);

  const downloadExcel = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_DOWNLOAD, {
      stationIdentifier: selectedStation.id,
      bulk: allowBulkDownload,
    });

    if (allowBulkDownload) {
      let since = dateRangeStart,
        until = dateRangeEnd;
      if (!dateRangeEnd || !dateRangeStart) {
        let { start, end } = getPresetPeriod(moment(), period);
        since = start;
        until = end;
      }

      bulkDownloadExcel(
        LangFile.current,
        farm.farmId,
        measureSettings,
        selectedStation.id,
        getDeviceLabel(selectedStation.identifier, farmSettings),
        since,
        until
      );
    } else if (weatherData) {
      weatherDataToCSV(measureSettings, weatherData, LangFile.current);
    }
  }, [
    allowBulkDownload,
    measureSettings,
    farm,
    stations,
    selectedStation,
    weatherData,
    dateRangeStart,
    dateRangeEnd,
    farmSettings,
  ]);

  const handleOnCloseFixGps = useCallback(() => {
    setFixStationGps(null);
    setShowGpsErrorDialog(false);
  }, []);

  const onShowGpsErrorDialog = useCallback((station) => {
    setShowGpsErrorDialog(station);
  }, []);

  const onFixGpsError = useCallback((station) => {
    setFixStationGps(station);
  }, []);

  let anyActive = stations.length > 0;
  let content = null;

  if (anyActive) {
    content = (
      <Fragment>
        <Grid container direction={'row'} wrap={'nowrap'}>
          <Grid item xs={2}>
            <WeatherStationSelector
              stations={stations}
              selectedStation={selectedStation}
              selectStation={onChangeStation}
              getDeviceLabel={getDeviceLabel}
              handleShowGpsError={onShowGpsErrorDialog}
              onManageConnections={onManageConnections}
              handleOnFixGpsError={onFixGpsError}
              onUpdateGlobalFarmSetting={onUpdateGlobalFarmSetting}
              language={language}
            />
          </Grid>

          {selectedStation && (
            <Grid item xs={4} container>
              <CurrentWeather
                width={'100%'}
                station={selectedStation}
                changeType={onChangeSensor}
                accumulation={accumulation}
                onAccumulationChanged={onAccumulationChanged}
                enableWindGust={enableWindGust}
                enableSprayConditions={enableSprayConditions}
                onEnableSprayConditionsChanged={onEnableSprayConditionsChanged}
                onEnableWindGustChanged={onEnableWindGustChanged}
                weatherSensor={weatherSensor}
              />
            </Grid>
          )}
          {selectedStation && (
            <Grid item xs={6} container>
              <WeatherHistory
                station={selectedStation}
                weatherSensor={weatherSensor}
                weatherData={weatherData}
                loadingReadings={loadingReadings}
                period={period}
                enableWindGust={enableWindGust}
                enableSprayConditions={enableSprayConditions}
                onTickClicked={onTickClicked}
                onDisableShortcuts={onDisableShortcuts}
                onEnableShortcuts={onEnableShortcuts}
                onSelectPeriodPreset={onSelectPeriodPreset}
                onPeriodChanged={onChangeDateRange}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  } else {
    if (error) {
      content = renderErrorState(classes, LangFile.current);
    } else if (loadingStations) {
      content = (
        <Fragment>
          <Grid
            container
            direction={'column'}
            justify={'center'}
            alignItems={'center'}
            style={{ height: WEATHER_DRAWER_HEIGHT }}
          >
            <CircularProgress variant={'indeterminate'} color={'primary'} size={48} />
          </Grid>
        </Fragment>
      );
    } else {
      content = renderInactiveState(classes, LangFile.current);
    }
  }

  return (
    <Fragment>
      <BottomDrawer
        shown={shown}
        initiallyShown={shown}
        canBeHidden={true}
        actionButtons={
          weatherData &&
          allowDataExport && (
            <Box>
              <Tooltip title={LangFile.current.WeatherDrawer.downloadCSV}>
                <Fab size="small" onClick={downloadCSV}>
                  <Box color={'#448E47'}>
                    <Typography variant={'caption'}>CSV</Typography>
                  </Box>
                </Fab>
              </Tooltip>
              <Tooltip title={LangFile.current.WeatherDrawer.downloadExcel}>
                <Fab size="small" onClick={downloadExcel}>
                  <Box color={'#61B565'}>
                    <Typography variant={'caption'}>Excel</Typography>
                  </Box>
                </Fab>
              </Tooltip>
            </Box>
          )
        }
      >
        {content}
      </BottomDrawer>

      <WeatherMarkers
        shown={shown}
        selectStation={onChangeStation}
        selectedStation={selectedStation}
        googleMap={googleMap}
        stations={stations}
        displayData={displayData}
      />

      {fixStationGps && <GpsFixContainer station={fixStationGps} onClose={handleOnCloseFixGps} />}

      {showGpsErrorDialog && (
        <GpsErrorDialog
          open={Boolean(showGpsErrorDialog)}
          onFix={() => onFixGpsError(showGpsErrorDialog)}
          onClose={() => setShowGpsErrorDialog(null)}
        />
      )}
    </Fragment>
  );
};

WeatherDrawer.propTypes = {
  shown: PropTypes.bool,
  error: PropTypes.bool,
  loadingStations: PropTypes.bool,
  loadingReadings: PropTypes.bool,
  enableWindGust: PropTypes.bool,
  enableSprayConditions: PropTypes.bool,
  period: PropTypes.string,
  accumulation: PropTypes.oneOf(Object.values(RAIN_ACCUMULATION)),
  stations: PropTypes.array,
  selectedStation: PropTypes.object,
  onUpdateGlobalFarmSetting: PropTypes.func,
  weatherData: PropTypes.object,
  googleMap: PropTypes.object,
  displayData: PropTypes.object,
  onChangeStation: PropTypes.func,
  onChangeSensor: PropTypes.func,
  onChangeDateRange: PropTypes.func,
  onSelectPeriodPreset: PropTypes.func,
  onEnableShortcuts: PropTypes.func,
  onDisableShortcuts: PropTypes.func,
  onTickClicked: PropTypes.func,
  onAccumulationChanged: PropTypes.func,
  onManageConnections: PropTypes.func,
  onEnableSprayConditionsChanged: PropTypes.func,
  onEnableWindGustChanged: PropTypes.func,
};

export default memo(withStyles(styles, { withTheme: true })(WeatherDrawer));
